import { Module, GetterTree, MutationTree, ActionTree } from "vuex"
import { DbRead } from "@/database"
import { CraftRecord, SiteKeyUserPermission } from "@/models/models"

interface State {
  craftRecords: CraftRecord[]
  standingScaffoldRecords: CraftRecord[]
  standingScaffoldsListener: () => void
}

const state: State = {
  craftRecords: [],
  standingScaffoldRecords: [],
  standingScaffoldsListener: undefined,
}

const getters: GetterTree<State, any> = {
  getStandingScaffolds: (state): State["standingScaffoldRecords"] => {
    return state.standingScaffoldRecords
  },
}

const mutations: MutationTree<State> = {
  setStandingScaffoldRecords: (
    state,
    payload: State["standingScaffoldRecords"]
  ) => {
    state.standingScaffoldRecords = payload
    localStorage.setItem("standingScaffoldRecords", JSON.stringify(payload))
  },
  setStandingScaffoldsListener: (
    state,
    payload: State["standingScaffoldsListener"]
  ) => {
    state.standingScaffoldsListener = payload
  },
  /** Unsubscribe from the listener before resetting the value. */
  clearStandingScaffoldsListener: (state) => {
    if (typeof state.standingScaffoldsListener === "function") {
      state.standingScaffoldsListener()
    }

    state.standingScaffoldsListener = undefined
  },
}

const actions: ActionTree<State, any> = {
  listenStandingScaffolds: async (
    { commit },
    payload: {
      siteKey: string
      siteKeyUserPermissions: SiteKeyUserPermission
    }
  ): Promise<void> => {
    const callback = (standingScaffolds: CraftRecord[]) => {
      commit("setStandingScaffoldRecords", standingScaffolds)
    }
    commit("clearStandingScaffoldsListener")

    const { siteKey, siteKeyUserPermissions } = payload
    const listener = await DbRead.craftRecords.listenStandingScaffoldRecords(
      siteKey,
      siteKeyUserPermissions,
      callback
    )
    commit("setStandingScaffoldRecords", listener)
  },
  initStandingScaffolds: ({ commit }): void => {
    let storedValue = localStorage.getItem("standingScaffoldRecords")
    if (storedValue != null) {
      storedValue = JSON.parse(storedValue)
      commit("standingScaffoldRecords", storedValue)
    }
  },
  resetData: ({ commit }): void => {
    commit("clearStandingScaffoldsListener")
    commit("standingScaffoldRecords", {})
  },
}

export const craftRecordsModule: Module<State, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
