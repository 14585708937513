import { Module, GetterTree, MutationTree, ActionTree } from "vuex"

// Local
import { DbRead, DbWrite } from "@/database"
import { ReportConfig } from "@/models/report-config"
import { sort } from "@/reports"

interface State {
  reportConfigs: ReportConfig[]
  reportConfigsListener?: () => void
}

const state: State = {
  reportConfigs: [],
  reportConfigsListener: undefined,
}

const getters: GetterTree<State, any> = {
  /**
   * Sort by timestampLastModified descending by default (newest to oldest).
   * @param state
   */
  getAll: (state): State["reportConfigs"] => {
    return sort.timestampLastModified(state.reportConfigs)
  },
  getConfig: (state) => (configId: string) => {
    return state.reportConfigs.find((config) => config.id === configId)
  },
}

const mutations: MutationTree<State> = {
  setReportConfigs: (state, payload: State["reportConfigs"]) => {
    state.reportConfigs = payload
    // Cache in localStorage.
    localStorage.setItem("reportConfigs", JSON.stringify(payload))
  },
  setReportConfigsListener: (
    state,
    payload: State["reportConfigsListener"]
  ) => {
    state.reportConfigsListener = payload
  },

  /** Unsubscribe from the listener before resetting value. */
  clearReportConfigsListener: (state) => {
    if (typeof state.reportConfigsListener === "function") {
      state.reportConfigsListener()
    }
    state.reportConfigsListener = undefined
  },
}

const actions: ActionTree<State, any> = {
  fetchReportConfigs: async (
    { commit },
    payload: {
      siteKey: string
      uid: string
    }
  ): Promise<void> => {
    const callback = (configs: ReportConfig[]) => {
      commit("setReportConfigs", configs)
    }
    commit("clearReportConfigsListener")
    const { siteKey, uid } = payload
    const listener = await DbRead.reports.listenUserConfigs(
      siteKey,
      uid,
      callback
    )
    commit("setReportConfigsListener", listener)
  },

  /** Retrieve from localStorage */
  initReportConfigs: ({ commit }): void => {
    let configs = localStorage.getItem("reportConfigs")
    if (configs != null) {
      configs = JSON.parse(configs)
      commit("setReportConfigs", configs)
    }
  },
  /** Reset module state to initial values */
  resetData: ({ commit }): void => {
    commit("clearReportConfigsListener")
    commit("setReportConfigs", [])
  },

  /**
   * Call database function to delete a report config.
   */
  save: async (
    _,
    payload: { config: ReportConfig; siteKey: string }
  ): Promise<void> => {
    await DbWrite.reports.saveConfig(payload.config, payload.siteKey)
  },

  delete: async (
    _,
    payload: { config: ReportConfig; siteKey: string }
  ): Promise<void> => {
    await DbWrite.reports.deleteConfig(payload.config.id, payload.siteKey)
  },
}

export const reportConfigsModule: Module<State, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
