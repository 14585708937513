
// Components
import NewObjectForm from "@/views/Inventory/NewObjectForm.vue"

// Models
import {
  InventoryObject,
  CraftType,
  InventoryObjectStatus,
} from "@/models/models"

// Libs
import { Util } from "@/helpers"
import { db } from "@/firebase-init"
import moment from "moment"
import sum from "lodash/sum"

const defaultColsVisibility = [
  { key: "title", visible: true },
  { key: "quantityAvailable", visible: true },
  { key: "quantityAwaitingPickup", visible: true },
  { key: "quantityInUse", visible: true },
  { key: "quantityReserved", visible: true },
  { key: "status", visible: true },
  { key: "timestampLastModified", visible: false },
  { key: "lastModifiedBy", visible: false },
]

export default {
  data() {
    return {
      showNewObjectForm: false,
      records: [],
      columns: [],
      sorter: {},
      exportData: [],
      exportLabels: {},
      exportFields: [],
      filteredTableRecords: [],
      exportFilename: `Inventory Objects - ${moment().format(
        "MM-DD-YYYY"
      )}.csv`,
      searchTerm: Util.getStorage("invobjetcs-search-term") || "",
      colsVisibility: Util.getStorage("invobjetcs-list-cols-visibility")
        ? JSON.parse(Util.getStorage("invobjetcs-list-cols-visibility"))
        : defaultColsVisibility,
    }
  },
  components: {
    "new-object-form": NewObjectForm,
  },
  computed: {
    siteKeyUserLocations(): string[] | null {
      return this.$store.state.firetableModule.siteKeyUserLocations
    },
    siteKeyLocations(): string[] | null {
      return this.$store.state.firetableModule.siteKeyLocations
    },
  },
  asyncComputed: {
    async objects(): Promise<Array<any>> {
      const { rootUserData, inventoryObjectList } =
        this.$store.state.firetableModule
      if (!rootUserData || !inventoryObjectList) return []
      const { defaultSiteKey } = rootUserData

      // Additional data
      return Promise.all(
        inventoryObjectList.map(async (object: InventoryObject) => {
          const { lastModifiedBy, craftTypes, timestampLastModified, status } =
            object
          // Get User details
          const userDetails =
            lastModifiedBy !== "server"
              ? await Util.getSiteKeyUserDetail(
                  db,
                  defaultSiteKey,
                  lastModifiedBy
                )
              : { displayName: "Server" }

          return {
            ...object,
            status: InventoryObjectStatus.getStatusString(status),
            craftTypes: craftTypes.map((ct) =>
              CraftType.getCraftTypeString(ct)
            ),
            timestampLastModified: moment(
              timestampLastModified.seconds * 1000
            ).format("MM/DD/YYYY hh:mm A"),
            lastModifiedBy: userDetails
              ? userDetails.displayName
              : "[Unavailable]",
          }
        })
      )
    },
  },
  watch: {
    siteKeyUserLocations(newValue: string | null) {
      if (!newValue) return
      this.startListening()
    },
    objects(records) {
      this.records = this.filterRecords(this.searchTerm, records)
      this.buildColumns()
    },
    searchTerm(newTerm) {
      // filter search on every term change
      this.records = this.filterRecords(newTerm, this.objects)
      Util.setStorage("invobjetcs-search-term", newTerm)
    },
  },
  methods: {
    startListening() {
      this.$store.dispatch("firetableModule/listenSiteKeyLocations")
      this.$store.dispatch("firetableModule/listenInventoryObjectList")
    },
    removeListeners() {
      this.$store.commit("firetableModule/unsubscribeSiteKeyLocationsListener")
      this.$store.commit(
        "firetableModule/unsubscribeInventoryObjectListListener"
      )
      this.$store.commit("firetableModule/removeInventoryObjectList")
    },
    setShowNewObjectForm(value) {
      this.showNewObjectForm = value
    },
    filterRecords(term, records) {
      return records.filter((record) => {
        return term
          .toLowerCase()
          .split(" ")
          .every((v) => record.title.toLowerCase().includes(v))
      })
    },
    buildColumns() {
      const sortedField = Util.getStorage("invobjetcs-list-sorter-field")
      const sortedOrder = Util.getStorage("invobjetcs-list-sorter-order")
      let filters: any = Util.getStorage("invobjetcs-list-filters")
      filters = filters ? JSON.parse(filters) : null
      this.columns = [
        {
          title: "Title",
          dataIndex: "title",
          align: "center",
          width: 110,
          show: this.colsVisibility.find((col) => col.key === "title").visible,
          sorter: (a, b) => a.title.localeCompare(b.title),
          sortOrder: sortedField === "title" && sortedOrder,
        },
        {
          title: "Qty Available",
          dataIndex: "quantityAvailable",
          align: "center",
          width: 110,
          show: this.colsVisibility.find(
            (col) => col.key === "quantityAvailable"
          ).visible,
          sorter: (a, b) => a.quantityAvailable - b.quantityAvailable,
          sortOrder: sortedField === "quantityAvailable" && sortedOrder,
        },
        {
          title: "Qty Awaiting Pickup",
          dataIndex: "quantityAwaitingPickup",
          align: "center",
          width: 110,
          show: this.colsVisibility.find(
            (col) => col.key === "quantityAwaitingPickup"
          ).visible,
          sorter: (a, b) => a.quantityAwaitingPickup - b.quantityAwaitingPickup,
          sortOrder: sortedField === "quantityAwaitingPickup" && sortedOrder,
        },
        {
          title: "Qty In Use",
          dataIndex: "quantityInUse",
          align: "center",
          width: 110,
          show: this.colsVisibility.find((col) => col.key === "quantityInUse")
            .visible,
          sorter: (a, b) => a.quantityInUse - b.quantityInUse,
          sortOrder: sortedField === "quantityInUse" && sortedOrder,
        },
        {
          title: "Qty Reserved",
          dataIndex: "quantityReserved",
          align: "center",
          width: 110,
          show: this.colsVisibility.find(
            (col) => col.key === "quantityReserved"
          ).visible,
          sorter: (a, b) => a.quantityReserved - b.quantityReserved,
          sortOrder: sortedField === "quantityReserved" && sortedOrder,
        },
        {
          title: "Status",
          dataIndex: "status",
          align: "center",
          width: 110,
          show: this.colsVisibility.find((col) => col.key === "status").visible,
          sorter: (a, b) => a.status.localeCompare(b.status),
          sortOrder: sortedField === "status" && sortedOrder,
        },
        {
          title: "Last Modified",
          dataIndex: "timestampLastModified",
          align: "center",
          width: 110,
          show: this.colsVisibility.find(
            (col) => col.key === "timestampLastModified"
          ).visible,
          sorter: (a, b) =>
            a.timestampLastModified.seconds - b.timestampLastModified.seconds,
          sortOrder: sortedField === "timestampLastModified" && sortedOrder,
        },
        {
          title: "Modified By",
          dataIndex: "lastModifiedBy",
          align: "center",
          width: 110,
          show: this.colsVisibility.find((col) => col.key === "lastModifiedBy")
            .visible,
          sorter: (a, b) => a.lastModifiedBy.localeCompare(b.lastModifiedBy),
          sortOrder: sortedField === "lastModifiedBy" && sortedOrder,
        },
      ]
    },
    saveTableFilters(_, filters, sorter, { currentDataSource }) {
      // Update current filtered table records
      this.filteredTableRecords = currentDataSource
      // Sorter
      const { field, order } = sorter
      if (order) {
        this.sorter = { field, order }
        Util.setStorage("invobjetcs-list-sorter-field", field)
        Util.setStorage("invobjetcs-list-sorter-order", order)
      } else {
        this.sorter = {}
        Util.removeStorage("invobjetcs-list-sorter-field")
        Util.removeStorage("invobjetcs-list-sorter-order")
      }

      // Filters
      if (Object.keys(filters).length > 0)
        Util.setStorage("invobjetcs-list-filters", JSON.stringify(filters))
      else Util.removeStorage("invobjetcs-list-filters")

      // Re-build columns
      this.buildColumns()
    },
    showClearFilters() {
      // Saved filters
      const sortedField = Util.getStorage("invobjetcs-list-sorter-field")
      const sortedOrder = Util.getStorage("invobjetcs-list-sorter-order")
      let filters: any = Util.getStorage("invobjetcs-list-filters")
      let colsVisibility = Util.getStorage("invobjetcs-list-cols-visibility")
      filters = filters ? JSON.parse(filters) : null
      colsVisibility = colsVisibility ? JSON.parse(colsVisibility) : null
      if (
        this.searchTerm ||
        sortedOrder ||
        sortedField ||
        filters ||
        colsVisibility
      )
        return true
      return false
    },
    clearFilters() {
      // Set search term
      this.searchTerm = ""
      // Cols visibility
      this.colsVisibility = defaultColsVisibility
      // Storage
      Util.removeStorage("invobjetcs-list-filters")
      Util.removeStorage("invobjetcs-list-sorter-field")
      Util.removeStorage("invobjetcs-list-sorter-order")
      Util.removeStorage("invobjetcs-list-cols-visibility")
      // Re-build columns
      this.buildColumns()
    },
    saveColVisibility(column, visible) {
      const index = this.colsVisibility.findIndex(
        (col) => col.key === column.dataIndex
      )
      this.colsVisibility[index].visible = visible
      // Save it to local storage
      Util.setStorage(
        "invobjetcs-list-cols-visibility",
        JSON.stringify(this.colsVisibility)
      )
    },
    async generateCsv() {
      for (const column of this.columns)
        this.exportLabels[column.dataIndex] = column.title

      const recordsToExport = this.filterRecords(
        this.searchTerm,
        this.filteredTableRecords.length > 0
          ? this.filteredTableRecords
          : this.records
      )
      this.exportFields = Object.keys(this.exportLabels)
      this.exportData = await Promise.all(
        recordsToExport.map((object) => {
          const {
            id,
            title,
            quantityAvailable,
            quantityAwaitingPickup,
            quantityInUse,
            quantityReserved,
            lastModifiedBy,
            timestampLastModified,
            status,
          } = object

          return {
            id,
            title,
            quantityAvailable,
            quantityAwaitingPickup,
            quantityInUse,
            quantityReserved,
            lastModifiedBy,
            timestampLastModified,
            status,
          }
        })
      )
      setTimeout(() => this.$refs.export.generate())
    },
    tableWidth() {
      return sum(this.columns.filter((col) => col.show).map((col) => col.width))
    },
  },
  mounted() {
    if (this.siteKeyUserLocations) this.startListening()
  },
  beforeDestroy(): void {
    this.removeListeners()
  },
}
