
// Libs
import Vue from "vue"
import firebase from "firebase/app"
import "firebase/functions"
import { string } from "../string"

export default Vue.extend({
  data() {
    return {
      loadingLinkSend: false,
      theme: process.env.VUE_APP_THEME,
      logo: process.env.VUE_APP_LOGO,
      slogan: process.env.VUE_APP_DESC,
      email: "",
    }
  },
  computed: {
    loading(): boolean {
      return this.$store.state.firetableModule.isLoading
    },
    formIsValid(): boolean {
      return this.email != ""
    },
  },
  methods: {
    fieldNotEmpty(value: string): string | boolean {
      if (value.length === 0) return "This field is required."
      return true
    },

    async sendPassResetEmail() {
      try {
        this.loadingLinkSend = true
        const sendPasswordResetLink = firebase
          .functions()
          .httpsCallable("sendPasswordResetLink")
        await sendPasswordResetLink({
          email: this.email,
          version: process.env.VUE_APP_THEME,
        })
        this.$store.commit("firetableModule/setSuccess", string.passReset)
        this.$router.push("/login")
      } catch (error) {
        this.$store.commit("firetableModule/setError", error.message)
      } finally {
        this.loadingLinkSend = false
      }
    },
  },
})
