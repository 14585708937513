import * as models from "@/models/models"
import { Tasks } from "@/models/task"
import firebase from "firebase/app"
export const mutations: any = {
  //** data assign functions started here */
  setSuccess(state: { success: string | null }, payload: string | null) {
    state.success = payload
  },
  setError(state: { error: string | null }, payload: string | null) {
    state.error = payload
  },
  setSiteKey(
    state: { siteKey: models.SiteKey | null },
    payload: models.SiteKey | null
  ) {
    state.siteKey = payload
  },
  setSiteKeyListener(
    state: { siteKeyListener: () => void | null },
    payload: () => void | null
  ) {
    state.siteKeyListener = payload
  },
  unsubscribeSiteKeyListener(state: { siteKeyListener: (() => void) | null }) {
    if (state.siteKeyListener == null) {
      return
    }
    state.siteKeyListener()
    state.siteKeyListener = null
  },
  setCurrentUser(
    state: { currentUser: firebase.User },
    payload: firebase.User
  ) {
    state.currentUser = payload
  },
  removeCurrentUser(state: { currentUser: null }) {
    state.currentUser = null
  },
  setRootUserData(
    state: { rootUserData: models.RootUser },
    payload: models.RootUser
  ) {
    state.rootUserData = payload
  },
  removeRootUserData(state: { rootUserData: null }) {
    state.rootUserData = null
  },
  addRootUserDataListener(
    state: { rootUserDataListener: () => void },
    payload: () => void
  ) {
    state.rootUserDataListener = payload
  },
  unsubscribeRootUserDataListener(state: {
    rootUserDataListener: (() => void) | null
  }) {
    if (state.rootUserDataListener == null) {
      return
    }
    state.rootUserDataListener()
    state.rootUserDataListener = null
  },
  setSiteKeyUserData(
    state: { siteKeyUserData: models.SiteKeyUsers },
    payload: models.SiteKeyUsers
  ) {
    state.siteKeyUserData = payload
  },
  removeSiteKeyUserData(state: { siteKeyUserData: null }) {
    state.siteKeyUserData = null
  },
  addSiteKeyUserDataListener(
    state: { siteKeyUserDataListener: () => void },
    payload: () => void
  ) {
    state.siteKeyUserDataListener = payload
  },
  unsubscribeSiteKeyUserDataListener(state: {
    siteKeyUserDataListener: (() => void) | null
  }) {
    if (state.siteKeyUserDataListener == null) {
      return
    }
    state.siteKeyUserDataListener()
    state.siteKeyUserDataListener = null
  },
  setSiteKeyUserPermission(
    state: { siteKeyUserPermissionData: models.SiteKeyUserPermission },
    payload: models.SiteKeyUserPermission
  ) {
    state.siteKeyUserPermissionData = payload
  },
  removeSiteKeyUserPermission(state: { siteKeyUserPermissionData: null }) {
    state.siteKeyUserPermissionData = null
  },
  addSiteKeyUserPermissionListener(
    state: { siteKeyUserPermissionListener: () => void },
    payload: () => void
  ) {
    state.siteKeyUserPermissionListener = payload
  },
  unsubscribeSiteKeyUserPermissionListener(state: {
    siteKeyUserPermissionListener: (() => void) | null
  }) {
    if (state.siteKeyUserPermissionListener == null) {
      return
    }
    state.siteKeyUserPermissionListener()
    state.siteKeyUserPermissionListener = null
  },

  setCraftRecordsList(
    state: { craftRecordsList: models.CraftRecord[] },
    payload: models.CraftRecord[]
  ) {
    state.craftRecordsList = payload
  },
  removeCraftRecordsList(state: { craftRecordsList: null }) {
    state.craftRecordsList = null
  },
  addCraftRecordsListListener(
    state: { craftRecordsListListener: () => void },
    payload: () => void
  ) {
    state.craftRecordsListListener = payload
  },

  setInventoryObjectList(
    state: { inventoryObjectList: models.InventoryObject[] },
    payload: models.InventoryObject[]
  ) {
    state.inventoryObjectList = payload
  },
  removeInventoryObjectList(state: { inventoryObjectList: null }) {
    state.inventoryObjectList = null
  },
  addInventoryObjectListListener(
    state: { inventoryObjectListListener: () => void },
    payload: () => void
  ) {
    state.inventoryObjectListListener = payload
  },
  unsubscribeInventoryObjectListListener(state: {
    inventoryObjectListListener: (() => void) | null
  }) {
    if (!state.inventoryObjectListListener) return
    // Calling the listener unsubscribes from the Firestore query.
    state.inventoryObjectListListener()
    state.inventoryObjectListListener = null
  },

  setInventoryTransactionList(
    state: { inventoryTransactionList: models.InventoryTransaction[] },
    payload: models.InventoryTransaction[]
  ) {
    state.inventoryTransactionList = payload
  },
  removeInventoryTransactionList(state: { inventoryTransactionList: null }) {
    state.inventoryTransactionList = null
  },
  addInventoryTransactionListListener(
    state: { inventoryTransactionListListener: () => void },
    payload: () => void
  ) {
    state.inventoryTransactionListListener = payload
  },
  unsubscribeInventoryTransactionListListener(state: {
    inventoryTransactionListListener: (() => void) | null
  }) {
    if (!state.inventoryTransactionListListener) return
    // Calling the listener unsubscribes from the Firestore query.
    state.inventoryTransactionListListener()
    state.inventoryTransactionListListener = null
  },

  setDeletedCraftRecordsList(
    state: { deletedCraftRecordsList: models.CraftRecord[] },
    payload: models.CraftRecord[]
  ) {
    state.deletedCraftRecordsList = payload
  },
  removeDeletedCraftRecordsList(state: { deletedCraftRecordsList: null }) {
    state.deletedCraftRecordsList = null
  },
  addDeletedCraftRecordsListListener(
    state: { deletedCraftRecordsListListener: () => void },
    payload: () => void
  ) {
    state.deletedCraftRecordsListListener = payload
  },

  // Craft record actions
  setCraftRecord(
    state: { craftRecord: models.CraftRecord },
    payload: models.CraftRecord
  ) {
    state.craftRecord = payload
  },
  removeCraftRecord(state: { craftRecord: null }) {
    state.craftRecord = null
  },
  addCraftRecordListener(
    state: { craftRecordListener: () => void },
    payload: () => void
  ) {
    state.craftRecordListener = payload
  },
  unsubscribeCraftRecordListener(state: {
    craftRecordListener: (() => void) | null
  }) {
    if (!state.craftRecordListener) return
    // Calling the listener unsubscribes from the Firestore query.
    state.craftRecordListener()
    state.craftRecordListener = null
  },

  // Tasks specific to a parent record
  setCraftRecordTaskList(
    state: { craftRecordTaskList: Tasks[] },
    payload: Tasks[]
  ) {
    state.craftRecordTaskList = payload
  },
  removeCraftRecordTaskList(state: { craftRecordTaskList: null }) {
    state.craftRecordTaskList = null
  },
  addCraftRecordTasklistListener(
    state: { craftRecordTasklistListener: () => void },
    payload: () => void
  ) {
    state.craftRecordTasklistListener = payload
  },
  unsubscribeCraftRecordTasklistListener(state: {
    craftRecordTasklistListener: (() => void) | null
  }) {
    if (!state.craftRecordTasklistListener) return
    // Calling the listener unsubscribes from the Firestore query.
    state.craftRecordTasklistListener()
    state.craftRecordTasklistListener = null
  },

  // Tasks specific to a parent record
  setDeletedTaskList(state: { deletedTaskList: Tasks[] }, payload: Tasks[]) {
    state.deletedTaskList = payload
  },
  removeDeletedTaskList(state: { deletedTaskList: null }) {
    state.deletedTaskList = null
  },
  addDeletedTasklistListener(
    state: { deletedTasklistListener: () => void },
    payload: () => void
  ) {
    state.deletedTasklistListener = payload
  },
  unsubscribeDeletedTasklistListener(state: {
    deletedTasklistListener: (() => void) | null
  }) {
    if (!state.deletedTasklistListener) return
    // Calling the listener unsubscribes from the Firestore query.
    state.deletedTasklistListener()
    state.deletedTasklistListener = null
  },

  // Call this mutation when component that needs CraftRecords query is
  // removed or destroyed.
  unsubscribeCraftRecordsListListener(state: {
    craftRecordsListListener: (() => void) | null
  }) {
    if (!state.craftRecordsListListener) return
    // Calling the listener unsubscribes from the Firestore query.
    state.craftRecordsListListener()
    state.craftRecordsListListener = null
  },
  unsubscribeDeletedCraftRecordsListListener(state: {
    deletedCraftRecordsListListener: (() => void) | null
  }) {
    if (state.deletedCraftRecordsListListener) return
    // Calling the listener unsubscribes from the Firestore query.
    state.deletedCraftRecordsListListener()
    state.deletedCraftRecordsListListener = null
  },

  // load site key companies start here
  addSiteKeyCompaniesListener(
    state: { siteKeyCompaniesListener: () => void },
    payload: () => void
  ) {
    state.siteKeyCompaniesListener = payload
  },
  setSiteKeyCompanies(
    state: { siteKeyCompanies: models.SiteKeyCompany[] },
    payload: models.SiteKeyCompany[]
  ) {
    state.siteKeyCompanies = payload
  },

  // Call this mutation when component that needs Location query is
  // removed or destroyed.
  unsubscribeSiteKeyCompaniesListener(state: {
    siteKeyCompaniesListener: (() => void) | null
  }) {
    if (!state.siteKeyCompaniesListener) return
    // Calling the listener unsubscribes from the Firestore query.
    state.siteKeyCompaniesListener()
    state.siteKeyCompaniesListener = null
  },

  // Event loading
  setEventList(state: { eventList: models.Event[] }, payload: models.Event[]) {
    state.eventList = payload
  },

  addPublicEventsListener(
    state: { publicEventsListener: () => void },
    payload: () => void
  ) {
    state.publicEventsListener = payload
  },
  addCompanyEventsListener(
    state: { companyEventsListener: () => void },
    payload: () => void
  ) {
    state.companyEventsListener = payload
  },

  unsubscribePublicEventListener(state: {
    publicEventsListener: (() => void) | null
  }) {
    if (!state.publicEventsListener) return
    state.publicEventsListener()
    state.publicEventsListener = null
  },

  unsubscribeCompanyEventListener(state: {
    companyEventsListener: (() => void) | null
  }) {
    if (!state.companyEventsListener) return
    state.companyEventsListener()
    state.companyEventsListener = null
  },

  // load site key locations start here
  addSiteKeyLocationsListener(
    state: { siteKeyLocationsListener: () => void },
    payload: () => void
  ) {
    state.siteKeyLocationsListener = payload
  },
  setSiteKeyLocations(
    state: { siteKeyLocations: models.Location[] },
    payload: models.Location[]
  ) {
    state.siteKeyLocations = payload
  },

  // Call this mutation when component that needs Location query is
  // removed or destroyed.
  unsubscribeSiteKeyLocationsListener(state: {
    siteKeyLocationsListener: (() => void) | null
  }) {
    if (state.siteKeyLocationsListener == null) {
      return
    }
    // Calling the listener unsubscribes from the Firestore query.
    state.siteKeyLocationsListener()
    state.siteKeyLocationsListener = null
  },
  // siteKey Locations loaded functionality done here

  // load site key user locations start here
  addSiteKeyUserLocationsListener(
    state: { siteKeyUserLocationsListener: () => void },
    payload: () => void
  ) {
    state.siteKeyUserLocationsListener = payload
  },
  setSiteKeyUserLocations(
    state: { siteKeyUserLocations: string[] },
    payload: string[]
  ) {
    state.siteKeyUserLocations = payload
  },

  // Call this mutation when component that needs user Location query is
  // removed or destroyed.
  unsubscribeSiteKeyUserLocationsListener(state: {
    siteKeyUserLocationsListener: (() => void) | null
  }) {
    if (state.siteKeyUserLocationsListener == null) {
      return
    }
    // Calling the listener unsubscribes from the Firestore query.
    state.siteKeyUserLocationsListener()
    state.siteKeyUserLocationsListener = null
  },

  // load site key user locations start here
  addSiteKeyUsersListener(
    state: { siteKeyUsersListener: () => void },
    payload: () => void
  ) {
    state.siteKeyUsersListener = payload
  },
  setSiteKeyUsers(
    state: { siteKeyUsers: models.SiteKeyUsers[] },
    payload: models.SiteKeyUsers[]
  ) {
    state.siteKeyUsers = payload
  },
  unsubscribeSiteKeyUsersListener(state: {
    siteKeyUsersListener: (() => void) | null
  }) {
    if (state.siteKeyUsersListener == null) {
      return
    }
    // Calling the listener unsubscribes from the Firestore query.
    state.siteKeyUsersListener()
    state.siteKeyUsersListener = null
  },

  // siteKey asset loaded functionality done here
  setAssetsList(
    state: { assetsList: models.Asset[] },
    payload: models.Asset[]
  ) {
    state.assetsList = payload
  },
  removeAssetsList(state: { assetsList: null }) {
    state.assetsList = null
  },
  addAssetsListListener(
    state: { assetsListListener: () => void },
    payload: () => void
  ) {
    state.assetsListListener = payload
  },

  // Call this mutation when component that needs CraftRecords query is
  // removed or destroyed.
  unsubscribeAssetsListListener(state: {
    assetsListListener: (() => void) | null
  }) {
    if (state.assetsListListener == null) {
      return
    }
    // Calling the listener unsubscribes from the Firestore query.
    state.assetsListListener()
    state.assetsListListener = null
  },

  // load tasks start here
  addTasklistListener(
    state: { tasklistListener: () => void },
    payload: () => void
  ) {
    state.tasklistListener = payload
  },
  setTaskList(state: { taskList: Tasks[] }, payload: Tasks[]) {
    state.taskList = payload
  },
  removeTaskList(state: { taskList: null }) {
    state.taskList = null
  },
  setScheduledAwaitingTaskListListener(
    state: { scheduledAwaitingTaskListListener: () => void },
    payload: () => void
  ) {
    state.scheduledAwaitingTaskListListener = payload
  },
  setScheduledAwaitingTaskList(
    state: { scheduledAwaitingTaskList: Tasks[] },
    payload: Tasks[]
  ) {
    state.scheduledAwaitingTaskList = payload
  },
  removeScheduledAwaitingTaskList(state: { scheduledAwaitingTaskList: null }) {
    state.scheduledAwaitingTaskList = null
  },

  // It is used for view particular task record
  setTask(state: { task: Tasks }, payload: Tasks) {
    state.task = payload
  },
  // Remove particular task
  removeTask(state: { task: null }) {
    state.task = null
  },
  // Call this mutation when component that needs Tasks query is
  // removed or destroyed.
  unsubscribeTasklistListener(state: {
    tasklistListener: (() => void) | null
  }) {
    if (!state.tasklistListener) return
    // Calling the listener unsubscribes from the Firestore query.
    state.tasklistListener()
    state.tasklistListener = null
  },
  // tasks loaded functionality done here

  startLoading(state: { isLoading: boolean }) {
    state.isLoading = true
  },
  stopLoading(state: { isLoading: boolean }) {
    state.isLoading = false
  },

  // It is used for view particular task record
  setGlobalCraftType(
    state: { globalCraftType: string | number },
    payload: string | number
  ) {
    state.globalCraftType = payload
  },

  // Adding extra items to lists for multi-craft projects
  setMultiCraftRecord(state, payload) {
    state.multiCraftRecord = payload
  },
  setMultiCraftRecordParentRecords(state, payload) {
    state.multiCraftRecordParentRecords = payload
  },
  setMultiCraftRecordTasks(state, payload) {
    state.multiCraftRecordTasks = payload
  },
  setMultiCraftRecordEvents(state, payload) {
    state.multiCraftRecordEvents = payload
  },

  //** data assign function ended here */
}
