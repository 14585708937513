
import Vue from "vue"

// Libs
import { db } from "@/firebase-init"
import { Util } from "@/helpers"
import { string } from "@/string"
import firebase from "firebase/app"
import has from "lodash/has"

// Components
import Gallery from "./Gallery.vue"
import TaskCard from "./Task.vue"
import CRDrawer from "@/components/EditCraftRecord.vue"
import ConfirmDialog from "@/components/ConfirmDialog.vue"

// Models
import {
  Asset,
  AttachmentDocument,
  CraftRecord,
  CraftType,
  Event,
  Location,
  SiteKey,
  SiteKeyCompany,
  SiteKeyUsers,
} from "@/models/models"
import { isEqual } from "lodash"
import omitBy from "lodash/omitBy"
import { DbRead, DbWrite } from "@/database"
import { Tasks } from "@/models/task"
import { mapGetters } from "vuex"
import EventTimeline from "@/components/EventTimeline.vue"
import * as process from "process"

export default Vue.extend({
  name: "CraftRecord",
  data() {
    return {
      theme: process.env.VUE_APP_THEME,
      snackbar: false,
      snackbarText: "",
      snackBarColor: "",
      mainView: "pictures",
      selectedOpenTask: null,
      selectedClosedTask: null,
      editCraftRecord: false,
      loadingTaskList: true,
      companies: Array<SiteKeyCompany>(),
      craftDetailsConfigs: [],
      updatedCraftDetails: {},
      craftDetailsUsers: {},
      createdByUser: SiteKeyUsers,
      location: Location,
      asset: Asset,
      loading: false,
      generatingQRTag: false,
      confirmDialog: {
        show: false,
        title: "",
        message: "",
        cancelText: "",
        okText: "",
      },
      multiCraftEnabled: process.env.VUE_APP_ENABLE_MULTICRAFT === "true",
    }
  },
  mounted() {
    this.loadViewData()
  },
  // Stop listeners/remove saved data upon view destroy
  beforeDestroy(): void {
    this.$store.commit("firetableModule/removeCraftRecord")
    this.$store.commit("firetableModule/removeCraftRecordTaskList")
    this.$store.commit("firetableModule/unsubscribeCraftRecordListener")
    this.$store.commit("firetableModule/unsubscribePublicEventListener")
    this.$store.commit("firetableModule/unsubscribeCompanyEventListener")
    this.$store.commit("firetableModule/unsubscribeCraftRecordTasklistListener")
  },
  asyncComputed: {
    async craftRecord(): Promise<CraftRecord> | null {
      return this.$store.state.firetableModule.craftRecord
    },
    async tasks(): Promise<Tasks[]> {
      const tasks = this.$store.state.firetableModule.craftRecordTaskList
      if (this.craftRecord && tasks) {
        await this.getCompanies()
        this.loadingTaskList = false
        return tasks
      } else {
        return []
      }
    },
  },
  computed: {
    ...mapGetters("aggregatesModule", {
      userDisplayNames: "getUserDisplayNames",
    }),
    ...mapGetters("attachmentsModule", ["getAttachments"]),
    siteKeyData(): SiteKey | null {
      return this.$store.state.firetableModule.siteKey
    },
    defaultSiteKey(): string | null {
      const { rootUserData } = this.$store.state.firetableModule
      return rootUserData ? rootUserData.defaultSiteKey : null
    },
    attachments(): AttachmentDocument[] | null {
      return this.getAttachments
    },
    eventList(): Array<Event> | [] {
      const { eventList } = this.$store.state.firetableModule
      return eventList
        ? eventList
            .filter((e) => e.craftRecordID === this.craftRecord.refPath)
            .sort((a, b) => {
              const aSecs = a.timestampCreated.seconds
              const bSecs = b.timestampCreated.seconds
              return bSecs - aSecs
            })
        : []
    },
    siteKeyUserPermissionData(): any | null {
      return this.$store.state.firetableModule.siteKeyUserPermissionData
    },
    multiCraftRecordID(): string | null {
      const { craftRecord } = this.$store.state.firetableModule
      const multiCraftRecordID = craftRecord?.multiCraftRecordID
      if (typeof multiCraftRecordID === "string") {
        this.$store.dispatch(
          "firetableModule/queryMultiCraftRecord",
          multiCraftRecordID
        )
        return multiCraftRecordID
      }
      return null
    },
    attachmentCount(): number {
      if (this.attachments) {
        return this.attachments.length
      } else {
        return 0
      }
    },
    closedTasks(): Tasks[] {
      if (this.tasks) {
        const closedTasks = this.tasks
          .filter((t) => t.taskStatus === 90)
          .sort((a, b) => {
            if (a.timestampTaskCompleted && b.timestampTaskCompleted) {
              const aSecs = a.timestampTaskCompleted.seconds
              const bSecs = b.timestampTaskCompleted.seconds
              return aSecs - bSecs
            }
            if (a.timestampCreated && b.timestampCreated) {
              const aSecs = a.timestampCreated.seconds
              const bSecs = b.timestampCreated.seconds
              return aSecs - bSecs
            }
          })
        this.highlightCurrentTask(closedTasks, "closed")
        return closedTasks
      } else {
        return []
      }
    },
    openTasks(): Tasks[] {
      if (this.tasks) {
        const openTasks = this.tasks
          .filter((t) => t.taskStatus !== 90)
          .sort((a, b) => {
            if (a.timestampScheduled && b.timestampScheduled) {
              const aSecs = a.timestampScheduled.seconds
              const bSecs = b.timestampScheduled.seconds
              return aSecs - bSecs
            }
            if (a.timestampScheduled) {
              return -1
            }
            if (b.timestampScheduled) {
              return 1
            }
            if (a.timestampCreated && b.timestampCreated) {
              const aSecs = a.timestampCreated.seconds
              const bSecs = b.timestampCreated.seconds
              return aSecs - bSecs
            }
          })
        this.highlightCurrentTask(openTasks, "open")
        return openTasks
      } else {
        return []
      }
    },
    siteClassification(): string | null {
      const siteKey = this.$store.state.firetableModule.siteKey
      return siteKey.customizations.siteClassification
    },
  },
  watch: {
    defaultSiteKey(newValue: string | null) {
      if (newValue) {
        this.loadViewData()
      }
    },
    siteKeyUserPermissionData(newValue: string | null) {
      if (newValue) this.loadViewData()
    },
    siteKeyData(newValue: SiteKey) {
      if (!newValue) return
      this.buildCraftDetails()
    },
    craftRecord(newValue: CraftRecord | null) {
      if (newValue) {
        this.fetchAdditionalData(newValue)
        this.buildCraftDetails()
        this.$store.dispatch("attachmentsModule/listenAttachmentDocuments", {
          siteKey: this.defaultSiteKey,
          siteKeyUserPermissions: this.siteKeyUserPermissionData,
          craftRecordID: newValue.id,
        })
      }
      if (newValue == null) {
        this.$router.push(`/craft-list/${this.craftRecord.craftType ?? "10"}`)
      }
    },
  },
  methods: {
    loadViewData() {
      if (!this.defaultSiteKey || !this.siteKeyUserPermissionData) return
      const { id } = this.$route.params
      const refPath = `siteKeys/${this.defaultSiteKey}/parentRecords/${id}`
      this.$store.commit("firetableModule/startLoading")
      this.$store.dispatch("firetableModule/listenEvents", {
        craftRecordID: refPath,
      })
      this.$store.dispatch("firetableModule/listenCraftRecord", id)
      this.$store.dispatch("firetableModule/listenCraftRecordTaskList", refPath)
      this.$store.commit("firetableModule/stopLoading")
    },

    highlightCurrentTask(tasks, type) {
      // Auto open task
      const { taskID } = this.$route.params
      const index = tasks.findIndex((t) => t.id === taskID)
      if (index === -1) return
      if (type === "closed") {
        this.selectedClosedTask = index
        this.selectedOpenTask = null
      } else {
        this.selectedOpenTask = index
        this.selectedClosedTask = null
      }
    },

    getUserDisplayName(uid: string | null) {
      if (uid == null) {
        return ""
      }
      if (uid in this.userDisplayNames) {
        return this.userDisplayNames[uid]
      }
      return "--"
    },

    async fetchAdditionalData(newValue: CraftRecord | null) {
      this.createdByUser = await this.getUserDetails(newValue.createdBy)
      this.location = await this.getSiteKeyLocation(newValue.locationID)
      this.asset = await this.getAssetDetails(newValue.assetID)
    },

    async getCompanies() {
      const { rootUserData, siteKeyUserPermissionData, craftRecord } =
        this.$store.state.firetableModule
      if (!rootUserData || !siteKeyUserPermissionData || !craftRecord) return
      this.companies = await Util.getSiteKeyCompanies({
        db,
        defaultSiteKey: rootUserData.defaultSiteKey,
        userPermissions: siteKeyUserPermissionData,
      })
      this.companies = this.companies.filter((record: SiteKeyCompany) =>
        record.craftTypes.includes(parseInt(craftRecord.craftType, 10))
      )
    },

    formatDate: (data) => Util.formatDate(data),

    setEditCR(edit) {
      this.editCraftRecord = edit
    },

    async getAssetDetails(assetID: string): Promise<Asset> | null {
      if (assetID == null) {
        return null
      }
      const { rootUserData, siteKeyUserPermissionData, craftRecord } =
        this.$store.state.firetableModule
      if (!rootUserData || !siteKeyUserPermissionData || !craftRecord) return
      return await Util.getAssetDetail(db, rootUserData.defaultSiteKey, assetID)
    },

    async getSiteKeyLocation(locationID: string): Promise<Location> | null {
      if (locationID == null) {
        return null
      }
      const { rootUserData, siteKeyUserPermissionData, craftRecord } =
        this.$store.state.firetableModule
      if (!rootUserData || !siteKeyUserPermissionData || !craftRecord) return
      return await Util.getLocationDetail(
        db,
        rootUserData.defaultSiteKey,
        locationID
      )
    },

    async getUserDetails(uid: string): Promise<SiteKeyUsers> | null {
      if (uid == null) {
        return null
      }
      const { rootUserData, siteKeyUserPermissionData, craftRecord } =
        this.$store.state.firetableModule
      if (!rootUserData || !siteKeyUserPermissionData || !craftRecord) return
      const siteKeyUser = await Util.getSiteKeyUserDetail(
        db,
        rootUserData.defaultSiteKey,
        uid
      )
      if (siteKeyUser.userPhoto_URL == null) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        siteKeyUser.userPhoto_URL = "/image/user-logo.png"
      }
      return siteKeyUser
    },

    getCurrencyString(value: number): string {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      })
      return formatter.format(value)
    },

    async buildCraftDetails() {
      const { craftRecord } = this.$store.state.firetableModule

      if (craftRecord == null) {
        return
      }

      this.craftDetailsConfigs = Util.getReadableDynamicDetailConfigs(
        this.siteKeyData,
        craftRecord,
        null
      )
      for (const i in this.craftDetailsConfigs) {
        const config = this.craftDetailsConfigs[i]
        if (config.key in this.craftRecord?.craftDetails) {
          if (config.type === "uid") {
            const { defaultSiteKey } =
              this.$store.state.firetableModule.rootUserData
            this.craftDetailsUsers[this.craftRecord.craftDetails[config.key]] =
              await Util.getSiteKeyUserDetail(
                db,
                defaultSiteKey,
                this.craftRecord.craftDetails[config.key]
              )
          }
        } else {
          this.craftRecord.craftDetails[config.key] = config.defaultValue
        }
      }
    },

    craftDetailsUpdated(updatedData) {
      const cd = { ...this.craftRecord?.craftDetails }
      this.updatedCraftDetails = omitBy(updatedData, function (val, key) {
        return isEqual(val, cd[key])
      })
    },

    async updateCraftRecord(data) {
      const { rootUserData } = this.$store.state.firetableModule
      if (!rootUserData) return null

      // Find updated fields
      const originalData = this.craftRecord.toMap()
      const updatedData = data.toMap()
      let diffData = omitBy(updatedData, function (val, key) {
        return isEqual(val, originalData[key])
      })
      if (Object.keys(diffData).length > 0) {
        diffData = {
          ...diffData,
          timestampLastModified:
            firebase.firestore.FieldValue.serverTimestamp(),
          lastModifiedBy: `${rootUserData.id}`,
        }
      }

      this.loading = true

      try {
        //Get all data related to SiteKey
        const craftDetailName = CraftType.getCraftTypeRecordString(
          this.craftRecord.craftType
        )
        // Save craftDetails by calling the cloud function (if applies)
        if (
          has(
            this.siteKeyData,
            `customizations.craftDetails.${craftDetailName}`
          )
        ) {
          await DbWrite.parentRecords.updateCraftDetails(
            this.updatedCraftDetails,
            this.craftRecord.refPath
          )
        }

        // Save Craft Record
        if (Object.keys(diffData).length > 0) {
          await db.doc(this.craftRecord.refPath).update(diffData)
          this.$store.commit("firetableModule/setSuccess", string.craftUpdated)
        }
        this.setEditCR(false)
        this.loading = false
      } catch (error) {
        Util.errorMessage(error.message)
        this.loading = false
      }
    },

    async updateLocation(location) {
      await Util.updateLocation(db, this.craftRecord.refPath, location)
    },

    closeDialog() {
      this.confirmDialog = {
        show: false,
        title: "",
        message: "",
        cancelText: "",
        okText: "",
      }
    },

    confirmDeleteRecord() {
      const open = this.craftRecord.numOpenTasks
      const closed = this.craftRecord.numClosedTasks
      this.confirmDialog = {
        show: true,
        title: "Delete Craft Record?",
        message: `This Craft Record and all of its tasks (${open} open and ${closed} closed) will be deleted. Continue?`,
        cancelText: "Cancel",
        okText: "Delete",
      }
    },

    async generateQrScaffoldTag() {
      try {
        this.generatingQRTag = true
        const url = await DbRead.qrCodes.generateScaffoldTag(
          this.siteKeyData.id,
          this.craftRecord.id,
          process.env.VUE_APP_THEME === "stilt" ? "stilt" : "aimpoint"
        )
        window.open(url, "_blank")
      } catch (error) {
        if (["permission-denied", "PERMISSION_DENIED"].includes(error.code)) {
          this.snackbar = true
          this.snackbarText =
            "You are unable to delete this craft record with your current user permissions."
          this.snackBarColor = "error-dark"
        }
      } finally {
        this.generatingQRTag = false
      }
    },

    async deleteParentRecord() {
      try {
        this.loading = true
        const craftType: number = this.craftRecord.craftType
        await DbWrite.parentRecords.deleteParentRecord(this.craftRecord.refPath)
        this.$store.commit("firetableModule/setSuccess", string.craftDeleted)
        this.$router.push(`/craft-list/${craftType}`)
      } catch (error) {
        if (["permission-denied", "PERMISSION_DENIED"].includes(error.code)) {
          this.snackbar = true
          this.snackbarText =
            "You are unable to delete this craft record with your current user permissions."
          this.snackBarColor = "error-dark"
        }
      } finally {
        this.loading = false
      }
    },
  },
  components: {
    EventTimeline,
    gallery: Gallery,
    "task-card": TaskCard,
    "craft-record-drawer": CRDrawer,
    "confirm-dialog": ConfirmDialog,
  },
})
