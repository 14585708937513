
// Models
import {
  TaskType,
  TaskTypes,
  CraftType,
  SiteKeyCompany,
  TaskStatus,
  Location,
  SiteKey,
} from "@/models/models"
import { Tasks } from "@/models/task"

// Libs
import { Util } from "@/helpers"
import moment from "moment"
import { db } from "@/firebase-init"
import sum from "lodash/sum"
import has from "lodash/has"

const defaultColsVisibility = [
  { key: "id", visible: false },
  { key: "craftTypeIcon", visible: true },
  { key: "title", visible: true },
  { key: "taskStatusString", visible: true },
  { key: "taskType", visible: true },
  { key: "description", visible: false },
  { key: "workOrder", visible: false },
  { key: "costCenter", visible: false },
  { key: "jobID", visible: false },
  { key: "purchaseOrder", visible: false },
  { key: "createdDate", visible: false },
  { key: "scheduledDate", visible: true },
  { key: "started", visible: false },
  { key: "timestampTaskCompleted", visible: false },
  { key: "createdBy", visible: false },
  { key: "locationName", visible: true },
]

export default {
  data() {
    return {
      dateFilter: {},
      searchTerm: "",
      exportData: [],
      exportLabels: {},
      exportFields: [],
      filteredTableRecords: [],
      exportFilename: `Tasks - ${moment().format("MM-DD-YYYY")}.csv`,
      records: [],
      columns: [],
      currentTask: Tasks,
      theme: process.env.VUE_APP_THEME,
    }
  },
  methods: {
    startListening() {
      this.$store.commit("firetableModule/unsubscribeTasklistListener")
      this.$store.commit("firetableModule/removeTaskList")
      this.$store.dispatch(
        "firetableModule/listenTaskList",
        this.$route.params.taskParam
      )
    },
    removeListeners() {
      this.$store.commit("firetableModule/unsubscribeTasklistListener")
      this.$store.commit("firetableModule/removeTaskList")
    },
    selectItem(task: Tasks) {
      if (this.siteClassification === "customers") {
        this.$router.push(
          `/work-record-and-tasks/${task.craftRecordID.split("/").pop()}`
        )
      } else {
        this.$router.push(
          `/craft-record/${task.craftRecordID.split("/").pop()}/${task.id}`
        )
      }
    },
    filterTasks(term, tasks) {
      tasks = tasks.filter((task) => {
        return term
          .toLowerCase()
          .split(" ")
          .every((v) => {
            // Status
            const isCompleted = task.taskStatus === TaskStatus.COMPLETE
            const isNotCompleted = task.taskStatus !== TaskStatus.COMPLETE
            // Sgring matches
            const titleMatch = task.title.toLowerCase().includes(v)
            const woMatch = task.workOrder.toLowerCase().includes(v)
            const costCenterMatch = task.costCenter.toLowerCase().includes(v)
            const descMatch = task.description
              ? task.description.toLowerCase().includes(v)
              : false
            // Date filters
            let inDateRange = true
            if (Object.keys(this.dateFilter).length > 0) {
              const {
                field,
                range: [from, to],
              } = this.dateFilter
              if (!task[field]) return
              const epocFrom = Math.floor(from.startOf("day") / 1000)
              const epocTo = Math.floor(to.endOf("day") / 1000)
              const taskDate = task[field].seconds
              inDateRange = taskDate >= epocFrom && taskDate <= epocTo
            }
            // If "Completed" filter is set, show only Completed
            if (this.$route.params.taskParam === "Completed")
              return (
                (titleMatch || descMatch || woMatch) &&
                isCompleted &&
                inDateRange
              )
            return (
              (titleMatch || descMatch || woMatch) &&
              isNotCompleted &&
              inDateRange
            )
          })
      })
      // Scheduled before today OR nextOpportunity = true
      if (this.$route.params.taskParam === "Backlog")
        tasks = tasks.filter((task: Tasks) => {
          return (
            ((task.timestampScheduled &&
              moment(task.timestampScheduled.seconds * 1000).valueOf() <
                moment().startOf("day").valueOf()) ||
              task.nextOpportunity) &&
            task.taskStatus === TaskStatus.AWAITING
          )
        })
      //Sort by more recent first
      tasks = tasks.sort((a, b) => {
        if (a.timestampCreated & b.timestampCreated) {
          const aSecs = a.timestampCreated.seconds
          const bSecs = b.timestampCreated.seconds
          return bSecs - aSecs
        }
      })
      return tasks
    },
    getTaskStatusClass(taskStatus) {
      return TaskStatus.getTaskStatusClass(taskStatus)
    },
    getTaskStatusIcon(taskStatus) {
      return TaskStatus.getTaskStatusIcon(taskStatus)
    },
    getTaskStatusString(taskStatus) {
      return TaskStatus.getTaskStatusStringSuccinct(taskStatus)
    },
    getCraftTypeString(craftType) {
      return CraftType.getCraftTypeString(craftType)
    },
    getTaskType(taskType) {
      return TaskTypes.getTaskTypeString(taskType)
    },
    getSavedFilters() {
      return window.localStorage.getItem("task-list-filters")
        ? JSON.parse(window.localStorage.getItem("task-list-filters"))
        : {}
    },
    clearFilters() {
      // Saved filters
      const savedFilters = this.getSavedFilters()
      // Set search term
      this.searchTerm = ""
      // Cols visibility
      this.colsVisibility = defaultColsVisibility
      // Remove all filters
      delete savedFilters[this.$route.params.taskParam]
      // Save it to local storage
      window.localStorage.setItem(
        "task-list-filters",
        JSON.stringify(savedFilters)
      )
      // Re-build columns
      this.buildColumns()
    },
    showClearFilters() {
      // Saved filters
      const savedFilters = this.getSavedFilters()
      const taskStatusFilters = savedFilters[this.$route.params.taskParam] || {}
      const { searchTerm, colsVisibility, sorterField, sorterOrder, filters } =
        taskStatusFilters
      if (searchTerm || sorterOrder || sorterField || filters || colsVisibility)
        return true
      return false
    },
    setSavedFilters() {
      // Saved filters
      const savedFilters = this.getSavedFilters()
      const taskStatusFilters = savedFilters[this.$route.params.taskParam] || {}
      // Set search term
      this.searchTerm = taskStatusFilters.searchTerm || ""
      // Cols visibility
      this.colsVisibility =
        taskStatusFilters.colsVisibility || defaultColsVisibility
      // Re-build columns
      this.buildColumns()
    },
    saveColVisibility(column, visible) {
      // Saved filters
      let savedFilters = this.getSavedFilters()
      const taskStatusFilters = savedFilters[this.$route.params.taskParam] || {}
      const { colsVisibility = defaultColsVisibility } = taskStatusFilters

      // Find col visibility
      const index = colsVisibility.findIndex(
        (col) => col.key === column.dataIndex
      )
      colsVisibility[index].visible = visible
      // Set visibility
      savedFilters = {
        ...savedFilters,
        [this.$route.params.taskParam]: {
          ...savedFilters[this.$route.params.taskParam],
          colsVisibility,
        },
      }
      // Save it to local storage
      window.localStorage.setItem(
        "task-list-filters",
        JSON.stringify(savedFilters)
      )
    },
    saveTableFilters(_, filters, sorter, { currentDataSource }) {
      // Update current filtered table records
      this.filteredTableRecords = currentDataSource
      // Saved filters
      let savedFilters = this.getSavedFilters()
      // Sorter
      const { field, order } = sorter
      if (order) {
        savedFilters = {
          ...savedFilters,
          [this.$route.params.taskParam]: {
            ...savedFilters[this.$route.params.taskParam],
            sorterField: field,
            sorterOrder: order,
          },
        }
      } else {
        delete savedFilters[this.$route.params.taskParam]?.sorterField
        delete savedFilters[this.$route.params.taskParam]?.sorterOrder
      }

      if (Object.keys(filters).length > 0) {
        if (!savedFilters[this.$route.params.taskParam])
          savedFilters[this.$route.params.taskParam] = {}
        savedFilters[this.$route.params.taskParam].filters = filters
      } else delete savedFilters[this.$route.params.taskParam]?.filters

      window.localStorage.setItem(
        "task-list-filters",
        JSON.stringify(savedFilters)
      )
      // Re-build columns
      this.buildColumns()
    },
    buildColumns() {
      // Saved filters
      const savedFilters = this.getSavedFilters()
      const sortedField =
        savedFilters[this.$route.params.taskParam]?.sorterField
      const sortedOrder =
        savedFilters[this.$route.params.taskParam]?.sorterOrder
      const filters = savedFilters[this.$route.params.taskParam]?.filters
      const colsVisibility =
        savedFilters[this.$route.params.taskParam]?.colsVisibility ||
        defaultColsVisibility
      const { siteKeyLocations } = this.$store.state.firetableModule
      this.columns = [
        {
          title: "ID",
          dataIndex: "id",
          align: "center",
          show: colsVisibility.find((col) => col.key === "id").visible,
          width: 170,
          sorter: (a, b) => a.id.length - b.id.length,
          sortOrder: sortedField === "id" && sortedOrder,
        },
        {
          title: "Craft Type",
          dataIndex: "craftTypeIcon",
          align: "center",
          show: colsVisibility.find((col) => col.key === "craftTypeIcon")
            .visible,
          width: 100,
          scopedSlots: {
            customRender: "tskIcon",
          },
          sorter: (a, b) => a.craftTypeIcon.length - b.craftTypeIcon.length,
          sortOrder: sortedField === "craftTypeIcon" && sortedOrder,
        },
        {
          title: "Title",
          dataIndex: "title",
          show: colsVisibility.find((col) => col.key === "title").visible,
          width: 350,
          scopedSlots: {
            customRender: "tskTitle",
          },
          sorter: (a, b) => a.title.localeCompare(b.title),
          sortOrder: sortedField === "title" && sortedOrder,
        },
        {
          title: "Current Status",
          dataIndex: "taskStatusString",
          show: colsVisibility.find((col) => col.key === "taskStatusString")
            .visible,
          width: 160,
          scopedSlots: {
            customRender: "tskStatus",
          },
          sorter: (a, b) => a.taskStatus - b.taskStatus,
          sortOrder: sortedField === "taskStatusString" && sortedOrder,
          filteredValue:
            filters && filters.taskStatusString
              ? filters.taskStatusString
              : null,
          filters: TaskStatus.getAllValues().map((status) => ({
            text: TaskStatus.getTaskStatusStringSuccinct(status),
            value: status,
          })),
          onFilter: (value, record) => record.taskStatus === value,
        },
        {
          title: "Task Type",
          dataIndex: "taskType",
          align: "center",
          show: colsVisibility.find((col) => col.key === "taskType").visible,
          width: 150,
          scopedSlots: {
            customRender: "tskType",
          },
          sorter: (a, b) => a.taskType - b.taskType,
          sortOrder: sortedField === "taskType" && sortedOrder,
        },
        {
          title: "Description",
          dataIndex: "description",
          align: "center",
          show: colsVisibility.find((col) => col.key === "description").visible,
          width: 150,
        },
        {
          title: "Work Order",
          dataIndex: "workOrder",
          align: "center",
          show: colsVisibility.find((col) => col.key === "workOrder").visible,
          width: 100,
          sorter: (a, b) => a.workOrder.localeCompare(b.workOrder),
          sortOrder: sortedField === "workOrder" && sortedOrder,
        },
        {
          title: "Cost Center",
          dataIndex: "costCenter",
          align: "center",
          show: colsVisibility.find((col) => col.key === "costCenter").visible,
          width: 100,
          sorter: (a, b) => a.costCenter.localeCompare(b.costCenter),
          sortOrder: sortedField === "costCenter" && sortedOrder,
        },
        {
          title: "Timestamp Created",
          dataIndex: "createdDate",
          align: "center",
          show: colsVisibility.find((col) => col.key === "createdDate").visible,
          width: 150,
          defaultSortOrder: "descend",
          scopedSlots: {
            customRender: "tskCreated",
          },
          sorter: (a, b) =>
            a.timestampCreated.seconds - b.timestampCreated.seconds,
          sortOrder: sortedField === "createdDate" && sortedOrder,
        },
        {
          title: "Timestamp Scheduled",
          dataIndex: "scheduledDate",
          align: "center",
          show: colsVisibility.find((col) => col.key === "scheduledDate")
            .visible,
          width: 150,
          scopedSlots: {
            customRender: "tskScheduled",
          },
          sorter: (a, b) => {
            if (!a.timestampScheduled || !b.timestampScheduled) return -1
            const aSecs = a.timestampScheduled.seconds
            const bSecs = b.timestampScheduled.seconds
            return bSecs - aSecs
          },
          sortOrder: sortedField === "scheduledDate" && sortedOrder,
        },
        {
          title: "Timestamp Task Started",
          dataIndex: "started",
          align: "center",
          show: colsVisibility.find((col) => col.key === "started").visible,
          width: 150,
          scopedSlots: {
            customRender: "tskStarted",
          },
          sorter: (a, b) => {
            if (!a.timestampTaskStarted || !b.timestampTaskStarted) return -1
            const aSecs = a.timestampTaskStarted.seconds
            const bSecs = b.timestampTaskStarted.seconds
            return bSecs - aSecs
          },
          sortOrder: sortedField === "started" && sortedOrder,
        },
        {
          title: "Timestamp Task Completed",
          dataIndex: "timestampTaskCompleted",
          align: "center",
          show: colsVisibility.find(
            (col) => col.key === "timestampTaskCompleted"
          ).visible,
          width: 200,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "tskCompleted",
          },
          sorter: (a, b) => {
            if (!a.timestampTaskCompleted || !b.timestampTaskCompleted)
              return -1
            const aSecs = a.timestampTaskCompleted.seconds
            const bSecs = b.timestampTaskCompleted.seconds
            return bSecs - aSecs
          },
          sortOrder: sortedField === "timestampTaskCompleted" && sortedOrder,
        },
        {
          title: "Created By",
          dataIndex: "createdBy",
          show: colsVisibility.find((col) => col.key === "createdBy").visible,
          align: "center",
          width: 120,
          scopedSlots: {
            customRender: "tskCreatedBy",
          },
          sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
          sortOrder: sortedField === "createdBy" && sortedOrder,
        },
        {
          title: "Location",
          dataIndex: "locationName",
          show: colsVisibility.find((col) => col.key === "locationName")
            .visible,
          align: "center",
          width: 120,
          scopedSlots: {
            customRender: "tskLocation",
          },
          sorter: (a, b) => a.locationName.length - b.locationName.length,
          sortOrder: sortedField === "locationName" && sortedOrder,
          filteredValue:
            filters && filters.locationName ? filters.locationName : null,
          filters: siteKeyLocations.map((loc) => ({
            text: loc.title,
            value: loc.id,
          })),
          onFilter: (value, record) => record.locationID === value,
        },
      ]

      const customColumns = [
        {
          title: "Job ID",
          dataIndex: "jobID",
          align: "center",
          show: colsVisibility.find((col) => col.key === "jobID").visible,
          width: 100,
          sorter: (a, b) => a.jobID.localeCompare(b.jobID),
          sortOrder: sortedField === "jobID" && sortedOrder,
        },
        {
          title: "PO & Release #",
          dataIndex: "purchaseOrder",
          align: "center",
          show: colsVisibility.find((col) => col.key === "purchaseOrder")
            .visible,
          width: 100,
          sorter: (a, b) => a.purchaseOrder.localeCompare(b.purchaseOrder),
          sortOrder: sortedField === "purchaseOrder" && sortedOrder,
        },
      ]

      // Add any custom columns
      const allSiteTaskDetails: string[] = []
      const siteTaskDetails =
        this.siteKeyData?.customizations?.taskSpecificDetails
      for (const craftType in siteTaskDetails) {
        for (const taskType in siteTaskDetails[craftType]) {
          const taskDetailKeys = Object.keys(
            siteTaskDetails[craftType][taskType]
          )
          allSiteTaskDetails.push(...taskDetailKeys)
        }
      }
      const taskDetailsMasterSet = new Set(allSiteTaskDetails)
      customColumns.forEach((c) => {
        if (taskDetailsMasterSet.has(c.dataIndex)) {
          this.columns.push(c)
        }
      })
    },
    async generateCsv() {
      // Get craft details
      this.siteKeyData = await Util.getSiteKey(
        db,
        this.$store.state.firetableModule.rootUserData.defaultSiteKey
      )
      const { taskSpecificDetails } = this.siteKeyData.customizations
      const specificDetails = {}
      for (const craftType in taskSpecificDetails)
        for (const taskType in taskSpecificDetails[craftType])
          for (const dynDetail in taskSpecificDetails[craftType][taskType])
            specificDetails[dynDetail] =
              taskSpecificDetails[craftType][taskType][dynDetail].title

      this.exportLabels = {
        id: "ID",
        title: "Title",
        status: "Current Status",
        craftType: "Craft Type",
        taskType: "Task Type",
        workOrder: "Work Order",
        costCenter: "Cost Center",
        jobID: "Job ID",
        purchaseOrder: "PO & Release #",
        description: "Description",
        created: "Timestamp Created",
        scheduledDate: "Timestamp Scheduled",
        started: "Timestamp Task Started",
        completed: "Timestamp Task Completed",
        createdBy: "Created By",
        location: "Location",
        nextOpportunity: "Next Opportunity",
        urgent: "Urgent",
        assignedCompany: "Assigned Company",
        ...specificDetails,
      }

      const recordsToExport = this.filterTasks(
        this.searchTerm,
        this.filteredTableRecords.length > 0
          ? this.filteredTableRecords
          : this.tasks
      )
      this.exportFields = Object.keys(this.exportLabels)
      this.exportData = await Promise.all(
        recordsToExport.map((task) => {
          const {
            id,
            title,
            taskStatusString: status,
            craftType,
            taskType,
            workOrder,
            costCenter,
            description,
            locationName: location,
            createdDate,
            createdTime,
            scheduledDate,
            started,
            completed,
            createdBy,
            nextOpportunity,
            urgent,
            assignedCompanyID,
          } = task

          // Format some fields
          const { taskSpecificDetails } = task
          const { taskSpecificDetails: defaults } =
            this.siteKeyData.customizations
          for (const key in taskSpecificDetails) {
            const taskTypeString = TaskTypes.getTaskTypeName(taskType)
            const craftTypeString =
              CraftType.getCraftTypeRecordString(craftType)
            let defaultValue = ""
            const defaultValuePath = `${defaults}.${craftTypeString}.${taskTypeString}.${key}.defaultValue`
            if (has(defaults, defaultValuePath))
              defaultValue =
                defaults[craftTypeString][taskTypeString][key].defaultValue ||
                ""
            taskSpecificDetails[key] = taskSpecificDetails[key] || defaultValue
          }
          const csvData = {
            id,
            title,
            status,
            workOrder,
            costCenter,
            craftType: CraftType.getCraftTypeString(craftType),
            taskType: TaskTypes.getTaskTypeString(taskType),
            description,
            created: `${createdDate} ${createdTime}`,
            scheduledDate,
            started,
            completed,
            createdBy,
            location,
            nextOpportunity,
            urgent,
            assignedCompanyID,
            ...taskSpecificDetails,
          }
          for (const i in this.exportFields) {
            if (!(this.exportFields[i] in csvData)) {
              csvData[this.exportFields[i]] = null
            }
          }
          return csvData
        })
      )
      setTimeout(() => this.$refs.export.generate())
    },
  },
  asyncComputed: {
    async siteKeyData() {
      return await Util.getSiteKey(
        db,
        this.$store.state.firetableModule.rootUserData?.defaultSiteKey
      )
    },
    async tasks() {
      const { taskList, siteKeyCompanies, rootUserData } =
        this.$store.state.firetableModule
      if (!taskList || !siteKeyCompanies || !rootUserData) return []

      //Additional fields
      return Promise.all(
        taskList.map(async (task: Tasks) => {
          const {
            assignedCompanyID,
            taskStatus,
            taskType,
            craftType,
            locationID,
            timestampTaskCompleted,
            timestampTaskStarted,
            timestampScheduled,
            timestampCreated,
            nextOpportunity,
            urgent,
            createdBy,
          } = task
          //Company Data
          const { siteKeyCompanies } = this.$store.state.firetableModule
          const companyData = siteKeyCompanies.find(
            (co: SiteKeyCompany) => co.id === assignedCompanyID
          )
          //Location Data
          const { siteKeyLocations } = this.$store.state.firetableModule
          const locData = siteKeyLocations.find((loc: Location) => {
            return locationID === loc.id
          })
          // Get User details
          const { defaultSiteKey } = rootUserData
          const userDetails = await Util.getSiteKeyUserDetail(
            db,
            defaultSiteKey,
            createdBy
          )

          const costCenterMap = {
            concreteFloorRepairs: "M23-002 Concrete Floor Repairs",
            electricalLightingMaintenance:
              "M23-018 Electrical Lighting Maintenance",
            electricalMaintenanceIRScansAndRepairs:
              "M23-015 Electrical Maintenance IR Scans and Repairs",
            fansMaintenance: "M23-001 Fans Maintenance",
            fireProtection: "M23-009 Fire Protection",
            generalConstruction: "M23-003 General Construction",
            generalElectricalMaintenance:
              "M23-005 General Electrical Maintenance",
            generalPlumbingMaintenance: "M23-022 General Plumbing Maintenance",
            generalDeepCleaning: "M23-010 General/Deep Cleaning",
            golfCartsLifts: "M23-012 Golf Carts and Lifts",
            housekeeping: "M23-013 Housekeeping",
            paintingStriping: "M23-020 Painting & Striping",
            pestControlMaintenance: "M23-021 Pest Control Maintenance",
            pressureWashing: "M23-023 Pressure Washing",
            replaceUnitsPastLiftExpectancy:
              "M23-028 Replace Units Past Life Expectancy",
            retentionPondsMaintenance: "M23-024 Retention Ponds Maintenance",
            roofingMaintenance: "M23-025 Roofing Maintenance",
            routineMaintenanceGenerators:
              "M23-011 Routine Maintenance - Generators",
            routineMaintenanceHVAC: "M23-014 Routine Maintenance - HVAC",
            routineMaintenanceLandscaping:
              "M23-016 Routine Maintenance - Landscaping",
            signage: "M23-026 Signage",
            tools: "M23-027 Tools",
          }
          return {
            ...task,
            costCenter:
              costCenterMap[task.taskSpecificDetails["costCenter"]] || "",
            workOrder: task.taskSpecificDetails["workOrder"] || "",
            jobID: task.taskSpecificDetails["jobID"] || "",
            purchaseOrder: task.taskSpecificDetails["purchaseOrder"] || "",
            originalData: task,
            inProgress: taskStatus === TaskStatus.IN_PROGRESS,
            craftTypeIcon: CraftType.getCraftTypeIcons(craftType),
            assignedCompany: companyData ? companyData.name : "",
            locationName: locData ? locData.title : "",
            nextOpportunity,
            urgent,
            createdBy: userDetails ? userDetails.displayName : "[Unavailable]",
            taskStatusString: TaskType.getTaskStatusStringVerbose(
              taskStatus,
              taskType
            ),
            createdDate: moment(timestampCreated.seconds * 1000).format(
              "MM/DD/YYYY"
            ),
            createdTime: moment(timestampCreated.seconds * 1000).format(
              "hh:mm A"
            ),
            completedDate: timestampTaskCompleted
              ? moment(timestampTaskCompleted.seconds * 1000).format(
                  "MM/DD/YYYY"
                )
              : "",
            completedTime: timestampTaskCompleted
              ? moment(timestampTaskCompleted.seconds * 1000).format("hh:mm A")
              : "",
            startedDate: timestampTaskStarted
              ? moment(timestampTaskStarted.seconds * 1000).format("MM/DD/YYYY")
              : "",
            startedTime: timestampTaskStarted
              ? moment(timestampTaskStarted.seconds * 1000).format("hh:mm A")
              : "",
            scheduledDate: timestampScheduled
              ? moment(timestampScheduled.seconds * 1000).format(
                  "MM/DD/YYYY hh:mm A"
                )
              : "",
            timestampScheduled: timestampScheduled
              ? timestampScheduled
              : { seconds: moment("01/01/2050", "MM/DD/YYYY").valueOf() },
          }
        })
      )
    },
  },
  computed: {
    loading(): boolean {
      return this.$store.state.firetableModule.isLoading
    },
    tableWidth() {
      return sum(this.columns.filter((col) => col.show).map((col) => col.width))
    },
    siteKeyUserLocations(): string[] | null {
      return this.$store.state.firetableModule.siteKeyUserLocations
    },
    siteKeyUserPermissionData(): any | null {
      return this.$store.state.firetableModule.siteKeyUserPermissionData
    },
    siteKeyLocations(): string[] | null {
      return this.$store.state.firetableModule.siteKeyLocations
    },
    urlChange(): string | null {
      return this.$route.params.taskParam
    },
    siteClassification(): string | null {
      const siteKey = this.$store.state.firetableModule.siteKey
      return siteKey.customizations?.siteClassification ?? "FACILITIES"
    },
  },
  watch: {
    siteKeyData(newValue: SiteKey | null) {
      if (!newValue) return
      this.buildColumns()
    },
    siteKeyUserLocations(newValue: string[] | null) {
      if (!newValue) return
      this.startListening()
    },
    siteKeyLocations(newValue: string | null) {
      if (!newValue) return
      this.buildColumns()
    },
    urlChange(newValue) {
      if (!newValue) return
      this.startListening()
      // Set saved filters
      this.setSavedFilters()
    },
    tasks(tasks) {
      // filter search manualy
      this.records = this.filterTasks(this.searchTerm, tasks)
    },
    searchTerm(newTerm) {
      // filter search on every term change
      this.records = this.filterTasks(newTerm, this.tasks)
      // Save search term
      let filters = window.localStorage.getItem("task-list-filters")
        ? JSON.parse(window.localStorage.getItem("task-list-filters"))
        : {}
      filters = {
        ...filters,
        [this.$route.params.taskParam]: {
          ...filters[this.$route.params.taskParam],
          searchTerm: newTerm,
        },
      }
      window.localStorage.setItem("task-list-filters", JSON.stringify(filters))
    },
    dateFilter() {
      this.records = this.filterTasks(this.searchTerm, this.tasks)
    },
  },
  mounted() {
    if (this.siteKeyUserLocations) {
      this.startListening()
      this.buildColumns()
      this.setSavedFilters()
    }
  },
  beforeDestroy(): void {
    this.removeListeners()
  },
}
