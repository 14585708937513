
// Models
import {
  TaskType,
  TaskStatus,
  TaskTypes,
  SiteKey,
  CraftType,
  CraftRecord,
  SiteKeyCompany,
} from "@/models/models"

// Components
import TaskDrawer from "@/components/EditTask.vue"
import TaskDateDialog from "@/views/CraftRecord/TaskDateDialog.vue"
import TaskCompleteDialog from "./TaskCompleteDialog.vue"
import ConfirmDialog from "@/components/ConfirmDialog.vue"
import TaskStatusChangeDialog from "./TaskStatusChangeDialog.vue"
import EventTimeline from "@/components/EventTimeline.vue"

// Libs
import { Util } from "@/helpers"
import firebase from "firebase/app"
import { string } from "@/string"
import { db } from "@/firebase-init"
import has from "lodash/has"
import Vue, { PropType } from "vue"
import { isEqual } from "lodash"
import omitBy from "lodash/omitBy"
import {
  checkCraftRecordPersistencePrompt,
  checkTsdRequired,
  Tasks,
} from "@/models/task"
import { mapGetters } from "vuex"

export default Vue.extend({
  props: {
    task: {
      type: Tasks,
      required: true,
    },
    companies: {
      type: Array as PropType<SiteKeyCompany[]>,
      required: true,
    },
    craftRecord: {
      type: CraftRecord,
      required: false,
    },
  },
  components: {
    EventTimeline,
    "task-drawer": TaskDrawer,
    "task-date-dialog": TaskDateDialog,
    "complete-dialog": TaskCompleteDialog,
    "status-change-dialog": TaskStatusChangeDialog,
    "confirm-dialog": ConfirmDialog,
  },
  data() {
    return {
      loading: false,
      snackbar: false,
      snackbarText: "",
      snackBarColor: "",
      editMode: false,
      showDateDialog: false,
      showTaskCompleteDialog: false,
      showCraftPersistence: false,
      showTaskStatusChangeDialog: false,
      taskDetailsUsers: [],
      taskDetailsConfigs: [],
      nextTaskStatus: null,
      taskType: TaskType,
      TaskStatus,
      isUserSubscribed: false,
      updatedTask: Tasks,
      assignedCompany: SiteKeyCompany,
      updatedTaskDetails: {},
      originalTaskSpecificDetails: {},
      confirmDialog: {
        show: false,
        title: "",
        message: "",
        cancelText: "",
        okText: "",
      },
    }
  },
  computed: {
    ...mapGetters("aggregatesModule", {
      userDisplayNames: "getUserDisplayNames",
    }),
    siteKeyUserPermissionData(): any | null {
      return this.$store.state.firetableModule.siteKeyUserPermissionData
    },
    eventList(): Array<Event> | [] {
      const { eventList } = this.$store.state.firetableModule
      return eventList
        ? eventList
            .filter((e) => e.taskID === this.task.id)
            .sort((a, b) => {
              const aSecs = a.timestampCreated.seconds
              const bSecs = b.timestampCreated.seconds
              return bSecs - aSecs
            })
        : []
    },
  },
  asyncComputed: {
    async siteKeyData() {
      return await Util.getSiteKey(
        db,
        this.$store.state.firetableModule.rootUserData.defaultSiteKey
      )
    },
  },
  watch: {
    siteKeyData(newValue: SiteKey) {
      if (!newValue) return
      this.buildTaskSpecificDetails()
    },
    task(newValue: Tasks | null) {
      if (!newValue || !this.siteKeyData) return
      this.buildTaskSpecificDetails()
      this.fetchAdditionalData(newValue)
    },
  },
  async mounted() {
    //Get task subscribers
    const {
      rootUserData: { defaultSiteKey, id },
    } = this.$store.state.firetableModule
    const subscribers = await Util.getTaskSubscribers(
      db,
      defaultSiteKey,
      this.task.id
    )
    await this.fetchAdditionalData(this.task)
    this.isUserSubscribed = subscribers.find((sub) => sub.key === id)
  },

  methods: {
    formatDate: (data) => Util.formatDate(data),

    async fetchAdditionalData(task: Tasks) {
      this.assignedCompany = await this.getAssignedCompanyDetails(
        task.assignedCompanyID
      )
    },

    async getAssignedCompanyDetails(
      companyID: string
    ): Promise<SiteKeyCompany> | null {
      if (companyID == null) {
        return null
      }
      const { rootUserData, siteKeyUserPermissionData } =
        this.$store.state.firetableModule
      if (!rootUserData || !siteKeyUserPermissionData) return
      return await Util.getSiteKeyCompanyDetail(
        db,
        rootUserData.defaultSiteKey,
        companyID
      )
    },

    async toggleSubscription() {
      try {
        const { defaultSiteKey, displayName, id } =
          this.$store.state.firetableModule.rootUserData
        const taskSubscriber = {
          key: `${id}`,
          displayName,
        }

        if (this.isUserSubscribed) {
          await Util.unsubscribeToTask(db, defaultSiteKey, id, this.task.id)
          this.$store.commit(
            "firetableModule/setSuccess",
            string.userUnsubscribedToTask
          )
          this.isUserSubscribed = false
        } else {
          await Util.subscribeToTask(
            db,
            defaultSiteKey,
            taskSubscriber,
            this.task.id
          )
          this.$store.commit(
            "firetableModule/setSuccess",
            string.userSubscribedToTask
          )
          this.isUserSubscribed = true
        }
      } catch (error) {
        Util.errorMessage(error.message)
      }
    },

    taskDetailHidden(config): boolean {
      if (!Object.keys(this.task.taskSpecificDetails).includes(config.key)) {
        return true
      }
      return config.defaultValue === this.task.taskSpecificDetails[config.key]
    },

    getUserDisplayName(uid: string | null) {
      if (uid == null) {
        return ""
      }
      if (uid in this.userDisplayNames) {
        return this.userDisplayNames[uid]
      }
      return ""
    },

    getMultiUserDisplayNameString(uids: string[]): string {
      const displayNames = []
      const { siteKeyUsers } = this.$store.state.firetableModule
      if (uids) {
        uids.forEach((uid) => {
          const userIndex = siteKeyUsers.findIndex((e) => e.id === uid)
          if (userIndex >= 0) {
            displayNames.push(siteKeyUsers[userIndex].displayName ?? "")
          } else {
            displayNames.push(uid)
          }
        })
      }
      return displayNames.join(", ")
    },

    getCurrencyString(value: number): string {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      })
      return formatter.format(value)
    },

    async buildTaskSpecificDetails() {
      // Store the original taskSpecificDetails...
      // ... because the rules prevent us from updating taskSpecificDetails directly, so we need to swap
      //     out with the original data before updating any task data
      // ... and because we want to store some intermediate state of what the taskSpecificDetails are (default)
      //     based off the existing config values. Sometimes the configs are updated after a task is created
      //     so this helps us manage that situation on the client until the server-side data is updated with
      //     the correct defaultValues
      // We will store any actual updated values on this.updatedTaskDetails
      this.originalTaskSpecificDetails = { ...this.task.taskSpecificDetails }

      this.taskDetailsConfigs = Util.getReadableDynamicDetailConfigs(
        this.siteKeyData,
        null,
        this.task
      )
    },

    setEditMode(edit) {
      this.editMode = edit
    },

    setShowDateDialog(show) {
      this.showDateDialog = show
    },

    setShowTaskCompleteDialog(show) {
      this.showTaskCompleteDialog = show
    },

    setShowTaskStatusChangeDialog(show) {
      this.showTaskStatusChangeDialog = show
    },

    getNextValidTaskStatus(task) {
      return TaskStatus.getNextValidTaskStatus(
        task,
        this.siteKeyUserPermissionData
      )
    },

    checkTaskRestrictions() {
      //Restricted statuses
      const restrictions = TaskType.taskRestrictions("delete")
      // User restrictions
      const canDeleteTasks =
        this.siteKeyUserPermissionData?.permissions?.canDeleteTasks
      return !restrictions.includes(this.task.taskStatus) && canDeleteTasks
    },

    async updateTaskSpecificDetails(
      taskSpecificDetails: {},
      refPath: string,
      siteKeyData: SiteKey
    ) {
      const craftDetailName = CraftType.getCraftTypeRecordString(
        this.task.craftType
      )
      const taskDetailName = TaskTypes.getTaskTypeName(this.task.taskType)
      // Save task specific details by calling the cloud function (if applies)
      if (
        has(
          siteKeyData,
          `customizations.taskSpecificDetails.${craftDetailName}.${taskDetailName}`
        ) &&
        taskSpecificDetails
      ) {
        // Validate task specific details first
        if (!this.validateDynamicDetails()) {
          this.loading = false
          return this.$message.warning("Please check the values in the form")
        }
        if (Object.keys(taskSpecificDetails).length > 0) {
          // Get just the data that differs from the current task
          const tsd = { ...this.task.taskSpecificDetails }
          const diffData = omitBy(taskSpecificDetails, function (val, key) {
            return isEqual(val, tsd[key])
          })
          const saveTaskDetails = firebase
            .functions()
            .httpsCallable("updateTaskSpecificDetails")
          await saveTaskDetails({
            ...diffData,
            refPath: refPath,
          })
        }
      }
    },

    async changeTaskStatus(nextTaskStatus: number) {
      const previousTaskStatus = this.task.taskStatus
      this.updatedTask = new Tasks(this.task)
      this.updatedTask.taskStatus = nextTaskStatus
      if (
        nextTaskStatus === TaskStatus.IN_PROGRESS &&
        previousTaskStatus !== TaskStatus.ON_HOLD
      ) {
        this.updatedTask.timestampTaskStarted =
          firebase.firestore.Timestamp.now()
      }

      if (nextTaskStatus === TaskStatus.COMPLETE)
        this.updatedTask.timestampTaskCompleted =
          firebase.firestore.Timestamp.now()

      if (
        nextTaskStatus === TaskStatus.AWAITING &&
        previousTaskStatus !== TaskStatus.AWAITING
      ) {
        this.updatedTask.timestampAwaitingStart =
          firebase.firestore.Timestamp.now()
      }

      const tsdRequired: boolean = checkTsdRequired(
        this.updatedTask,
        this.$store.state.firetableModule.siteKey,
        previousTaskStatus
      )
      this.showCraftPersistence = await checkCraftRecordPersistencePrompt(
        this.updatedTask,
        previousTaskStatus
      )

      if (tsdRequired || this.showCraftPersistence) {
        this.setShowTaskStatusChangeDialog(true)
        return
      } else {
        await this.updateTask(this.updatedTask, null)
      }

      // TODO: permitting dialog
    },

    async saveTaskScheduleChanges(updatedTask: Tasks) {
      const previousTaskStatus: number = this.task.taskStatus
      this.updatedTask = new Tasks(updatedTask)
      // Check:
      // if any task-specific details apply for this new status,
      // if the craft record persistence prompt needs to be shown
      // if permitting prompt needs to be shown
      const tsdRequired: boolean = checkTsdRequired(
        updatedTask,
        this.$store.state.firetableModule.siteKey,
        previousTaskStatus
      )
      this.showCraftPersistence = await checkCraftRecordPersistencePrompt(
        updatedTask,
        previousTaskStatus
      )
      // TODO: Finish up permitting prompt logic...
      // const showPermittingPrompt: boolean = checkPermittingPrompt(
      //     updatedTaskData,
      //     previousTaskStatus
      // )

      if (tsdRequired || this.showCraftPersistence) {
        this.setShowTaskStatusChangeDialog(true)
      } else {
        this.setShowDateDialog(false)
        await this.updateTask(this.updatedTask, null)
      }

      // TODO: save to firestore!
    },

    async updateTask(updatedTaskData, updatedTaskSpecificDetails) {
      try {
        this.loading = true

        const { rootUserData } = this.$store.state.firetableModule
        if (!rootUserData) return null

        // Update task data
        const taskData = {
          ...updatedTaskData,
          timestampLastModified:
            firebase.firestore.FieldValue.serverTimestamp(),
          lastModifiedBy: `${rootUserData.id}`,
        }
        // Need to ensure original taskSpecificDetails are put back because rules will prevent any changes to these
        // fields directly (we will call the CF right after updating the task document)
        // taskData.taskSpecificDetails = this.originalTaskSpecificDetails
        delete taskData.taskSpecificDetails
        delete taskData.refPath
        delete taskData.id
        // Remove undefined values
        Object.keys(taskData).forEach((key) =>
          taskData[key] === undefined ? delete taskData[key] : {}
        )

        // If status changed to awaiting, update the timestampAwaitingStart
        // TODO: Redundant? Can this be removed?
        if (
          taskData.taskStatus == TaskStatus.AWAITING &&
          this.task.taskStatus != TaskStatus.AWAITING
        ) {
          taskData.timestampAwaitingStart = firebase.firestore.Timestamp.now()
        }

        await db.doc(this.task.refPath).update(taskData)

        const originalData = { ...this.originalTaskSpecificDetails }
        const diffData = omitBy(
          updatedTaskSpecificDetails,
          function (val, key) {
            return isEqual(val, originalData[key])
          }
        )

        // Then update taskSpecificDetails
        if (Object.keys(diffData).length > 0) {
          await this.updateTaskSpecificDetails(
            diffData,
            updatedTaskData.refPath,
            this.siteKeyData
          )
        }
        this.$store.commit("firetableModule/setSuccess", string.taskUpdated)
        this.setEditMode(false)
        this.setShowDateDialog(false)
        this.loading = false
      } catch (error) {
        Util.errorMessage(error.message)
        this.loading = false
      }
    },

    validateDynamicDetails() {
      // Validate Barrier comments
      if (
        Util.barrierCommentsRequired({
          currentValues: this.updatedTaskDetails,
          templateValues: this.taskDetailsConfigs,
        })
      ) {
        return false
      }
      this.taskDetailsConfigs.forEach((config) => {
        // Check for required values
        if (
          config.required &&
          [undefined, "", null].includes(this.updatedTaskDetails[config.key])
        ) {
          return false
        }

        // If not required and cleared out, remove it
        if (
          !config.required &&
          config.type === "number" &&
          [undefined, "", null].includes(this.updatedTaskDetails[config.key])
        ) {
          this.updatedTaskDetails[config.key] = 0
        }

        // Fix numbers parsed as strings
        if (
          config.type === "number" &&
          config.editable &&
          typeof this.updatedTaskDetails[config.key] === "string"
        ) {
          this.updatedTaskDetails[config.key] = parseInt(
            this.updatedTaskDetails[config.key],
            10
          )
        }

        if (
          config.type === "number" &&
          config.editable &&
          isNaN(this.updatedTaskDetails[config.key])
        ) {
          this.updatedTaskDetails[config.key] = null
        }
      })
      return true
    },

    closeDialog() {
      this.confirmDialog = {
        show: false,
        title: "",
        message: "",
        cancelText: "",
        okText: "",
      }
    },

    confirmDeleteTask() {
      this.confirmDialog = {
        show: true,
        title: "Delete Task?",
        message: "This Task will be deleted. Continue?",
        cancelText: "Cancel",
        okText: "Delete",
      }
    },

    async deleteTask() {
      try {
        this.loading = true
        const deleteTask = firebase.functions().httpsCallable("deleteTask")
        await deleteTask({ refPath: this.task.refPath })
        this.$store.commit("firetableModule/setSuccess", string.taskDeleted)
      } catch (error) {
        if (["permission-denied", "PERMISSION_DENIED"].includes(error.code)) {
          this.snackbar = true
          this.snackbarText =
            "You are unable to delete this task with your current user permissions."
          this.snackBarColor = "error-dark"
        }
      } finally {
        this.loading = false
      }
    },
  },
})
