
import Vue from "vue"
export default Vue.extend({
  async created() {
    if (this.$route.path === "/") {
      this.stiltlogo = true
      await this.timeout(5000)
      this.stiltlogo = false
      this.$router.push("/login")
    }
  },
  data() {
    return {
      theme: process.env.VUE_APP_THEME,
      logo: process.env.VUE_APP_LOGO,
      slogan: process.env.VUE_APP_DESC,
      username: "",
      password: "",
      passShow: false,
      typePass: "password",
      stiltlogo: false,
    }
  },
  computed: {
    loading(): boolean {
      return this.$store.state.firetableModule.isLoading
    },
    formIsValid(): boolean {
      return this.username !== "" && this.password !== ""
    },
  },
  methods: {
    timeout(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    passwordVisible() {
      this.passShow = !this.passShow
      this.typePass = this.passShow ? "text" : "password"
    },
    signIn() {
      const payload = {
        email: this.username,
        password: this.password,
      }
      this.$store.dispatch("firetableModule/firebaseSignIn", payload)
    },
  },
})
