export const string = {
  // success messages
  userRegister:
    "Account created! A verification email has been sent to your inbox.",
  passReset: "An email with a password reset link has been sent to your inbox.",
  downloadCodeLink:
    "An email with the download instructions has been sent to your inbox.",
  forgotMail: "Reset password link sent to your email address.",
  taskAdded: "Task added successfully.",
  taskChanged: "Task status changed successfully.",
  taskDeleted: "Task deleted successfully.",
  taskRestored: "Task restored successfully.",
  taskUpdated: "Task updated successfully.",
  craftUpdated: "Craft Record updated successfully.",
  craftDeleted: "Craft Record deleted successfully.",
  craftRestored: "Craft Record restored successfully.",
  assetAdded: "Asset added successfully.",
  assetUpdated: "Asset updated successfully.",
  assetDeleted: "Asset deleted successfully.",
  locationAdded: "Location added successfully.",
  locationUpdated: "Location updated successfully.",
  locationDeleted: "Location deleted successfully.",
  companyAdded: "Company added successfully.",
  companyUpdated: "Company updated successfully.",
  companyDeleted: "Company deleted successfully.",
  imageUpload: "Image uploaded successfully.",
  imageDeleted: "Image deleted successfully.",
  notificationStatusChanged: "Notification status changed.",
  defaultSiteKeyChanged: "Default site key changed.",
  siteUserLocationChanged: "Locations updated.",
  siteUserPhoneChanged: "Phone number changed.",
  craftLocationChanged: "Map location changed.",
  downloadCodeSent: "Your download link has been sent.",
  userSubscribedToTask: "You will receive notifications from this task.",
  userUnsubscribedToTask: "You will not receive notifications from this task.",

  // error messages
  verificationError: "Error sending verification email. Please contact support",
  generalError:
    "An error has occurred. Please try again or contact administrator.",
  permissionError: "User does not have necessary permissions.",
  noData: "Data is not available.",
}
