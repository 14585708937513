import firebase from "firebase/app"
import "firebase/functions"
import "firebase/firestore"
// Local
import { ReportConfig } from "@/models/report-config"
import { ReportData } from "@/models/report-data"
import {
  AttachmentDocument,
  CraftRecord,
  CraftTypes,
  SiteKeyUserPermission,
} from "@/models/models"
import { db } from "@/firebase-init"
import { Customer } from "@/models/customer"
import { CustomerLocation } from "@/models/customer_location"

type FirestoreDoc = firebase.firestore.DocumentSnapshot

/**
 * Create a ReportData instance from Firestore document snapshot.
 */
export function createReportDataFromSnapshot(
  snapshot: FirestoreDoc
): ReportData {
  const docData: unknown = snapshot.data()
  // todo: think about doing a strong type-guard validation check here
  return new ReportData({
    id: snapshot.id,
    data: docData["data"],
    downloadURL: docData["downloadURL"],
    reportConfig: docData["reportConfig"],
    reportSpec: docData["reportSpec"],
    timestampCreated: docData["timestampCreated"],
    type: docData["type"],
    user: docData["user"],
  })
}

/**
 * Create a ReportData instance from Firestore document snapshot.
 */
export function createReportConfigFromSnapshot(
  snapshot: FirestoreDoc
): ReportConfig {
  const docData: unknown = snapshot.data()
  // todo: think about doing a strong type-guard validation check here
  return {
    id: snapshot.id,
    configCustomizations: docData["configCustomizations"],
    configName: docData["configName"],
    frequency: docData["frequency"],
    subscribed: docData["subscribed"],
    timestampLastModified: docData["timestampLastModified"],
    type: docData["type"],
    user: docData["user"],
    whiteLabel: docData["whiteLabel"],
  }
}

export class DbRead {
  static rootUser = {
    listenRootUserPrivateDoc: async (
      uid: string,
      setRootUserSiteKeysCallback: (
        siteKeysMap: Record<string, boolean>
      ) => void
    ): Promise<() => void> => {
      const docReference = firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .collection("privateColl")
        .doc("privateDoc")
      try {
        const listener = await docReference.onSnapshot((snapshot) => {
          const siteKeys = snapshot.get("siteKeys")
          if (typeof siteKeys === "object" && !Array.isArray(siteKeys)) {
            setRootUserSiteKeysCallback(siteKeys)
          } else {
            setRootUserSiteKeysCallback({})
          }
        })
        return listener
      } catch (error) {
        throw new Error(
          `Unable to read root user private doc: ${error.message}`
        )
      }
    },
  }

  static attachments = {
    listenAttachmentDocuments: async (
      siteKey: string,
      siteKeyUserPermissions: SiteKeyUserPermission,
      craftRecordID: string | undefined,
      taskID: string | undefined,
      assetID: string | undefined,
      setAttachmentsCallback: (attachments: AttachmentDocument[]) => void
    ): Promise<() => void> => {
      let query: firebase.firestore.Query<firebase.firestore.DocumentData> =
        firebase.firestore().collection(`siteKeys/${siteKey}/attachments`)

      if (craftRecordID) {
        query = query.where("craftRecordID", "==", craftRecordID)
      }

      if (taskID) {
        query = query.where("taskID", "==", taskID)
      }

      if (assetID) {
        query = query.where("assetID", "==", assetID)
      }

      try {
        const listener = query.onSnapshot((querySnapshot) => {
          const attachments: AttachmentDocument[] = querySnapshot.docs.map(
            (doc) => {
              return AttachmentDocument.fromFirestore(doc)
            }
          )
          setAttachmentsCallback(attachments)
        })
        return listener
      } catch (error) {
        throw new Error(`Unable to query for attachments: ${error}`)
      }
    },
  }

  static customers = {
    listenCustomerDocuments: async (
      siteKey: string,
      siteKeyUserPermissions: SiteKeyUserPermission,
      setCustomersCallback: (customers: Customer[]) => void
    ): Promise<() => void> => {
      const query: firebase.firestore.Query<firebase.firestore.DocumentData> =
        firebase.firestore().collection(`siteKeys/${siteKey}/customers`)

      try {
        return query.onSnapshot((querySnapshot) => {
          const customers: Customer[] = querySnapshot.docs.map((doc) => {
            return Customer.fromFirestore(doc)
          })
          setCustomersCallback(customers)
        })
      } catch (error) {
        throw new Error(`Unable to query for customers: ${error}`)
      }
    },
  }

  static customerLocations = {
    listenCustomerLocationDocuments: async (
      siteKey: string,
      siteKeyUserPermissions: SiteKeyUserPermission,
      setCustomerLocationsCallback: (
        customerLocations: CustomerLocation[]
      ) => void
    ): Promise<() => void> => {
      const query: firebase.firestore.Query<firebase.firestore.DocumentData> =
        firebase.firestore().collection(`siteKeys/${siteKey}/customerLocations`)

      try {
        return query.onSnapshot((querySnapshot) => {
          const customerLocations: CustomerLocation[] = querySnapshot.docs.map(
            (doc) => {
              return CustomerLocation.fromFirestore(doc)
            }
          )
          setCustomerLocationsCallback(customerLocations)
        })
      } catch (error) {
        throw new Error(`Unable to query for customer locations: ${error}`)
      }
    },
  }

  static craftRecords = {
    listenStandingScaffoldRecords: async (
      siteKey: string,
      siteKeyUserPermissions: SiteKeyUserPermission,
      setStandingRecordsCallback: (craftRecords: CraftRecord[]) => void
    ): Promise<() => void> => {
      let query = firebase
        .firestore()
        .collection(`siteKeys/${siteKey}/parentRecords`)
        .where("open", "==", true)
        .where("craftType", "==", CraftTypes.SCAFFOLDING)
        .where("craftDetails.installed", "==", true)
      if (!siteKeyUserPermissions.permissions.isPlantPersonnel) {
        query = query.where(
          "authorizedCompanies",
          "array-contains",
          siteKeyUserPermissions.companyID
        )
      }

      try {
        const listener = query.onSnapshot((querySnapshot) => {
          const craftRecords: CraftRecord[] = querySnapshot.docs.map((doc) => {
            return CraftRecord.fromFirestore(doc)
          })
          setStandingRecordsCallback(craftRecords)
        })
        return listener
      } catch (error) {
        throw new Error(`Unable to query for all open records: ${error}`)
      }
    },
  }

  static aggregates = {
    listenUserDisplayNames: async (
      siteKey: string,
      siteKeyUserPermissions: SiteKeyUserPermission,
      setUserDisplayNamesCallback: (
        userDisplayNames: Record<string, string>
      ) => void
    ): Promise<() => void> => {
      const docReference = firebase
        .firestore()
        .collection(`siteKeys/${siteKey}/aggregates`)
        .doc("userDisplayNames")
      try {
        const listener = await docReference.onSnapshot((snapshot) => {
          const userDisplayNames: Record<string, string> = snapshot.data()
          if (typeof userDisplayNames === "object") {
            setUserDisplayNamesCallback(userDisplayNames)
          } else {
            setUserDisplayNamesCallback({})
          }
        })
        return listener
      } catch (error) {
        throw new Error(`Unable to query for userDisplayNames: ${error}`)
      }
    },
  }

  static reports = {
    /**
     * Send an email with the report's download link to the owner's email
     * address.
     */
    sendEmail: async (siteKey: string, reportDataId: string) => {
      const sendEmail = firebase
        .functions()
        .httpsCallable("emailReportDownloadURL")
      const payload = { siteKey, reportDataId }
      return sendEmail(payload)
    },

    /** Return a object of report specifications */
    getTypes: async (siteKey: string): Promise<unknown> => {
      const getReportTypes = firebase
        .functions()
        .httpsCallable("listReportTypes")
      const specs = await getReportTypes({ siteKey: siteKey })
      return specs.data
    },

    /** Attach real-time listeners and execute callback on reportConfigs data
     * on update */
    listenUserConfigs: async (
      siteKey: string,
      uid: string,
      setReportConfigsCallback: (configs: ReportConfig[]) => void
    ): Promise<() => void> => {
      const query = firebase
        .firestore()
        .collection(`siteKeys/${siteKey}/reportConfigs`)
        .where("user", "==", uid)
      const listener = query.onSnapshot((querySnapshot) => {
        const configs: any = querySnapshot.docs.map((snapshot) => {
          return createReportConfigFromSnapshot(snapshot)
        })
        setReportConfigsCallback(configs)
      })
      return listener
    },

    listenAllReportData: async (
      siteKey: string,
      uid: string,
      setReportDataListCallback: (reportDataList: ReportData[]) => void
    ): Promise<() => void> => {
      const query = firebase
        .firestore()
        .collection(`siteKeys/${siteKey}/reportData`)
        .where("user", "==", uid)
      const listener = query.onSnapshot((querySnapshot) => {
        const reportDataList: ReportData[] = querySnapshot.docs.map((doc) => {
          return createReportDataFromSnapshot(doc)
        })
        setReportDataListCallback(reportDataList)
      })
      return listener
    },
  }

  static qrCodes = {
    generateScaffoldTag: async (
      siteKey: string,
      craftRecordID: string,
      whiteLabel: string
    ): Promise<string> => {
      const generateQrTag = firebase
        .functions()
        .httpsCallable("handleGenerateQRScaffoldTagCF")
      const payload = { siteKey, craftRecordID, whiteLabel }
      const response = await generateQrTag(payload)
      return response.data
    },
  }
}

export const DbWrite = {
  attachments: {
    addNewAttachment: async (
      attachment: AttachmentDocument,
      siteKey: string
    ) => {
      const updateData: Record<string, any> = attachment.toMap()
      await db
        .collection(`siteKeys/${siteKey}/attachments`)
        .doc()
        .set(updateData)
    },
    deleteAttachment: async (attachment: AttachmentDocument) => {
      await db.doc(attachment.refPath).delete()
    },
  },
  parentRecords: {
    updateCraftRecord: async (craftRecord: CraftRecord, uid: string) => {
      const updateData: Record<string, any> = craftRecord.toMap()
      updateData.timestampLastModified =
        firebase.firestore.FieldValue.serverTimestamp()
      updateData.lastModifiedBy = uid
      await db.doc(craftRecord.refPath).update(updateData)
    },
    deleteParentRecord: async (refPath: string) => {
      const deleteParentRecord = firebase
        .functions()
        .httpsCallable("deleteParentRecord")
      await deleteParentRecord({ refPath: refPath })
    },
    updateCraftDetails: async (
      craftDetails: Record<string, any>,
      refPath: string
    ) => {
      const updateCraftDetails = firebase
        .functions()
        .httpsCallable("updateCraftDetails")
      await updateCraftDetails({ ...craftDetails, refPath })
    },
  },
  reports: {
    saveConfig: async (configuration: ReportConfig, siteKey: string) => {
      const saveCallable = firebase
        .functions()
        .httpsCallable("saveReportConfig")
      await saveCallable({ siteKey: siteKey, reportConfig: configuration })
    },
    deleteConfig: async (configId: string, siteKey: string) => {
      const deleteCallable = firebase
        .functions()
        .httpsCallable("deleteReportConfig")
      await deleteCallable({ siteKey: siteKey, reportConfigId: configId })
    },
    deleteData: async (reportDataId: string, siteKey: string) => {
      const callable = firebase.functions().httpsCallable("deleteReportData")
      await callable({ siteKey: siteKey, reportDataId: reportDataId })
    },

    /**
     * Call the cloud function to generate report data based on the report
     * configuration. Returns a ReportData object which includes the downloadURL.
     */
    generateReportData: async (args: {
      siteKey: string
      configuration: ReportConfig
    }): Promise<ReportData> => {
      const { siteKey, configuration } = args
      const generateReportCallable = firebase
        .functions()
        .httpsCallable("generateReport")
      const response = await generateReportCallable({
        siteKey: siteKey,
        reportConfig: configuration,
      })

      return response.data
    },
  },
}

async function createCustomTokenKey() {
  const createCustomToken = firebase
    .functions()
    .httpsCallable("createCustomToken")
  const response = await createCustomToken()
  return response.data.tokenKey
}

export const Tokens = {
  createCustomTokenKey: createCustomTokenKey,
}
