// todo: consolidate these into a class instance

export function devOnlyLog(...data: any[]) {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log(...data)
  }
}

export function devOnlyError(...data: any[]) {
  const env = process.env.NODE_ENV
  if (env === "development") {
    // eslint-disable-next-line no-console
    console.error(...data)
  }
}
