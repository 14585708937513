import { Module, GetterTree, MutationTree, ActionTree } from "vuex"
import * as Sentry from "@sentry/browser"

// Local
import { DbRead } from "@/database"
import { Util } from "@/helpers"
import { ReportSpec } from "@/models/report-spec"

interface State {
  reportSpecs: Record<string, ReportSpec>
}

const state: State = {
  reportSpecs: {},
}

const getters: GetterTree<State, any> = {
  allSpecs: (state): State["reportSpecs"] => {
    return state.reportSpecs
  },
  allSpecsWithId: (state) => {
    const output = {}
    for (const [key, value] of Object.entries(state.reportSpecs)) {
      output[key] = { id: key, ...value }
    }
    return output
  },
  getSpec: (state) => (reportType: string) => {
    return state.reportSpecs[reportType]
  },
}

const mutations: MutationTree<State> = {
  setReportSpecs: (state, payload: State["reportSpecs"]) => {
    state.reportSpecs = payload
    // Cache in localStorage.
    localStorage.setItem("reportSpecs", JSON.stringify(payload))
  },
}

const actions: ActionTree<State, any> = {
  fetchReportSpecs: async (
    { commit, rootState },
    siteKey: string
  ): Promise<void> => {
    if (typeof siteKey === "string") {
      const specs = await DbRead.reports.getTypes(siteKey)
      commit("setReportSpecs", specs)
    } else {
      const msg = `Expected siteKey to be a string! ${siteKey}`
      Util.consoleErrorOnlyOnDev(msg)
      Util.consoleDirOnlyOnDev(rootState)
      Sentry.captureException(msg, { extra: { appState: rootState } })
    }
  },
  /** Retrieve from localStorage */
  initReportSpecs: ({ commit }): void => {
    let specs = localStorage.getItem("reportSpecs")
    if (specs != null) {
      specs = JSON.parse(specs)
      commit("setReportSpecs", specs)
    }
  },
  /** Reset module state to initial values */
  resetData: ({ commit }): void => {
    commit("setReportSpecs", {})
  },
}

export const reportSpecModule: Module<State, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
