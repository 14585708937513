var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.craftDetailsConfigs.length > 0)?_c('v-row',{staticClass:"m-0 justify-start pt-0"},[_vm._l((_vm.craftDetailsConfigs),function(config){return _c('v-col',{key:config.key,staticClass:"pt-0",attrs:{"lg":"6","md":"6","sm":"12","cols":"12","hidden":config.editable === false ||
      (_vm.craftRecordCreation === true &&
        config.hideOnCraftRecordCreation === true)}},[(config.type === 'bool')?_c('v-checkbox',{staticClass:"mt-1",attrs:{"label":`${config.title} ${config.required ? '*' : ''}`,"required":config.required},on:{"change":_vm.updateCraftDetails},model:{value:(_vm.updatedCraftDetails[config.key]),callback:function ($$v) {_vm.$set(_vm.updatedCraftDetails, config.key, $$v)},expression:"updatedCraftDetails[config.key]"}}):_vm._e(),(config.type === 'string')?_c('v-text-field',{class:`p-0 m-0 ${config.required ? 'required' : null}`,attrs:{"rules":[
        (v) => (config.required && !v ? 'This field is required' : true),
      ],"required":config.required,"maxlength":config.maxLength,"minlength":config.minLength,"type":"text","placeholder":`Enter ${config.title}`,"hint":`${config.title} ${config.required ? '*' : ''}`,"persistent-hint":""},on:{"change":_vm.updateCraftDetails},model:{value:(_vm.updatedCraftDetails[config.key]),callback:function ($$v) {_vm.$set(_vm.updatedCraftDetails, config.key, $$v)},expression:"updatedCraftDetails[config.key]"}}):_vm._e(),(config.type === 'number' || config.type === 'currency')?_c('v-text-field',{class:`p-0 m-0 ${config.required ? 'required' : null}`,attrs:{"rules":[
        (v) =>
          !v && v !== 0 && config.required ? 'This field is required' : true,
        (v) =>
          parseInt(v, 10) < config.minValue
            ? `${config.title} should be above ${config.minValue}`
            : true,
        (v) =>
          parseInt(v, 10) > config.maxValue
            ? `${config.title} should be below ${config.maxValue}`
            : true,
      ],"required":config.required,"type":"number","prefix":config.type === 'currency' ? '$' : '',"hint":`${config.title}`,"persistent-hint":"","placeholder":`Enter ${config.title}`},on:{"change":_vm.updateCraftDetails,"wheel":_vm.stopInputValueChange},model:{value:(_vm.updatedCraftDetails[config.key]),callback:function ($$v) {_vm.$set(_vm.updatedCraftDetails, config.key, _vm._n($$v))},expression:"updatedCraftDetails[config.key]"}}):_vm._e(),(config.type === 'timestamp')?_c('div',{staticClass:"w-100 pt-2 justify-content-start align-content-start"},[(config.type === 'timestamp')?_c('input',{class:`w-100 p-0 m-0 ${config.required ? 'required' : null}`,staticStyle:{"font-size":"1.2em","border-bottom":"1px solid rgba(0, 0, 0, 0.42)","padding-bottom":"4px"},attrs:{"type":"datetime-local","required":config.required,"placeholder":`Enter ${config.title}`},domProps:{"value":_vm.getDatePickerDate(_vm.updatedCraftDetails[config.key])},on:{"change":function($event){return _vm.updateDate($event, config.key)}}}):_vm._e(),(config.type === 'timestamp')?_c('div',{staticClass:"justify-content-start pt-2"},[_vm._v(" "+_vm._s(config.title)+" ")]):_vm._e()]):_vm._e(),_c('span',[(config.type === 'uid')?_c('v-container',{staticClass:"d-inline-flex p-0 m-0 hover:tw-bg-gray-50 tw-bg-primary-300",attrs:{"fluid":""},on:{"click":function($event){return _vm.setShowUserModal(true, config.key)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"tw-text-left"},[_vm._v(" "+_vm._s(_vm.getUserDisplayName(_vm.updatedCraftDetails[config.key]))+" ")]),_c('div',{staticClass:"tw-text-sm tw-text-left"},[_vm._v(" "+_vm._s(config.title)+" ")])]),_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-right")])],1):_vm._e()],1)],1)}),_c('user-list-dialog',{attrs:{"title":"Select User","show":_vm.showUserModal},on:{"onSelect":_vm.setUserSelection,"close":_vm.setShowUserModal}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }