import { Module, GetterTree, MutationTree, ActionTree } from "vuex"

interface State {
  /** List of messages in the format [message, msg level/color] */
  messages: [string, string][]
}

const state: State = {
  messages: [],
}

const getters: GetterTree<State, any> = {
  /** List of messages in the format [message, msg level/color] */
  getMessages: (state): State["messages"] => {
    return state.messages
  },
}

const mutations: MutationTree<State> = {
  addMessage: (state, payload: [string, string]) => {
    state.messages = [...state.messages, payload]
  },
  removeMessage: (state, payload: number) => {
    const messagesCopy = [...state.messages]
    messagesCopy.splice(payload, 1)
    state.messages = messagesCopy
  },
}

const actions: ActionTree<State, any> = {
  addMsg: ({ commit }, payload: [string, string]) => {
    commit("addMessage", payload)
  },
  removeMsg: ({ commit }, payload: number) => {
    commit("removeMessage", payload)
  },
}

export const messagesModule: Module<State, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
