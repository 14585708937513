
// Libs
import { db } from "@/firebase-init"
import { Util } from "@/helpers"
import { string } from "@/string"
import firebase from "firebase/app"
import exifOrient from "exif-orient"

// Components
import imgLightbox from "@/components/imgLightbox.vue"

export default {
  props: ["id", "refPath"],
  created() {
    this.getPhotos()
  },
  data() {
    return {
      theme: process.env.VUE_APP_THEME,
      picPlaceholder: "/image/no-image-placeholder.png",
      loading: false,
      photos: [],
      photoIndex: 0,
      showImage: false,
      expandedImg: null,
    }
  },
  methods: {
    async getPhotos() {
      const { defaultSiteKey } = this.$store.state.firetableModule.rootUserData
      try {
        // Get all the site key documents.
        const docsRef: any = await db
          .collection(
            `siteKeys/${defaultSiteKey}/parentRecords/${this.id}/photos`
          )
          .get()
        this.$set(this.photos, [])
        for (const doc of docsRef.docs)
          this.photos.push({ src: doc.data().photoURL, id: doc.id })
      } catch (error) {
        Util.errorMessage(error)
      } finally {
        if (this.photos.length === 0)
          this.photos.push({
            src: this.picPlaceholder,
          })
      }
    },

    async removeImage(photo) {
      try {
        const { id } = photo
        this.loading = true
        await Util.removePhotos(db, this.refPath, id)
        this.photos = this.photos.filter((pic) => pic.id !== id)
        if (this.photos.length === 0)
          this.photos.push({
            src: this.picPlaceholder,
          })
        this.$store.commit("firetableModule/setSuccess", string.imageDeleted)
        this.loading = false
      } catch (error) {
        Util.errorMessage(error.message)
        this.loading = false
      }
    },

    async fixImgOrientation(base64) {
      return new Promise((resolve, reject) => {
        const tmpImg: any = new Image()
        tmpImg.src = base64
        tmpImg.onload = async () => {
          window["EXIF"].getData(tmpImg, () => {
            const orientation = tmpImg.exifdata.Orientation || 1
            exifOrient(tmpImg, orientation, (err, canvas) => {
              if (err) return reject(err)
              const img: any = new Image()
              img.src = canvas.toDataURL()
              img.onload = () => resolve(img)
            })
          })
        }
      })
    },

    uploadImage() {
      const { rootUserData } = this.$store.state.firetableModule
      if (!rootUserData) return null
      const defaultSiteKey: string = rootUserData.defaultSiteKey
      try {
        const input = this.$refs.addFiles[0]
        // Ensure that you have a file before attempting to read it
        if (input.files && input.files.length === 0) return
        this.loading = true
        for (const file of input.files) {
          const reader = new FileReader()
          reader.onload = async (e: any) => {
            const img = await this.fixImgOrientation(e.target.result)
            const fullUrl = Util.getFullImageUrl(img)
            const reducedUrl = Util.getReducedImageUrl(img)
            const thumbUrl = Util.getThumbImageUrl(img)
            const image: { [key: string]: any } = {
              fileName: file.name,
              fullUrl,
              reducedUrl,
              thumbUrl,
            }
            let photoURLMap: any = await Util.savePhotoToStorage(
              defaultSiteKey,
              image
            )
            photoURLMap = {
              ...photoURLMap,
              timestampCreated: firebase.firestore.FieldValue.serverTimestamp(),
              createdBy: `${rootUserData.id}`,
            }
            // Add photo to database
            const addPhoto = await db
              .collection(`${this.refPath}/photos`)
              .add(photoURLMap)
            // Update thumb
            await db.doc(this.refPath).update({
              thumbnailURL: photoURLMap.photoURL_thumb,
              timestampLastModified:
                firebase.firestore.FieldValue.serverTimestamp(),
              lastModifiedBy: `${rootUserData.id}`,
            })
            // Add locally
            if (this.photos.length === 1 && !this.photos[0].id) {
              this.photos = [
                {
                  id: addPhoto.id,
                  src: image.reducedUrl,
                },
              ]
              this.photoIndex = 0
            } else {
              this.photos.push({
                id: addPhoto.id,
                src: image.reducedUrl,
              })
              this.photoIndex = this.photos.length - 1
            }
            this.$store.commit("firetableModule/setSuccess", string.imageUpload)
            this.loading = false
          }
          reader.readAsDataURL(file)
        }
      } catch (error) {
        this.loading = false
        Util.errorMessage(error.message)
      }
    },

    expandImage(image) {
      this.expandedImg = image.src
      this.showImage = true
    },

    closeImgDialog() {
      this.showImage = false
      this.expandedImg = null
    },
  },
  components: {
    "img-lightbox": imgLightbox,
  },
}
