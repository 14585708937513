
export default {
  props: ["show", "title"],
  data() {
    return {
      searchTerm: "",
    }
  },
  computed: {
    filteredUsers() {
      const { siteKeyUsers } = this.$store.state.firetableModule
      if (!this.searchTerm) return siteKeyUsers
      return siteKeyUsers.filter((user) => {
        return user.displayName
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      })
    },
  },
  methods: {
    close() {
      this.$emit("close", false, null)
    },
    select(user) {
      this.$emit("onSelect", user)
    },
  },
}
