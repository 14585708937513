/**
 * This module is partially implemented since siteKeyCompanies are currently
 * fetched in the "firetableModule". Until a larger refactor is done, this module
 * is only using getters for the users company doc and if checklists are enabled.
 */

// Libs
import { Module, GetterTree, MutationTree, ActionTree } from "vuex"

// Local
import { CraftTypes, SiteKeyCompany } from "@/models/models"
import { devOnlyLog } from "@/logging"

interface State {
  /**
   * Not yet implemented. Retrieved through a getter accessing existing data
   * from "firetable" module.
   */
  siteKeyCompany: SiteKeyCompany | null
}

const state: State = {
  siteKeyCompany: null,
}

const getters: GetterTree<State, any> = {
  /**
   * Retrieves the user's company doc from the list of siteKeyCompanies fetch
   * in the "firetable" store module.
   */
  getUsersSiteKeyCompanyDoc: (
    state,
    getters,
    rootState
  ): SiteKeyCompany | undefined => {
    // Accessing another module's state by going through rootState, since we
    // already have a query for the siteKeyCompanies collection.
    const userPermissions = rootState.firetableModule.siteKeyUserPermissionData
    if (userPermissions == null) return

    const companiesList = rootState.firetableModule.siteKeyCompanies
    if (!Array.isArray(companiesList)) return

    return companiesList.find((element) => {
      const companyID = element.id
      return companyID === userPermissions.companyID
    })
  },
  /**
   * Returns true if the user's company has Checklists (Code 110) enabled.
   */
  getChecklistsEnabled: (state, getters): boolean => {
    const companyDoc = getters.getUsersSiteKeyCompanyDoc
    if (companyDoc == null) return false

    const companyCraftTypes = companyDoc?.craftTypes

    if (Array.isArray(companyCraftTypes)) {
      return companyCraftTypes.includes(CraftTypes.CHECKLISTS)
    } else {
      devOnlyLog(
        `Expected companyDoc.craftTypes to be an array. value:  ${companyCraftTypes}`
      )
      return false
    }
  },
}

const mutations: MutationTree<State> = {
  /**
   * Not yet implemented.
   */
  setSiteKeyCompany: (state, payload: SiteKeyCompany) => {
    state.siteKeyCompany = payload
    localStorage.setItem("siteKeyCompany", JSON.stringify(payload))
  },
}

const actions: ActionTree<State, any> = {}

export const userCompanyModule: Module<State, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
