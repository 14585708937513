
import Vue from "vue"

// Local
import { Tokens } from "@/database"
import { devOnlyError } from "@/logging"
import LoadingClipboardIcon from "@/components/LoadingClipboardIcon.vue"
import {
  DEV_REACT_APP_AUTO_LOGIN_URL,
  PROD_REACT_APP_AUTO_LOGIN_URL,
  CREATE_CUSTOMER_TASK_PATH,
} from "@/constants"

function reactAppAutoLoginURL(tokenKey: string): string {
  const firebaseProject = process.env.VUE_APP_FIREBASE_PROJECT
  if (firebaseProject === "scaffoldtracker") {
    return `${PROD_REACT_APP_AUTO_LOGIN_URL}?tokenKey=${tokenKey}&destination=${CREATE_CUSTOMER_TASK_PATH}`
  } else if (firebaseProject === "stilt-dev") {
    return `${DEV_REACT_APP_AUTO_LOGIN_URL}?tokenKey=${tokenKey}&destination=${CREATE_CUSTOMER_TASK_PATH}`
  } else {
    throw Error("Invalid Firebase project setting in Admin My Sites IFrame")
  }
}

export default Vue.extend({
  name: "CreateCustomerTaskIFrame",
  components: {
    LoadingClipboardIcon,
  },
  data() {
    return {
      tokenKey: null,
      iframeSource: "/404.html",
      loading: true, // Set to true to show loading icon
    }
  },
  beforeMount() {
    Tokens.createCustomTokenKey().then((tokenKey) => {
      if (typeof tokenKey === "string") {
        this.loading = false
        this.tokenKey = tokenKey
        this.iframeSource = reactAppAutoLoginURL(this.tokenKey)
      } else {
        devOnlyError("Unable to automatically log into the module.")
      }
    })
  },
})
