import { ActionTree } from "vuex"
import firebase from "firebase/app"
import "firebase/auth"
import * as models from "@/models/models"
import { Tasks } from "@/models/task"
import { db } from "@/firebase-init"
import { router } from "@/router"
import CollectionReference = firebase.firestore.CollectionReference
import DocumentReference = firebase.firestore.DocumentReference
import Query = firebase.firestore.Query
import { Util } from "@/helpers"
import { state } from "./index.module"
import moment from "moment"
import * as Sentry from "@sentry/browser"
import { SiteKeyUsers } from "@/models/models"

export const actions: ActionTree<any, any> = {
  // TODO: this is use for only testing purpose. once unit test is working fine means
  // we can remove that code.
  moduleActionClick({ commit }: any) {
    commit("increment")
  },

  // clear snackbar messages
  clearMessage({ commit }: any) {
    commit("setError", null)
    commit("setSuccess", null)
  },

  // We can use this version of the sign in function on any login form.
  async firebaseSignIn(
    { commit }: any,
    payload: { email: string; password: string }
  ) {
    commit("startLoading")
    try {
      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
      if (response !== null) {
        // Check if a next url is set
        const next = localStorage.getItem("next")
        if (next) {
          localStorage.removeItem("next")
          return router.push(next)
        }
        await router.push("/home")
      }
    } catch (error) {
      commit("setError", error.message)
    } finally {
      commit("stopLoading")
    }
  },
  async firebaseSignOut({ dispatch }) {
    try {
      await firebase.auth().signOut()
      await router.push("/login")
      await dispatch("clearStoreData", null, { root: true })
    } catch (error) {
      Sentry.captureException(error)
      Util.errorMessage(error.message)
    }
  },

  // Call this action at the main app level mounted() method to listen for
  // changes to the auth user across the whole app. User will persist as
  // Firebase has it's own session/cache locally. This will set currentUser
  // state to null if there is no user.
  async listenOnAuthChanged({ commit, dispatch }) {
    await firebase.auth().onAuthStateChanged(
      (user) => {
        commit("setCurrentUser", user)
        // Clean up some data if user is null.
        if (user == null) {
          commit("unsubscribeRootUserDataListener")
          commit("unsubscribeSiteKeyCompaniesListener")
          commit("unsubscribeSiteKeyUsersListener")
          commit("unsubscribeSiteKeyListener")
          commit("unsubscribeSiteKeyUserDataListener")
          commit("unsubscribeSiteKeyLocationsListener")
          commit("unsubscribeSiteKeyUserLocationsListener")
          commit("unsubscribeSiteKeyUserPermissionListener")
          commit("removeRootUserData")
          commit("removeSiteKeyUserData")
          commit("removeSiteKeyUserPermission")
          dispatch("clearStoreData", null, { root: true })
          return
        }
        // Init Sentry
        Util.initSentry(user)
      },
      (error) => {
        Sentry.captureException(error)
        commit("removeCurrentUser")
      }
    )
  },

  async listenRootUserData({ commit }: any, userId: string | null) {
    if (userId == null) return
    const ref: DocumentReference = db.doc(`users/${userId}`)
    const listener = await ref.onSnapshot((snapshot) => {
      const outputDoc = models.RootUser.fromFirestore(snapshot)
      commit("setRootUserData", outputDoc)
    })
    commit("addRootUserDataListener", listener)
  },

  async listenSiteKey({ commit, state }: any) {
    if (state.rootUserData?.defaultSiteKey == null) return
    const defaultSiteKey = state.rootUserData.defaultSiteKey

    const ref: DocumentReference = db.doc(`siteKeys/${defaultSiteKey}`)

    const listener = await ref.onSnapshot((snapshot) => {
      const outputDoc = models.SiteKey.fromFirestore(snapshot)
      commit("setSiteKey", outputDoc)
    })
    commit("setSiteKeyListener", listener)
  },

  async listenSiteKeyUserData({ commit }: any) {
    if (state.rootUserData == null) return
    if (state.rootUserData.defaultSiteKey == null) return
    const defaultSiteKey = state.rootUserData.defaultSiteKey
    const userId = state.rootUserData.id

    const ref: DocumentReference = db.doc(
      `siteKeys/${defaultSiteKey}/siteKeyUsers/${userId}`
    )
    const listener = await ref.onSnapshot((snapshot) => {
      const outputDoc = models.SiteKeyUsers.fromFirestore(snapshot)
      commit("setSiteKeyUserData", outputDoc)
    })
    commit("addSiteKeyUserDataListener", listener)
  },

  async listenSiteKeyUserLocations({ commit }: any) {
    if (state.rootUserData == null) return
    if (state.rootUserData.defaultSiteKey == null) return

    const defaultSiteKey = state.rootUserData.defaultSiteKey
    const userId = state.rootUserData.id

    const ref: CollectionReference = db.collection(
      `siteKeys/${defaultSiteKey}/siteKeyUsers/${userId}/userLocations`
    )
    const query: Query = ref.where("value", "==", true)

    const queryListener = query.onSnapshot((snapshot) => {
      const outputList = snapshot.docs.map((doc) => doc.get("key"))

      // Save the list of user locations objects to our app state.
      commit("setSiteKeyUserLocations", outputList)
      // commit("stopLoading");
    })
    commit("addSiteKeyUserLocationsListener", queryListener)
  },

  async listenSiteKeyUsers({ commit }: any) {
    if (state.rootUserData == null) return
    if (state.rootUserData.defaultSiteKey == null) return
    if (state.siteKeyUserPermissionData == null) return

    const siteKeyUserPermissionData = state.siteKeyUserPermissionData
    const defaultSiteKey = state.rootUserData.defaultSiteKey

    if (siteKeyUserPermissionData.permissions.isPlantPersonnel) {
      const ref: CollectionReference = db.collection(
        `siteKeys/${defaultSiteKey}/siteKeyUsers`
      )

      const listener = ref.onSnapshot((snapshot) => {
        let outputList = snapshot.docs.map((doc) =>
          SiteKeyUsers.fromFirestore(doc)
        )
        outputList = outputList.filter((a) => !(a.id === "server"))
        commit("setSiteKeyUsers", outputList)
        // commit("stopLoading");
      })
      commit("addSiteKeyUsersListener", listener)
    } else {
      // If not plantPersonnel, need to get visible users list from CF first
      const getVisibleCompaniesAndUsers = firebase
        .functions()
        .httpsCallable("getVisibleCompaniesAndUsers")
      const response = await getVisibleCompaniesAndUsers({
        siteKey: defaultSiteKey,
      })
      // Parse users from companies (flatten), could extract this logic.
      let uidList = []
      const userList = []
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [value] of Object.entries(response.data.data)) {
        // Extending list of uids
        uidList = uidList.concat(value)
      }

      // Query for all user documents.
      const promises = uidList.map(async (uid) => {
        const snapshot = await firebase
          .firestore()
          .doc(`siteKeys/${defaultSiteKey}/siteKeyUsers/${uid}`)
          .get()
        const userDoc = SiteKeyUsers.fromFirestore(snapshot)
        userList.push(userDoc)
      })
      await Promise.all(promises)
      commit("setSiteKeyUsers", userList)
    }
  },

  async listenSiteKeyUserPermissions({ commit }: any) {
    if (!state.rootUserData) return
    if (state.rootUserData.defaultSiteKey == null) return

    const defaultSiteKey = state.rootUserData.defaultSiteKey
    const userId = state.rootUserData.id
    commit("startLoading")
    try {
      const ref: DocumentReference = db.doc(
        `siteKeys/${defaultSiteKey}/siteKeyUsers/${userId}/privateColl/privateDoc/`
      )
      const listener = await ref.onSnapshot(
        (snapshot) => {
          const outputDoc = models.SiteKeyUserPermission.fromFirestore(snapshot)
          commit("setSiteKeyUserPermission", outputDoc)
          commit("stopLoading")
        },
        (error) => {
          Sentry.captureException(error)
          commit("stopLoading")
        }
      )
      commit("addSiteKeyUserPermissionListener", listener)
    } catch (error) {
      commit("stopLoading")
    }
  },

  async listenSiteKeyLocations({ commit, state }: any) {
    if (state.rootUserData == null) return
    if (state.rootUserData.defaultSiteKey == null) return

    try {
      const defaultSiteKey = state.rootUserData.defaultSiteKey
      const ref: CollectionReference = db.collection(
        `siteKeys/${defaultSiteKey}/locations`
      )
      // commit("startLoading");
      const queryListener = ref.onSnapshot((snapshot) => {
        const outputList = snapshot.docs.map((doc) =>
          models.Location.fromFirestore(doc)
        )
        // Save the list of locations objects to our app state.
        commit("setSiteKeyLocations", outputList)
        // commit("stopLoading");
      })
      commit("addSiteKeyLocationsListener", queryListener)
    } catch (error) {
      // commit("stopLoading");
    }
  },

  async listenSiteKeyCompanies({ commit, state }: any) {
    const { rootUserData, siteKeyUserPermissionData } = state
    if (!rootUserData) return
    if (!siteKeyUserPermissionData) return
    try {
      commit("startLoading")
      /*
       * Restrict company personnel to only taks for its company:
       * if isPlantPersonnel === true, then they should be able to view all
       * tasks that are assigned to all companies.
       * if isPlantPersonnel !== true  then they should only be able to view
       * tasks assigned to the company that they belong to
       */
      const { defaultSiteKey } = rootUserData
      const { companyID, permissions } = siteKeyUserPermissionData
      let queryListener
      if (permissions.isPlantPersonnel) {
        const ref = db.collection(`siteKeys/${defaultSiteKey}/siteKeyCompanies`)
        queryListener = ref.onSnapshot(
          (snapshot) => {
            const outputList = snapshot.docs.map((doc) =>
              models.SiteKeyCompany.fromFirestore(doc)
            )
            // Save the list of locations objects to our app state.
            commit("setSiteKeyCompanies", outputList)
          },
          (error) => {
            Util.errorMessage(error.message)
            commit("stopLoading")
          }
        )
      } else {
        const ref = db
          .collection(`siteKeys/${defaultSiteKey}/siteKeyCompanies`)
          .doc(companyID)
        queryListener = ref.onSnapshot(
          (snapshot) => {
            const outputDoc = models.SiteKeyCompany.fromFirestore(snapshot)
            // Save the list of locations objects to our app state.
            commit("setSiteKeyCompanies", [outputDoc])
          },
          (error) => {
            Util.errorMessage(error.message)
            commit("stopLoading")
          }
        )
      }

      commit("addSiteKeyCompaniesListener", queryListener)
      commit("stopLoading")
    } catch (error) {
      Util.errorMessage(error.message)
      commit("stopLoading")
    }
  },

  async listenAssetsList({ commit, state }: any) {
    if (!state.rootUserData) return
    try {
      const defaultSiteKey = state.rootUserData.defaultSiteKey
      const ref: CollectionReference = db.collection(
        `siteKeys/${defaultSiteKey}/assets`
      )
      commit("startLoading")
      const queryListener = ref.onSnapshot(
        (snapshot) => {
          const outputList = snapshot.docs.map((doc) =>
            models.Asset.fromFirestore(doc)
          )
          // Save the list of locations objects to our app state.
          commit("setAssetsList", outputList)
          commit("stopLoading")
        },
        (error) => {
          Sentry.captureException(error)
          commit("stopLoading")
        }
      )
      commit("addAssetsListListener", queryListener)
    } catch (error) {
      Util.errorMessage(error.message)
      commit("stopLoading")
    }
  },

  // Call this action to begin listening to a query of craftRecords. Call
  // this in the main view component that needs the data. Remember to unsubscribe
  // when that component is destroyed/removed.
  async listenCraftRecordsList(
    { commit, state }: any,
    payload: {
      craftType: models.CraftTypes | null
      open: boolean | null
    }
  ) {
    const { rootUserData, siteKeyUserLocations, siteKeyUserPermissionData } =
      state
    if (
      !rootUserData ||
      !siteKeyUserLocations ||
      (siteKeyUserLocations && siteKeyUserLocations.length === 0)
    )
      return
    commit("startLoading")
    const { defaultSiteKey } = rootUserData
    const { companyID, permissions } = siteKeyUserPermissionData
    let queryListener
    try {
      const ref: CollectionReference = db.collection(
        `siteKeys/${defaultSiteKey}/parentRecords`
      )

      let query: Query = ref
      if (payload.open === false) {
        query = query.where("open", "==", false)
      } else {
        query = query.where("open", "==", true)
      }
      if (payload.craftType) {
        query = query.where("craftType", "==", payload.craftType)
      }
      if (!permissions.isPlantPersonnel)
        query = query.where("authorizedCompanies", "array-contains", companyID)
      queryListener = query.onSnapshot(
        (snapshot) => {
          const outputList = snapshot.docs
            .map((doc) => models.CraftRecord.fromFirestore(doc))
            .filter((item) => siteKeyUserLocations.includes(item.locationID))
          // Save the list of CraftRecord objects to our app state.
          commit("setCraftRecordsList", outputList)
          commit("stopLoading")
        },
        (error) => {
          Util.errorMessage(error.message)
          commit("stopLoading")
        }
      )
      // Save the listener of the query to later be removed.
      commit("addCraftRecordsListListener", queryListener)
    } catch (error) {
      commit("stopLoading")
    }
  },

  /**
   * Listens to deleted parent records collection
   */
  async listenDeletedCraftRecordsList({ commit, state }: any) {
    const { rootUserData, siteKeyUserLocations, siteKeyUserPermissionData } =
      state
    if (
      !rootUserData ||
      !siteKeyUserLocations ||
      (siteKeyUserLocations && siteKeyUserLocations.length === 0)
    )
      return

    commit("startLoading")
    const { defaultSiteKey } = rootUserData
    const { companyID, permissions } = siteKeyUserPermissionData
    let queryListener
    try {
      const ref: CollectionReference = db.collection(
        `siteKeys/${defaultSiteKey}/deletedParentRecords`
      )

      let query: Query = ref
      query = query.where("open", "==", true)
      if (!permissions.isPlantPersonnel)
        query = query.where("authorizedCompanies", "array-contains", companyID)
      queryListener = query.onSnapshot(
        (snapshot) => {
          const outputList = snapshot.docs
            .map((doc) => models.CraftRecord.fromFirestore(doc))
            .filter((item) => siteKeyUserLocations.includes(item.locationID))
          // Save the list of CraftRecord objects to our app state.
          commit("setDeletedCraftRecordsList", outputList)
          commit("stopLoading")
        },
        (error) => {
          Util.errorMessage(error.message)
          commit("stopLoading")
        }
      )
      // Save the listener of the query to later be
      commit("addDeletedCraftRecordsListListener", queryListener)
    } catch (error) {
      commit("stopLoading")
    }
  },

  /**
   * Creates a listener for Inventory Objects
   */
  async listenInventoryObjectList({ commit, state }: any) {
    const { rootUserData, siteKeyUserLocations } = state
    if (
      !rootUserData ||
      !siteKeyUserLocations ||
      (siteKeyUserLocations && siteKeyUserLocations.length === 0)
    )
      return
    commit("startLoading")
    const { defaultSiteKey } = rootUserData
    let queryListener
    try {
      const ref: CollectionReference = db.collection(
        `siteKeys/${defaultSiteKey}/inventoryObjects`
      )
      const query: Query = ref
      queryListener = query.onSnapshot(
        (snapshot) => {
          const records = snapshot.docs.map((doc) =>
            models.InventoryObject.fromFirestore(doc)
          )
          // Save the list of InventoryObject objects to our app state.
          commit("setInventoryObjectList", records)
          commit("stopLoading")
        },
        (error) => {
          Util.errorMessage(error.message)
          commit("stopLoading")
        }
      )
      // Save the listener of the query to later be removed.
      commit("addInventoryObjectListListener", queryListener)
    } catch (error) {
      commit("stopLoading")
    }
  },

  /**
   * Creates a listener for Inventory Transactions
   */
  async listenInventoryTransactionList({ commit, state }: any) {
    const { rootUserData, siteKeyUserLocations } = state
    if (
      !rootUserData ||
      !siteKeyUserLocations ||
      (siteKeyUserLocations && siteKeyUserLocations.length === 0)
    )
      return
    commit("startLoading")
    const { defaultSiteKey } = rootUserData
    let queryListener
    try {
      const ref: CollectionReference = db.collection(
        `siteKeys/${defaultSiteKey}/inventoryTransactions`
      )
      const query: Query = ref
      queryListener = query.onSnapshot(
        (snapshot) => {
          const records = snapshot.docs.map((doc) =>
            models.InventoryTransaction.fromFirestore(doc)
          )
          // Save the list of InventoryTransaction objects to our app state.
          commit("setInventoryTransactionList", records)
          commit("stopLoading")
        },
        (error) => {
          Util.errorMessage(error.message)
          commit("stopLoading")
        }
      )
      // Save the listener of the query to later be removed.
      commit("addInventoryTransactionListListener", queryListener)
    } catch (error) {
      commit("stopLoading")
    }
  },

  // Call this action to begin listening to events.
  listenEvents({ commit, state }: any, payload: { craftRecordID: string }) {
    const { craftRecordID } = payload
    const { rootUserData, siteKeyUserPermissionData } = state
    if (!rootUserData || !siteKeyUserPermissionData) return
    const { defaultSiteKey } = rootUserData
    try {
      let events = []
      let companyListener
      let publicListener
      let publicQuery: Query
      let companyQuery: Query
      const baseQuery: Query = db
        .collection(`siteKeys/${defaultSiteKey}/events`)
        .where("craftRecordID", "==", craftRecordID)

      /*
       * Restrict company personnel to only events for its company or public:
       * if isPlantPersonnel === true, then they should be able to view all events
       * if isPlantPersonnel === false  then they should only be able to view
       * events assigned to the company that they belong to AND public ones (companyID = null)
       */
      const { companyID, permissions } = siteKeyUserPermissionData
      if (!permissions.isPlantPersonnel) {
        companyQuery = baseQuery.where("assignedCompanyID", "==", companyID)
        companyListener = companyQuery.onSnapshot(
          (snapshot) => {
            const companyEvents = snapshot.docs.map((doc) =>
              models.Event.fromFirestore(doc)
            )
            // Remove all company events
            events = events.filter((e) => e.assignedCompanyID !== companyID)
            // Re-add company events
            events = [...companyEvents, ...events]
            // Save events
            commit("setEventList", events)
          },
          (error) => {
            Util.errorMessage(error.message)
          }
        )

        publicQuery = baseQuery.where("assignedCompanyID", "==", null)
        publicListener = publicQuery.onSnapshot(
          (snapshot) => {
            const publicEvents = snapshot.docs.map((doc) =>
              models.Event.fromFirestore(doc)
            )
            // Remove all public events
            events = events.filter((e) => e.assignedCompanyID)
            // Re-add public events
            events = [...publicEvents, ...events]
            // Save events
            commit("setEventList", events)
          },
          (error) => {
            Util.errorMessage(error.message)
          }
        )
        commit("addCompanyEventsListener", companyListener)
      } else {
        publicListener = baseQuery.onSnapshot((snapshot) => {
          events = snapshot.docs.map((doc) => models.Event.fromFirestore(doc))
          // Save events
          commit("setEventList", events)
        })
      }
      commit("addPublicEventsListener", publicListener)
    } catch (error) {
      Util.errorMessage(error.message)
    }
  },

  // Call this action to begin listening to a query of Tasks. Call
  // this in the main view component that needs the data. Remember to unsubscribe
  // when that component is destroyed/removed.
  async listenTaskList({ commit, state }: any, payload: string) {
    const { rootUserData, siteKeyUserLocations, siteKeyUserPermissionData } =
      state
    const { TaskStatus, TaskTypes } = models

    if (
      !rootUserData ||
      !siteKeyUserLocations ||
      (siteKeyUserLocations && siteKeyUserLocations.length === 0)
    )
      return

    commit("startLoading")
    const { defaultSiteKey } = rootUserData
    try {
      const ref = db.collection(`siteKeys/${defaultSiteKey}/tasks`)
      let query: Query
      switch (payload) {
        case "Urgent":
          query = ref
            .where("urgent", "==", true)
            .orderBy("timestampCreated", "desc")
          break
        case "Scheduled":
          query = ref
            .where("taskStatus", "in", [
              TaskStatus.SCHEDULED,
              TaskStatus.AWAITING,
            ])
            .where(
              "timestampScheduled",
              ">=",
              firebase.firestore.Timestamp.fromDate(
                moment().hours(0).seconds(0).milliseconds(0).toDate()
              )
            )
            .orderBy("timestampScheduled", "asc")
          break
        case "Estimate Approval":
          query = ref
            .where("taskStatus", "in", [
              TaskStatus.AWAITING_ESTIMATE,
              TaskStatus.AWAITING_APPROVAL,
            ])
            .orderBy("timestampScheduled", "asc")
          break
        case "Awaiting Review":
          query = ref
            .where("taskStatus", "in", [
              TaskStatus.AWAITING_ESTIMATE,
              TaskStatus.AWAITING_APPROVAL,
              TaskStatus.AWAITING_REVIEW,
              TaskStatus.AWAITING_PARTS,
            ])
            .orderBy("timestampScheduled", "asc")
          break
        case "In Progress":
          query = ref
            .where("taskStatus", "in", [
              TaskStatus.IN_PROGRESS,
              TaskStatus.ON_HOLD,
            ])
            .orderBy("timestampCreated", "desc")
          break
        case "Inspection":
          query = ref
            .where("taskType", "==", TaskTypes.INSPECTION)
            .orderBy("timestampCreated", "desc")
          break
        case "Backlog":
          query = ref
            .where("taskStatus", "==", TaskStatus.AWAITING)
            .orderBy("timestampScheduled", "asc")
          break
        default:
          query = ref
          break
      }
      /*
       * Restrict company personnel to only tasks for its company:
       * if isPlantPersonnel === true, then they should be able to view all
       * tasks that are assigned to all companies.
       * if isPlantPersonnel !== true  then they should only be able to view
       * tasks assigned to the company that they belong to
       */
      const { companyID, permissions } = siteKeyUserPermissionData
      if (!permissions.isPlantPersonnel)
        query = query.where("assignedCompanyID", "==", companyID)
      // Saved craft type
      if (state.globalCraftType !== "all")
        query = query.where(
          "craftType",
          "==",
          parseInt(state.globalCraftType, 10)
        )
      const queryListener = await query.onSnapshot(
        (snapshot) => {
          const outputList = snapshot.docs
            .map((doc) => Tasks.fromFirestore(doc))
            .filter((item) => siteKeyUserLocations.includes(item.locationID))
          // Save the list of Tasks objects to our app state.
          commit("setTaskList", outputList)
          commit("stopLoading")
        },
        (error) => {
          Util.logOnlyOnDev(error)
          Util.errorMessage(error.message)
          commit("stopLoading")
        }
      )
      // Save the listener of the query to later be removed.
      commit("addTasklistListener", queryListener)
    } catch (error) {
      Util.logOnlyOnDev(error)
      Util.errorMessage(error.message)
      commit("stopLoading")
    }
  },

  // Call this action to begin listening to a query of Tasks. Call
  // this in the main view component that needs the data. Remember to unsubscribe
  // when that component is destroyed/removed.
  async listenScheduledAwaitingTaskList({ commit, state }: any) {
    const { rootUserData, siteKeyUserLocations, siteKeyUserPermissionData } =
      state
    const { TaskStatus } = models

    if (
      !rootUserData ||
      !siteKeyUserLocations ||
      (siteKeyUserLocations && siteKeyUserLocations.length === 0)
    )
      return

    commit("startLoading")
    const { defaultSiteKey } = rootUserData
    try {
      const ref = await firebase
        .firestore()
        .collection(`siteKeys/${defaultSiteKey}/tasks`)
      let query: Query
      query = ref.where("taskStatus", "in", [
        TaskStatus.SCHEDULED,
        TaskStatus.AWAITING,
        TaskStatus.AWAITING_ESTIMATE,
        TaskStatus.AWAITING_APPROVAL,
      ])
      /*
       * Restrict company personnel to only tasks for its company:
       * if isPlantPersonnel === true, then they should be able to view all
       * tasks that are assigned to all companies.
       * if isPlantPersonnel !== true  then they should only be able to view
       * tasks assigned to the company that they belong to
       */
      const { companyID, permissions } = siteKeyUserPermissionData
      if (!permissions.isPlantPersonnel)
        query = query.where("assignedCompanyID", "==", companyID)
      // Saved craft type
      if (state.globalCraftType !== "all")
        query = query.where(
          "craftType",
          "==",
          parseInt(state.globalCraftType, 10)
        )
      const queryListener = await query.onSnapshot(
        (snapshot) => {
          const outputList = snapshot.docs
            .map((doc) => Tasks.fromFirestore(doc))
            .filter((item) => siteKeyUserLocations.includes(item.locationID))
          // Save the list of Tasks objects to our app state.
          commit("setScheduledAwaitingTaskList", outputList)
          commit("stopLoading")
        },
        (error) => {
          Util.logOnlyOnDev(error)
          Util.errorMessage(error.message)
          commit("stopLoading")
        }
      )
      // Save the listener of the query to later be removed.
      commit("setScheduledAwaitingTaskListListener", queryListener)
    } catch (error) {
      Util.logOnlyOnDev(error)
      Util.errorMessage(error.message)
      commit("stopLoading")
    }
  },

  async listenDeletedTaskList({ commit, state }: any) {
    const { rootUserData, siteKeyUserLocations, siteKeyUserPermissionData } =
      state

    if (
      !rootUserData ||
      !siteKeyUserLocations ||
      (siteKeyUserLocations && siteKeyUserLocations.length === 0)
    )
      return

    commit("startLoading")
    const { defaultSiteKey } = rootUserData
    try {
      let query: Query = db.collection(
        `siteKeys/${defaultSiteKey}/deletedTasks`
      )
      /*
       * Restrict company personnel to only tasks for its company:
       * if isPlantPersonnel === true, then they should be able to view all
       * tasks that are assigned to all companies.
       * if isPlantPersonnel !== true  then they should only be able to view
       * tasks assigned to the company that they belong to
       */
      const { companyID, permissions } = siteKeyUserPermissionData
      if (!permissions.isPlantPersonnel)
        query = query.where("assignedCompanyID", "==", companyID)
      // Saved craft type
      if (state.globalCraftType !== "all")
        query = query.where(
          "craftType",
          "==",
          parseInt(state.globalCraftType, 10)
        )
      const queryListener = await query.onSnapshot(
        (snapshot) => {
          const outputList = snapshot.docs
            .map((doc) => Tasks.fromFirestore(doc))
            .filter((item) => siteKeyUserLocations.includes(item.locationID))
          // Save the list of Tasks objects to our app state.
          commit("setDeletedTaskList", outputList)
          commit("stopLoading")
        },
        (error) => {
          Util.logOnlyOnDev(error)
          Util.errorMessage(error.message)
          commit("stopLoading")
        }
      )
      // Save the listener of the query to later be removed.
      commit("addDeletedTasklistListener", queryListener)
    } catch (error) {
      Util.logOnlyOnDev(error)
      Util.errorMessage(error.message)
      commit("stopLoading")
    }
  },

  // Here we subscribe to task list specific to a parent record
  async listenCraftRecordTaskList({ commit, state }: any, payload: string) {
    try {
      const { rootUserData, siteKeyUserPermissionData } = state
      if (!rootUserData || !siteKeyUserPermissionData) return
      const { defaultSiteKey } = rootUserData

      let query: Query = db
        .collection(`siteKeys/${defaultSiteKey}/tasks`)
        .where("craftRecordID", "==", payload)

      /*
       * Restrict company personnel to only events for its company or public:
       * if isPlantPersonnel === true, then they should be able to view all events
       * if isPlantPersonnel === false  then they should only be able to view
       * events assigned to the company that they belong to or public ones
       */
      const { companyID, permissions } = siteKeyUserPermissionData

      if (!permissions.isPlantPersonnel)
        query = query.where("assignedCompanyID", "==", companyID)
      const queryListener = await query.onSnapshot(
        (snapshot) => {
          const outputList = snapshot.docs.map((doc) =>
            Tasks.fromFirestore(doc)
          )
          // Save the list of Tasks objects to our app state.
          commit("setCraftRecordTaskList", outputList)
        },
        (error) => {
          Util.errorMessage(error.message)
        }
      )
      // Save the listener of the query to later be removed.
      commit("addCraftRecordTasklistListener", queryListener)
    } catch (error) {
      Util.errorMessage(error.message)
    }
  },

  async listenCraftRecord({ commit, state }: any, payload: string) {
    if (!state.rootUserData) return
    const { defaultSiteKey } = state.rootUserData
    try {
      const query: DocumentReference = await db
        .collection(`siteKeys/${defaultSiteKey}/parentRecords`)
        .doc(payload)
      const queryListener = await query.onSnapshot(
        (snapshot) => {
          const craftDetail = models.CraftRecord.fromFirestore(snapshot)
          // Save the list of Tasks objects to our app state.
          commit("setCraftRecord", craftDetail)
        },
        (error) => {
          Util.errorMessage(error.message)
        }
      )
      // Save the listener of the query to later be removed.
      commit("addCraftRecordListener", queryListener)
    } catch (error) {
      commit("stopLoading")
      Util.errorMessage(error.message)
    }
  },

  //**  view particular task records details get function */

  async viewTask({ commit, state }: any, payload: { id: string }) {
    if (state.rootUserData == null) return
    const defaultSiteKey = state.rootUserData.defaultSiteKey

    const docRef: DocumentReference = db
      .collection(`siteKeys/${defaultSiteKey}/tasks`)
      .doc(payload.id)
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const taskDetail = Tasks.fromFirestore(doc)
          commit("setTask", taskDetail)
        }
      })
      .catch(function (error) {
        Util.errorMessage(error.message)
      })
  },

  // async changeTaskStatus(
  //   { commit, state }: any,
  //   payload: { taskID: string; task: Tasks }
  // ) {
  //   try {
  //     if (!state.rootUserData) return
  //     const defaultSiteKey = state.rootUserData.defaultSiteKey
  //     await db
  //       .collection(`siteKeys/${defaultSiteKey}/tasks`)
  //       .doc(payload.taskID)
  //       .update(payload.task)
  //
  //     commit("setSuccess", string.taskChanged)
  //   } catch (error) {
  //     Util.errorMessage(error.message)
  //   }
  // },

  // Actions for Multi-Craft view.
  async queryMultiCraftRecord({ commit, state }, payload) {
    Util.logOnlyOnDev("Starting multi craft record query.")
    try {
      if (!state.rootUserData) return
      const defaultSiteKey = state.rootUserData.defaultSiteKey

      const docPath = `siteKeys/${defaultSiteKey}/multiCraftRecords/${payload}`
      Util.logOnlyOnDev(docPath)
      const document = await db.doc(docPath).get()
      if (document.exists === false) {
        const msg = `Document ${docPath} doesn't exist!`
        Sentry.captureException(msg)
        Util.logOnlyOnDev(msg)
        return
      }

      commit("setMultiCraftRecord", document.data())
    } catch (error) {
      Sentry.captureException(error)
      Util.logOnlyOnDev(error)
    }
  },

  async queryMultiCraftParentRecords({ commit, state }) {
    Util.logOnlyOnDev("Starting multi craft Parent Records query.")

    try {
      if (!state.rootUserData) return
      const defaultSiteKey = state.rootUserData.defaultSiteKey

      // todo: can change to real-time listeners in the future.
      let parentRecordIDs = state.multiCraftRecord?.craftRecordIDs
      if (!parentRecordIDs || !Array.isArray(parentRecordIDs)) {
        Util.errorMessage(`Expected a list o parent record ids!`)
        return
      }

      // Remove the main Craft Record ID from the list.
      const mainCraftRecordID = state.craftRecord.id
      parentRecordIDs = parentRecordIDs.filter((el) => {
        return el !== mainCraftRecordID
      })

      const queryPromises = parentRecordIDs.map(async (documentId) => {
        const doc = await db
          .doc(`siteKeys/${defaultSiteKey}/parentRecords/${documentId}`)
          .get()
        return models.CraftRecord.fromFirestore(doc)
      })
      const docList = await Promise.all(queryPromises)

      commit("setMultiCraftRecordParentRecords", docList)
    } catch (error) {
      Util.logOnlyOnDev(error)
    }
  },

  async queryMultiCraftTasks({ commit, state }) {
    Util.logOnlyOnDev("Starting multi craft Tasks query.")

    try {
      if (!state.rootUserData) return
      const defaultSiteKey = state.rootUserData.defaultSiteKey

      // todo: can change to real-time listeners in the future.
      let parentRecordIDs = state.multiCraftRecord?.craftRecordIDs
      if (!parentRecordIDs || !Array.isArray(parentRecordIDs)) {
        Util.errorMessage(`Expected a list o parent record ids!`)
        return
      }

      // Remove the main Craft Record ID from the list.
      const mainCraftRecordID = state.craftRecord.id
      parentRecordIDs = parentRecordIDs.filter((el) => {
        return el !== mainCraftRecordID
      })

      let allTaskResults: Tasks[] = []
      const queryPromises = parentRecordIDs.map(async (documentId) => {
        const fullCraftRecordPath = `siteKeys/${defaultSiteKey}/parentRecords/${documentId}`
        const query = await db
          .collection(`siteKeys/${defaultSiteKey}/tasks`)
          .where("craftRecordID", "==", fullCraftRecordPath)

        const querySnapshot = await query.get()
        const docList = querySnapshot.docs.map((doc) =>
          Tasks.fromFirestore(doc)
        )
        allTaskResults = allTaskResults.concat(docList)
      })
      await Promise.all(queryPromises)

      commit("setMultiCraftRecordTasks", allTaskResults)
    } catch (error) {
      Sentry.captureException(error)
      Util.logOnlyOnDev(error)
    }
  },
}
