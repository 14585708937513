
// Models
import { TaskTypes } from "@/models/models"

// Libs
import firebase from "firebase/app"
import { db } from "@/firebase-init"
import { Util } from "@/helpers"
import { string } from "@/string"

export default {
  props: ["show", "task", "companies"],
  data() {
    return {
      theme: process.env.VUE_APP_THEME,
      createNewTask: false,
      newTask: {
        taskType: this.task.taskType + 1,
        taskName: TaskTypes.getTaskTypeString(this.task.taskType + 1),
      },
    }
  },
  methods: {
    close() {
      this.newTask = {}
      this.$emit("close")
    },
    async save() {
      if (!this.createNewTask) {
        this.$emit("close")
        return
      }
      if (!this.$refs.form.validate()) return
      const { rootUserData } = this.$store.state.firetableModule
      const taskData = {
        assignedCompanyID: this.newTask.assignedCompanyID,
        craftRecordID: this.task.craftRecordID,
        craftRecordPersistence: this.task.craftRecordPersistence,
        craftType: this.task.craftType,
        createdBy: `${rootUserData.id}`,
        crewCount: 0,
        description: this.newTask.description,
        durations: {},
        holdDurations: {},
        locationID: this.task.locationID,
        nextOpportunity: false,
        notifyCompanyOnCreation: true,
        taskSpecificDetails: {},
        taskStatus: 30,
        taskType: this.task.taskType + 1,
        thumbnailURL: null,
        timestampAwaitingStart: null,
        timestampCreated: firebase.firestore.FieldValue.serverTimestamp(),
        timestampScheduled: firebase.firestore.FieldValue.serverTimestamp(),
        timestampTaskCompleted: null,
        timestampTaskStarted: null,
        timestampLastModified: firebase.firestore.FieldValue.serverTimestamp(),
        lastModifiedBy: `${rootUserData.id}`,
        title: `${this.newTask.taskName} - ${this.task.title.split("-").pop()}`,
        urgent: false,
        workOrder: null,
      }
      // save task record details.
      const taskDocPath = `siteKeys/${rootUserData.defaultSiteKey}/tasks`
      const saveTask = await Util.saveTask(db, taskDocPath, taskData)

      if (!saveTask) {
        this.$store.commit("firetableModule/setError", string.generalError)
        return
      }
      this.$store.commit("firetableModule/setSuccess", string.taskAdded)
      this.$emit("close")
    },
  },
}
