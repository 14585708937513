
import Vue from "vue"
import { SiteKeyUsers } from "../models/models"
import { db } from "../firebase-init"
import { Util } from "../helpers"
import { RootUser, SiteKeyUserPermission } from "../models/models"
import NoDefaultSiteKeyCard from "@/components/NoDefaultSiteKeyCard.vue"
import { mapGetters } from "vuex"
import ApplyToSiteKeyModal from "@/components/ApplyToSiteKeyModal.vue"

interface CreateObject {
  theme: string
  siteKeyPopup: boolean
  locationPopup: boolean
  phonePopup: boolean
  rootUserSiteKeys: { [key: string]: any }
  siteLocations: Location[]
  siteKeyUserLocations: any[]
  siteLocationStatus: { [key: string]: boolean }
  siteKey: string
  phoneNo: number
  showNewSiteKeyDialog: boolean
}
export default Vue.extend({
  data(): CreateObject {
    return {
      theme: process.env.VUE_APP_THEME,
      siteKeyPopup: false,
      locationPopup: false,
      phonePopup: false,
      rootUserSiteKeys: {},
      siteLocations: [],
      siteKeyUserLocations: [],
      siteLocationStatus: {},
      siteKey: "",
      phoneNo: 0,
      showNewSiteKeyDialog: false,
    }
  },
  components: {
    ApplyToSiteKeyModal,
    NoDefaultSiteKeyCard,
  },
  computed: {
    ...mapGetters("rootUserPrivateDocModule", ["getRootUserSiteKeys"]),
    rootUser(): RootUser {
      return this.$store.state.firetableModule.rootUserData
    },
    siteKeyUser(): SiteKeyUsers {
      return this.$store.state.firetableModule.siteKeyUserData
    },
    siteKeyPermissionData(): SiteKeyUserPermission | null {
      return this.$store.state.firetableModule.siteKeyUserPermissionData
    },
    defaultSiteKey(): string | null {
      if (!this.$store.state.firetableModule.rootUserData) return null
      return this.$store.state.firetableModule.rootUserData.defaultSiteKey
    },
    isAdmin() {
      return this.siteKeyPermissionData?.permissions?.isSiteAdmin
    },
    isDefaultSiteKeyApproved(): boolean {
      if (typeof this.rootUser?.defaultSiteKey === "string") {
        return this.getRootUserSiteKeys[this.rootUser.defaultSiteKey] === true
      } else {
        return false
      }
    },
  },
  methods: {
    /* change sitkey user notification method. */
    async notificationChange(val) {
      const rootUser = this.$store.state.firetableModule.rootUserData
      if (rootUser) {
        await Util.changeNotificationStatus(db, rootUser.refPath, val)
      }
    },
    /* When click defaultsite key means open siteKey popup method. */
    async defaultSiteKeyClick() {
      const rootUser = this.$store.state.firetableModule.rootUserData
      if (rootUser) {
        this.siteKeyPopup = true
        const rootUserPermissionPath = `${rootUser.refPath}/privateColl/privateDoc/`
        this.rootUserSiteKeys = await Util.rootUserSiteKeys(
          db,
          rootUserPermissionPath
        )
      }
    },
    /* change default site Key method. */
    async changeDefaultSiteKey() {
      const rootUser = this.$store.state.firetableModule.rootUserData
      this.siteKeyPopup = false
      if (rootUser) {
        await Util.changeDefaultSiteKey(db, rootUser.refPath, this.siteKey)
      }
    },

    /* When click select locations  means open location popup method.
     * and get some initial datas
     */
    async selectLocationClick() {
      this.locationPopup = true
      this.siteKeyUserLocations = []
      const siteKeyUser = this.$store.state.firetableModule.siteKeyUserData
      if (siteKeyUser) {
        const siteKeyUserPermissionPath = `${siteKeyUser.refPath}/userLocations`
        const locations = await Util.getsiteKeyUserLocations(
          db,
          siteKeyUserPermissionPath
        )
        // Ordering
        if (this.$store.state.firetableModule.siteKeyLocations) {
          this.siteLocations =
            this.$store.state.firetableModule.siteKeyLocations.sort((a, b) =>
              a.title.localeCompare(b.title)
            )
          for (const loc of this.siteLocations) {
            this.siteKeyUserLocations.push(
              locations.find((l) => l.id === loc.id)
            )
          }
        }
      }
    },
    getLocation(locId: string) {
      return this.siteLocations.find((loc) => loc.id === locId)
    },
    /* change sitekey user locations settings method. */
    async changesiteKeyUserLocations(locId: string) {
      const siteKeyUser = this.$store.state.firetableModule.siteKeyUserData
      this.siteKeyPopup = false
      if (siteKeyUser) {
        const { id } = this.siteKeyUserLocations.find((loc) => loc.id === locId)
        const siteKeyUserLocationPath = `${siteKeyUser.refPath}/userLocations/${id}`
        const locationObj = {
          key: id,
          value: this.siteLocationStatus[id],
        }
        await Util.changeSiteKeyUserLocations(
          db,
          siteKeyUserLocationPath,
          locationObj
        )
      }
    },
    /* save siteKey user phone number method. */
    async savePhoneNo() {
      const siteKeyUser = this.$store.state.firetableModule.siteKeyUserData
      this.phonePopup = false
      if (siteKeyUser) {
        await Util.updatePhoneNo(db, siteKeyUser.refPath, this.phoneNo)
      }
    },
  },
})
