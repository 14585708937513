import { Module } from "vuex"
import firebase from "firebase/app"
import "firebase/auth"

import * as models from "@/models/models"
import { Tasks } from "@/models/task"
import { mutations } from "@/store/mutations"
import { actions } from "@/store/actions"
import { CraftRecord } from "@/models/models"

// Set some strong typing for use in our state object.
export interface TableState {
  siteKey: models.SiteKey | null
  siteKeyListener: () => void | null
  currentUser: firebase.User | null
  rootUserData: models.RootUser | null
  rootUserDataListener: (() => void) | null
  siteKeyUserData: models.SiteKeyUsers | null
  siteKeyUserDataListener: (() => void) | null
  siteKeyUserPermissionData: models.SiteKeyUserPermission | null
  siteKeyUserPermissionListener: (() => void) | null
  craftRecordsList: Array<models.CraftRecord> | null
  craftRecordsListListener: (() => void) | null
  deletedCraftRecordsList: Array<models.CraftRecord> | null
  deletedCraftRecordsListListener: (() => void) | null
  craftRecordTaskList: Array<Tasks> | null
  craftRecordTasklistListener: (() => void) | null
  deletedTaskList: Array<Tasks> | null
  deletedTaskListListener: (() => void) | null
  siteKeyCompanies: Array<models.SiteKeyCompany> | null
  siteKeyCompaniesListener: (() => void) | null
  eventList: Array<models.Event> | null
  inventoryObjectList: Array<models.InventoryObject> | null
  inventoryTransactionList: Array<models.InventoryTransaction> | null
  publicEventsListener: (() => void) | null
  companyEventsListener: (() => void) | null
  siteKeyLocations: Array<models.Location> | null
  siteKeyLocationsListener: (() => void) | null
  siteKeyUserLocations: Array<models.SiteKeyUserLocations> | null
  siteKeyUserLocationsListener: (() => void) | null
  siteKeyUsers: Array<models.SiteKeyUsers> | null
  siteKeyUsersListener: (() => void) | null
  assetsList: Array<models.Asset> | null
  assetsListListener: (() => void) | null
  craftRecord: models.CraftRecord | null
  isLoading: boolean
  error: string | null
  success: string | null
  clicks: number
  taskList: Array<Tasks> | null
  tasklistListener: (() => void) | null
  scheduledAwaitingTaskList: Array<Tasks> | null
  scheduledAwaitingTaskListListener: (() => void) | null
  task: Tasks | null
  globalCraftType: string | number

  multiCraftRecord: unknown | null
  multiCraftRecordParentRecords: unknown | null
  multiCraftRecordTasks: unknown | null
  multiCraftRecordEvents: unknown | null
}

export const state: any = {
  siteKey: null,
  siteKeyListener: null,
  currentUser: null,
  rootUserData: null,
  rootUserDataListener: null,
  siteKeyUserData: null,
  siteKeyUserDataListener: null,
  craftRecordsList: null,
  craftRecordsListListener: null,
  craftRecordTaskList: null,
  craftRecordTasklistListener: null,
  deletedCraftRecordsList: null,
  deletedCraftRecordsListListener: null,
  deletedTaskList: null,
  deletedTaskListListener: null,
  craftRecord: null,
  siteKeyLocations: null,
  siteKeyLocationsListener: null,
  siteKeyUserLocations: null,
  siteKeyUserLocationsListener: null,
  siteKeyUserPermissionData: null,
  siteKeyUserPermissionListener: null,
  siteKeyCompanies: null,
  siteKeyCompaniesListener: null,
  siteKeyUsers: null,
  siteKeyUsersListener: null,
  eventList: null,
  inventoryObjectList: null,
  inventoryTransactionList: null,
  publicEventsListener: null,
  companyEventsListener: null,
  assetsList: null,
  assetsListListener: null,
  isLoading: false,
  error: null,
  success: null,
  clicks: 0,
  taskList: null,
  tasklistListener: null,
  task: null,
  globalCraftType: "all",
  scheduledAwaitingTaskList: null,
  scheduledAwaitingTaskListListener: null,
  multiCraftRecord: null,
  multiCraftRecordParentRecords: null,
  multiCraftRecordTasks: null,
  multiCraftRecordEvents: null,
}

export const firetableModule: Module<TableState, any> = {
  namespaced: true,
  state,
  mutations,
  actions,
}
