<template>
  <a-tooltip>
    <template slot="title">
      {{ kpi.tooltip }}
    </template>
    <v-card
      :elevation="selectedKpi.key === kpi.key ? 2 : 0"
      outlined
      class="p-2 stats-card"
      @click="$emit('click')"
    >
      <a-statistic
        class="d-flex flex-column-reverse align-center text-center pointer"
        :title="kpi.title"
        :value="kpi.value"
        :precision="kpi.precision"
        :value-style="{ color: kpi.color || undefined }"
      >
        <template #formatter v-if="!kpi.displayCardValue">
          <a-icon :type="kpi.icon" />
        </template>
        <template #prefix v-if="kpi.displayCardValue">
          <a-icon :type="kpi.icon" class="stats-icon" />
        </template>
      </a-statistic>
    </v-card>
  </a-tooltip>
</template>

<script>
export default {
  props: ["kpi", "selectedKpi"],
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.stats-icon {
  font-size: 16px;
  position: absolute;
  left: 10px;
  top: 10px;
}
.stats-card {
  height: 100px;
}
</style>
