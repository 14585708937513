import Vue from "vue"
import Router from "vue-router"
import HomePage from "@/components/Home.vue"
import LoginPage from "./views/Login.vue"
import AppDownload from "./views/AppDownload.vue"
import ForgotPasswordPage from "./views/ForgotPassword.vue"
import RegisterPage from "./views/CreateUser.vue"
import UserInfoPage from "./views/UserInfo.vue"
import DeletedCraftList from "@/views/CraftList/Deleted.vue"
import DeletedTaskList from "@/views/TaskList/Deleted.vue"
import TaskList from "@/views/TaskList/List.vue"
import UsageMetrics from "@/views/UsageMetrics/UsageMetrics.vue"
import KPIDash from "@/views/KPIs/Dashboard.vue"
import UserPermissionPage from "./views/UserPermission.vue"
import CreateTaskPage from "./views/CreateTask.vue"
import AssetListPage from "./views/AssetList.vue"
import CraftRecord from "./views/CraftRecord/CraftRecord.vue"
import MapViewPage from "@/views/MapFilterView.vue"
import InventoryTrxView from "@/views/Inventory/Transactions.vue"
import InventoryObjectsView from "@/views/Inventory/Objects.vue"
import ChangePasswordPage from "@/views/ChangePassword.vue"
import NotFoundComponent from "@/components/NotFoundComponent.vue"
import LocationsManagementIFrame from "@/views/LocationsManagementIFrame.vue"
import CompanyManagementIFrame from "@/views/CompanyManagementIFrame.vue"
import firebase from "firebase/app"
import CreateCustomerTaskIFrame from "@/views/CreateCustomerTaskIFrame.vue"

Vue.use(Router)

/**
 * Used with spread operator (...) to insert object into an array based on a
 * condition.
 */
function insertIf(condition: boolean, ...elements) {
  return condition ? elements : []
}

// const isDevApp = process.env.VUE_APP_FIREBASE_PROJECT === "stilt-dev"

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "main",
      component: LoginPage,
    },
    {
      path: "/login",
      name: "login",
      component: LoginPage,
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ForgotPasswordPage,
    },
    {
      path: "/home",
      name: "home",
      component: HomePage,
      meta: { requiresAuth: true },
    },
    {
      path: "/download",
      name: "download",
      component: AppDownload,
      meta: { requiresAuth: true },
    },
    {
      path: "/change-password",
      name: "change-password",
      component: ChangePasswordPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/customers/estimates/:estimateID",
      name: "view-estimate",
      component: () =>
        import(
          /* webpackChunkName: "react-pricebook" */ "@/views/ViewEstimateIFrame.vue"
        ),
      meta: { requiresAuth: true },
    },
    ...insertIf(process.env.VUE_APP_ENABLE_CUSTOMERS === "true", {
      path: "/customers",
      name: "customers",
      component: () =>
        import(
          /* webpackChunkName: "react-customers" */ "@/views/CustomersIFrame.vue"
        ),
      meta: { requiresAuth: true },
    }),

    {
      path: "/pricebook",
      name: "pricebook",
      component: () =>
        import(
          /* webpackChunkName: "react-pricebook" */ "@/views/PriceBookIFrame.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/customers-work-record-list/:craftType",
      name: "work-record-list",
      component: () =>
        import(
          /* webpackChunkName: "react-craft-record-list-by-type" */ "@/views/CustomersWorkRecordListIFrame.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/craft-list/:craftType",
      name: "craft-list",
      component: () =>
        import(
          /* webpackChunkName: "craft-list" */ "@/views/CraftList/List.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/customers-work-record-list-deleted",
      name: "work-record-list-deleted",
      component: () =>
        import(
          /* webpackChunkName: "react-work-record-list-deleted" */ "@/views/CustomersDeletedWorkRecordListIFrame.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/craft-list-deleted",
      name: "craft-list-deleted",
      component: DeletedCraftList,
      meta: { requiresAuth: true },
    },
    {
      path: "/customers-task-list-deleted",
      name: "task-list-deleted",
      component: () =>
        import(
          /* webpackChunkName: "react-task-list-deleted" */ "@/views/DeletedCustomersTaskListIFrame.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/task-list-deleted",
      name: "task-list-deleted",
      component: DeletedTaskList,
      meta: { requiresAuth: true },
    },
    {
      path: "/user-info",
      name: "user-info",
      component: UserInfoPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/create-user",
      name: "create-user",
      component: RegisterPage,
    },
    {
      path: "/craft-record/:id/:taskID?",
      name: "craft-record",
      component: CraftRecord,
      meta: { requiresAuth: true },
    },
    // todo: remove feature toggle when ready for production
    ...insertIf(process.env.VUE_APP_ENABLE_MULTICRAFT === "true", {
      path: "/multi-craft-record/:id/:taskID?",
      name: "multi-craft-record",
      component: () =>
        import(
          /* webpackChunkName: "multi-craft-record" */ "@/views/CraftRecord/MultiCraftRecord.vue"
        ),
      meta: { requiresAuth: true },
    }),
    {
      path: "/assigned-tasks",
      name: "assigned-tasks",
      component: () =>
        import(
          /* webpackChunkName: "assigned-tasks-layout" */ "@/views/AssignedTasksLayout.vue"
        ),
      meta: { requiresAuth: true },
    },
    // todo: remove
    {
      path: "/admin/user-management",
      name: "user-management",
      component: () =>
        import(
          /* webpackChunkName: "user-management" */ "@/views/UserManagementIFrame.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/report-templates",
      name: "report-templates",
      component: () =>
        import(
          /* webpackChunkName: "user-management" */ "@/views/KPIsV2IFrame.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/checklists",
      name: "checklists",
      component: () =>
        import(
          /* webpackChunkName: "react-checklists" */ "@/views/ChecklistsIFrame.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/admin/my-sites",
      name: "admin-my-sites",
      component: () =>
        import(
          /* webpackChunkName: "react-admin-my-sites" */ "@/views/AdminMySitesIFrame.vue"
        ),
      meta: { requiresAuth: true },
    },
    ...insertIf(process.env.VUE_APP_ENABLE_COMPLIANCE === "true", {
      path: "/compliance",
      name: "compliance",
      component: () =>
        import(
          /* webpackChunkName: "react-compliance" */ "@/views/ComplianceIFrame.vue"
        ),
      meta: { requiresAuth: true },
    }),
    {
      path: "/user-permissions",
      name: "user-permissions",
      component: UserPermissionPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/customers-task-list/:taskParam",
      name: "task-list",
      component: () =>
        import(
          /* webpackChunkName: "react-task-list" */ "@/views/CustomersTaskListIFrame.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/task-list/:taskParam",
      name: "task-list",
      component: TaskList,
      meta: { requiresAuth: true },
    },
    {
      path: "/create-task",
      name: "create-task",
      component: CreateTaskPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/create-task/:craftType/:craftID",
      name: "CreateTask",
      component: CreateTaskPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/create-customer-task",
      name: "Create Task for Customer",
      component: CreateCustomerTaskIFrame,
      meta: { requiresAuth: true },
    },
    {
      path: "/kpis/dashboard",
      name: "KPIs",
      component: KPIDash,
      meta: { requiresAuth: true },
    },
    ...insertIf(process.env.VUE_APP_ENABLE_ROUTING === "true", {
      path: "/scheduling",
      name: "Scheduling",
      component: () =>
        import(
          /* webpackChunkName: "react-scheduling" */ "@/views/SchedulingIFrame.vue"
        ),
      meta: { requiresAuth: true },
    }),
    {
      path: "/estimates",
      name: "Estimates",
      component: () =>
        import(
          /* webpackChunkName: "react-estimates" */ "@/views/EstimatesIFrame.vue"
        ),
      meta: { requiresAuth: true },
    },
    ...insertIf(process.env.VUE_APP_ENABLE_INVOICES === "true", {
      path: "/invoices",
      name: "Invoices",
      component: () =>
        import(
          /* webpackChunkName: "react-invoices" */ "@/views/InvoicesIFrame.vue"
        ),
      meta: { requiresAuth: true },
    }),
    {
      path: "/work-record-and-tasks/:id",
      name: "Work Details",
      component: () =>
        import(
          /* webpackChunkName: "react-work-record-task-detail" */ "@/views/WorkRecordTaskDetailIFrame.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/usage-metrics",
      name: "Usage Metrics",
      component: UsageMetrics,
      meta: { requiresAuth: true },
    },
    {
      path: "/asset-list",
      name: "AssetList",
      component: AssetListPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/location-list",
      name: "LocationList",
      component: LocationsManagementIFrame,
      meta: { requiresAuth: true },
    },
    {
      path: "/company-list",
      name: "CompanyList",
      component: CompanyManagementIFrame,
      meta: { requiresAuth: true },
    },
    {
      path: "/inventory/transactions",
      name: "InventoryTransactions",
      component: InventoryTrxView,
      meta: { requiresAuth: true },
    },
    {
      path: "/inventory/objects",
      name: "InventoryObjects",
      component: InventoryObjectsView,
      meta: { requiresAuth: true },
    },
    {
      path: "/map-view",
      name: "MapView",
      component: MapViewPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/reports",
      name: "reports",
      component: () =>
        import(
          /* webpackChunkName: "group-reports" */ "@/views/ReportsContainer.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/feedback",
      name: "Feedback",
      component: () =>
        import(
          /* webpackChunkName: "react-invoices" */ "@/views/FeedbackIFrame.vue"
        ),
      meta: { requiresAuth: true },
    },
    // Catch all for 404 not found responses when using history mode.
    {
      path: "*",
      component: NotFoundComponent,
    },
  ],
})

router.beforeEach((to, _, next) => {
  return firebase.auth().onAuthStateChanged((user) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!user) {
        if (to.path !== "/login") localStorage.setItem("next", to.path)
        return next({ path: "/login" })
      }
      next()
    } else if (user && (to.path === "/login" || to.path === "/")) {
      next({ path: "/home" })
    } else {
      next()
    }
  })
})
