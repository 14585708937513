
// Libs
import moment from "moment"

// Models
import {
  GlobalCraftType,
  CraftType,
  TaskTypes,
  KPIMetric,
} from "@/models/models"

export default {
  props: ["companies", "locations", "loading", "defaultFilters", "selectedKpi"],
  data() {
    return {
      theme: process.env.VUE_APP_THEME,
      filters: this.defaultFilters,
      filteredCraftTypes: [],
      filteredCompanies: [],
      taskTypes: TaskTypes.values().map((taskType) => ({
        value: taskType,
        name: TaskTypes.getTaskTypeString(taskType),
      })),
    }
  },
  watch: {
    selectedKpi(metric: KPIMetric | null) {
      if (!metric) return
      // Filter Craft Types
      this.filteredCraftTypes = GlobalCraftType.values().map((craftType) => ({
        value: craftType,
        name: CraftType.getCraftTypeString(craftType),
      }))
      // Filter companies
      this.filteredCompanies = this.companies
    },
  },
  methods: {
    moment,
    getObjectKeys(obj) {
      return Object.keys(obj).sort()
    },
    filterDepartments(selected) {
      this.filters.departments = selected
    },
    update() {
      this.$emit("updateFilters", {
        ...this.filters,
        dates: [
          moment(
            `${this.filters.dates[0].format("MM/DD/YYYY")} 00:00:00`,
            "MM/DD/YYYY HH:mm:ss"
          ).toDate(),
          moment(
            `${this.filters.dates[1].format("MM/DD/YYYY")} 23:59:59`,
            "MM/DD/YYYY HH:mm:ss"
          ).toDate(),
        ],
      })
    },
  },
}
