
import Vue from "vue"
import firebase from "firebase/app"
import "firebase/functions"
import { Util } from "@/helpers"
import { db } from "../firebase-init"

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
    },
    buttonText: {
      type: String,
      default: "Apply",
    },
  },
  data() {
    return {
      title: "Apply to a New Site",
      siteKey: "",
      completed: false,
      responseText: "",
      errorText: "",
      loading: false,
    }
  },
  computed: {
    show: {
      get(): boolean {
        return this.value
      },
      set(value: boolean) {
        this.$emit("input", value)
      },
    },
    sendButtonDisabled() {
      if (this.siteKey.length === 0) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async sendApplication(siteKey: string) {
      this.loading = true
      const applyToSiteKey = firebase
        .functions()
        .httpsCallable("applyToSiteKey")
      try {
        await applyToSiteKey({ siteKey: siteKey })
        this.completed = true
        this.responseText = "Success! Your application has been sent."
        if (
          this.$store.state.firetableModule.rootUserData?.defaultSiteKey ===
          null
        ) {
          const rootUser = this.$store.state.firetableModule.rootUserData
          if (rootUser) {
            await Util.changeDefaultSiteKey(db, rootUser.refPath, siteKey)
          }
        }
      } catch (e) {
        this.errorText = e.message
      } finally {
        this.loading = false
      }
    },
    clearAndCloseModal(): void {
      this.completed = false
      this.siteKey = ""
      this.responseText = ""
      this.errorText = ""
      this.show = false
    },
  },
})
