
import Vue from "vue"
import "firebase/auth"
import "firebase/firestore"
import { Location, Asset } from "../models/models"
import { db } from "../firebase-init"
import { Util } from "../helpers"
import { string } from "../string"
import { ValidationProvider, extend, ValidationObserver } from "vee-validate"
import { required } from "vee-validate/dist/rules"
import { RootUser, SiteKeyUserPermission } from "../models/models"

extend("required", {
  ...required,
  message: "This field is required",
})

interface AssetObject {
  theme: string
  title: string
  search: string //define class object in data values
  locations: Array<Location> //define class object in data values
  locationPosition: {}
  asset: Asset
  dialog: boolean
  headers: any[]
  loading: boolean
  view: boolean
}
export default Vue.extend({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data(): AssetObject {
    return {
      theme: process.env.VUE_APP_THEME,
      title: "",
      search: "",
      locations: Array<Location>(),
      loading: false,
      asset: {} as Asset,
      dialog: false,
      headers: [
        { text: "Title", value: "title" },
        { text: "Description", value: "description" },
        { text: "Location", value: "location" },
        { text: "Actions", value: "action", sortable: false },
      ],
      view: false,
      locationPosition: {},
    }
  },

  computed: {
    currentUser(): RootUser | null {
      return this.$store.state.firetableModule.currentUser
    },
    formIsValid(): boolean {
      return true
    },
    siteKeyPermissionData(): SiteKeyUserPermission | null {
      return this.$store.state.firetableModule.siteKeyUserPermissionData
    },
    myData(): Asset[] | null {
      if (
        this.$store.state.firetableModule.assetsList == null ||
        this.$store.state.firetableModule.siteKeyLocations == null
      )
        return null
      const locations = this.$store.state.firetableModule.siteKeyLocations
      return this.$store.state.firetableModule.assetsList.map(
        (record: Asset) => {
          return {
            id: record.id,
            title: record.title,
            description: record.description,
            location: this.locationNameFetch(record.locationID, locations),
          }
        }
      )
    },
    // Compute the default site key based off rootUserData state.
    defaultSiteKey(): string | null {
      if (this.$store.state.firetableModule.rootUserData == null) return null
      return this.$store.state.firetableModule.rootUserData.defaultSiteKey
    },
    isLoading(): boolean {
      return this.$store.state.firetableModule.isLoading
    },
  },

  watch: {
    defaultSiteKey(newValue: string | null) {
      if (newValue == null) return
      this.startListening()
    },
  },

  mounted() {
    if (this.defaultSiteKey != null) {
      this.startListening()
    }
  },

  beforeDestroy(): void {
    this.$store.commit("firetableModule/unsubscribeAssetsListListener")
    this.$store.commit("firetableModule/removeAssetsList")
  },

  methods: {
    startListening() {
      this.$store.dispatch("firetableModule/listenAssetsList")
    },
    clearAndCloseModal(): void {
      this.dialog = false
      this.view = false
    },
    fieldNotEmpty(value: string): string | boolean {
      if (value.length === 0) {
        return "This field is required."
      } else {
        return true
      }
    },
    async getData(assetID: string | null, view: boolean | undefined) {
      this.dialog = true
      this.loading = true
      this.asset = {} as Asset
      if (
        this.$store.state.firetableModule.rootUserData == null ||
        this.$store.state.firetableModule.siteKeyUserPermissionData == null
      )
        return null
      const defaultSiteKey =
        this.$store.state.firetableModule.rootUserData.defaultSiteKey

      // Util.logOnlyOnDev(this.locations)
      if (this.locations.length > 0) {
        this.asset.locationID = this.locations[0].id ? this.locations[0].id : ""
      }

      if (assetID) {
        this.title = "Edit Asset"
        const assetDetail = await Util.getAssetDetail(
          db,
          defaultSiteKey,
          assetID
        )
        if (assetDetail) {
          this.asset = assetDetail
          if (view) {
            this.view = true
            const location = this.locations.filter((record: Location) => {
              return this.asset.locationID == record.id
            })
            this.locationPosition = {
              lat: location[0].latitude ? location[0].latitude : 0,
              lng: location[0].longitude ? location[0].longitude : 0,
            }
          }
        }
      } else {
        this.title = "Create Asset"
      }
      this.loading = false
    },
    locationNameFetch(locationID: string, locations: Location[]) {
      this.locations = locations
      const location = locations.filter((record: Location) => {
        return locationID == record.id
      })
      return location ? location[0].title : ""
    },

    async submitAsset() {
      if (this.defaultSiteKey == null || this.siteKeyPermissionData == null)
        return null
      this.loading = true
      try {
        if (!this.siteKeyPermissionData.permissions.isPlantPersonnel) {
          this.$store.commit("firetableModule/setError", string.permissionError)
          return
        }

        const assetRecord = new Asset(this.asset).toMap()
        if (this.asset.id) {
          const assetPath = `siteKeys/${this.defaultSiteKey}/assets/${this.asset.id}`
          const saveAsset = await Util.saveAsset(
            db,
            assetPath,
            assetRecord,
            "update"
          )
          if (saveAsset) {
            this.$store.commit(
              "firetableModule/setSuccess",
              string.assetUpdated
            )
          }
        } else {
          const assetPath = `siteKeys/${this.defaultSiteKey}/assets`
          const saveAsset = await Util.saveAsset(
            db,
            assetPath,
            assetRecord,
            "add"
          )
          if (saveAsset) {
            this.$store.commit("firetableModule/setSuccess", string.assetAdded)
          }
        }
      } catch (error) {
        // todo: add sentry error catching.
        Util.errorMessage(error.message)
      } finally {
        this.loading = false
        this.clearAndCloseModal()
      }
    },
  },
})
