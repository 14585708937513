
import Vue from "vue"
// Libs
import { db } from "@/firebase-init"
import { Util } from "@/helpers"
import { string } from "@/string"
import firebase from "firebase/app"
import "firebase/functions"
import * as Sentry from "@sentry/browser"

// Models
import { DownloadCode } from "@/models/models"

export default Vue.extend({
  data() {
    return {
      loading: false,
      theme: process.env.VUE_APP_THEME,
      logo: process.env.VUE_APP_LOGO,
      slogan: process.env.VUE_APP_DESC,
    }
  },
  computed: {
    rootUserData(): string | null {
      return this.$store.state.firetableModule.rootUserData
    },
  },
  methods: {
    async sendDownloadCode(platform) {
      try {
        this.loading = true
        //Get next available code
        let code: DownloadCode = null
        if (platform === "ios" && this.theme === "aimpoint") {
          code = await Util.getDownloadCode(db)
          if (!code) {
            const msg = "No iOS download codes available"
            Util.logOnlyOnDev(msg)
            return Util.errorMessage(msg)
          }
        }

        const sendAppDownloadCodeLink = firebase
          .functions()
          .httpsCallable("sendAppDownloadCodeLink")
        await sendAppDownloadCodeLink({
          platform,
          code: code ? code.id : null,
          version: this.theme,
        })
        // Mark it as redeemed
        if (code)
          await Util.redeemDownloadCode(db, code.refPath, this.rootUserData.id)
        this.loading = false
        this.$store.commit(
          "firetableModule/setSuccess",
          string.downloadCodeSent
        )
      } catch (error) {
        Util.logOnlyOnDev(error)
        Sentry.captureException(error)
        this.loading = false
        Util.errorMessage(error.message)
      }
    },
  },
})
