var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"m-0"},[_c('v-col',{staticClass:"d-flex align-center justify-start pl-0",attrs:{"lg":"12","cols":"12"}},[_c('v-btn',{staticClass:"float-left p-0 primary--text mr-3",attrs:{"height":"50","text":"","to":"/home"}},[_c('span',{staticClass:"f-35 mdi mdi-arrow-left"})]),_c('h3',{staticClass:"primary--text f-f f-30 mb-0"},[_vm._v("Map View")])],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"position":"relative"}},[_c('GmapMap',{style:(`height: ${_vm.window.height - 200}px`),attrs:{"center":_vm.center,"zoom":16,"map-type-id":"satellite","options":{
          disableDefaultUI: true,
        }}},[_vm._l((_vm.filteredTasks),function(task,index){return _c('GmapMarker',{key:index,attrs:{"position":{
            lat: task.parentRecord.latitude,
            lng: task.parentRecord.longitude,
          },"icon":{
            path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
            fillColor: task.taskAssignedColor,
            fillOpacity: 1,
            strokeColor: 'black',
            strokeWeight: 1,
            scale: 2,
            anchor: { x: 12, y: 24 },
          },"clickable":true},on:{"click":() => (_vm.selectedTask = task)}})}),(_vm.selectedTask)?_c('gmap-info-window',{attrs:{"opened":_vm.selectedTask !== null,"position":{
            lat: _vm.selectedTask.parentRecord.latitude,
            lng: _vm.selectedTask.parentRecord.longitude,
          },"options":{
            pixelOffset: {
              width: 0,
              height: -35,
            },
          }},on:{"closeclick":(value) => (_vm.selectedTask = null)}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"450"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_c('img',{staticStyle:{"width":"30px","height":"30px","filter":"grayscale(100%)"},attrs:{"src":`/image/icons/${_vm.CraftType.getCraftTypeIcons(
                      _vm.selectedTask.craftType
                    )}`}}),_vm._v(" "+_vm._s(_vm.CraftType.getCraftTypeString(_vm.selectedTask.craftType))+" ")]),_c('v-list-item-title',{staticClass:"mb-1"},[_c('a',{staticClass:"text-wrap",on:{"click":function($event){return _vm.selectItem(_vm.selectedTask)}}},[_vm._v(_vm._s(_vm.selectedTask.title))])]),_c('v-list-item-subtitle',[_c('v-col',[_c('v-row',{staticClass:"d-flex justify-start",attrs:{"md":"12"}},[_c('span',{staticClass:"mr-1 grey--text text--darken-1"},[_vm._v("Company:")]),_c('span',{staticClass:"green--text text--darken-3"},[_vm._v(_vm._s(_vm.selectedTask.assignedCompany.name))])]),_c('v-row',{staticClass:"d-flex justify-start",attrs:{"md":"12"}},[_c('span',{staticClass:"mr-1 grey--text text--darken-1"},[_vm._v("Status:")]),_c('span',{staticClass:"green--text text--darken-3"},[_vm._v(_vm._s(_vm.TaskType.getTaskStatusStringVerbose( _vm.selectedTask.taskStatus, _vm.selectedTask.taskType )))])]),_c('v-row',{staticClass:"d-flex justify-start",attrs:{"md":"12"}},[(_vm.selectedTask.timestampScheduled)?_c('span',{staticClass:"caption"},[_c('span',{staticClass:"mr-1 grey--text text--darken-1"},[_vm._v("Scheduled for")]),_c('span',[_vm._v(_vm._s(_vm.formatDate({ date: _vm.selectedTask.timestampScheduled.seconds * 1000, outFormat: "MMM DD YYYY hh:mma", }))+" ")])]):_vm._e()])],1)],1)],1),_c('v-img',{attrs:{"max-height":"150","max-width":"220","contain":"","src":_vm.selectedTask.parentRecord.thumbnailURL ||
                  '/image/no-image-placeholder.png'},on:{"click":function($event){return _vm.selectItem(_vm.selectedTask)}}})],1)],1)],1):_vm._e()],2),(_vm.loading)?_c('div',{staticClass:"loading-overlay primary--text"},[_vm._v(" Loading Data... ")]):_vm._e(),_c('div',{staticClass:"map-options p-1"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"f-19 pb-0 primary--text",attrs:{"cols":"12"}},[_vm._v("Map Options ")]),_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.taskStatuses),function(tt,i){return _c('v-checkbox',{key:i,staticClass:"m-0 checkbox-option",attrs:{"color":tt.color,"value":tt.value,"disabled":_vm.loading},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"list-image"},[_c('span',{staticClass:"f-18 mdi mdi-map-marker-radius-outline",style:(`color: ${tt.color}`)}),_c('span',[_vm._v(_vm._s(tt.name))])])]},proxy:true}],null,true),model:{value:(_vm.filters.taskStatuses),callback:function ($$v) {_vm.$set(_vm.filters, "taskStatuses", $$v)},expression:"filters.taskStatuses"}})}),1)],1),_c('v-row',[_c('v-col',{staticClass:"f-19 primary--text",attrs:{"cols":"12"}},[_vm._v("Task Types")]),_c('v-col',{staticClass:"pt-0 scrollable-list",attrs:{"cols":"12"}},_vm._l((_vm.taskTypes),function(tt,i){return _c('v-checkbox',{key:i,staticClass:"m-0 checkbox-option",attrs:{"disabled":_vm.loading,"value":tt.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"list-image"},[_c('span',[_vm._v(_vm._s(tt.name))])])]},proxy:true}],null,true),model:{value:(_vm.filters.taskTypes),callback:function ($$v) {_vm.$set(_vm.filters, "taskTypes", $$v)},expression:"filters.taskTypes"}})}),1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }