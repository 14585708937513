import Vue from "vue"
import Vuex from "vuex"
import { firetableModule } from "@/store/index.module"
import { reportSpecModule } from "@/store/modules/report-spec-module"
import { reportConfigsModule } from "@/store/modules/report-config-module"
import { reportDataModule } from "@/store/modules/report-data-module"
import { rootUserPrivateDocModule } from "@/store/modules/root-user-private-module"
import { craftRecordsModule } from "@/store/modules/craft-records-module"
import { userCompanyModule } from "@/store/modules/user-company-module"
import { aggregatesModule } from "@/store/modules/aggregates-module"
import { attachmentsModule } from "@/store/modules/attachments-module"
import { messagesModule } from "@/store/modules/messages-module"

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    firetableModule: firetableModule,
    reportSpecModule,
    reportConfigsModule,
    reportDataModule,
    craftRecordsModule,
    rootUserPrivateDocModule,
    userCompanyModule,
    aggregatesModule,
    attachmentsModule,
    messagesModule,
  },
  actions: {
    clearStoreData: async ({ dispatch }) => {
      await Promise.all([
        dispatch("reportSpecModule/resetData"),
        dispatch("reportConfigsModule/resetData"),
        dispatch("rootUserPrivateDocModule/resetData"),
        dispatch("reportDataModule/resetData"),
        dispatch("craftRecordsModule/resetData"),
        dispatch("aggregatesModule/resetData"),
        dispatch("attachmentsModule/resetData"),
      ])
    },
    initStoreFromLocalStorage: async ({ dispatch }) => {
      await Promise.all([
        dispatch("reportSpecModule/initReportSpecs"),
        dispatch("reportConfigsModule/initReportConfigs"),
        dispatch("rootUserPrivateDocModule/initRootUserPrivateDocData"),
        dispatch("reportDataModule/initReportData"),
        dispatch("craftRecordsModule/initStandingScaffolds"),
        dispatch("aggregatesModule/initUserDisplayNamesData"),
        dispatch("attachmentsModule/initAttachmentsData"),
      ])
    },
  },
})
