import firebase from "firebase"
import Timestamp = firebase.firestore.Timestamp
import DocumentSnapshot = firebase.firestore.DocumentSnapshot
import { CustomerLocation } from "@/models/customer_location"

export const customerTypes = ["residential", "commercial"] as const
// Create the types from the TS array 'customerTypes'.
export type CustomerTypes = (typeof customerTypes)[number]

export class Customer {
  id?: string
  refPath?: string
  name: string
  firstName: string | null
  lastName: string | null
  email: string | null
  phone: string | null
  notes: string | null
  type: CustomerTypes
  timestampCreated: Timestamp
  timestampLastModified: Timestamp
  createdBy: string
  lastModifiedBy: string
  tags: string[]
  customData: { [key: string]: any }
  isTaxExempt: boolean
  notificationGroups: string[]
  billToCustomerID: string | null
  website: string | null
  quickbooksID: string | null
  deleted: boolean
  customerLocations: CustomerLocation[]

  constructor(args: {
    id?: string
    refPath?: string
    name: string
    firstName: string | null
    lastName: string | null
    email: string | null
    phone: string | null
    notes: string | null
    type: CustomerTypes
    timestampCreated: Timestamp
    timestampLastModified: Timestamp
    createdBy: string
    lastModifiedBy: string
    tags: string[]
    customData: { [key: string]: any }
    isTaxExempt: boolean
    notificationGroups: string[]
    billToCustomerID: string | null
    website: string | null
    quickbooksID: string | null
    deleted: boolean
    customerLocations: CustomerLocation[]
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.name = args.name
    this.firstName = args.firstName
    this.lastName = args.lastName
    this.email = args.email
    this.phone = args.phone
    this.notes = args.notes
    this.type = args.type
    this.timestampCreated = args.timestampCreated
    this.timestampLastModified = args.timestampLastModified
    this.createdBy = args.createdBy
    this.lastModifiedBy = args.lastModifiedBy
    this.tags = args.tags
    this.customData = args.customData
    this.isTaxExempt = args.isTaxExempt
    this.notificationGroups = args.notificationGroups
    this.billToCustomerID = args.billToCustomerID
    this.website = args.website
    this.quickbooksID = args.quickbooksID
    this.deleted = args.deleted
    this.customerLocations = []
  }

  static fromFirestore(snapshot: DocumentSnapshot): Customer {
    return new Customer({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      name: snapshot.get("name"),
      firstName: snapshot.get("firstName"),
      lastName: snapshot.get("lastName"),
      email: snapshot.get("email"),
      phone: snapshot.get("phone"),
      notes: snapshot.get("notes"),
      type: snapshot.get("type"),
      timestampCreated: snapshot.get("timestampCreated"),
      timestampLastModified: snapshot.get("timestampLastModified"),
      createdBy: snapshot.get("createdBy"),
      lastModifiedBy: snapshot.get("lastModifiedBy"),
      tags: snapshot.get("tags"),
      customData: snapshot.get("customData"),
      isTaxExempt: snapshot.get("isTaxExempt"),
      notificationGroups: snapshot.get("notificationGroups"),
      billToCustomerID: snapshot.get("billToCustomerID"),
      website: snapshot.get("website"),
      quickbooksID: snapshot.get("quickbooksID"),
      deleted: snapshot.get("deleted"),
      customerLocations: [],
    })
  }
}
