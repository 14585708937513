
// Components
import TaskDate from "@/views/CraftRecord/TaskDate.vue"

// Models
import { Tasks } from "@/models/task"
import Vue from "vue"

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
    },
    task: {
      type: Tasks,
      required: true,
    },
    saveButtonPressed: {
      type: Function,
    },
    loading: {
      type: Boolean,
    },
  },
  components: {
    "task-date": TaskDate,
  },
  data() {
    return {
      updatedTaskBeforeSave: new Tasks(this.task),
    }
  },
  watch: {
    task(task: Tasks) {
      this.updatedTaskBeforeSave = task
    },
  },
  methods: {
    close() {
      this.$emit("close")
    },

    clearAndCloseModal() {
      this.close()
    },

    updateTask(updatedTask: Tasks) {
      this.updatedTaskBeforeSave = updatedTask
    },
  },
})
