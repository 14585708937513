
//Libs
import { Util } from "@/helpers"
import { db } from "@/firebase-init"
import firebase from "firebase/app"
import { string } from "@/string"
import moment from "moment"
import sum from "lodash/sum"
import has from "lodash/has"
import * as Sentry from "@sentry/browser"

// Models
import {
  Location,
  CraftType,
  SiteKeyCompany,
  TaskStatus,
  TaskType,
  TaskTypes,
} from "@/models/models"
import { Tasks } from "@/models/task"

// Components
import ConfirmDialog from "@/components/ConfirmDialog.vue"

const defaultColsVisibility = [
  { key: "id", visible: false },
  { key: "craftTypeIcon", visible: true },
  { key: "title", visible: true },
  { key: "taskStatusString", visible: true },
  { key: "taskType", visible: true },
  { key: "description", visible: false },
  { key: "workOrder", visible: false },
  { key: "createdDate", visible: false },
  { key: "scheduledDate", visible: true },
  { key: "started", visible: false },
  { key: "timestampTaskCompleted", visible: false },
  { key: "createdBy", visible: false },
  { key: "locationName", visible: true },
]

export default {
  data() {
    return {
      TaskTypes,
      CraftType,
      TaskStatus,
      saving: false,
      record: null,
      records: [],
      columns: [],
      filteredTableRecords: [],
      exportFilename: `Deleted Tasks - ${moment().format("MM-DD-YYYY")}.csv`,
      exportData: [],
      exportLabels: {},
      exportFields: [],
      sorter: {},
      colsVisibility: null,
      searchTerm: Util.getStorage("deleted-task-search-term") || "",
      confirmDialog: {
        show: false,
        title: "",
        message: "",
        cancelText: "",
        okText: "",
      },
    }
  },
  components: {
    "confirm-dialog": ConfirmDialog,
  },
  methods: {
    startListening() {
      this.$store.dispatch("firetableModule/listenDeletedTaskList")
    },
    removeListeners() {
      this.$store.commit("firetableModule/unsubscribeDeletedTasklistListener")
      this.$store.commit("firetableModule/removeDeletedTaskList")
    },
    showClearFilters() {
      // Saved filters
      const sortedField = Util.getStorage("deleted-task-list-sorter-field")
      const sortedOrder = Util.getStorage("deleted-task-list-sorter-order")
      let filters: any = Util.getStorage("deleted-task-list-filters")
      let colsVisibility = Util.getStorage("deleted-task-list-cols-visibility")
      filters = filters ? JSON.parse(filters) : null
      colsVisibility = colsVisibility ? JSON.parse(colsVisibility) : null
      if (
        this.searchTerm ||
        sortedOrder ||
        sortedField ||
        filters ||
        colsVisibility
      )
        return true
      return false
    },
    clearFilters() {
      // Set search term
      this.searchTerm = ""
      // Cols visibility
      this.colsVisibility = defaultColsVisibility
      // Storage
      Util.removeStorage("deleted-task-list-filters")
      Util.removeStorage("deleted-task-list-sorter-field")
      Util.removeStorage("deleted-task-list-sorter-order")
      Util.removeStorage("deleted-task-list-cols-visibility")
      // Re-build columns
      this.buildColumns()
    },
    buildColumns() {
      const sortedField = Util.getStorage("deleted-task-list-sorter-field")
      const sortedOrder = Util.getStorage("deleted-task-list-sorter-order")
      let filters: any = Util.getStorage("deleted-task-list-filters")
      filters = filters ? JSON.parse(filters) : null
      this.columns = [
        {
          title: "ID",
          dataIndex: "id",
          align: "center",
          show: this.colsVisibility.find((col) => col.key === "id")?.visible,
          width: 170,
          sorter: (a, b) => a.id.length - b.id.length,
          sortOrder: sortedField === "id" && sortedOrder,
        },
        {
          title: "Craft Type",
          dataIndex: "craftTypeIcon",
          align: "center",
          show: this.colsVisibility.find((col) => col.key === "craftTypeIcon")
            ?.visible,
          width: 100,
          scopedSlots: {
            customRender: "tskIcon",
          },
          sorter: (a, b) => a.craftTypeIcon.length - b.craftTypeIcon.length,
          sortOrder: sortedField === "craftTypeIcon" && sortedOrder,
        },
        {
          title: "Title",
          dataIndex: "title",
          show: this.colsVisibility.find((col) => col.key === "title")?.visible,
          width: 350,
          scopedSlots: {
            customRender: "tskTitle",
          },
          sorter: (a, b) => a.title.length - b.title.length,
          sortOrder: sortedField === "title" && sortedOrder,
        },
        {
          title: "Current Status",
          dataIndex: "taskStatusString",
          show: this.colsVisibility.find(
            (col) => col.key === "taskStatusString"
          )?.visible,
          width: 160,
          scopedSlots: {
            customRender: "tskStatus",
          },
          sorter: (a, b) => a.taskStatus - b.taskStatus,
          filteredValue:
            filters && filters.taskStatusString
              ? filters.taskStatusString
              : null,
          filters: TaskStatus.getAllValues().map((status) => ({
            text: TaskStatus.getTaskStatusStringSuccinct(status),
            value: status,
          })),
          onFilter: (value, record) => record.taskStatus === value,
        },
        {
          title: "Task Type",
          dataIndex: "taskType",
          align: "center",
          show: this.colsVisibility.find((col) => col.key === "taskType")
            ?.visible,
          width: 150,
          scopedSlots: {
            customRender: "tskType",
          },
          sorter: (a, b) => a.taskType - b.taskType,
          sortOrder: sortedField === "taskType" && sortedOrder,
        },
        {
          title: "Description",
          dataIndex: "description",
          align: "center",
          show: this.colsVisibility.find((col) => col.key === "description")
            ?.visible,
          width: 150,
        },
        {
          title: "Work Order",
          dataIndex: "workOrder",
          align: "center",
          show: this.colsVisibility.find((col) => col.key === "workOrder")
            ?.visible,
          width: 100,
          sorter: (a, b) => a.workOrder.length - b.workOrder.length,
          sortOrder: sortedField === "workOrder" && sortedOrder,
        },
        {
          title: "Timestamp Created",
          dataIndex: "createdDate",
          align: "center",
          show: this.colsVisibility.find((col) => col.key === "createdDate")
            ?.visible,
          width: 150,
          defaultSortOrder: "descend",
          scopedSlots: {
            customRender: "tskCreated",
          },
          sorter: (a, b) =>
            a.timestampCreated.seconds - b.timestampCreated.seconds,
          sortOrder: sortedField === "createdDate" && sortedOrder,
        },
        {
          title: "Timestamp Scheduled",
          dataIndex: "scheduledDate",
          align: "center",
          show: this.colsVisibility.find((col) => col.key === "scheduledDate")
            ?.visible,
          width: 150,
          scopedSlots: {
            customRender: "tskScheduled",
          },
          sorter: (a, b) => {
            if (!a.timestampScheduled || !b.timestampScheduled) return -1
            const aSecs = a.timestampScheduled.seconds
            const bSecs = b.timestampScheduled.seconds
            return bSecs - aSecs
          },
          sortOrder: sortedField === "scheduledDate" && sortedOrder,
        },
        {
          title: "Timestamp Task Started",
          dataIndex: "started",
          align: "center",
          show: this.colsVisibility.find((col) => col.key === "started")
            ?.visible,
          width: 150,
          scopedSlots: {
            customRender: "tskStarted",
          },
          sorter: (a, b) => {
            if (!a.timestampTaskStarted || !b.timestampTaskStarted) return -1
            const aSecs = a.timestampTaskStarted.seconds
            const bSecs = b.timestampTaskStarted.seconds
            return bSecs - aSecs
          },
          sortOrder: sortedField === "started" && sortedOrder,
        },
        {
          title: "Timestamp Task Completed",
          dataIndex: "timestampTaskCompleted",
          align: "center",
          show: this.colsVisibility.find(
            (col) => col.key === "timestampTaskCompleted"
          )?.visible,
          width: 200,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "tskCompleted",
          },
          sorter: (a, b) => {
            if (!a.timestampTaskCompleted || !b.timestampTaskCompleted)
              return -1
            const aSecs = a.timestampTaskCompleted.seconds
            const bSecs = b.timestampTaskCompleted.seconds
            return bSecs - aSecs
          },
          sortOrder: sortedField === "timestampTaskCompleted" && sortedOrder,
        },
        {
          title: "Created By",
          dataIndex: "createdBy",
          show: this.colsVisibility.find((col) => col.key === "createdBy")
            ?.visible,
          align: "center",
          width: 120,
          scopedSlots: {
            customRender: "tskCreatedBy",
          },
          sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
          sortOrder: sortedField === "createdBy" && sortedOrder,
        },
        {
          title: "Location",
          dataIndex: "locationName",
          show: this.colsVisibility.find((col) => col.key === "locationName")
            ?.visible,
          align: "center",
          width: 120,
          scopedSlots: {
            customRender: "tskLocation",
          },
          sorter: (a, b) => a.locationName.localeCompare(b.locationName),
          // sorter: (a, b) => a.locationName.length - b.locationName.length,
          filteredValue:
            filters && filters.locationName ? filters.locationName : null,
          filters: this.siteKeyLocations.map((loc) => ({
            text: loc.title,
            value: loc.id,
          })),
          onFilter: (value, record) => record.locationID === value,
        },
        {
          title: "Actions",
          dataIndex: "actions",
          show: true,
          width: 80,
          scopedSlots: {
            customRender: "prActions",
          },
        },
      ]
    },
    saveTableFilters(_, filters, sorter, { currentDataSource }) {
      // Update current filtered table records
      this.filteredTableRecords = currentDataSource
      // Sorter
      const { field, order } = sorter
      if (order) {
        this.sorter = { field, order }
        Util.setStorage("deleted-task-list-sorter-field", field)
        Util.setStorage("deleted-task-list-sorter-order", order)
      } else {
        this.sorter = {}
        Util.removeStorage("deleted-task-list-sorter-field")
        Util.removeStorage("deleted-task-list-sorter-order")
      }
      // Filters
      if (Object.keys(filters).length > 0)
        Util.setStorage("deleted-task-list-filters", JSON.stringify(filters))
      else Util.removeStorage("deleted-task-list-filters")
      // Re-build columns
      this.buildColumns()
    },
    setColsVisibility() {
      const storedCols = Util.getStorage("deleted-task-list-cols-visibility")
      if (!storedCols) {
        this.colsVisibility = defaultColsVisibility
        return
      }
      if (JSON.stringify(defaultColsVisibility) !== storedCols) {
        this.colsVisibility = defaultColsVisibility
        return
      }
      this.colsVisibility = JSON.parse(storedCols)
    },
    saveColVisibility(column, visible) {
      const index = this.colsVisibility.findIndex(
        (col) => col.key === column.dataIndex
      )
      this.colsVisibility[index].visible = visible
      // Save it to local storage
      Util.setStorage(
        "deleted-task-list-cols-visibility",
        JSON.stringify(this.colsVisibility)
      )
    },
    async generateCsv() {
      // Get craft details
      this.siteKeyData = await Util.getSiteKey(
        db,
        this.$store.state.firetableModule.rootUserData.defaultSiteKey
      )
      const { taskSpecificDetails } = this.siteKeyData.customizations
      const specificDetails = {}
      for (const craftType in taskSpecificDetails)
        for (const taskType in taskSpecificDetails[craftType])
          for (const dynDetail in taskSpecificDetails[craftType][taskType])
            specificDetails[dynDetail] =
              taskSpecificDetails[craftType][taskType][dynDetail].title
      this.exportLabels = {
        id: "ID",
        title: "Title",
        status: "Current Status",
        craftType: "Craft Type",
        taskType: "Task Type",
        workOrder: "Work Order",
        description: "Description",
        created: "Timestamp Created",
        scheduledDate: "Timestamp Scheduled",
        started: "Timestamp Task Started",
        completed: "Timestamp Task Completed",
        createdBy: "Created By",
        location: "Location",
        nextOpportunity: "Next Opportunity",
        urgent: "Urgent",
        assignedCompany: "Assigned Company",
        ...specificDetails,
      }
      const recordsToExport = this.filterRecords(
        this.searchTerm,
        this.filteredTableRecords.length > 0
          ? this.filteredTableRecords
          : this.tasks
      )
      this.exportFields = Object.keys(this.exportLabels)
      this.exportData = await Promise.all(
        recordsToExport.map((task) => {
          const {
            id,
            title,
            taskStatusString: status,
            craftType,
            taskType,
            workOrder,
            description,
            locationName: location,
            createdDate,
            createdTime,
            scheduledDate,
            started,
            completed,
            createdBy,
            nextOpportunity,
            urgent,
            assignedCompany,
          } = task
          // Format some fields
          const { taskSpecificDetails } = task
          const { taskSpecificDetails: defaults } =
            this.siteKeyData.customizations
          for (const key in taskSpecificDetails) {
            const taskTypeString = TaskTypes.getTaskTypeName(taskType)
            const craftTypeString =
              CraftType.getCraftTypeRecordString(craftType)
            let defaultValue = ""
            const defaultValuePath = `${defaults}.${craftTypeString}.${taskTypeString}.${key}.defaultValue`
            if (has(defaults, defaultValuePath))
              defaultValue =
                defaults[craftTypeString][taskTypeString][key].defaultValue ||
                ""
            taskSpecificDetails[key] = taskSpecificDetails[key] || defaultValue
          }
          return {
            id,
            title,
            status,
            workOrder,
            craftType: CraftType.getCraftTypeString(craftType),
            taskType: TaskTypes.getTaskTypeString(taskType),
            description,
            created: `${createdDate} ${createdTime}`,
            scheduledDate,
            started,
            completed,
            createdBy,
            location,
            nextOpportunity,
            urgent,
            assignedCompany,
            ...taskSpecificDetails,
            squareFootage: taskSpecificDetails.squareFootage || "", //Remove this on the excel version
          }
        })
      )
      setTimeout(() => this.$refs.export.generate())
    },
    filterRecords(term, records) {
      return records.filter((record) => {
        return term
          .toLowerCase()
          .split(" ")
          .every((v) => {
            const titleMatch = record.title.toLowerCase().includes(v)
            const woMatch = record.workOrder.toLowerCase().includes(v)
            const descMatch = record.description
              ? record.description.toLowerCase().includes(v)
              : false
            const craftTypeMatch =
              this.globalCraftType === "all" ||
              record.craftType === this.globalCraftType
            return (titleMatch || descMatch || woMatch) && craftTypeMatch
          })
      })
    },
    closeDialog() {
      this.confirmDialog = {
        show: false,
        title: "",
        message: "",
        cancelText: "",
        okText: "",
      }
    },
    confirmRestore(record) {
      this.record = record
      this.confirmDialog = {
        show: true,
        title: "Restore Task?",
        message: "This Task will be restored. Continue?",
        cancelText: "Cancel",
        okText: "Restore",
      }
    },
    async restore() {
      try {
        // Check if parent record is also deleted
        this.saving = true
        const { refPath, craftRecordID } = this.record
        const parentRecord = await Util.getCraftRecord(db, craftRecordID)
        if (!parentRecord.timestampRecordCreated) {
          this.confirmDialog = {
            show: true,
            title: "Cannot be Restored",
            message:
              "This Task cannot be restored because its Craft Record is also deleted. Please restore the Craft Record and then try again.",
            cancelText: "OK",
          }
          return
        }
        const restoreTask = firebase.functions().httpsCallable("restoreTask")
        await restoreTask({ refPath })
        this.$store.commit("firetableModule/setSuccess", string.taskRestored)
        this.record = null
        this.closeDialog()
      } catch (error) {
        Sentry.captureException(error)
        Util.errorMessage(error.message)
      } finally {
        this.saving = false
      }
    },
  },
  watch: {
    siteKeyUserLocations(newValue: string | null) {
      if (!newValue) return
      this.startListening()
    },
    siteKeyLocations(newValue: string | null) {
      if (!newValue) return
      this.buildColumns()
    },
    tasks(records) {
      // filter search manualy
      this.records = this.filterRecords(this.searchTerm, records)
    },
    searchTerm(newTerm) {
      // filter search on every term change
      this.records = this.filterRecords(newTerm, this.tasks)
      Util.setStorage("deleted-task-search-term", newTerm)
    },
    globalCraftType() {
      this.records = this.filterRecords(this.searchTerm, this.tasks)
    },
  },
  computed: {
    siteKeyUserLocations(): string[] | null {
      return this.$store.state.firetableModule.siteKeyUserLocations
    },
    siteKeyLocations(): string[] | null {
      return this.$store.state.firetableModule.siteKeyLocations
    },
    tableWidth() {
      return sum(this.columns.filter((col) => col.show).map((col) => col.width))
    },
    globalCraftType(): string | number | null {
      return this.$store.state.firetableModule.globalCraftType
    },
  },
  asyncComputed: {
    async tasks() {
      const { deletedTaskList, siteKeyCompanies, rootUserData } =
        this.$store.state.firetableModule
      if (!deletedTaskList || !siteKeyCompanies || !rootUserData) return []
      //Additional fields
      return Promise.all(
        deletedTaskList.map(async (task: Tasks) => {
          const {
            assignedCompanyID,
            taskStatus,
            taskType,
            craftType,
            locationID,
            timestampTaskCompleted,
            timestampTaskStarted,
            timestampScheduled,
            timestampCreated,
            nextOpportunity,
            urgent,
            workOrder,
            createdBy,
          } = task
          //Company Data
          const { siteKeyCompanies } = this.$store.state.firetableModule
          const companyData = siteKeyCompanies.find(
            (co: SiteKeyCompany) => co.id === assignedCompanyID
          )
          //Location Data
          const { siteKeyLocations } = this.$store.state.firetableModule
          const locData = siteKeyLocations.find((loc: Location) => {
            return locationID === loc.id
          })
          // Get User details
          const { defaultSiteKey } = rootUserData
          const userDetails = await Util.getSiteKeyUserDetail(
            db,
            defaultSiteKey,
            createdBy
          )
          return {
            ...task,
            workOrder: workOrder || "",
            originalData: task,
            inProgress: taskStatus === TaskStatus.IN_PROGRESS,
            craftTypeIcon: CraftType.getCraftTypeIcons(craftType),
            assignedCompany: companyData ? companyData.name : "",
            locationName: locData ? locData.title : "",
            nextOpportunity,
            urgent,
            createdBy: userDetails ? userDetails.displayName : "[Unavailable]",
            taskStatusString: TaskType.getTaskStatusStringVerbose(
              taskStatus,
              taskType
            ),
            createdDate: moment(timestampCreated.seconds * 1000).format(
              "MM/DD/YYYY"
            ),
            createdTime: moment(timestampCreated.seconds * 1000).format(
              "hh:mm A"
            ),
            completedDate: timestampTaskCompleted
              ? moment(timestampTaskCompleted.seconds * 1000).format(
                  "MM/DD/YYYY"
                )
              : "",
            completedTime: timestampTaskCompleted
              ? moment(timestampTaskCompleted.seconds * 1000).format("hh:mm A")
              : "",
            startedDate: timestampTaskStarted
              ? moment(timestampTaskStarted.seconds * 1000).format("MM/DD/YYYY")
              : "",
            startedTime: timestampTaskStarted
              ? moment(timestampTaskStarted.seconds * 1000).format("hh:mm A")
              : "",
            scheduledDate: timestampScheduled
              ? moment(timestampScheduled.seconds * 1000).format(
                  "MM/DD/YYYY hh:mm A"
                )
              : "",
            timestampScheduled: timestampScheduled
              ? timestampScheduled
              : { seconds: moment("01/01/2050", "MM/DD/YYYY").valueOf() },
          }
        })
      )
    },
  },
  mounted() {
    if (this.siteKeyUserLocations) this.startListening()
    this.setColsVisibility()
    this.buildColumns()
  },
  beforeDestroy(): void {
    this.removeListeners()
  },
}
