/**
 * Set custom css property for vertical scroll position.
 */
function init() {
  window.addEventListener("scroll", () => {
    document.documentElement.style.setProperty(
      "--scroll-y",
      `${window.scrollY}px`
    )
  })
}

/**
 * Disable scrolling for the body element.
 */
function enable() {
  const scrollY = document.documentElement.style.getPropertyValue("--scroll-y")
  const body = document.body
  body.style.position = "fixed"
  body.style.top = `-${scrollY}`
}

/**
 * Re-enable scrolling for the body element and return to previous Y position.
 */
function disable() {
  const body = document.body
  const scrollY = body.style.top
  body.style.position = ""
  body.style.top = ""
  window.scrollTo(0, parseInt(scrollY || "0") * -1)
}

/**
 * Controls to enable or disable preventing scroll actions during modals.
 */
export function useScrollLock() {
  return {
    init,
    enable,
    disable,
  }
}
