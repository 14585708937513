
// Libs
import { Util } from "@/helpers"
import moment from "moment"
import firebase from "firebase/app"
import { TimePicker } from "ant-design-vue"

// Models
import { TaskStatus } from "@/models/models"
import { Tasks, updateTaskFromScheduleChange } from "@/models/task"
import { displayNextOpportunity } from "@/customizations"
import Vue from "vue"

export default Vue.extend({
  props: {
    // Task data
    task: {
      type: Tasks,
      required: true,
    },
    // Callback function
    updateTask: {
      type: Function,
      required: true,
    },
    //
    newTaskCreation: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    "a-time-picker": TimePicker,
  },
  data() {
    return {
      scheduleVal: "",
      calendarOptions: [],
      date: moment().format("YYYY-MM-DD"),
      time: moment("07:00 am", "hh:mm a"),
      updatedTask: new Tasks(this.task),
      calendarStart: moment().format("YYYY-MM-DD"),
    }
  },
  watch: {
    task(task: Tasks) {
      this.updatedTask = task
      this.calendarOptions = []
      this.calendarOptions.push("Urgent")

      if (
        this.$store.state.firetableModule.siteKey?.validTaskStatusCodes.includes(
          TaskStatus.AWAITING_SCHEDULE
        )
      ) {
        this.calendarOptions.push("Awaiting Schedule")
      }
      if (
        displayNextOpportunity(
          this.$store.state.firetableModule.siteKey,
          this.updatedTask.craftType,
          this.updatedTask.taskType
        ) === true
      ) {
        this.calendarOptions.push("Next Opportunity")
      }
    },
  },
  computed: {
    allowSchedulingTasksInThePast(): boolean {
      return (
        this.$store.state.firetableModule.siteKey?.customizations
          .allowSchedulingTasksInThePast === true
      )
    },
  },
  mounted() {
    this.defaultData()
  },
  methods: {
    defaultData() {
      if (this.updatedTask.timestampScheduled) {
        this.date = Util.formatDate({
          date: this.updatedTask.timestampScheduled.seconds * 1000,
          outFormat: "YYYY-MM-DD",
        })
        this.time = Util.formatDate({
          date: this.updatedTask.timestampScheduled.seconds * 1000,
          outFormat: "hh:mm a",
        })
      }
      if (this.updatedTask.urgent) {
        this.scheduleVal = "Urgent"
      } else if (this.updatedTask.nextOpportunity) {
        this.scheduleVal = "Next Opportunity"
      } else if (this.updatedTask.taskStatus === TaskStatus.AWAITING_SCHEDULE) {
        this.scheduleVal = "Awaiting Schedule"
      } else if (this.scheduleVal == "") {
        this.updatedTask.timestampScheduled = this.getTimestampFromDayAndTime()
      }
      this.updateTask(this.updatedTask)
    },
    formatDate: (data) => Util.formatDate(data),

    async timeChanged(time) {
      this.time = Util.formatDate({
        date: time,
        outFormat: "hh:mm a",
      })
      this.updatedTask.timestampScheduled = this.getTimestampFromDayAndTime()
      this.updatedTask.urgent = false
      this.updatedTask.nextOpportunity = false
      this.updatedTask = await updateTaskFromScheduleChange(
        this.updatedTask,
        this.scheduleVal,
        this.newTaskCreation,
        this.$store.state.firetableModule.siteKey,
        this.$store.state.firetableModule.siteKeyUserPermissionData
      )
      this.updateTask(this.updatedTask)
    },

    async dayChanged() {
      this.scheduleVal = ""
      // If a different day was selected, reset the time back to 7am
      this.time = moment("07:00 am", "hh:mm a")
      this.updatedTask.timestampScheduled = this.getTimestampFromDayAndTime()
      this.updatedTask.urgent = false
      this.updatedTask.nextOpportunity = false
      this.updatedTask = await updateTaskFromScheduleChange(
        this.updatedTask,
        this.scheduleVal,
        this.newTaskCreation,
        this.$store.state.firetableModule.siteKey,
        this.$store.state.firetableModule.siteKeyUserPermissionData
      )
      this.updateTask(this.updatedTask)
    },

    async scheduleRadioSelected() {
      this.date = null
      this.time = null
      if (this.scheduleVal === "Urgent") {
        this.date = moment().format("YYYY-MM-DD")
        this.time = moment().format("hh:mm a")
        this.updatedTask.timestampScheduled = this.getTimestampFromDayAndTime()
        this.updatedTask.timestampAwaitingStart =
          this.getTimestampFromDayAndTime()
        this.updatedTask.urgent = true
        this.updatedTask.nextOpportunity = false
      }
      if (this.scheduleVal === "Awaiting Schedule") {
        this.date = null
        this.time = null
        this.updatedTask.timestampScheduled = null
        this.updatedTask.timestampAwaitingStart = null
        this.updatedTask.urgent = false
        this.updatedTask.nextOpportunity = false
      }
      if (this.scheduleVal === "Next Opportunity") {
        this.date = null
        this.time = null
        this.updatedTask.timestampScheduled = null
        this.updatedTask.timestampAwaitingStart =
          firebase.firestore.Timestamp.now()
        this.updatedTask.urgent = false
        this.updatedTask.nextOpportunity = true
      }
      this.updatedTask = await updateTaskFromScheduleChange(
        this.updatedTask,
        this.scheduleVal,
        this.newTaskCreation,
        this.$store.state.firetableModule.siteKey,
        this.$store.state.firetableModule.siteKeyUserPermissionData
      )
      this.updateTask(this.updatedTask)
    },

    getTimestampFromDayAndTime(): firebase.firestore.Timestamp {
      const time =
        typeof this.time === "object"
          ? Util.formatDate({
              date: this.time,
              outFormat: "hh:mm a",
            })
          : this.time
      return firebase.firestore.Timestamp.fromDate(
        moment(`${this.date} ${time}`, "YYYY-MM-DD hh:mm a").toDate()
      )
    },
  },
})
