
import NoDefaultSiteKeyCardPure from "@/components/NoDefaultSiteKeyCardPure.vue"
import { Util } from "@/helpers"
import { store } from "@/store"
import { db } from "@/firebase-init"

export default {
  components: { NoDefaultSiteKeyCardPure },
  asyncComputed: {
    userId() {
      const user = store.state.firetableModule.currentUser
      return user?.uid
    },
    async approvedKeys(): Promise<string[]> {
      // todo: cut this out into a more testable function.
      if (!this.userId) return []
      const rootUserPrivateDocPath = `users/${this.userId}/privateColl/privateDoc`
      const siteKeysMap = await Util.rootUserSiteKeys(
        db,
        rootUserPrivateDocPath
      )
      if (siteKeysMap) {
        // Filter the map of site keys for approved (true) values.
        return Object.keys(siteKeysMap).filter((siteKey) => {
          return siteKeysMap[siteKey] === true
        })
      } else {
        return []
      }
    },
  },
  methods: {
    async updateDefaultSiteKey(siteKey: string) {
      const userPath = store.state.firetableModule.rootUserData.refPath
      if (userPath) {
        // todo(Dan): I want to move these database functions out of helpers/utils
        await Util.changeDefaultSiteKey(db, userPath, siteKey)
      }
    },
  },
}
