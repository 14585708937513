var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('GmapMap',{ref:"mapRef",style:({ width: _vm.width, height: _vm.height }),attrs:{"center":_vm.markers[0].position,"options":{
    disableDefaultUI: true,
    fullscreenControl: true,
  },"zoom":17,"map-type-id":"satellite"},on:{"dragend":_vm.mapDragEnd}},[_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"title":_vm.drag ? 'Drag to move Location' : '',"visible":true,"position":m.position,"clickable":true,"options":{
      disableDefaultUI: true,
    },"draggable":_vm.drag ? _vm.drag : false},on:{"click":function($event){_vm.center = m.position
      _vm.infoWindow = true},"dragend":_vm.updateCoordinates}})}),_c('gmap-info-window',{attrs:{"opened":_vm.infoWindow && _vm.craftData,"position":_vm.infoPosition,"options":{
      pixelOffset: {
        width: 0,
        height: -35,
      },
    }},on:{"closeclick":function($event){_vm.infoWindow = false}}},[_vm._v(" "+_vm._s(_vm.craftData ? _vm.craftData.title : "")+" "),_c('br'),(_vm.craftData)?_c('div',[(_vm.craftData.numOpenTasks > 0)?_c('span',[_vm._v(_vm._s(`${_vm.craftData.numOpenTasks} open task${ _vm.craftData.numOpenTasks > 1 ? "s" : "" }`))]):_vm._e()]):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }