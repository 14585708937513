// Libs
import firebase from "firebase/app"
import "firebase/firestore"
import DocumentSnapshot = firebase.firestore.DocumentSnapshot
import moment from "moment"
import { Tasks } from "@/models/task"

/**
 * Defined craft type codes.
 */
export enum CraftTypes {
  SCAFFOLDING = 10,
  INSULATION = 15,
  STEAM_TRACING = 16,
  FIREPROOFING = 17,
  WATERBLASTING = 20,
  VACUUM_TRUCK = 21,
  SANDBLASTING = 25,
  FILTER_CLOTHS = 26,
  PAINTING = 30,
  HOISTWELLS = 40,
  MOBILE_EQUIPMENT = 50,
  CAMERAS = 51,
  HVAC = 60,
  ELECTRICAL = 65,
  CARPENTRY = 70,
  PLUMBING = 80,
  PERMITTING = 90,
  GENERAL_LABOR = 98,
  HOUSEKEEPING = 99,
  CLEANING = 100,
  CHECKLISTS = 110,
}

/**
 * Defines craft record persistence types
 */
export enum CraftRecordPersistenceTypes {
  CLOSE = "close",
  PROMPT = "prompt",
  KEEP = "keep",
}

/*
 * Defines event model
 */
export class EventTypes {
  static NEW_USER_APPROVED = 10
  static NEW_USER_APPLIED = 11
  static TASK_STATUS_UPDATED = 31
  static TASK_REASSIGNED_COMPANY = 32
  static TASK_DESCRIPTION_UPDATED = 33
  static TASK_WORK_ORDER_UPDATED = 34
  static TASK_DETAILS_UPDATED = 36
  static TASK_SCHEDULE_DATE_CHANGED = 96
  static TASK_DELETED = 98
  static TASK_RESTORED = 198
  static NEW_TASK_ADDED = 30
  static UPDATED_TITLE = 40
  static UPDATED_DESCRIPTION = 41
  static ADDED_PHOTO = 42
  static REMOVED_PHOTO = 43
  static CHANGED_ASSET = 44
  static UPDATED_CRAFT_DETAILS = 45
  static CHANGED_LOCATION_ID = 46
  static UPDATED_LOCATION_ON_MAP = 47
  static ADDED_ATTACHMENT = 48
  static REMOVED_ATTACHMENT = 49
  static CRAFT_RECORD_CREATED = 20
  static CRAFT_RECORD_DELETED = 99
  static CRAFT_RECORD_RESTORED = 199

  static values() {
    return [
      EventTypes.NEW_USER_APPROVED,
      EventTypes.NEW_USER_APPLIED,
      EventTypes.CRAFT_RECORD_CREATED,
      EventTypes.NEW_TASK_ADDED,
      EventTypes.TASK_STATUS_UPDATED,
      EventTypes.TASK_REASSIGNED_COMPANY,
      EventTypes.TASK_DESCRIPTION_UPDATED,
      EventTypes.TASK_WORK_ORDER_UPDATED,
      EventTypes.TASK_DETAILS_UPDATED,
      EventTypes.TASK_SCHEDULE_DATE_CHANGED,
      EventTypes.UPDATED_TITLE,
      EventTypes.UPDATED_DESCRIPTION,
      EventTypes.ADDED_PHOTO,
      EventTypes.REMOVED_PHOTO,
      EventTypes.CHANGED_ASSET,
      EventTypes.UPDATED_CRAFT_DETAILS,
      EventTypes.CHANGED_LOCATION_ID,
      EventTypes.UPDATED_LOCATION_ON_MAP,
      EventTypes.ADDED_ATTACHMENT,
      EventTypes.REMOVED_ATTACHMENT,
      EventTypes.TASK_DELETED,
      EventTypes.CRAFT_RECORD_DELETED,
      EventTypes.CRAFT_RECORD_RESTORED,
      EventTypes.TASK_RESTORED,
    ]
  }

  static getEventTypeString(eventType: number, displayName: string = null) {
    switch (eventType) {
      case EventTypes.NEW_USER_APPROVED:
        return "New user approved"
      case EventTypes.NEW_USER_APPLIED:
        return `${displayName} has requested approval for this site`
      case EventTypes.CRAFT_RECORD_CREATED:
        return "Craft record created"
      case EventTypes.NEW_TASK_ADDED:
        return "New task added"
      case EventTypes.TASK_STATUS_UPDATED:
        return "Task status updated"
      case EventTypes.TASK_REASSIGNED_COMPANY:
        return "Task reassigned to a different company"
      case EventTypes.TASK_DESCRIPTION_UPDATED:
        return "Task description changed"
      case EventTypes.TASK_WORK_ORDER_UPDATED:
        return "Task work order updated"
      case EventTypes.TASK_DETAILS_UPDATED:
        return "Task details updated"
      case EventTypes.TASK_SCHEDULE_DATE_CHANGED:
        return "Task Schedule Date Changed"
      case EventTypes.UPDATED_TITLE:
        return "Updated title"
      case EventTypes.UPDATED_DESCRIPTION:
        return "Updated description"
      case EventTypes.ADDED_PHOTO:
        return "Added photo"
      case EventTypes.REMOVED_PHOTO:
        return "Removed photo"
      case EventTypes.CHANGED_ASSET:
        return "Changed asset"
      case EventTypes.UPDATED_CRAFT_DETAILS:
        return "Craft Record details updated"
      case EventTypes.CHANGED_LOCATION_ID:
        return "Changed location"
      case EventTypes.UPDATED_LOCATION_ON_MAP:
        return "Updated location on map"
      case EventTypes.ADDED_ATTACHMENT:
        return "Added attachment"
      case EventTypes.REMOVED_ATTACHMENT:
        return "Removed attachment"
      case EventTypes.TASK_DELETED:
        return "Task deleted"
      case EventTypes.CRAFT_RECORD_DELETED:
        return "Craft record deleted"
      case EventTypes.CRAFT_RECORD_RESTORED:
        return "Craft record restored"
      case EventTypes.TASK_RESTORED:
        return "Task restored"
      default:
        return "Unknown Event"
    }
  }

  static getEventTypeIconDetails(eventType: number) {
    switch (eventType) {
      case EventTypes.NEW_USER_APPROVED:
        return { icon: "mdi-account-check", color: "green lighten-1" }
      case EventTypes.NEW_USER_APPLIED:
        return { icon: "mdi-account-alert", color: "green lighten-1" }
      case EventTypes.CRAFT_RECORD_CREATED:
        return { icon: "mdi-shape", color: "green lighten-1" }
      case EventTypes.NEW_TASK_ADDED:
        return { icon: "mdi-clipboard-plus-outline", color: "green lighten-1" }
      case EventTypes.TASK_STATUS_UPDATED:
        return { icon: "mdi-file-clock-outline", color: "purple lighten-2" }
      case EventTypes.TASK_REASSIGNED_COMPANY:
        return { icon: "mdi-office-building", color: "purple lighten-2" }
      case EventTypes.TASK_DESCRIPTION_UPDATED:
        return { icon: "mdi-calendar-text-outline", color: "purple lighten-2" }
      case EventTypes.TASK_WORK_ORDER_UPDATED:
        return { icon: "mdi-calendar-today", color: "purple lighten-2" }
      case EventTypes.TASK_DETAILS_UPDATED:
        return {
          icon: "mdi-card-account-details-outline",
          color: "purple lighten-2",
        }
      case EventTypes.TASK_SCHEDULE_DATE_CHANGED:
        return {
          icon: "mdi-calendar-edit",
          color: "purple lighten-2",
        }
      case EventTypes.UPDATED_TITLE:
        return { icon: "mdi-pencil", color: "purple lighten-2" }
      case EventTypes.UPDATED_DESCRIPTION:
        return { icon: "mdi-format-wrap-inline", color: "purple lighten-2" }
      case EventTypes.ADDED_PHOTO:
        return { icon: "mdi-image-plus", color: "green lighten-1" }
      case EventTypes.REMOVED_PHOTO:
        return { icon: "mdi-image-off", color: "red lighten-2" }
      case EventTypes.CHANGED_ASSET:
        return { icon: "mdi-tag-text-outline", color: "purple lighten-2" }
      case EventTypes.UPDATED_CRAFT_DETAILS:
        return { icon: "mdi-shape-plus", color: "purple lighten-2" }
      case EventTypes.CHANGED_LOCATION_ID:
        return {
          icon: "mdi-map-marker-right-outline",
          color: "purple lighten-2",
        }
      case EventTypes.UPDATED_LOCATION_ON_MAP:
        return {
          icon: "mdi-map-marker-multiple-outline",
          color: "purple lighten-2",
        }
      case EventTypes.ADDED_ATTACHMENT:
        return {
          icon: "mdi-attachment",
          color: "purple lighten-2",
        }
      case EventTypes.REMOVED_ATTACHMENT:
        return { icon: "mdi-delete", color: "red lighten-2" }
      case EventTypes.TASK_DELETED:
        return { icon: "mdi-delete", color: "red lighten-2" }
      case EventTypes.CRAFT_RECORD_DELETED:
        return { icon: "mdi-delete", color: "red lighten-2" }
      case EventTypes.CRAFT_RECORD_RESTORED:
      case EventTypes.TASK_RESTORED:
        return { icon: "mdi-file-restore-outline", color: "green lighten-1" }
      default:
        return { icon: "mdi-cloud-question", color: "grey" }
    }
  }
}

/**
 * Defined task type codes.
 */
export class TaskTypes {
  static PHONE_CALL = 3
  static ESTIMATE = 5
  static INSTALLATION = 10
  static MODIFICATION = 11
  static REMOVAL = 12
  static INSPECTION = 13
  static MATERIAL_PICKUP = 14
  static AUDIT = 15
  static WATERBLASTING = 20
  static VACUUM_TRUCK = 25
  static SANDBLASTING = 30
  static CLOTH_REPLACEMENT = 31
  static INSULATION = 40
  static STEAM_TRACING = 41
  static FIREPROOFING = 42
  static ABATEMENT = 50
  static PAINTING = 60
  static REPAIR = 70
  static CARPENTRY = 75
  static LOCKOUT = 90
  static WALKTHROUGH = 91
  static PERFORMING_WORK = 92
  static GENERAL_LABOR = 95
  static AUTO_DETAILING = 96
  static HOUSEKEEPING = 99
  static CLEANING = 100
  static OPERATOR_ROUNDS = 110
  static SAFETY_INSPECTION = 111
  static PM_ROUTE = 112
  static HOUSEKEEPING_INSPECTION = 113
  static EQUIPMENT_INSPECTION = 114

  static values() {
    return [
      TaskTypes.PHONE_CALL,
      TaskTypes.ESTIMATE,
      TaskTypes.INSTALLATION,
      TaskTypes.MODIFICATION,
      TaskTypes.REMOVAL,
      TaskTypes.INSPECTION,
      TaskTypes.MATERIAL_PICKUP,
      TaskTypes.AUDIT,
      TaskTypes.WATERBLASTING,
      TaskTypes.VACUUM_TRUCK,
      TaskTypes.SANDBLASTING,
      TaskTypes.CLOTH_REPLACEMENT,
      TaskTypes.INSULATION,
      TaskTypes.STEAM_TRACING,
      TaskTypes.FIREPROOFING,
      TaskTypes.ABATEMENT,
      TaskTypes.PAINTING,
      TaskTypes.REPAIR,
      TaskTypes.CARPENTRY,
      TaskTypes.LOCKOUT,
      TaskTypes.WALKTHROUGH,
      TaskTypes.PERFORMING_WORK,
      TaskTypes.GENERAL_LABOR,
      TaskTypes.AUTO_DETAILING,
      TaskTypes.HOUSEKEEPING,
      TaskTypes.CLEANING,
      TaskTypes.OPERATOR_ROUNDS,
      TaskTypes.SAFETY_INSPECTION,
      TaskTypes.PM_ROUTE,
      TaskTypes.HOUSEKEEPING_INSPECTION,
      TaskTypes.EQUIPMENT_INSPECTION,
    ]
  }

  static getTaskTypeString(taskType: number) {
    switch (taskType) {
      case TaskTypes.INSTALLATION:
        return "Installation"
      case TaskTypes.MODIFICATION:
        return "Modification"
      case TaskTypes.REMOVAL:
        return "Removal"
      case TaskTypes.INSPECTION:
        return "Inspection"
      case TaskTypes.MATERIAL_PICKUP:
        return "Material Pickup"
      case TaskTypes.AUDIT:
        return "Audit"
      case TaskTypes.WATERBLASTING:
        return "Waterblasting"
      case TaskTypes.VACUUM_TRUCK:
        return "Vacuum Truck"
      case TaskTypes.SANDBLASTING:
        return "Sandblasting"
      case TaskTypes.INSULATION:
        return "Insulation"
      case TaskTypes.STEAM_TRACING:
        return "Steam Tracing"
      case TaskTypes.FIREPROOFING:
        return "Fireproofing"
      case TaskTypes.ABATEMENT:
        return "Abatement"
      case TaskTypes.PAINTING:
        return "Painting"
      case TaskTypes.REPAIR:
        return "Repair"
      case TaskTypes.CARPENTRY:
        return "Carpentry"
      case TaskTypes.LOCKOUT:
        return "Lockout"
      case TaskTypes.WALKTHROUGH:
        return "Walkthrough"
      case TaskTypes.PERFORMING_WORK:
        return "Performing Work"
      case TaskTypes.CLOTH_REPLACEMENT:
        return "Cloth Replacement"
      case TaskTypes.GENERAL_LABOR:
        return "General Labor"
      case TaskTypes.AUTO_DETAILING:
        return "Auto Detailing"
      case TaskTypes.HOUSEKEEPING:
        return "Housekeeping"
      case TaskTypes.OPERATOR_ROUNDS:
        return "Operator Rounds"
      case TaskTypes.EQUIPMENT_INSPECTION:
        return "Equipment Inspection"
      case TaskTypes.HOUSEKEEPING_INSPECTION:
        return "Housekeeping Inspection"
      case TaskTypes.SAFETY_INSPECTION:
        return "Safety Inspection"
      case TaskTypes.PM_ROUTE:
        return "PM Route"
      case TaskTypes.CLEANING:
        return "Cleaning"
      default:
        return "UNKNOWN"
    }
  }

  static getTaskTypeName(taskType: TaskTypes) {
    switch (taskType) {
      case TaskTypes.INSTALLATION:
        return "installation"
      case TaskTypes.MODIFICATION:
        return "modification"
      case TaskTypes.REMOVAL:
        return "removal"
      case TaskTypes.INSPECTION:
        return "inspection"
      case TaskTypes.MATERIAL_PICKUP:
        return "materialPickup"
      case TaskTypes.AUDIT:
        return "audit"
      case TaskTypes.WATERBLASTING:
        return "waterblasting"
      case TaskTypes.VACUUM_TRUCK:
        return "vacuumTruck"
      case TaskTypes.SANDBLASTING:
        return "sandblasting"
      case TaskTypes.INSULATION:
        return "insulation"
      case TaskTypes.STEAM_TRACING:
        return "steamTracing"
      case TaskTypes.FIREPROOFING:
        return "fireproofing"
      case TaskTypes.ABATEMENT:
        return "abatement"
      case TaskTypes.PAINTING:
        return "painting"
      case TaskTypes.REPAIR:
        return "repair"
      case TaskTypes.CARPENTRY:
        return "carpentry"
      case TaskTypes.LOCKOUT:
        return "lockout"
      case TaskTypes.WALKTHROUGH:
        return "walkthrough"
      case TaskTypes.PERFORMING_WORK:
        return "performingWork"
      case TaskTypes.CLOTH_REPLACEMENT:
        return "clothReplacement"
      case TaskTypes.GENERAL_LABOR:
        return "generalLabor"
      case TaskTypes.AUTO_DETAILING:
        return "autoDetailing"
      case TaskTypes.HOUSEKEEPING:
        return "housekeeping"
      case TaskTypes.OPERATOR_ROUNDS:
        return "operatorRounds"
      case TaskTypes.EQUIPMENT_INSPECTION:
        return "equipmentInspection"
      case TaskTypes.SAFETY_INSPECTION:
        return "safetyInspection"
      case TaskTypes.HOUSEKEEPING_INSPECTION:
        return "housekeepingInspection"
      case TaskTypes.PM_ROUTE:
        return "pmRoute"
      case TaskTypes.CLEANING:
        return "cleaning"
      default:
        return "UNKNOWN"
    }
  }
}

/**
 * Defined task type codes.
 */
export class TaskStatus {
  static AWAITING_ESTIMATE = 5
  static AWAITING_REVIEW = 6
  static AWAITING_APPROVAL = 8
  static AWAITING_SCHEDULE = 10
  static AWAITING_PARTS = 11
  static SCHEDULED = 20
  static AWAITING = 30
  static IN_ROUTE = 35
  static IN_PROGRESS = 40
  static ON_HOLD = 50
  static AWAITING_PAYMENT = 80
  static COMPLETE = 90
  static CANCELED = 99

  static getAllValues() {
    return [
      TaskStatus.AWAITING_ESTIMATE,
      TaskStatus.AWAITING_REVIEW,
      TaskStatus.AWAITING_APPROVAL,
      TaskStatus.AWAITING_SCHEDULE,
      TaskStatus.AWAITING_PARTS,
      TaskStatus.SCHEDULED,
      TaskStatus.AWAITING,
      TaskStatus.IN_ROUTE,
      TaskStatus.IN_PROGRESS,
      TaskStatus.ON_HOLD,
      TaskStatus.AWAITING_PAYMENT,
      TaskStatus.COMPLETE,
      TaskStatus.CANCELED,
    ]
  }

  static getNextValidTaskStatus(
    task: Tasks,
    siteKeyUserPermissionsDoc: SiteKeyUserPermission
  ) {
    switch (task.taskStatus) {
      case TaskStatus.AWAITING_ESTIMATE:
        return [TaskStatus.AWAITING_APPROVAL]
      case TaskStatus.AWAITING_REVIEW:
        return [TaskStatus.COMPLETE]
      case TaskStatus.AWAITING_APPROVAL:
        if (siteKeyUserPermissionsDoc.permissions.isPlantPersonnel === false) {
          return []
        }
        if (task.nextOpportunity === true || task.urgent === true) {
          return [TaskStatus.AWAITING]
        }
        if (task.timestampScheduled === null) {
          return [TaskStatus.AWAITING_SCHEDULE]
        }
        if (
          moment(task.timestampScheduled.seconds * 1000).valueOf() <=
          moment().endOf("day").valueOf()
        ) {
          return [TaskStatus.AWAITING]
        }
        if (
          moment(task.timestampScheduled.seconds * 1000).valueOf() >
          moment().endOf("day").valueOf()
        ) {
          return [TaskStatus.SCHEDULED]
        }
        return []
      case TaskStatus.AWAITING_SCHEDULE:
        return []
      case TaskStatus.SCHEDULED:
        return []
      case TaskStatus.AWAITING:
        return [TaskStatus.IN_PROGRESS]
      case TaskStatus.IN_PROGRESS:
        return [TaskStatus.COMPLETE, TaskStatus.ON_HOLD]
      case TaskStatus.ON_HOLD:
        return [TaskStatus.IN_PROGRESS]
      case TaskStatus.COMPLETE:
        return []
      default:
        return []
    }
  }

  static getTaskStatusStringSuccinct(taskStatus: number) {
    switch (taskStatus) {
      case TaskStatus.AWAITING_ESTIMATE:
        return "Awaiting Estimate"
      case TaskStatus.AWAITING_REVIEW:
        return "Awaiting Review"
      case TaskStatus.AWAITING_APPROVAL:
        return "Awaiting Approval"
      case TaskStatus.AWAITING_SCHEDULE:
        return "Awaiting Schedule"
      case TaskStatus.AWAITING_PARTS:
        return "Awaiting Parts"
      case TaskStatus.SCHEDULED:
        return "Scheduled"
      case TaskStatus.AWAITING:
        return "Awaiting"
      case TaskStatus.IN_PROGRESS:
        return "In Progress"
      case TaskStatus.IN_ROUTE:
        return "In Route"
      case TaskStatus.ON_HOLD:
        return "Hold"
      case TaskStatus.AWAITING_PAYMENT:
        return "Awaiting Payment"
      case TaskStatus.COMPLETE:
        return "Complete"
      case TaskStatus.CANCELED:
        return "Canceled"
      default:
        return "UNKNOWN"
    }
  }

  static getTaskStatusIcon(taskStatus: number) {
    switch (taskStatus) {
      case TaskStatus.AWAITING_ESTIMATE:
        return "mdi-calculator"
      case TaskStatus.AWAITING_REVIEW:
        return "mdi-check-decagram-outline"
      case TaskStatus.AWAITING_APPROVAL:
        return "mdi-check-decagram-outline"
      case TaskStatus.AWAITING_SCHEDULE:
        return "mdi-clock-alert-outline"
      case TaskStatus.AWAITING_PARTS:
        return "mdi-check-decagram-outline"
      case TaskStatus.SCHEDULED:
        return "mdi-clock-check-outline"
      case TaskStatus.AWAITING:
        return "mdi-calendar-clock"
      case TaskStatus.IN_PROGRESS:
        return "mdi-progress-check"
      case TaskStatus.IN_ROUTE:
        return "mdi-route"
      case TaskStatus.ON_HOLD:
        return "mdi-pause-circle-outline"
      case TaskStatus.AWAITING_PAYMENT:
        return "mdi-payment"
      case TaskStatus.COMPLETE:
        return "mdi-check-all"
      case TaskStatus.CANCELED:
        return "mdi-cancel"
      default:
        return "mdi-alert-circle"
    }
  }

  static getTaskStatusClass(taskStatus: number) {
    switch (taskStatus) {
      case TaskStatus.AWAITING_ESTIMATE:
        return "purple--text text--accent-4"
      case TaskStatus.AWAITING_REVIEW:
        return "purple--text text--accent-4"
      case TaskStatus.AWAITING_APPROVAL:
        return "purple--text text--accent-4"
      case TaskStatus.AWAITING_SCHEDULE:
        return "purple--text text--accent-4"
      case TaskStatus.AWAITING_PARTS:
        return "purple--text text--accent-4"
      case TaskStatus.SCHEDULED:
        return "blue--text"
      case TaskStatus.AWAITING:
        return "orange--text text--accent-4"
      case TaskStatus.IN_ROUTE:
        return "blue--text text--accent-4"
      case TaskStatus.IN_PROGRESS:
        return "green--text text-darken-1"
      case TaskStatus.ON_HOLD:
        return "red--text text--darken-2"
      case TaskStatus.AWAITING_PAYMENT:
        return "green--text text--darken-3"
      case TaskStatus.COMPLETE:
        return "green--text text--darken-3"
      case TaskStatus.CANCELED:
        return "grey--text text--darken-3"
      default:
        return "white"
    }
  }
}

/**
 * Inventory Transaction Document
 */
export class InventoryTransaction {
  id?: string
  refPath?: string
  companyID: string
  inventoryObjectID: string
  parentRecordID: string
  taskID: string
  type: number
  value: number
  timestampCreated: firebase.firestore.Timestamp | null
  timestampLastModified: firebase.firestore.Timestamp | null
  createdBy: string
  lastModifiedBy: string
  deleted: boolean

  constructor(args: {
    id?: string
    refPath?: string
    companyID: string
    inventoryObjectID: string
    parentRecordID: string
    taskID: string
    type: number
    value: number
    timestampCreated: firebase.firestore.Timestamp | null
    timestampLastModified: firebase.firestore.Timestamp | null
    createdBy: string
    lastModifiedBy: string
    deleted: boolean
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.companyID = args.companyID
    this.inventoryObjectID = args.inventoryObjectID
    this.parentRecordID = args.parentRecordID
    this.taskID = args.taskID
    this.type = args.type
    this.value = args.value
    this.timestampCreated = args.timestampCreated
    this.timestampLastModified = args.timestampLastModified
    this.createdBy = args.createdBy
    this.lastModifiedBy = args.lastModifiedBy
    this.deleted = args.deleted
  }

  static fromFirestore(snapshot: DocumentSnapshot): InventoryTransaction {
    return new InventoryTransaction({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      companyID: snapshot.get("companyID"),
      inventoryObjectID: snapshot.get("inventoryObjectID"),
      parentRecordID: snapshot.get("parentRecordID"),
      taskID: snapshot.get("taskID"),
      type: snapshot.get("type"),
      value: snapshot.get("value"),
      timestampCreated: snapshot.get("timestampCreated"),
      timestampLastModified: snapshot.get("timestampLastModified"),
      createdBy: snapshot.get("createdBy"),
      lastModifiedBy: snapshot.get("lastModifiedBy"),
      deleted: snapshot.get("deleted"),
    })
  }

  toMap() {
    return {
      companyID: this.companyID,
      inventoryObjectID: this.inventoryObjectID,
      parentRecordID: this.parentRecordID,
      taskID: this.taskID,
      type: this.type,
      value: this.value,
      timestampCreated: this.timestampCreated,
      timestampLastModified: this.timestampLastModified,
      createdBy: this.createdBy,
      lastModifiedBy: this.lastModifiedBy,
      deleted: this.deleted,
    }
  }
}

export class InventoryTransactionTypes {
  static RESERVE = 5

  // This will decrease quantityReserved and increase quantityAvailable
  static UNRESERVE = 6

  // For parts or consumables that were used on a task and will not be returning to stock
  // This will decrease quantityReserved
  static CONSUMED_FROM_RESERVE = 10

  // This will decrease quantityAvailable
  static CONSUMED_FROM_STOCK = 11

  // TODO: Implement rental types
  // For rental equipment like scaffolding or equipment
  // This will decrease quantityReserved and increase quantityInUse
  static RESERVED_TO_CR_RENTAL = 20

  // This will decrease available and increase inUse
  static STOCK_TO_CR_RENTAL = 21

  // This will decrease quantityInUse and increase quantityAvailable
  static CR_RENTAL_TO_STOCK = 22

  // This will not change any quantities
  static CR_RENTAL_TO_CR_RENTAL = 23

  // Field can be thought of as another "stock" location
  // Will leave these commended out for now until needed... less cognitive load
  // static const CR_TO_FIELD = 40;
  // static const FIELD_TO_STOCK = 50;
  // static const FIELD_TO_CR = 60;
  // static const FIELD_TO_FIELD = 70;

  // Corrections
  static CORRECTION_AVAILABLE = 90
  static CORRECTION_AWAITING_PICKUP = 91
  static CORRECTION_IN_USE = 92
  static CORRECTION_RESERVED = 93

  // Moves between inventoryLocations
  static SENT_TO_LOCATION = 94
  static RECEIVED_FROM_LOCATION = 95

  static values() {
    return [
      InventoryTransactionTypes.RESERVE,
      InventoryTransactionTypes.UNRESERVE,
      InventoryTransactionTypes.CONSUMED_FROM_STOCK,
      InventoryTransactionTypes.CONSUMED_FROM_RESERVE,
      InventoryTransactionTypes.RESERVED_TO_CR_RENTAL,
      InventoryTransactionTypes.STOCK_TO_CR_RENTAL,
      InventoryTransactionTypes.CR_RENTAL_TO_STOCK,
      InventoryTransactionTypes.CR_RENTAL_TO_CR_RENTAL,
      InventoryTransactionTypes.SENT_TO_LOCATION,
      InventoryTransactionTypes.RECEIVED_FROM_LOCATION,
    ]
  }

  static correctionValues() {
    return [
      InventoryTransactionTypes.CORRECTION_AVAILABLE,
      InventoryTransactionTypes.CORRECTION_AWAITING_PICKUP,
      InventoryTransactionTypes.CORRECTION_IN_USE,
      InventoryTransactionTypes.CORRECTION_RESERVED,
    ]
  }

  static getInventoryCorrectionName(type: number) {
    switch (type) {
      case InventoryTransactionTypes.CORRECTION_AVAILABLE:
        return "quantityAvailable"
      case InventoryTransactionTypes.CORRECTION_AWAITING_PICKUP:
        return "quantityAwaitingPickup"
      case InventoryTransactionTypes.CORRECTION_IN_USE:
        return "quantityInUse"
      case InventoryTransactionTypes.CORRECTION_RESERVED:
        return "quantityReserved"
      default:
        return null
    }
  }

  static getInventoryTransactionTypeString(transactionType: number) {
    switch (transactionType) {
      case InventoryTransactionTypes.RESERVE:
        return "Reserve"
      case InventoryTransactionTypes.UNRESERVE:
        return "Un-Reserve"
      case InventoryTransactionTypes.CONSUMED_FROM_RESERVE:
        return "Consumed from Reserve"
      case InventoryTransactionTypes.CONSUMED_FROM_STOCK:
        return "Consumed from Stock"
      case InventoryTransactionTypes.RESERVED_TO_CR_RENTAL:
        return "On Rent (from reserved)"
      case InventoryTransactionTypes.STOCK_TO_CR_RENTAL:
        return "On Rent (from laydown yard)"
      case InventoryTransactionTypes.CR_RENTAL_TO_STOCK:
        return "Off Rent (back to laydown yard)"
      case InventoryTransactionTypes.CR_RENTAL_TO_CR_RENTAL:
        return "Transfer Rental"
      case InventoryTransactionTypes.CORRECTION_AVAILABLE:
        return "Quantity Available - Correction"
      case InventoryTransactionTypes.CORRECTION_AWAITING_PICKUP:
        return "Quantity Awaiting Pickup - Correction"
      case InventoryTransactionTypes.CORRECTION_IN_USE:
        return "Quantity In Use -  Correction"
      case InventoryTransactionTypes.CORRECTION_RESERVED:
        return "Quantity Reserved - Correction"
      case InventoryTransactionTypes.SENT_TO_LOCATION:
        return "Sent to other Inventory Location"
      case InventoryTransactionTypes.RECEIVED_FROM_LOCATION:
        return "Received from other Inventory Location"
      default:
        return "UNKNOWN INVENTORY TRANSACTION"
    }
  }
}

/**
 * Inventory Initiating Event
 */
export class InventoryInitiatingEvents {
  static CR_INSTALLATION_COMPLETE = 10
  static CR_REMOVAL_COMPLETE = 20
  static CR_REMOVAL_COMPLETE_NEEDS_PICKUP = 21
  static CR_EDIT = 30
  static CR_START = 40
  static MATERIAL_PICKUP_COMPLETED = 50

  static getRecommendedTypeFromInitiatingEvent(initiatingEvent: number) {
    switch (initiatingEvent) {
      case InventoryInitiatingEvents.CR_INSTALLATION_COMPLETE:
        return InventoryTransactionTypes.STOCK_TO_CR_RENTAL
      case InventoryInitiatingEvents.CR_REMOVAL_COMPLETE:
        return InventoryTransactionTypes.CR_RENTAL_TO_STOCK
      case InventoryInitiatingEvents.CR_REMOVAL_COMPLETE_NEEDS_PICKUP:
        return InventoryTransactionTypes.CR_RENTAL_TO_STOCK
      // case InventoryInitiatingEvents.CR_EDIT:
      //   return InventoryTransactionTypes.CR_TO_STOCK
      // case InventoryInitiatingEvents.CR_START:
      //   return InventoryTransactionTypes.STOCK_TO_CR
      // case InventoryInitiatingEvents.MATERIAL_PICKUP_COMPLETED:
      //   return InventoryTransactionTypes.
      default:
        return null
    }
  }

  static setInitiatingEvent(taskStatus: number, taskType: number) {
    switch (taskType) {
      case TaskTypes.INSTALLATION:
        switch (taskStatus) {
          case TaskStatus.COMPLETE:
            return InventoryInitiatingEvents.CR_INSTALLATION_COMPLETE
          default:
            return null
        }
      default:
        return null
    }
  }
}

export interface InventoryLocationQuantity {
  available: number
  reserved: number
  inUse: number
  awaitingPickup: number
  lowQuantityThreshold: number
}

export class InventoryObject {
  id?: string
  refPath?: string
  title: string
  authorizedCompanies: [string?]
  craftTypes: [number?]
  lastModifiedBy: string
  status: number
  timestampLastModified: firebase.firestore.Timestamp | null
  quantities: Record<string, InventoryLocationQuantity>
  quantityAvailable: number
  quantityAwaitingPickup: number
  quantityInUse: number
  quantityReserved: number
  lowQuantityThreshold: number

  constructor(args: {
    id?: string
    refPath?: string
    authorizedCompanies: [string?]
    craftTypes: [number?]
    lastModifiedBy: string
    status: number
    timestampLastModified: firebase.firestore.Timestamp | null
    title: string
    quantities: Record<string, InventoryLocationQuantity>
    quantityAvailable: number
    quantityAwaitingPickup: number
    quantityInUse: number
    quantityReserved: number
    lowQuantityThreshold: number
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.authorizedCompanies = args.authorizedCompanies
    this.craftTypes = args.craftTypes
    this.lastModifiedBy = args.lastModifiedBy
    this.status = args.status
    this.timestampLastModified = args.timestampLastModified
    this.title = args.title
    this.quantities = args.quantities
    this.quantityAvailable = args.quantityAvailable
    this.quantityAwaitingPickup = args.quantityAwaitingPickup
    this.quantityInUse = args.quantityInUse
    this.quantityReserved = args.quantityReserved
    this.lowQuantityThreshold = args.lowQuantityThreshold
  }

  static fromFirestore(snapshot: DocumentSnapshot): InventoryObject {
    let qA = 0
    let qAP = 0
    let qIU = 0
    let qR = 0
    let lQT = 0
    if (snapshot.get("quantities")) {
      Object.values(snapshot.get("quantities")).map(
        (q: Record<string, number>) => {
          qA += q.available ?? 0
          qAP += q.awaitingPickup ?? 0
          qIU += q.inUse ?? 0
          qR += q.reserved ?? 0
          if (q.lowQuantityThreshold < lQT) {
            lQT = q.lowQuantityThreshold ?? 0
          }
        }
      )
    }
    return new InventoryObject({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      authorizedCompanies: snapshot.get("authorizedCompanies"),
      craftTypes: snapshot.get("craftTypes"),
      lastModifiedBy: snapshot.get("lastModifiedBy"),
      status: snapshot.get("status"),
      timestampLastModified: snapshot.get("timestampLastModified"),
      title: snapshot.get("title"),
      quantities: snapshot.get("quantities"),
      quantityAvailable: qA,
      quantityAwaitingPickup: qAP,
      quantityInUse: qIU,
      quantityReserved: qR,
      lowQuantityThreshold: lQT,
    })
  }

  toMap() {
    return {
      authorizedCompanies: this.authorizedCompanies,
      craftTypes: this.craftTypes,
      lastModifiedBy: this.lastModifiedBy,
      status: this.status,
      timestampLastModified: this.timestampLastModified,
      title: this.title,
      quantities: this.quantities,
    }
  }
}

/**
 * Inventory Object Status Document
 */
export class InventoryObjectStatus {
  static ACTIVE = 10
  static INACTIVE = 90

  static getStatusString(status: number) {
    switch (status) {
      case InventoryObjectStatus.ACTIVE:
        return "Active"
      case InventoryObjectStatus.INACTIVE:
        return "Inactive"
      default:
        return "UNKNOWN"
    }
  }
}

export class GlobalCraftType {
  static values() {
    return [
      CraftTypes.SCAFFOLDING,
      CraftTypes.INSULATION,
      CraftTypes.STEAM_TRACING,
      CraftTypes.FIREPROOFING,
      CraftTypes.WATERBLASTING,
      CraftTypes.VACUUM_TRUCK,
      CraftTypes.SANDBLASTING,
      CraftTypes.FILTER_CLOTHS,
      CraftTypes.PAINTING,
      CraftTypes.HOISTWELLS,
      CraftTypes.MOBILE_EQUIPMENT,
      CraftTypes.CAMERAS,
      CraftTypes.HVAC,
      CraftTypes.ELECTRICAL,
      CraftTypes.CARPENTRY,
      CraftTypes.PLUMBING,
      CraftTypes.PERMITTING,
      CraftTypes.GENERAL_LABOR,
      CraftTypes.HOUSEKEEPING,
      CraftTypes.CLEANING,
      CraftTypes.CHECKLISTS,
    ]
  }
}

export class CraftType {
  static values() {
    if (localStorage.globalCraftType === "all")
      return [
        CraftTypes.SCAFFOLDING,
        CraftTypes.INSULATION,
        CraftTypes.STEAM_TRACING,
        CraftTypes.FIREPROOFING,
        CraftTypes.WATERBLASTING,
        CraftTypes.VACUUM_TRUCK,
        CraftTypes.SANDBLASTING,
        CraftTypes.FILTER_CLOTHS,
        CraftTypes.PAINTING,
        CraftTypes.HOISTWELLS,
        CraftTypes.MOBILE_EQUIPMENT,
        CraftTypes.CAMERAS,
        CraftTypes.HVAC,
        CraftTypes.ELECTRICAL,
        CraftTypes.CARPENTRY,
        CraftTypes.PLUMBING,
        CraftTypes.PERMITTING,
        CraftTypes.GENERAL_LABOR,
        CraftTypes.HOUSEKEEPING,
        CraftTypes.CLEANING,
        CraftTypes.CHECKLISTS,
      ]
    return [parseInt(localStorage.globalCraftType)]
  }

  static getCraftTypeString(craftType: number | null) {
    switch (craftType) {
      case CraftTypes.SCAFFOLDING:
        return "Scaffolding"
      case CraftTypes.INSULATION:
        return "Insulation"
      case CraftTypes.STEAM_TRACING:
        return "Steam Tracing"
      case CraftTypes.FIREPROOFING:
        return "Fireproofing"
      case CraftTypes.WATERBLASTING:
        return "Waterblasting"
      case CraftTypes.VACUUM_TRUCK:
        return "Vacuum Truck"
      case CraftTypes.SANDBLASTING:
        return "Sandblasting"
      case CraftTypes.PAINTING:
        return "Painting"
      case CraftTypes.HOISTWELLS:
        return "Hoistwells"
      case CraftTypes.MOBILE_EQUIPMENT:
        return "Mobile Equipment"
      case CraftTypes.HVAC:
        return "HVAC"
      case CraftTypes.ELECTRICAL:
        return "Electrical"
      case CraftTypes.CARPENTRY:
        return "Carpentry"
      case CraftTypes.PLUMBING:
        return "Plumbing"
      case CraftTypes.PERMITTING:
        return "Permitting"
      case CraftTypes.GENERAL_LABOR:
        return "General Labor"
      case CraftTypes.HOUSEKEEPING:
        return "Housekeeping"
      case CraftTypes.CHECKLISTS:
        return "Checklists"
      case CraftTypes.FILTER_CLOTHS:
        return "Filter Cloths"
      case CraftTypes.CAMERAS:
        return "Cameras"
      case CraftTypes.CLEANING:
        return "Cleaning"
      case null:
        return "All"
      default:
        return "UNKNOWN"
    }
  }

  static getCraftTypeIcons(craftType: number) {
    switch (craftType) {
      case CraftTypes.SCAFFOLDING:
        return "Scaffolding.png"
      case CraftTypes.WATERBLASTING:
        return "Waterblasting.png"
      case CraftTypes.VACUUM_TRUCK:
        return "VacuumTruck.png"
      case CraftTypes.SANDBLASTING:
        return "Sandblasting.png"
      case CraftTypes.INSULATION:
        return "Insulation.png"
      case CraftTypes.STEAM_TRACING:
        return "GeneralLabor.png"
      case CraftTypes.FIREPROOFING:
        return "GeneralLabor.png"
      case CraftTypes.PAINTING:
        return "Painting.png"
      case CraftTypes.HOISTWELLS:
        return "Hoistwells.png"
      case CraftTypes.MOBILE_EQUIPMENT:
        return "Mobile_equipment.png"
      case CraftTypes.HVAC:
        return "HVAC.png"
      case CraftTypes.ELECTRICAL:
        return "HVAC.png"
      case CraftTypes.CARPENTRY:
        return "Carpentry.png"
      case CraftTypes.PLUMBING:
        return "Carpentry.png"
      case CraftTypes.HOUSEKEEPING:
        return "Housekeeping.png"
      case CraftTypes.CLEANING:
        return "Housekeeping.png"
      case CraftTypes.GENERAL_LABOR:
        return "GeneralLabor.png"
      case CraftTypes.FILTER_CLOTHS:
        return "GeneralLabor.png"
      case CraftTypes.CAMERAS:
        return "GeneralLabor.png"
      case CraftTypes.PERMITTING:
        return "Permitting.png"
      case CraftTypes.CHECKLISTS:
        return "checklists.png"
      default:
        return "UNKNOWN"
    }
  }

  static getCraftTypeRecordString(craftType: number) {
    switch (craftType) {
      case CraftTypes.SCAFFOLDING:
        return "scaffoldRecords"
      case CraftTypes.INSULATION:
        return "insulationRecords"
      case CraftTypes.STEAM_TRACING:
        return "steamTracing"
      case CraftTypes.FIREPROOFING:
        return "fireproofing"
      case CraftTypes.WATERBLASTING:
        return "waterblastingRecords"
      case CraftTypes.VACUUM_TRUCK:
        return "vacuumTruckRecords"
      case CraftTypes.SANDBLASTING:
        return "sandblastingRecords"
      case CraftTypes.FILTER_CLOTHS:
        return "filterCloths"
      case CraftTypes.PAINTING:
        return "paintingRecords"
      case CraftTypes.HOISTWELLS:
        return "hoistwellRecords"
      case CraftTypes.MOBILE_EQUIPMENT:
        return "mobileEquipmentRecords"
      case CraftTypes.CAMERAS:
        return "cameras"
      case CraftTypes.HVAC:
        return "hvacRecords"
      case CraftTypes.ELECTRICAL:
        return "electricalRecords"
      case CraftTypes.CARPENTRY:
        return "carpentryRecords"
      case CraftTypes.PLUMBING:
        return "plumbingRecords"
      case CraftTypes.PERMITTING:
        return "permittingRecords"
      case CraftTypes.GENERAL_LABOR:
        return "generalLaborRecords"
      case CraftTypes.HOUSEKEEPING:
        return "housekeepingRecords"
      case CraftTypes.CHECKLISTS:
        return "checklistRecords"
      case CraftTypes.CLEANING:
        return "cleaningRecords"
      default:
        return "UNKNOWN"
    }
  }
}

export class NewTaskIconTypes {
  static CRAFT_TYPE = 0
  static TASK_TYPE = 1
  static CRAFT_RECORD = 2
  static SELECT_LOCATION = 3
  static CRAFT_RECORD_INFO = 4
  static SCHEDULE_DATE = 5
  static TASK_INFO = 6

  static values() {
    ;[
      NewTaskIconTypes.CRAFT_TYPE,
      NewTaskIconTypes.TASK_TYPE,
      NewTaskIconTypes.CRAFT_RECORD,
      NewTaskIconTypes.SELECT_LOCATION,
      NewTaskIconTypes.CRAFT_RECORD_INFO,
      NewTaskIconTypes.SCHEDULE_DATE,
      NewTaskIconTypes.TASK_INFO,
    ]
  }

  static iconMap() {
    const icons: { [key: number]: string } = {}
    icons[NewTaskIconTypes.CRAFT_TYPE] = "mdi-shape"
    icons[NewTaskIconTypes.TASK_TYPE] = "mdi-call-merge"
    icons[NewTaskIconTypes.CRAFT_RECORD] = "mdi-shape"
    icons[NewTaskIconTypes.SELECT_LOCATION] = "mdi-target"
    icons[NewTaskIconTypes.CRAFT_RECORD_INFO] = "mdi-information"
    icons[NewTaskIconTypes.SCHEDULE_DATE] = "mdi-calendar"
    icons[NewTaskIconTypes.TASK_INFO] = "mdi-information"
    return icons
  }

  static textMap() {
    const text: { [key: number]: string } = {}
    text[NewTaskIconTypes.CRAFT_TYPE] = "Craft\nType"
    text[NewTaskIconTypes.TASK_TYPE] = "Task\nType"
    text[NewTaskIconTypes.CRAFT_RECORD] = "Craft\nRecord"
    text[NewTaskIconTypes.SELECT_LOCATION] = "Select\nLocation"
    text[NewTaskIconTypes.CRAFT_RECORD_INFO] = "Main\nInfo"
    text[NewTaskIconTypes.SCHEDULE_DATE] = "Schedule\nDate"
    text[NewTaskIconTypes.TASK_INFO] = "Task\nInfo"
    return text
  }
}

export class FlowMapTypes {
  // Change this FlowMap appearance based on where the user came from
  static NEW_TASK_FROM_HOME = 1
  static NEW_TASK_FROM_CRAFT_RECORD = 2
  static CREATE_CRAFT_RECORD = 3
  static EDIT_TASK_FROM_TASK_DETAIL = 4
  static EDIT_CRAFTRECORD_FROM_CRAFT_DETAIL = 5

  static values() {
    ;[
      FlowMapTypes.NEW_TASK_FROM_HOME,
      FlowMapTypes.NEW_TASK_FROM_CRAFT_RECORD,
      FlowMapTypes.CREATE_CRAFT_RECORD,
      FlowMapTypes.EDIT_TASK_FROM_TASK_DETAIL,
      FlowMapTypes.EDIT_CRAFTRECORD_FROM_CRAFT_DETAIL,
    ]
  }

  static flowMapArrangement() {
    const flowMap: { [key: string]: number[] } = {}
    flowMap[FlowMapTypes.NEW_TASK_FROM_HOME] = [
      NewTaskIconTypes.CRAFT_TYPE,
      NewTaskIconTypes.TASK_TYPE,
      NewTaskIconTypes.CRAFT_RECORD,
      NewTaskIconTypes.SCHEDULE_DATE,
      NewTaskIconTypes.TASK_INFO,
    ]
    flowMap[FlowMapTypes.NEW_TASK_FROM_CRAFT_RECORD] = [
      NewTaskIconTypes.TASK_TYPE,
      NewTaskIconTypes.SCHEDULE_DATE,
      NewTaskIconTypes.TASK_INFO,
    ]
    flowMap[FlowMapTypes.CREATE_CRAFT_RECORD] = [
      NewTaskIconTypes.CRAFT_TYPE,
      NewTaskIconTypes.TASK_TYPE,
      NewTaskIconTypes.SELECT_LOCATION,
      NewTaskIconTypes.CRAFT_RECORD_INFO,
      NewTaskIconTypes.SCHEDULE_DATE,
      NewTaskIconTypes.TASK_INFO,
    ]
    flowMap[FlowMapTypes.EDIT_TASK_FROM_TASK_DETAIL] = [
      NewTaskIconTypes.TASK_TYPE,
      NewTaskIconTypes.SCHEDULE_DATE,
      NewTaskIconTypes.TASK_INFO,
    ]
    return flowMap
  }
}

export class TaskType {
  static values() {
    return [
      TaskTypes.PHONE_CALL,
      TaskTypes.ESTIMATE,
      TaskTypes.INSTALLATION,
      TaskTypes.MODIFICATION,
      TaskTypes.REMOVAL,
      TaskTypes.INSPECTION,
      TaskTypes.MATERIAL_PICKUP,
      TaskTypes.AUDIT,
      TaskTypes.WATERBLASTING,
      TaskTypes.VACUUM_TRUCK,
      TaskTypes.SANDBLASTING,
      TaskTypes.INSULATION,
      TaskTypes.STEAM_TRACING,
      TaskTypes.FIREPROOFING,
      TaskTypes.ABATEMENT,
      TaskTypes.PAINTING,
      TaskTypes.REPAIR,
      TaskTypes.CARPENTRY,
      TaskTypes.LOCKOUT,
      TaskTypes.WALKTHROUGH,
      TaskTypes.PERFORMING_WORK,
      TaskTypes.GENERAL_LABOR,
      TaskTypes.AUTO_DETAILING,
      TaskTypes.HOUSEKEEPING,
      TaskTypes.CLEANING,
      TaskTypes.OPERATOR_ROUNDS,
    ]
  }

  static getFlowIcon() {
    return {
      NEW_TASK_FROM_HOME: [
        NewTaskIconTypes.CRAFT_TYPE,
        NewTaskIconTypes.TASK_TYPE,
        NewTaskIconTypes.CRAFT_RECORD,
        NewTaskIconTypes.SCHEDULE_DATE,
        NewTaskIconTypes.TASK_INFO,
      ],
      // Already know craft record and craft type
      NEW_TASK_FROM_CRAFT_RECORD: [
        NewTaskIconTypes.TASK_TYPE,
        NewTaskIconTypes.SCHEDULE_DATE,
        NewTaskIconTypes.TASK_INFO,
      ],
      // Need to create the craft record
      CREATE_CRAFT_RECORD: [
        NewTaskIconTypes.CRAFT_TYPE,
        NewTaskIconTypes.TASK_TYPE,
        NewTaskIconTypes.SELECT_LOCATION,
        NewTaskIconTypes.CRAFT_RECORD_INFO,
        NewTaskIconTypes.SCHEDULE_DATE,
        NewTaskIconTypes.TASK_INFO,
      ],
    }
  }

  static taskRestrictions(taskAction: string) {
    switch (taskAction) {
      case "delete":
        return [TaskStatus.ON_HOLD, TaskStatus.IN_PROGRESS, TaskStatus.COMPLETE]
        break
    }
  }

  static getValidTaskTypesForSite(craftType: number, siteKey: SiteKey) {
    const taskTypes = this.getValidTaskTypes(craftType)
    return taskTypes.filter((t) => siteKey.validTaskTypes.includes(t))
  }

  static getValidTaskTypes(craftType: number) {
    switch (craftType) {
      case CraftTypes.SCAFFOLDING:
        return [
          TaskTypes.INSTALLATION,
          TaskTypes.MODIFICATION,
          TaskTypes.REMOVAL,
          TaskTypes.INSPECTION,
          TaskTypes.MATERIAL_PICKUP,
          TaskTypes.AUDIT,
        ]
      case CraftTypes.WATERBLASTING:
        return [TaskTypes.WATERBLASTING]
      case CraftTypes.VACUUM_TRUCK:
        return [TaskTypes.VACUUM_TRUCK]
      case CraftTypes.SANDBLASTING:
        return [TaskTypes.SANDBLASTING]
      case CraftTypes.INSULATION:
        return [TaskTypes.INSULATION, TaskTypes.ABATEMENT]
      case CraftTypes.STEAM_TRACING:
        return [TaskTypes.STEAM_TRACING]
      case CraftTypes.FIREPROOFING:
        return [TaskTypes.FIREPROOFING]
      case CraftTypes.PAINTING:
        return [TaskTypes.PAINTING]
      case CraftTypes.HOISTWELLS:
        return [
          TaskTypes.INSTALLATION,
          TaskTypes.REPAIR,
          TaskTypes.REMOVAL,
          TaskTypes.INSPECTION,
        ]
      case CraftTypes.CAMERAS:
        return [
          TaskTypes.INSTALLATION,
          TaskTypes.REPAIR,
          TaskTypes.REMOVAL,
          TaskTypes.INSPECTION,
        ]
      case CraftTypes.FILTER_CLOTHS:
        return [
          TaskTypes.CLOTH_REPLACEMENT,
          TaskTypes.REPAIR,
          TaskTypes.HOUSEKEEPING,
          TaskTypes.INSPECTION,
        ]
      case CraftTypes.MOBILE_EQUIPMENT:
        return [TaskTypes.REPAIR, TaskTypes.INSPECTION]
      case CraftTypes.HVAC:
        return [
          TaskTypes.INSTALLATION,
          TaskTypes.REPAIR,
          TaskTypes.ESTIMATE,
          TaskTypes.PHONE_CALL,
        ]
      case CraftTypes.ELECTRICAL:
        return [TaskTypes.REPAIR, TaskTypes.ESTIMATE, TaskTypes.PHONE_CALL]
      case CraftTypes.CARPENTRY:
        return [TaskTypes.CARPENTRY]
      case CraftTypes.PLUMBING:
        return [TaskTypes.REPAIR, TaskTypes.ESTIMATE, TaskTypes.PHONE_CALL]
      case CraftTypes.HOUSEKEEPING:
        return [TaskTypes.HOUSEKEEPING]
      case CraftTypes.GENERAL_LABOR:
        return [TaskTypes.GENERAL_LABOR, TaskTypes.AUTO_DETAILING]
      case CraftTypes.PERMITTING:
        return [
          TaskTypes.LOCKOUT,
          TaskTypes.WALKTHROUGH,
          TaskTypes.PERFORMING_WORK,
        ]
      case CraftTypes.CHECKLISTS:
        return [TaskTypes.OPERATOR_ROUNDS]
      case CraftTypes.CLEANING:
        return [TaskTypes.CLEANING]
      default:
        return []
    }
  }

  static getTaskTypeString(taskType: TaskTypes) {
    switch (taskType) {
      case TaskTypes.PHONE_CALL:
        return "Phone Call"
      case TaskTypes.ESTIMATE:
        return "Estimate"
      case TaskTypes.INSTALLATION:
        return "Installation"
      case TaskTypes.MODIFICATION:
        return "Modification"
      case TaskTypes.REMOVAL:
        return "Removal"
      case TaskTypes.INSPECTION:
        return "Inspection"
      case TaskTypes.MATERIAL_PICKUP:
        return "Material Pickup"
      case TaskTypes.AUDIT:
        return "Audit"
      case TaskTypes.WATERBLASTING:
        return "Waterblasting"
      case TaskTypes.VACUUM_TRUCK:
        return "Vacuum Truck"
      case TaskTypes.SANDBLASTING:
        return "Sandblasting"
      case TaskTypes.INSULATION:
        return "Insulation"
      case TaskTypes.STEAM_TRACING:
        return "Steam Tracing"
      case TaskTypes.FIREPROOFING:
        return "Fireproofing"
      case TaskTypes.ABATEMENT:
        return "Abatement"
      case TaskTypes.PAINTING:
        return "Painting"
      case TaskTypes.REPAIR:
        return "Repair"
      case TaskTypes.CARPENTRY:
        return "Carpentry"
      case TaskTypes.LOCKOUT:
        return "Lockout"
      case TaskTypes.WALKTHROUGH:
        return "Walkthrough"
      case TaskTypes.PERFORMING_WORK:
        return "Performing Work"
      case TaskTypes.GENERAL_LABOR:
        return "General Labor"
      case TaskTypes.AUTO_DETAILING:
        return "Auto Detailing"
      case TaskTypes.HOUSEKEEPING:
        return "Housekeeping"
      case TaskTypes.OPERATOR_ROUNDS:
        return "Operator Rounds"
      case TaskTypes.CLEANING:
        return "Cleaning"
      default:
        return "UNKNOWN"
    }
  }

  static getTaskStatusStringVerbose(
    taskStatus: TaskStatus,
    taskType: TaskTypes
  ) {
    switch (taskStatus) {
      case TaskStatus.AWAITING_ESTIMATE:
        return "Awaiting Estimate"
      case TaskStatus.AWAITING_REVIEW:
        return "Awaiting Review"
      case TaskStatus.AWAITING_APPROVAL:
        return "Awaiting Approval"
      case TaskStatus.AWAITING_SCHEDULE:
        return "Awaiting Schedule"
      case TaskStatus.AWAITING_PAYMENT:
        return "Awaiting Estimate"
      case TaskStatus.SCHEDULED:
        return "Scheduled"
      case TaskStatus.AWAITING:
        return "Awaiting " + this.getTaskTypeString(taskType)
      case TaskStatus.IN_PROGRESS:
        return "In Progress " + this.getTaskTypeString(taskType)
      case TaskStatus.ON_HOLD:
        return "In Progress " + this.getTaskTypeString(taskType) + " - Hold"
      case TaskStatus.COMPLETE:
        return this.getTaskTypeString(taskType) + " Complete"
      case TaskStatus.CANCELED:
        return this.getTaskTypeString(taskType) + " Complete"
      default:
        return "UNKNOWN"
    }
  }
}

/**
 * Contains fields for the database RootUser objects.
 */
export class RootUser {
  id?: string
  refPath?: string
  appLastOpenedTimestamp: firebase.firestore.Timestamp | null
  companyName: string
  currentAppVersion: number | null
  currentBundleID: string | null
  defaultSiteKey: string | null
  department: string
  displayName: string
  email: string
  jobTitle: string
  phone: string
  receiveNotifications: boolean

  constructor(args: {
    id?: string
    refPath?: string
    appLastOpenedTimestamp: firebase.firestore.Timestamp | null
    companyName: string
    currentAppVersion: number | null
    currentBundleID: string | null
    defaultSiteKey: string | null
    department: string
    displayName: string
    email: string
    jobTitle: string
    phone: string
    receiveNotifications: boolean
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.appLastOpenedTimestamp = args.appLastOpenedTimestamp
    this.companyName = args.companyName
    this.currentAppVersion = args.currentAppVersion
    this.currentBundleID = args.currentBundleID
    this.defaultSiteKey = args.defaultSiteKey
    this.department = args.department
    this.displayName = args.displayName
    this.email = args.email
    this.jobTitle = args.jobTitle
    this.phone = args.phone
    this.receiveNotifications = args.receiveNotifications
  }

  /**
   * Create a RootUser object from a Firestore DocumentSnapshot.
   * @param snapshot
   */
  static fromFirestore(snapshot: DocumentSnapshot): RootUser {
    return new RootUser({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      appLastOpenedTimestamp: snapshot.get("appLastOpenedTimestamp"),
      companyName: snapshot.get("companyName"),
      currentAppVersion: snapshot.get("currentAppVersion"),
      currentBundleID: snapshot.get("currentBundleID"),
      defaultSiteKey: snapshot.get("defaultSiteKey"),
      department: snapshot.get("department"),
      displayName: snapshot.get("displayName"),
      email: snapshot.get("email"),
      jobTitle: snapshot.get("jobTitle"),
      phone: snapshot.get("phone"),
      receiveNotifications: snapshot.get("receiveNotifications"),
    })
  }

  /**
   * Export an object (Map/Dict in other terminology) to use when writing to
   * Firestore.
   */
  toMap() {
    return {
      appLastOpenedTimestamp: this.appLastOpenedTimestamp,
      companyName: this.companyName,
      currentAppVersion: this.currentAppVersion,
      currentBundleID: this.currentBundleID,
      defaultSiteKey: this.defaultSiteKey,
      department: this.department,
      displayName: this.displayName,
      email: this.email,
      receiveNotifications: this.receiveNotifications,
      jobTitle: this.jobTitle,
      phone: this.phone,
    }
  }
}

/**
 * Contains fields for the database subscribers objects.
 */
export class TaskSubscriber {
  id?: string
  refPath?: string
  key: string
  displayName: string

  constructor(args: {
    id?: string
    refPath?: string
    key: string
    displayName: string
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.key = args.key
    this.displayName = args.displayName
  }

  /**
   * Create a TaskSubscriber object from a Firestore DocumentSnapshot.
   * @param snapshot
   */
  static fromFirestore(snapshot: DocumentSnapshot): TaskSubscriber {
    return new TaskSubscriber({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      key: snapshot.get("key"),
      displayName: snapshot.get("displayName"),
    })
  }

  /**
   * Export an object to use when writing to Firestore.
   */
  toMap() {
    return {
      key: this.key,
      displayName: this.displayName,
    }
  }
}

export class SiteKeyUserLocations {
  id?: string
  refPath?: string
  key: string
  value: boolean

  constructor(args: {
    id?: string
    refPath?: string
    key: string
    value: boolean
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.key = args.key
    this.value = args.value
  }

  /**
   * Create a SiteKeyUserLocations object from a Firestore DocumentSnapshot.
   * @param snapshot
   */
  static fromFirestore(snapshot: DocumentSnapshot): SiteKeyUserLocations {
    return new SiteKeyUserLocations({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      key: snapshot.get("key"),
      value: snapshot.get("value"),
    })
  }

  toMap() {
    return {
      key: this.key,
      value: this.value,
    }
  }
}

export class PermissionMap {
  getsNewTaskNotifications: boolean
  canEditContractorDetails: boolean
  canCreateTasks: boolean
  canUpdateTasks: boolean
  canDeleteTasks: boolean
  canCreateCraftRecords: boolean
  canUpdateCraftRecords: boolean
  canDeleteCraftRecords: boolean
  isPlantPersonnel: boolean
  isSiteAdmin: boolean

  constructor(args: {
    getsNewTaskNotifications: boolean
    canEditContractorDetails: boolean
    canCreateTasks: boolean
    canUpdateTasks: boolean
    canDeleteTasks: boolean
    canCreateCraftRecords: boolean
    canUpdateCraftRecords: boolean
    canDeleteCraftRecords: boolean
    isPlantPersonnel: boolean
    isSiteAdmin: boolean
  }) {
    this.getsNewTaskNotifications = args.getsNewTaskNotifications
    this.canEditContractorDetails = args.canEditContractorDetails
    this.canCreateTasks = args.canCreateTasks
    this.canUpdateTasks = args.canUpdateTasks
    this.canDeleteTasks = args.canDeleteTasks
    this.canCreateCraftRecords = args.canCreateCraftRecords
    this.canUpdateCraftRecords = args.canUpdateCraftRecords
    this.canDeleteCraftRecords = args.canDeleteCraftRecords
    this.isPlantPersonnel = args.isPlantPersonnel
    this.isSiteAdmin = args.isSiteAdmin
  }

  static fromMap(map: { [key: string]: any }): PermissionMap {
    return new PermissionMap({
      getsNewTaskNotifications: map["getsNewTaskNotifications"],
      canEditContractorDetails: map["canEditContractorDetails"],
      canCreateTasks: map["canCreateTasks"],
      canUpdateTasks: map["canUpdateTasks"],
      canDeleteTasks: map["canDeleteTasks"],
      canCreateCraftRecords: map["canCreateCraftRecords"],
      canUpdateCraftRecords: map["canUpdateCraftRecords"],
      canDeleteCraftRecords: map["canDeleteCraftRecords"],
      isPlantPersonnel: map["isPlantPersonnel"],
      isSiteAdmin: map["isSiteAdmin"],
    })
  }

  toMap() {
    return {
      getsNewTaskNotifications: this.getsNewTaskNotifications,
      canEditContractorDetails: this.canEditContractorDetails,
      canCreateTasks: this.canCreateTasks,
      canUpdateTasks: this.canUpdateTasks,
      canDeleteTasks: this.canDeleteTasks,
      canCreateCraftRecords: this.canCreateCraftRecords,
      canUpdateCraftRecords: this.canUpdateCraftRecords,
      canDeleteCraftRecords: this.canDeleteCraftRecords,
      isPlantPersonnel: this.isPlantPersonnel,
      isSiteAdmin: this.isSiteAdmin,
    }
  }
}

export class SiteKeyUserPermission {
  id?: string
  refPath?: string
  approved: boolean
  companyID: string
  defaultLocationID: string | null
  customData: {}
  managementSubscriptions: {}
  permissions: PermissionMap

  constructor(args: {
    id?: string
    refPath?: string
    approved: boolean
    companyID: string
    defaultLocationID: string | null
    customData: {}
    managementSubscriptions: {}
    permissions: PermissionMap
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.approved = args.approved
    this.companyID = args.companyID
    this.defaultLocationID = args.defaultLocationID
    this.customData = args.customData
    this.managementSubscriptions = args.managementSubscriptions
    this.permissions = args.permissions
  }

  /**
   * Create a siteKeyUserPermission object from a Firestore DocumentSnapshot.
   * @param snapshot
   */
  static fromFirestore(snapshot: DocumentSnapshot): SiteKeyUserPermission {
    return new SiteKeyUserPermission({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      approved: snapshot.get("approved"),
      companyID: snapshot.get("companyID"),
      defaultLocationID: snapshot.get("defaultLocationID"),
      customData: snapshot.get("customData"),
      managementSubscriptions: snapshot.get("managementSubscriptions"),
      permissions: PermissionMap.fromMap(snapshot.get("permissions")),
    })
  }

  toMap() {
    return {
      approved: this.approved,
      companyID: this.companyID,
      defaultLocationID: this.defaultLocationID,
      customData: this.customData,
      managementSubscriptions: this.managementSubscriptions,
      permissions: this.permissions,
    }
  }
}

/**
 * Contains fields for the database CraftRecord objects.
 */
export class CraftRecord {
  id?: string
  refPath?: string
  multiCraftRecordID?: string
  assetID: string | null
  closedTasks: []
  closedTaskTypes: []
  authorizedCompanies: []
  craftDetails: { [key: string]: any }
  craftType: number
  createdBy: string
  description: string | null
  latitude: number
  locationID: string
  longitude: number
  numClosedTasks: number
  numOpenTasks: number
  open: boolean
  openTasks: []
  openTaskTypes: []
  thumbnailURL: string | null
  timestampRecordClosed: firebase.firestore.Timestamp | null
  timestampRecordCreated: firebase.firestore.Timestamp
  timestampLastModified: firebase.firestore.Timestamp
  lastModifiedBy: string
  title: string

  constructor(args: {
    id?: string
    refPath?: string
    multiCraftRecordID?: string
    assetID: string | null
    closedTasks: []
    closedTaskTypes: []
    authorizedCompanies: []
    craftDetails: {}
    craftType: number
    createdBy: string
    description: string | null
    latitude: number
    locationID: string
    longitude: number
    numClosedTasks: number
    numOpenTasks: number
    open: boolean
    openTasks: []
    openTaskTypes: []
    thumbnailURL: string | null
    timestampRecordClosed: firebase.firestore.Timestamp | null
    timestampRecordCreated: firebase.firestore.Timestamp
    timestampLastModified: firebase.firestore.Timestamp
    lastModifiedBy: string
    title: string
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.multiCraftRecordID = args.multiCraftRecordID
    this.assetID = args.assetID
    this.closedTasks = args.closedTasks
    this.closedTaskTypes = args.closedTaskTypes
    this.authorizedCompanies = args.authorizedCompanies
    this.craftDetails = args.craftDetails
    this.craftType = args.craftType
    this.createdBy = args.createdBy
    this.description = args.description
    this.latitude = args.latitude
    this.locationID = args.locationID
    this.longitude = args.longitude
    this.numClosedTasks = args.numClosedTasks
    this.numOpenTasks = args.numOpenTasks
    this.open = args.open
    this.openTasks = args.openTasks
    this.openTaskTypes = args.openTaskTypes
    this.thumbnailURL = args.thumbnailURL
    this.timestampRecordClosed = args.timestampRecordClosed
    this.timestampRecordCreated = args.timestampRecordCreated
    this.timestampLastModified = args.timestampLastModified
    this.lastModifiedBy = args.lastModifiedBy
    this.title = args.title
  }

  /**
   * Create a CraftRecord object from a Firestore DocumentSnapshot.
   * @param snapshot
   */
  static fromFirestore(snapshot: DocumentSnapshot): CraftRecord {
    if (snapshot.data() == null) {
      return null
    }
    return new CraftRecord({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      multiCraftRecordID: snapshot.get("multiCraftRecordID"),
      assetID: snapshot.get("assetID"),
      closedTasks: snapshot.get("closedTasks"),
      closedTaskTypes: snapshot.get("closedTaskTypes"),
      authorizedCompanies: snapshot.get("authorizedCompanies"),
      craftDetails: snapshot.get("craftDetails"),
      craftType: snapshot.get("craftType"),
      createdBy: snapshot.get("createdBy"),
      description: snapshot.get("description"),
      latitude: snapshot.get("latitude"),
      locationID: snapshot.get("locationID"),
      longitude: snapshot.get("longitude"),
      numClosedTasks: snapshot.get("numClosedTasks"),
      numOpenTasks: snapshot.get("numOpenTasks"),
      open: snapshot.get("open"),
      openTasks: snapshot.get("openTasks"),
      openTaskTypes: snapshot.get("openTaskTypes"),
      thumbnailURL: snapshot.get("thumbnailURL"),
      timestampRecordClosed: snapshot.get("timestampRecordClosed"),
      timestampRecordCreated: snapshot.get("timestampRecordCreated"),
      timestampLastModified: snapshot.get("timestampLastModified"),
      lastModifiedBy: snapshot.get("lastModifiedBy"),
      title: snapshot.get("title"),
    })
  }

  /**
   * Export an object (Map/Dict in other terminology) to use when writing to
   * Firestore.
   */
  // todo: May need to add multiCraftRecordID to output and handle undefined.
  toMap() {
    return {
      assetID: this.assetID,
      closedTasks: this.closedTasks,
      closedTaskTypes: this.closedTaskTypes,
      authorizedCompanies: this.authorizedCompanies,
      craftDetails: this.craftDetails,
      craftType: this.craftType,
      createdBy: this.createdBy,
      description: this.description,
      latitude: this.latitude,
      locationID: this.locationID,
      longitude: this.longitude,
      numClosedTasks: this.numClosedTasks,
      numOpenTasks: this.numOpenTasks,
      open: this.open,
      openTasks: this.openTasks,
      openTaskTypes: this.openTaskTypes,
      thumbnailURL: this.thumbnailURL,
      timestampRecordClosed: this.timestampRecordClosed,
      timestampRecordCreated: this.timestampRecordCreated,
      timestampLastModified: this.timestampLastModified,
      lastModifiedBy: this.lastModifiedBy,
      title: this.title,
    }
  }
}

/**
 * Contains fields for the database Location objects.
 */
export class Location {
  id?: string
  department: string
  latitude: number
  longitude: number
  title: string

  constructor(args: {
    id?: string
    department: string
    latitude: number
    longitude: number
    title: string
  }) {
    this.id = args.id
    this.department = args.department
    this.latitude = args.latitude
    this.longitude = args.longitude
    this.title = args.title
  }

  /**
   * Create a Location object from a Firestore DocumentSnapshot.
   * @param snapshot
   */
  static fromFirestore(snapshot: DocumentSnapshot): Location {
    return new Location({
      id: snapshot.id,
      department: snapshot.get("department"),
      latitude: snapshot.get("latitude"),
      longitude: snapshot.get("longitude"),
      title: snapshot.get("title"),
    })
  }

  /**
   * Export an object (Map/Dict in other terminology) to use when writing to
   * Firestore.
   */
  toMap() {
    return {
      department: this.department,
      latitude: this.latitude,
      longitude: this.longitude,
      title: this.title,
    }
  }
}

export class SiteKeyCompany {
  id?: string
  refPath: string
  craftTypes: CraftType[]
  logoPhotoURL: string | null
  mainPointOfContact: string | null
  members: [] | null
  name: string

  constructor(args: {
    id?: string
    refPath: string
    craftTypes: CraftType[]
    logoPhotoURL: string | null
    mainPointOfContact: string | null
    members: [] | null
    name: string
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.craftTypes = args.craftTypes
    this.logoPhotoURL = args.logoPhotoURL
    this.mainPointOfContact = args.mainPointOfContact
    this.members = args.members
    this.name = args.name
  }

  static fromFirestore(snapshot: DocumentSnapshot): SiteKeyCompany {
    return new SiteKeyCompany({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      craftTypes: snapshot.get("craftTypes"),
      logoPhotoURL: snapshot.get("logoPhotoURL"),
      mainPointOfContact: snapshot.get("mainPointOfContact"),
      members: snapshot.get("members"),
      name: snapshot.get("name"),
    })
  }

  /**
   * Export an object (Map/Dict in other terminology) to use when writing to
   * Firestore.
   */
  toMap() {
    return {
      craftTypes: this.craftTypes,
      logoPhotoURL: this.logoPhotoURL,
      mainPointOfContact: this.mainPointOfContact,
      members: this.members,
      name: this.name,
    }
  }
}

/**
 * Class defining attachment documents
 * /siteKeys/{siteKey}/attachments/{attachmentID}
 */
export class AttachmentDocument {
  id?: string
  refPath?: string
  exists?: boolean
  authorizedCompanies: string[]
  filename: string
  url: string
  timestampCreated: firebase.firestore.Timestamp | firebase.firestore.FieldValue
  createdBy: string
  craftRecordID?: string
  taskID?: string
  assetID?: string

  constructor({
    id,
    refPath,
    exists,
    authorizedCompanies,
    filename,
    url,
    timestampCreated,
    createdBy,
    craftRecordID,
    taskID,
    assetID,
  }: {
    id?: string
    refPath?: string
    exists?: boolean
    authorizedCompanies: string[]
    filename: string
    url: string
    timestampCreated:
      | firebase.firestore.Timestamp
      | firebase.firestore.FieldValue
    createdBy: string
    craftRecordID?: string
    taskID?: string
    assetID?: string
  }) {
    this.id = id
    this.refPath = refPath
    this.exists = exists
    this.authorizedCompanies = authorizedCompanies
    this.filename = filename
    this.url = url
    this.timestampCreated = timestampCreated
    this.createdBy = createdBy
    this.craftRecordID = craftRecordID
    this.taskID = taskID
    this.assetID = assetID
  }

  /**
   * Create an AttachmentDocument object from a Firestore document snapshot.
   * @param snapshot
   */
  static fromFirestore(snapshot: DocumentSnapshot): AttachmentDocument {
    return new AttachmentDocument({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      exists: snapshot.exists,
      authorizedCompanies: snapshot.get("authorizedCompanies"),
      filename: snapshot.get("filename"),
      url: snapshot.get("url"),
      timestampCreated: snapshot.get("timestampCreated"),
      createdBy: snapshot.get("createdBy"),
      craftRecordID: snapshot.get("craftRecordID"),
      taskID: snapshot.get("taskID"),
      assetID: snapshot.get("assetID"),
    })
  }

  /**
   * Return a map/object ready to be written to firestore.
   */
  toMap() {
    const optionalFields = {}
    if (this.craftRecordID !== undefined) {
      optionalFields["craftRecordID"] = this.craftRecordID
    }
    if (this.taskID !== undefined) {
      optionalFields["taskID"] = this.taskID
    }
    if (this.assetID !== undefined) {
      optionalFields["assetID"] = this.assetID
    }

    return {
      authorizedCompanies: this.authorizedCompanies,
      filename: this.filename,
      url: this.url,
      timestampCreated: this.timestampCreated,
      createdBy: this.createdBy,
      ...optionalFields,
    }
  }
}

export class UserTokenObj {
  userID: string | null
  token: string | null

  constructor(userID: string, token: string) {
    this.userID = userID
    this.token = token
  }
}

export class DownloadCode {
  id?: string
  refPath: string
  redeemed: boolean
  redeemedBy: string

  constructor(args: {
    id?: string
    refPath: string
    redeemed: boolean
    redeemedBy: string
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.redeemed = args.redeemed
    this.redeemedBy = args.redeemedBy
  }

  static fromFirestore(snapshot: DocumentSnapshot): DownloadCode {
    return new DownloadCode({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      redeemed: snapshot.get("redeemed"),
      redeemedBy: snapshot.get("redeemedBy"),
    })
  }

  toMap() {
    return {
      redeemed: this.redeemed,
      redeemedBy: this.redeemedBy,
    }
  }
}

export class Asset {
  id: string
  refPath: string
  title: string
  description: string
  customID: string
  locationID: string
  latitude: number
  longitude: number
  qrCode: string
  thumbnailURL: string

  constructor(args: {
    id?: string
    refPath?: string
    title: string
    description?: string | null
    customID?: string | null
    locationID: string
    latitude: number
    longitude: number
    qrCode?: string | null
    thumbnailURL?: string | null
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.title = args.title
    this.description = args.description
    this.customID = args.customID
    this.locationID = args.locationID
    this.latitude = args.latitude
    this.longitude = args.longitude
    this.qrCode = args.qrCode
    this.thumbnailURL = args.thumbnailURL
  }

  static fromFirestore(snapshot: DocumentSnapshot): Asset {
    return new Asset({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      title: snapshot.get("title"),
      description: snapshot.get("description"),
      customID: snapshot.get("customID"),
      locationID: snapshot.get("locationID"),
      latitude: snapshot.get("latitude"),
      longitude: snapshot.get("longitude"),
      qrCode: snapshot.get("qrCode"),
      thumbnailURL: snapshot.get("thumbnailURL"),
    })
  }

  toMap() {
    return {
      title: this.title,
      description: this.description,
      customID: this.customID,
      locationID: this.locationID,
      latitude: this.latitude,
      longitude: this.longitude,
      qrCode: this.qrCode,
      thumbnailURL: this.thumbnailURL,
    }
  }
}

export class SiteKeyUsers {
  id?: string
  refPath: string
  companyName: string
  department: string | null
  displayName: string
  email: string
  jobTitle: string
  phone: string
  userPhoto_URL: string | null

  constructor(args: {
    id?: string
    refPath: string
    companyName: string
    department: string | null
    displayName: string
    email: string
    jobTitle: string
    phone: string
    userPhoto_URL: string | null
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.companyName = args.companyName
    this.department = args.department
    this.displayName = args.displayName
    this.email = args.email
    this.jobTitle = args.jobTitle
    this.phone = args.phone
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.userPhoto_URL = args.userPhoto_URL
  }

  static fromFirestore(snapshot: DocumentSnapshot): SiteKeyUsers {
    return new SiteKeyUsers({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      companyName: snapshot.get("companyName"),
      department: snapshot.get("department"),
      displayName: snapshot.get("displayName"),
      email: snapshot.get("email"),
      jobTitle: snapshot.get("jobTitle"),
      phone: snapshot.get("phone"),
      // eslint-disable-next-line @typescript-eslint/camelcase
      userPhoto_URL: snapshot.get("userPhoto_URL"),
    })
  }

  toMap() {
    return {
      id: this.id,
      refPath: this.refPath,
      companyName: this.companyName,
      department: this.department,
      displayName: this.displayName,
      email: this.email,
      jobTitle: this.jobTitle,
      phone: this.phone,
      // eslint-disable-next-line @typescript-eslint/camelcase
      userPhoto_URL: this.userPhoto_URL,
    }
  }
}

export class SelectObject {
  text: string
  value: string

  constructor(text: string, value: string) {
    this.text = text
    this.value = value
  }
}

export class SiteKey {
  id?: string
  refPath?: string
  customizations: {}
  latitude: number
  longitude: number
  kpiConfig: {}
  managingCompanyID: string
  name: string
  timezone: string
  validCraftTypes: []
  validEventTypes: []
  validTaskStatusCodes: Array<number>
  validTaskTypes: number[]

  constructor(data: {
    id?: string
    refPath?: string
    customizations: {}
    latitude: number
    longitude: number
    kpiConfig: {}
    managingCompanyID: string
    name: string
    timezone: string
    validCraftTypes: []
    validEventTypes: []
    validTaskStatusCodes: []
    validTaskTypes: []
  }) {
    this.id = data.id
    this.refPath = data.refPath
    this.customizations = data.customizations
    this.latitude = data.latitude
    this.longitude = data.longitude
    this.kpiConfig = data.kpiConfig
    this.managingCompanyID = data.managingCompanyID
    this.name = data.name
    this.timezone = data.timezone
    this.validCraftTypes = data.validCraftTypes
    this.validEventTypes = data.validEventTypes
    this.validTaskStatusCodes = data.validTaskStatusCodes
    this.validTaskTypes = data.validTaskTypes
  }

  /**
   * Create a SiteKey instance from a Firestore DocumentSnapshot.
   * @param snapshot
   */
  static fromFirestore(snapshot: DocumentSnapshot): SiteKey {
    return new SiteKey({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      customizations: snapshot.get("customizations"),
      latitude: snapshot.get("latitude"),
      longitude: snapshot.get("longitude"),
      kpiConfig: snapshot.get("kpiConfig"),
      managingCompanyID: snapshot.get("managingCompanyID"),
      name: snapshot.get("name"),
      timezone: snapshot.get("timezone"),
      validCraftTypes: snapshot.get("validCraftTypes"),
      validEventTypes: snapshot.get("validEventTypes"),
      validTaskStatusCodes: snapshot.get("validTaskStatusCodes"),
      validTaskTypes: snapshot.get("validTaskTypes"),
    })
  }

  toMap() {
    return {
      customizations: this.customizations,
      latitude: this.latitude,
      longitude: this.longitude,
      kpiConfig: this.kpiConfig,
      managingCompanyID: this.managingCompanyID,
      name: this.name,
      timezone: this.timezone,
      validCraftTypes: this.validCraftTypes,
      validEventTypes: this.validEventTypes,
      validTaskStatusCodes: this.validTaskStatusCodes,
      validTaskTypes: this.validTaskTypes,
    }
  }
}

export class Event {
  id?: string
  refPath?: string
  assignedCompanyID: string
  craftRecordID: string
  createdBy: string
  eventType: number
  taskID: string
  taskStatus: number
  title: string
  timestampCreated: firebase.firestore.Timestamp

  constructor(data: {
    id?: string
    refPath?: string
    assignedCompanyID: string
    craftRecordID: string
    createdBy: string
    eventType: number
    taskID: string
    taskStatus: number
    title: string
    timestampCreated: firebase.firestore.Timestamp
  }) {
    this.id = data.id
    this.refPath = data.refPath
    this.assignedCompanyID = data.assignedCompanyID
    this.craftRecordID = data.craftRecordID
    this.createdBy = data.createdBy
    this.eventType = data.eventType
    this.taskID = data.taskID
    this.taskStatus = data.taskStatus
    this.timestampCreated = data.timestampCreated
    this.title = data.title
  }

  /**
   * Create an Event instance from a Firestore DocumentSnapshot.
   * @param snapshot
   */
  static fromFirestore(snapshot: DocumentSnapshot): Event {
    return new Event({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      assignedCompanyID: snapshot.get("assignedCompanyID"),
      craftRecordID: snapshot.get("craftRecordID"),
      createdBy: snapshot.get("createdBy"),
      eventType: snapshot.get("eventType"),
      taskID: snapshot.get("taskID"),
      taskStatus: snapshot.get("taskStatus"),
      title: snapshot.get("title"),
      timestampCreated: snapshot.get("timestampCreated"),
    })
  }

  toMap() {
    return {
      assignedCompanyID: this.assignedCompanyID,
      craftRecordID: this.craftRecordID,
      createdBy: this.createdBy,
      eventType: this.eventType,
      taskID: this.taskID,
      taskStatus: this.taskStatus,
      title: this.title,
      timestampCreated: this.timestampCreated,
    }
  }
}

export class KPIDocument {
  id?: string
  refPath?: string
  companyID: string
  craftType: number
  date: firebase.firestore.Timestamp
  kpiAverageDifficulty: number
  kpiAverageTaskScheduleDays: number
  kpiAverageUrgentResponseHours: number
  kpiBarrierHours: number
  kpiBarrierWeatherDelay: number
  kpiBarrierWaitingOnService: number
  kpiBarrierIncorrectMaterials: number
  kpiBarrierAreaNotReady: number
  kpiBarrierHeatRest: number
  kpiBarrierEquipmentAvailability: number
  kpiBarrierPermitDelay: number
  kpiBarrierOther: number
  kpiBarrierScopeChange: number
  kpiBarrierSiteEmergency: number
  kpiLegFeetPerHour: number
  kpiLaborHours: number
  kpiLegFeetMoved: number
  kpiLinearFeetPainted: number
  kpiLinearFeetSandblasted: number
  kpiNewTasksCreated: number
  kpiNextOpportunityCompleted: number
  kpiTasksCompletedWithDifficulty: number
  kpiStandingScaffolds: number
  kpiTotalDaysStanding: number
  kpiTotalScheduleTasks: number
  kpiTotalScheduleBacklog: number
  kpiTotalScheduleBreakIn: number
  kpiTotalScheduleEmergency: number
  kpiTotalScheduleNotOnSchedule: number
  kpiTotalScheduleOnSchedule: number
  kpiTotalScheduleUnscheduled: number
  kpiTotalScheduleUnclassified: number
  kpiUrgent: number
  kpiSquareFeetPainted: number
  kpiSquareFeetSandblasted: number
  kpiTasksCompleted: number
  locationID: string
  taskType: number
  taskIDs: Array<string>

  constructor(args: {
    id?: string
    refPath?: string
    companyID: string
    craftType: number
    date: firebase.firestore.Timestamp
    kpiAverageDifficulty: number
    kpiAverageTaskScheduleDays: number
    kpiAverageUrgentResponseHours: number
    kpiBarrierHours: number
    kpiBarrierWeatherDelay: number
    kpiBarrierWaitingOnService: number
    kpiBarrierIncorrectMaterials: number
    kpiBarrierAreaNotReady: number
    kpiBarrierHeatRest: number
    kpiBarrierEquipmentAvailability: number
    kpiBarrierPermitDelay: number
    kpiBarrierOther: number
    kpiBarrierScopeChange: number
    kpiBarrierSiteEmergency: number
    kpiLegFeetPerHour: number
    kpiLaborHours: number
    kpiLegFeetMoved: number
    kpiLinearFeetPainted: number
    kpiLinearFeetSandblasted: number
    kpiNewTasksCreated: number
    kpiNextOpportunityCompleted: number
    kpiTasksCompletedWithDifficulty: number
    kpiStandingScaffolds: number
    kpiTotalDaysStanding: number
    kpiTotalScheduleTasks: number
    kpiTotalScheduleBacklog: number
    kpiTotalScheduleBreakIn: number
    kpiTotalScheduleEmergency: number
    kpiTotalScheduleNotOnSchedule: number
    kpiTotalScheduleOnSchedule: number
    kpiTotalScheduleUnscheduled: number
    kpiTotalScheduleUnclassified: number
    kpiUrgent: number
    kpiSquareFeetPainted: number
    kpiSquareFeetSandblasted: number
    kpiTasksCompleted: number
    locationID: string
    taskType: number
    taskIDs: Array<string>
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.companyID = args.companyID
    this.craftType = args.craftType
    this.date = args.date
    this.kpiAverageDifficulty = args.kpiAverageDifficulty
    this.kpiAverageTaskScheduleDays = args.kpiAverageTaskScheduleDays
    this.kpiAverageUrgentResponseHours = args.kpiAverageUrgentResponseHours
    this.kpiBarrierHours = args.kpiBarrierHours
    this.kpiBarrierWeatherDelay = args.kpiBarrierWeatherDelay
    this.kpiBarrierWaitingOnService = args.kpiBarrierWaitingOnService
    this.kpiBarrierIncorrectMaterials = args.kpiBarrierIncorrectMaterials
    this.kpiBarrierAreaNotReady = args.kpiBarrierAreaNotReady
    this.kpiBarrierHeatRest = args.kpiBarrierHeatRest
    this.kpiBarrierEquipmentAvailability = args.kpiBarrierEquipmentAvailability
    this.kpiBarrierPermitDelay = args.kpiBarrierPermitDelay
    this.kpiBarrierOther = args.kpiBarrierOther
    this.kpiBarrierScopeChange = args.kpiBarrierScopeChange
    this.kpiBarrierSiteEmergency = args.kpiBarrierSiteEmergency
    this.kpiLegFeetPerHour = args.kpiLegFeetPerHour
    this.kpiLaborHours = args.kpiLaborHours
    this.kpiLegFeetMoved = args.kpiLegFeetMoved
    this.kpiLinearFeetPainted = args.kpiLinearFeetPainted
    this.kpiLinearFeetSandblasted = args.kpiLinearFeetSandblasted
    this.kpiNewTasksCreated = args.kpiNewTasksCreated
    this.kpiNextOpportunityCompleted = args.kpiNextOpportunityCompleted
    this.kpiTasksCompletedWithDifficulty = args.kpiTasksCompletedWithDifficulty
    this.kpiStandingScaffolds = args.kpiStandingScaffolds
    this.kpiTotalDaysStanding = args.kpiTotalDaysStanding
    this.kpiTotalScheduleTasks = args.kpiTotalScheduleTasks
    this.kpiTotalScheduleBacklog = args.kpiTotalScheduleBacklog
    this.kpiTotalScheduleBreakIn = args.kpiTotalScheduleBreakIn
    this.kpiTotalScheduleEmergency = args.kpiTotalScheduleEmergency
    this.kpiTotalScheduleNotOnSchedule = args.kpiTotalScheduleNotOnSchedule
    this.kpiTotalScheduleOnSchedule = args.kpiTotalScheduleOnSchedule
    this.kpiTotalScheduleUnscheduled = args.kpiTotalScheduleUnscheduled
    this.kpiTotalScheduleUnclassified = args.kpiTotalScheduleUnclassified
    this.kpiUrgent = args.kpiUrgent
    this.kpiSquareFeetPainted = args.kpiSquareFeetPainted
    this.kpiSquareFeetSandblasted = args.kpiSquareFeetSandblasted
    this.kpiTasksCompleted = args.kpiTasksCompleted
    this.locationID = args.locationID
    this.taskType = args.taskType
    this.taskIDs = args.taskIDs
  }

  static fromFirestore(snapshot: DocumentSnapshot): KPIDocument {
    return new KPIDocument({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      companyID: snapshot.get("companyID"),
      craftType: snapshot.get("craftType"),
      date: snapshot.get("date"),
      kpiAverageDifficulty: snapshot.get("kpiAverageDifficulty"),
      kpiAverageTaskScheduleDays: snapshot.get("kpiAverageTaskScheduleDays"),
      kpiAverageUrgentResponseHours: snapshot.get(
        "kpiAverageUrgentResponseHours"
      ),
      kpiBarrierHours: snapshot.get("kpiBarrierHours"),
      kpiBarrierWeatherDelay: snapshot.get("kpiBarrierWeatherDelay"),
      kpiBarrierWaitingOnService: snapshot.get("kpiBarrierWaitingOnService"),
      kpiBarrierIncorrectMaterials: snapshot.get(
        "kpiBarrierIncorrectMaterials"
      ),
      kpiBarrierAreaNotReady: snapshot.get("kpiBarrierAreaNotReady"),
      kpiBarrierHeatRest: snapshot.get("kpiBarrierHeatRest"),
      kpiBarrierEquipmentAvailability: snapshot.get(
        "kpiBarrierEquipmentAvailability"
      ),
      kpiBarrierPermitDelay: snapshot.get("kpiBarrierPermitDelay"),
      kpiBarrierOther: snapshot.get("kpiBarrierOther"),
      kpiBarrierScopeChange: snapshot.get("kpiBarrierScopeChange"),
      kpiBarrierSiteEmergency: snapshot.get("kpiBarrierSiteEmergency"),
      kpiLegFeetPerHour: snapshot.get("kpiLegFeetPerHour"),
      kpiLaborHours: snapshot.get("kpiLaborHours"),
      kpiLegFeetMoved: snapshot.get("kpiLegFeetMoved"),
      kpiLinearFeetPainted: snapshot.get("kpiLinearFeetPainted"),
      kpiLinearFeetSandblasted: snapshot.get("kpiLinearFeetSandblasted"),
      kpiNewTasksCreated: snapshot.get("kpiNewTasksCreated"),
      kpiNextOpportunityCompleted: snapshot.get("kpiNextOpportunityCompleted"),
      kpiTasksCompletedWithDifficulty: snapshot.get(
        "kpiTasksCompletedWithDifficulty"
      ),
      kpiStandingScaffolds: snapshot.get("kpiStandingScaffolds"),
      kpiTotalDaysStanding: snapshot.get("kpiTotalDaysStanding"),
      kpiTotalScheduleTasks: snapshot.get("kpiTotalScheduleTasks"),
      kpiTotalScheduleBacklog: snapshot.get("kpiTotalScheduleBacklog"),
      kpiTotalScheduleBreakIn: snapshot.get("kpiTotalScheduleBreakIn"),
      kpiTotalScheduleEmergency: snapshot.get("kpiTotalScheduleEmergency"),
      kpiTotalScheduleNotOnSchedule: snapshot.get(
        "kpiTotalScheduleNotOnSchedule"
      ),
      kpiTotalScheduleOnSchedule: snapshot.get("kpiTotalScheduleOnSchedule"),
      kpiTotalScheduleUnscheduled: snapshot.get("kpiTotalScheduleUnscheduled"),
      kpiTotalScheduleUnclassified: snapshot.get(
        "kpiTotalScheduleUnclassified"
      ),
      kpiUrgent: snapshot.get("kpiUrgent"),
      kpiSquareFeetPainted: snapshot.get("kpiSquareFeetPainted"),
      kpiSquareFeetSandblasted: snapshot.get("kpiSquareFeetSandblasted"),
      kpiTasksCompleted: snapshot.get("kpiTasksCompleted"),
      locationID: snapshot.get("locationID"),
      taskType: snapshot.get("taskType"),
      taskIDs: snapshot.get("taskIDs"),
    })
  }

  toMap() {
    return {
      companyID: this.companyID,
      craftType: this.craftType,
      date: this.date,
      kpiAverageDifficulty: this.kpiAverageDifficulty,
      kpiAverageTaskScheduleDays: this.kpiAverageTaskScheduleDays,
      kpiAverageUrgentResponseHours: this.kpiAverageUrgentResponseHours,
      kpiBarrierHours: this.kpiBarrierHours,
      kpiBarrierWeatherDelay: this.kpiBarrierWeatherDelay,
      kpiBarrierWaitingOnService: this.kpiBarrierWaitingOnService,
      kpiBarrierIncorrectMaterials: this.kpiBarrierIncorrectMaterials,
      kpiBarrierAreaNotReady: this.kpiBarrierAreaNotReady,
      kpiBarrierHeatRest: this.kpiBarrierHeatRest,
      kpiBarrierEquipmentAvailability: this.kpiBarrierEquipmentAvailability,
      kpiBarrierPermitDelay: this.kpiBarrierPermitDelay,
      kpiBarrierOther: this.kpiBarrierOther,
      kpiBarrierScopeChange: this.kpiBarrierScopeChange,
      kpiBarrierSiteEmergency: this.kpiBarrierSiteEmergency,
      kpiLegFeetPerHour: this.kpiLegFeetPerHour,
      kpiLaborHours: this.kpiLaborHours,
      kpiLegFeetMoved: this.kpiLegFeetMoved,
      kpiLinearFeetPainted: this.kpiLinearFeetPainted,
      kpiLinearFeetSandblasted: this.kpiLinearFeetSandblasted,
      kpiNewTasksCreated: this.kpiNewTasksCreated,
      kpiNextOpportunityCompleted: this.kpiNextOpportunityCompleted,
      kpiTasksCompletedWithDifficulty: this.kpiTasksCompletedWithDifficulty,
      kpiStandingScaffolds: this.kpiStandingScaffolds,
      kpiTotalDaysStanding: this.kpiTotalDaysStanding,
      kpiTotalScheduleTasks: this.kpiTotalScheduleTasks,
      kpiTotalScheduleBacklog: this.kpiTotalScheduleBacklog,
      kpiTotalScheduleBreakIn: this.kpiTotalScheduleBreakIn,
      kpiTotalScheduleEmergency: this.kpiTotalScheduleEmergency,
      kpiTotalScheduleNotOnSchedule: this.kpiTotalScheduleNotOnSchedule,
      kpiTotalScheduleOnSchedule: this.kpiTotalScheduleOnSchedule,
      kpiTotalScheduleUnscheduled: this.kpiTotalScheduleUnscheduled,
      kpiTotalScheduleUnclassified: this.kpiTotalScheduleUnclassified,
      kpiUrgent: this.kpiUrgent,
      kpiSquareFeetPainted: this.kpiSquareFeetPainted,
      kpiSquareFeetSandblasted: this.kpiSquareFeetSandblasted,
      kpiTasksCompleted: this.kpiTasksCompleted,
      locationID: this.locationID,
      taskType: this.taskType,
      taskIDs: this.taskIDs,
    }
  }
}

export class KPIMetric {
  id?: string
  refPath?: string
  key: string
  title: string
  icon: string
  tooltip: string
  allowedTimescales: Array<string>
  currentTimescale: string
  type: string
  denominatorKey: string
  precision: number
  calculationInfo: string
  chartType: string | null
  customSeriesFields: Array<string> | null
  craftTypes: Array<number>
  enabled: boolean
  displayCardValue: boolean
  value: number

  constructor(args: {
    id?: string
    refPath?: string
    key: string
    title: string
    icon: string
    tooltip: string
    allowedTimescales: Array<string>
    currentTimescale: string
    type: string
    denominatorKey: string
    precision: number
    calculationInfo: string
    chartType: string | null
    customSeriesFields: Array<string> | null
    craftTypes: Array<number>
    enabled: boolean
    displayCardValue: boolean
    value: number
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.key = args.key
    this.title = args.title
    this.icon = args.icon
    this.tooltip = args.tooltip
    this.allowedTimescales = args.allowedTimescales
    this.currentTimescale = args.currentTimescale
    this.type = args.type
    this.denominatorKey = args.denominatorKey
    this.precision = args.precision
    this.calculationInfo = args.calculationInfo
    this.chartType = args.chartType
    this.customSeriesFields = args.customSeriesFields
    this.craftTypes = args.craftTypes
    this.enabled = args.enabled
    this.displayCardValue = args.displayCardValue
    this.value = args.value
  }

  static fromFirestore(snapshot: DocumentSnapshot): KPIMetric {
    return new KPIMetric({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      key: snapshot.get("key"),
      title: snapshot.get("title"),
      icon: snapshot.get("icon"),
      tooltip: snapshot.get("tooltip"),
      allowedTimescales: snapshot.get("allowedTimescales"),
      currentTimescale: snapshot.get("currentTimescale"),
      type: snapshot.get("type"),
      denominatorKey: snapshot.get("denominatorKey"),
      precision: snapshot.get("precision"),
      calculationInfo: snapshot.get("calculationInfo"),
      chartType: snapshot.get("chartType"),
      customSeriesFields: snapshot.get("customSeriesFields"),
      craftTypes: snapshot.get("craftTypes"),
      enabled: snapshot.get("enabled"),
      displayCardValue: snapshot.get("displayCardValue"),
      value: 0,
    })
  }
}
