
import Vue from "vue"
import ApplyToSiteKeyModal from "@/components/ApplyToSiteKeyModal.vue"

/**
 * Used to show the user they do not have a default site selected. They can
 * then choose to apply to a new site or select from a list of ones they've been
 * approved for.
 */
export default Vue.extend({
  components: { ApplyToSiteKeyModal },
  props: {
    chooseButtonText: {
      type: String,
      default: "choose",
    },
    applyButtonText: {
      type: String,
      default: "apply",
    },
    siteKeys: {
      type: Array,
      default: () => ["Site One", "Site Two", "Site Three", "Site Four"],
    },
    headingColor: {
      type: String,
      default: "#ffffff",
    },
    elevation: {
      type: Number,
      default: 4,
    },
    buttonStyle: {
      type: String,
      default: "outlined",
    },
    demoMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "Missing/Unapproved Default Site",
      body: `You either have no default site selected or you are not yet approved for your default site.
      If you have not yet applied for a site, please tap on the APPLY button below. If you have already applied,
      contact a system administrator for approval or select from the list of approved sites by tapping on the
      CHOOSE button below.`,
      showModalForm: false,
    }
  },
  methods: {
    handleApplyToSite() {
      if (this.demoMode) {
        this.$emit("apply-to-site-key")
      } else {
        this.showModalForm = true
      }
    },
  },
})
