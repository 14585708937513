import Vue from "vue"

// Libs
import vuetify from "./plugins/vuetify"
import Antd from "ant-design-vue"
import MapCmp from "@/components/Maps.vue"
import AsyncComputed from "vue-async-computed"
import JsonCSV from "vue-json-csv"
import * as VueGoogleMaps from "vue2-google-maps"
import Ripple from "vue-material-design-ripple"

// App
import App from "./App.vue"
import { router } from "./router"
import { store } from "./store"

// Styles
import "ant-design-vue/dist/antd.css"
import "./assets/css/tailwind.css"
import "vue-material-design-ripple/dist/vue-material-design-ripple.css"

// Build Flavor Styles
import "@/assets/css/flavors.scss"

// Middleware
Vue.directive("custom-ripple", Ripple)
Vue.use(AsyncComputed)
Vue.use(Antd)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
  },
})

Vue.config.productionTip = false

// Components
Vue.component("downloadCsv", JsonCSV)
Vue.component("map-component", MapCmp)

// Middleware
Vue.use(AsyncComputed)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
