
export default {
  props: ["show", "title", "selectedUIDs"],
  data() {
    return {
      searchTerm: "",
      updatedUIDs: [],
    }
  },
  computed: {
    filteredUsers() {
      const { siteKeyUsers } = this.$store.state.firetableModule
      if (!this.searchTerm) return siteKeyUsers
      return siteKeyUsers.filter((user) => {
        return user.displayName
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      })
    },
  },
  watch: {
    selectedUIDs(newValue) {
      this.updatedUIDs = newValue?.slice() ?? []
    },
  },
  mounted() {
    this.updatedUIDs = this.selectedUIDs?.slice() ?? []
  },
  methods: {
    close() {
      this.$emit("close", false, null)
    },
    save() {
      this.$emit("save", this.updatedUIDs)
    },
  },
}
