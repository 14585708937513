
// Models

// Libs
import { Util } from "@/helpers"
import { db } from "@/firebase-init"
import moment from "moment"
import sum from "lodash/sum"
import {
  InventoryTransaction,
  InventoryTransactionTypes,
  InventoryObject,
  SiteKeyCompany,
} from "@/models/models"

const defaultColsVisibility = [
  { key: "objectTitle", visible: true },
  { key: "user", visible: true },
  { key: "company", visible: true },
  { key: "created", visible: true },
  { key: "type", visible: true },
  { key: "value", visible: true },
  { key: "parentRecord", visible: false },
  { key: "task", visible: false },
]

export default {
  data() {
    return {
      records: [],
      columns: [],
      filteredTableRecords: [],
      exportData: [],
      exportLabels: {},
      exportFields: [],
      exportFilename: `Inventory Transactions - ${moment().format(
        "MM-DD-YYYY"
      )}.csv`,
      searchTerm: Util.getStorage("invtrxs-search-term") || "",
      colsVisibility: Util.getStorage("invtrxs-list-cols-visibility")
        ? JSON.parse(Util.getStorage("invtrxs-list-cols-visibility"))
        : defaultColsVisibility,
    }
  },
  computed: {
    siteKeyUserLocations(): string[] | null {
      return this.$store.state.firetableModule.siteKeyUserLocations
    },
    siteKeyLocations(): string[] | null {
      return this.$store.state.firetableModule.siteKeyLocations
    },
  },
  asyncComputed: {
    async objects(): Promise<Array<any>> {
      const { rootUserData, inventoryTransactionList, siteKeyCompanies } =
        this.$store.state.firetableModule
      if (!rootUserData || !inventoryTransactionList) return []
      const { defaultSiteKey } = rootUserData

      // Additional data
      return Promise.all(
        inventoryTransactionList.map(async (object: InventoryTransaction) => {
          const {
            inventoryObjectID,
            createdBy,
            companyID,
            timestampCreated,
            parentRecordID,
            taskID,
            type,
            value,
          } = object
          // Get Inventory Object \
          const objectDetails: InventoryObject = await Util.getInventoryObject(
            defaultSiteKey,
            inventoryObjectID
          )
          // Get User details
          const userDetails = await Util.getSiteKeyUserDetail(
            db,
            defaultSiteKey,
            createdBy
          )
          //Company Data
          const companyData = siteKeyCompanies.find(
            (co: SiteKeyCompany) => co.id === companyID
          )
          // Craft Record
          const craftPath = `/siteKeys/${defaultSiteKey}/parentRecords/${parentRecordID}`
          const craftRecord = parentRecordID
            ? await Util.getCraftRecord(db, craftPath)
            : null
          // Task
          const taskRecord = parentRecordID
            ? await Util.getTask(db, defaultSiteKey, taskID)
            : null
          return {
            ...object,
            objectTitle: objectDetails ? objectDetails.title : "[Unavailable]",
            user: userDetails ? userDetails.displayName : "[Unavailable]",
            company: companyData ? companyData.name : "[Unavailable]",
            created: moment(timestampCreated.seconds * 1000).format(
              "MM/DD/YYYY hh:mm A"
            ),
            type: InventoryTransactionTypes.getInventoryTransactionTypeString(
              type
            ),
            parentRecord: craftRecord ? craftRecord.title : "[Unavailable]",
            task: taskRecord ? taskRecord.title : "[Unavailable]",
            value,
          }
        })
      )
    },
  },
  watch: {
    siteKeyUserLocations(newValue: string | null) {
      if (!newValue) return
      this.startListening()
    },
    objects(records) {
      this.records = this.filterRecords(this.searchTerm, records)
      this.buildColumns()
    },
    searchTerm(newTerm) {
      // filter search on every term change
      this.records = this.filterRecords(newTerm, this.objects)
      Util.setStorage("invtrxs-search-term", newTerm)
    },
  },
  methods: {
    startListening() {
      this.$store.dispatch("firetableModule/listenSiteKeyLocations")
      this.$store.dispatch("firetableModule/listenSiteKeyCompanies")
      this.$store.dispatch("firetableModule/listenInventoryTransactionList")
    },
    removeListeners() {
      this.$store.commit("firetableModule/unsubscribeSiteKeyLocationsListener")
      this.$store.commit("firetableModule/unsubscribeSiteKeyCompaniesListener")
      this.$store.commit(
        "firetableModule/unsubscribeInventoryTransactionListListener"
      )
      this.$store.commit("firetableModule/removeInventoryTransactionList")
    },
    filterRecords(term, records) {
      return records.filter((record) => {
        return term
          .toLowerCase()
          .split(" ")
          .every((v) => record.objectTitle.toLowerCase().includes(v))
      })
    },
    buildColumns() {
      const sortedField = Util.getStorage("invtrxs-list-sorter-field")
      const sortedOrder = Util.getStorage("invtrxs-list-sorter-order")
      let filters: any = Util.getStorage("invtrxs-list-filters")
      filters = filters ? JSON.parse(filters) : null
      this.columns = [
        {
          title: "Object",
          dataIndex: "objectTitle",
          align: "center",
          width: 110,
          show: this.colsVisibility.find((col) => col.key === "objectTitle")
            .visible,
          sorter: (a, b) => a.objectTitle.localeCompare(b.objectTitle),
          sortOrder: sortedField === "objectTitle" && sortedOrder,
        },
        {
          title: "User",
          dataIndex: "user",
          align: "center",
          width: 70,
          show: this.colsVisibility.find((col) => col.key === "user").visible,
          sorter: (a, b) => {
            const _a = a.user || "N/A"
            const _b = b.user || "N/A"
            return _a.localeCompare(_b)
          },
          sortOrder: sortedField === "user" && sortedOrder,
        },
        {
          title: "Company",
          dataIndex: "company",
          align: "center",
          width: 110,
          show: this.colsVisibility.find((col) => col.key === "company")
            .visible,
          sorter: (a, b) => a.company.localeCompare(b.company),
          sortOrder: sortedField === "company" && sortedOrder,
        },
        {
          title: "Type",
          dataIndex: "type",
          align: "center",
          width: 110,
          show: this.colsVisibility.find((col) => col.key === "type").visible,
          sorter: (a, b) => a.type.localeCompare(b.type),
          sortOrder: sortedField === "type" && sortedOrder,
        },
        {
          title: "Qty",
          dataIndex: "value",
          align: "center",
          width: 60,
          show: this.colsVisibility.find((col) => col.key === "value").visible,
          sorter: (a, b) => a.value - b.value,
          sortOrder: sortedField === "value" && sortedOrder,
        },
        {
          title: "Created",
          dataIndex: "created",
          align: "center",
          width: 110,
          show: this.colsVisibility.find((col) => col.key === "created")
            .visible,
          sorter: (a, b) =>
            a.timestampCreated.seconds - b.timestampCreated.seconds,
          sortOrder: sortedField === "created" && sortedOrder,
        },
        {
          title: "Craft Record",
          dataIndex: "parentRecord",
          align: "center",
          width: 110,
          show: this.colsVisibility.find((col) => col.key === "parentRecord")
            .visible,
          sorter: (a, b) => a.parentRecord.localeCompare(b.parentRecord),
          sortOrder: sortedField === "parentRecord" && sortedOrder,
        },
        {
          title: "Task",
          dataIndex: "task",
          align: "center",
          width: 110,
          show: this.colsVisibility.find((col) => col.key === "task").visible,
          sorter: (a, b) => a.task.localeCompare(b.task),
          sortOrder: sortedField === "task" && sortedOrder,
        },
      ]
    },
    saveTableFilters(_, filters, sorter, { currentDataSource }) {
      // Update current filtered table records
      this.filteredTableRecords = currentDataSource
      // Sorter
      const { field, order } = sorter
      if (order) {
        this.sorter = { field, order }
        Util.setStorage("invtrxs-list-sorter-field", field)
        Util.setStorage("invtrxs-list-sorter-order", order)
      } else {
        this.sorter = {}
        Util.removeStorage("invtrxs-list-sorter-field")
        Util.removeStorage("invtrxs-list-sorter-order")
      }

      // Filters
      if (Object.keys(filters).length > 0)
        Util.setStorage("invtrxs-list-filters", JSON.stringify(filters))
      else Util.removeStorage("invtrxs-list-filters")

      // Re-build columns
      this.buildColumns()
    },
    saveColVisibility(column, visible) {
      const index = this.colsVisibility.findIndex(
        (col) => col.key === column.dataIndex
      )
      this.colsVisibility[index].visible = visible
      // Save it to local storage
      Util.setStorage(
        "invtrxs-list-cols-visibility",
        JSON.stringify(this.colsVisibility)
      )
    },
    showClearFilters() {
      // Saved filters
      const sortedField = Util.getStorage("invtrxs-list-sorter-field")
      const sortedOrder = Util.getStorage("invtrxs-list-sorter-order")
      let filters: any = Util.getStorage("invtrxs-list-filters")
      let colsVisibility = Util.getStorage("invtrxs-list-cols-visibility")
      filters = filters ? JSON.parse(filters) : null
      colsVisibility = colsVisibility ? JSON.parse(colsVisibility) : null
      if (
        this.searchTerm ||
        sortedOrder ||
        sortedField ||
        filters ||
        colsVisibility
      )
        return true
      return false
    },
    clearFilters() {
      // Set search term
      this.searchTerm = ""
      // Cols visibility
      this.colsVisibility = defaultColsVisibility
      // Storage
      Util.removeStorage("invtrxs-list-filters")
      Util.removeStorage("invtrxs-list-sorter-field")
      Util.removeStorage("invtrxs-list-sorter-order")
      Util.removeStorage("invtrxs-list-cols-visibility")
      // Re-build columns
      this.buildColumns()
    },
    async generateCsv() {
      for (const column of this.columns)
        this.exportLabels[column.dataIndex] = column.title

      const recordsToExport = this.filterRecords(
        this.searchTerm,
        this.filteredTableRecords.length > 0
          ? this.filteredTableRecords
          : this.records
      )
      this.exportFields = Object.keys(this.exportLabels)
      this.exportData = await Promise.all(
        recordsToExport.map((object) => {
          const {
            id,
            objectTitle,
            user,
            company,
            type,
            value,
            created,
            parentRecord,
            task,
          } = object

          return {
            id,
            objectTitle,
            user,
            company,
            type,
            value,
            created,
            parentRecord,
            task,
          }
        })
      )
      setTimeout(() => this.$refs.export.generate())
    },
    tableWidth() {
      return sum(this.columns.filter((col) => col.show).map((col) => col.width))
    },
  },
  mounted() {
    if (this.siteKeyUserLocations) this.startListening()
  },
  beforeDestroy(): void {
    this.removeListeners()
  },
}
