<template>
  <GmapMap
    ref="mapRef"
    :center="markers[0].position"
    :options="{
      disableDefaultUI: true,
      fullscreenControl: true,
    }"
    :zoom="17"
    @dragend="mapDragEnd"
    map-type-id="satellite"
    :style="{ width, height }"
  >
    <GmapMarker
      :key="index"
      :title="drag ? 'Drag to move Location' : ''"
      :visible="true"
      v-for="(m, index) in markers"
      :position="m.position"
      :clickable="true"
      :options="{
        disableDefaultUI: true,
      }"
      :draggable="drag ? drag : false"
      @click="
        center = m.position
        infoWindow = true
      "
      @dragend="updateCoordinates"
    />
    <gmap-info-window
      :opened="infoWindow && craftData"
      @closeclick="infoWindow = false"
      :position="infoPosition"
      :options="{
        pixelOffset: {
          width: 0,
          height: -35,
        },
      }"
    >
      {{ craftData ? craftData.title : "" }}
      <br />
      <div v-if="craftData">
        <span v-if="craftData.numOpenTasks > 0">{{
          `${craftData.numOpenTasks} open task${
            craftData.numOpenTasks > 1 ? "s" : ""
          }`
        }}</span>
      </div>
    </gmap-info-window>
  </GmapMap>
</template>

<script>
import Vue from "vue"
import { CraftRecord } from "@/models/models"
import { db } from "@/firebase-init"

// eslint-disable-next-line @typescript-eslint/no-var-requires
const VueGoogleMaps = require("vue2-google-maps")

// eslint-disable-next-line @typescript-eslint/no-var-requires
const markerLib = require("vue2-google-maps/src/components/marker")

Vue.component("GmapMarker", markerLib.GmapMarker)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
})
export default Vue.extend({
  async created() {
    if (this.$props.craftRefPath) {
      try {
        const query = await db.doc(this.$props.craftRefPath).get()
        this.craftData = CraftRecord.fromFirestore(query)
      } catch (error) {
        this.craftData = null
      }
    }
  },
  data() {
    return {
      locationName: "",
      infoWindow: false,
      infoPosition: this.$props.markers[0].position,
      craftData: null,
    }
  },
  props: ["height", "width", "markers", "drag", "craftRefPath"],

  methods: {
    mapDragEnd() {
      this.$refs.mapRef.$mapPromise.then((map) => {
        const mapCenter = map.getCenter()
        const locationObj = {
          lat: mapCenter.lat(),
          lng: mapCenter.lng(),
        }
        this.infoPosition = locationObj
        this.$emit("draggable", locationObj)
      })
    },

    //if user drag to change location means to call parent emit function
    updateCoordinates(location) {
      const locationObj = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      }
      this.infoPosition = locationObj
      this.$emit("draggable", locationObj)
    },
  },
})
</script>
