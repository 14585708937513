import { Module, GetterTree, MutationTree, ActionTree } from "vuex"
import { DbRead } from "@/database"
import { SiteKeyUserPermission } from "@/models/models"

interface State {
  userDisplayNames: Record<string, string>
  userDisplayNamesListener: () => void
}

const state: State = {
  userDisplayNames: {},
  userDisplayNamesListener: undefined,
}

const getters: GetterTree<State, any> = {
  getUserDisplayNames: (state): State["userDisplayNames"] => {
    return state.userDisplayNames
  },
}

const mutations: MutationTree<State> = {
  setUserDisplayNames: (state, payload: State["userDisplayNames"]) => {
    state.userDisplayNames = payload
    localStorage.setItem("userDisplayNames", JSON.stringify(payload))
  },
  setUserDisplayNamesListener: (
    state,
    payload: State["userDisplayNamesListener"]
  ) => {
    state.userDisplayNamesListener = payload
  },
  /** Unsubscribe from the listener before resetting the value. */
  clearUserDisplayNamesListener: (state) => {
    if (typeof state.userDisplayNamesListener === "function") {
      state.userDisplayNamesListener()
    }

    state.userDisplayNamesListener = undefined
  },
}

const actions: ActionTree<State, any> = {
  listenUserDisplayNames: async (
    { commit },
    payload: {
      siteKey: string
      siteKeyUserPermissions: SiteKeyUserPermission
    }
  ): Promise<void> => {
    const callback = (userDisplayNames: Record<string, string>) => {
      commit("setUserDisplayNames", userDisplayNames)
    }
    commit("clearUserDisplayNamesListener")

    const { siteKey, siteKeyUserPermissions } = payload
    const listener = await DbRead.aggregates.listenUserDisplayNames(
      siteKey,
      siteKeyUserPermissions,
      callback
    )
    commit("setUserDisplayNamesListener", listener)
  },
  initUserDisplayNamesData: ({ commit }): void => {
    let storedValue = localStorage.getItem("userDisplayNames")
    if (storedValue != null) {
      storedValue = JSON.parse(storedValue)
      commit("setUserDisplayNames", storedValue)
    }
  },
  resetData: ({ commit }): void => {
    commit("clearUserDisplayNamesListener")
    commit("setUserDisplayNames", {})
  },
}

export const aggregatesModule: Module<State, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
