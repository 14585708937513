
// Models
import {
  Location,
  Asset,
  SiteKey,
  CraftRecord,
  AttachmentDocument,
} from "@/models/models"

// Libs
import { Util } from "@/helpers"
import { db } from "@/firebase-init"
import { DbWrite } from "@/database"
import { Drawer } from "ant-design-vue"
import cloneDeep from "lodash/cloneDeep"

// Components
import DynamicCraftDetails from "@/components/DynamicCraftDetails.vue"
import Vue, { PropType } from "vue"
import firebase from "firebase"
import { string } from "@/string"

export default Vue.extend({
  props: {
    craftRecord: {
      type: CraftRecord,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    attachments: {
      type: Array as PropType<AttachmentDocument[]>,
      required: true,
    },
  },
  data() {
    return {
      theme: process.env.VUE_APP_THEME,
      formValid: false,
      drawerWidth: window.innerWidth < 960 ? "90%" : "50%",
      locations: Array<Location>(),
      assets: Array<Asset>(),
      siteKeyData: SiteKey,
      updatedCraftRecord: CraftRecord,
      loadFilePicker: false,
    }
  },
  created() {
    this.getLocations()
    this.getAssets()
    this.getSitekeyData()
    this.updatedCraftRecord = cloneDeep(this.craftRecord)
  },
  components: {
    "dynamic-craft-details": DynamicCraftDetails,
    "a-drawer": Drawer,
  },
  methods: {
    close() {
      this.$emit("close")
    },

    storeDynamicValues(values) {
      this.$emit("storeDynamicValues", values)
    },

    async getLocations() {
      this.locations = await Util.getSiteKeyLocations(
        db,
        this.$store.state.firetableModule.rootUserData.defaultSiteKey
      )
    },

    async getAssets() {
      this.assets = await Util.getSiteKeyAssets(
        db,
        this.$store.state.firetableModule.rootUserData.defaultSiteKey
      )
    },

    async getSitekeyData() {
      this.siteKeyData = await Util.getSiteKey(
        db,
        this.$store.state.firetableModule.rootUserData.defaultSiteKey
      )
    },

    update() {
      if (!this.$refs.form.validate()) return
      this.$emit("update", this.updatedCraftRecord)
    },

    openAttachment(attachment) {
      window.open(attachment.url)
    },

    async deleteAttachment(attachment) {
      await DbWrite.attachments.deleteAttachment(attachment)
    },

    handleFileImport() {
      this.loadFilePicker = true

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.loadFilePicker = false
        },
        { once: true }
      )

      // Trigger click on the FileInput
      this.$refs.uploader.click()
    },
    async onFileChanged(e) {
      const { rootUserData } = this.$store.state.firetableModule

      try {
        // Ensure that you have a file before attempting to read it
        if (!e.target.files[0]) return
        this.loadFilePicker = true
        const selectedFile = e.target.files[0]

        const attachmentURL: string = await Util.saveAttachmentToStorage(
          rootUserData.defaultSiteKey,
          selectedFile
        )

        // Add attachment document to Firestore
        const newAttachment = new AttachmentDocument({
          timestampCreated: firebase.firestore.FieldValue.serverTimestamp(),
          craftRecordID: this.craftRecord.id,
          createdBy: rootUserData.id,
          filename: selectedFile.name,
          url: attachmentURL,
          authorizedCompanies: [],
        })
        await DbWrite.attachments.addNewAttachment(
          newAttachment,
          rootUserData.defaultSiteKey
        )
        this.$store.commit("firetableModule/setSuccess", string.imageUpload)
        this.loadFilePicker = false
      } catch (error) {
        this.loadFilePicker = false
        Util.errorMessage(error.message)
      }
    },
  },
})
