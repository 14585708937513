const whiteLabel = process.env.VUE_APP_FLAVOR
const AIMPOINT_PROD_URL = "https://aimpoint-iframes.web.app/auto-login"

export const PROD_REACT_APP_AUTO_LOGIN_URL =
  whiteLabel === "aimpoint"
    ? AIMPOINT_PROD_URL
    : "https://go-stilt-iframes.web.app/auto-login/"

export const DEV_REACT_APP_AUTO_LOGIN_URL =
  process.env.VUE_APP_USE_LOCAL_REACT_APP === "true"
    ? "http://localhost:3000/auto-login"
    : "https://stilt-dev-iframes.web.app/auto-login"

/**
 * Underscores are changes to forward slashes '/' by the react app.
 * ex: admin_user-management -> admin/user-management
 */
export const ADMIN_USER_MANAGEMENT_PATH = "admin_user-management"
export const ADMIN_COMPANIES_PATH = "admin_companies"
export const ADMIN_LOCATIONS_PATH = "admin_locations"
export const ADMIN_MY_SITES_PATH = "admin_my-sites"
export const COMPLIANCE_PATH = "compliance"
export const SCHEDULING_PATH = "scheduling"
export const WORK_RECORD_AND_TASKS = "work-record-and-tasks"
export const CUSTOMERS_PATH = "customers"
export const VIEW_ESTIMATE_PATH = "customers_estimates"
export const PRICEBOOK_PATH = "pricebook"
export const CHECKLISTS_PATH = "checklists"
export const CREATE_CUSTOMER_TASK_PATH = "customers_create-task"
export const INVOICES_PATH = "invoices"
export const ESTIMATES_PATH = "estimates"
export const FEEDBACK_PATH = "feedback"
export const CUSTOMERS_WORK_RECORD_LIST_DELETED_PATH =
  "customers-work-record-list-deleted"
export const CUSTOMERS_WORK_RECORD_LIST_PATH = "customers-work-record-list"
export const CUSTOMERS_TASK_LIST_DELETED_PATH = "customers-task-list-deleted"
export const CUSTOMERS_TASK_LIST_PATH = "customers-task-list"
export const KPIS_V2 = "report-templates"
