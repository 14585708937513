
import { Util } from "@/helpers"
import { EventTypes } from "@/models/models"
import { mapGetters } from "vuex"
import Vue, { PropType } from "vue"

export default Vue.extend({
  name: "EventTimeline",
  props: {
    eventList: {
      type: Array as PropType<Event[]>,
      required: true,
    },
  },
  computed: {
    ...mapGetters("aggregatesModule", {
      userDisplayNames: "getUserDisplayNames",
    }),
  },
  methods: {
    formatDate: (data) => Util.formatDate(data),

    getEventString(type) {
      return EventTypes.getEventTypeString(type)
    },

    getEventIcon(type) {
      return EventTypes.getEventTypeIconDetails(type)
    },

    getUserDisplayName(uid: string | null) {
      if (uid == null) {
        return ""
      }
      if (uid in this.userDisplayNames) {
        return this.userDisplayNames[uid]
      }
      return ""
    },
  },
})
