import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/analytics"
import "firebase/functions"
import { Util } from "@/helpers"

// Your web app's Firebase configuration
const firebaseProject = process.env.VUE_APP_FIREBASE_PROJECT
// const firebaseProject = "scaffoldtracker"

let firebaseConfig

// Uses the production Firebase project when building release version.
// Otherwise uses Stilt-Dev development Firebase project.
if (firebaseProject === "scaffoldtracker") {
  firebaseConfig = {
    apiKey: "AIzaSyDjQDwrqqMD17aSWc5AbCKIuoc8Vg4pEyg",
    authDomain: "scaffoldtracker.firebaseapp.com",
    databaseURL: "https://scaffoldtracker.firebaseio.com",
    projectId: "scaffoldtracker",
    storageBucket: "scaffoldtracker.appspot.com",
    messagingSenderId: "280039922335",
    appId: "1:280039922335:web:a888f42e802e2e6f",
    measurementId: "G-KHXG4NCLFL",
  }
} else if (firebaseProject === "stilt-dev") {
  firebaseConfig = {
    apiKey: "AIzaSyBPVPrVZI8HnMuUe3yT3erZDYt5G23a_70",
    authDomain: "stilt-dev.firebaseapp.com",
    databaseURL: "https://stilt-dev.firebaseio.com",
    projectId: "stilt-dev",
    storageBucket: "stilt-dev.appspot.com",
    messagingSenderId: "382623900204",
    appId: "1:382623900204:web:02c2bb64673545bcdb6789",
  }
} else {
  throw "Improperly configured VUE_APP_FIREBASE_PROJECT env variable."
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

if (process.env.VUE_APP_USE_FUNCTIONS_EMULATOR === "true") {
  if (firebaseProject === "scaffoldtracker") {
    throw new Error(
      "Attempting to use the local functions emulator with the production database!"
    )
  }
  import("firebase/functions")
  firebase.functions().useEmulator("localhost", 5001)
  Util.logOnlyOnDev("Using local Firebase Functions emulator.")
}

export const db = firebase.firestore()
