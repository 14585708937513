
// Libs
import moment from "moment"
import groupBy from "lodash/groupBy"
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themesAnimated from "@amcharts/amcharts4/themes/animated"
import { Util } from "@/helpers"
import { db } from "@/firebase-init"
import sortBy from "lodash/sortBy"

//Components
import Filters from "@/views/UsageMetrics/Filters.vue"

// Models
import { Location, CraftRecord } from "@/models/models"
import { Tasks } from "@/models/task"
import { mapGetters } from "vuex"

// Amcharts theme
am4core.useTheme(am4themesAnimated)

export default {
  components: {
    filters: Filters,
  },
  data() {
    return {
      loading: false,
      chart: null,
      locations: [],
      selectedTasks: 0,
      totalTasks: 0,
      filters: {
        dates: [
          moment().subtract(1, "months").startOf("month"),
          moment().subtract(1, "months").endOf("month"),
        ],
        chartTypes: [
          {
            value: "task-created-by-user",
            title: "Tasks Created by User",
            restricted: true,
          },
          {
            value: "task-created-by-user-on-behalf-of",
            title: "Tasks Created On Behalf Of",
            restricted: true,
          },
          {
            value: "tasks-created-by-location",
            title: "Tasks Created by Location",
            default: true,
            restricted: false,
          },
          {
            value: "urgent-tasks-created-by-user",
            title: "Urgent Tasks Created by User",
            restricted: true,
          },
          {
            value: "urgent-tasks-created-by-user-on-behalf-of",
            title: "Urgent Tasks Created On Behalf Of",
            restricted: true,
          },
          {
            value: "urgent-tasks-created-by-location",
            title: "Urgent Tasks Created by Location",
            restricted: false,
          },
          {
            value: "same-day-tasks-created-by-user",
            title: "Same-day Tasks Created by User",
            restricted: true,
          },
          {
            value: "same-day-tasks-created-by-on-behalf-of",
            title: "Same-day Tasks Created On Behalf Of",
            restricted: true,
          },
          {
            value: "same-day-tasks-created-by-location",
            title: "Same-day Tasks Created by Location",
            restricted: false,
          },
          {
            value: "craft-records-created-without-work-order-by-user",
            title: "Craft Records Created without Work Order by User",
            restricted: true,
          },
        ],
        departments: ["all"],
        craftTypes: ["all"],
        taskTypes: ["all"],
      },
    }
  },
  computed: {
    ...mapGetters("aggregatesModule", {
      userDisplayNames: "getUserDisplayNames",
    }),
    defaultSiteKey(): string | null {
      const { rootUserData } = this.$store.state.firetableModule
      if (!rootUserData) return null
      return rootUserData.defaultSiteKey
    },
    siteKeyUserPermissionData(): any | null {
      return this.$store.state.firetableModule.siteKeyUserPermissionData
    },
    siteKeyUserLocations(): any | null {
      return this.$store.state.firetableModule.siteKeyUserLocations
    },
  },
  watch: {
    defaultSiteKey(newValue: string | null) {
      if (!newValue) return
      this.renderChart()
    },
    siteKeyUserPermissionData(newValue: any | null) {
      if (!newValue) return
      this.renderChart()
    },
    siteKeyUserLocations(newValue: any | null) {
      if (!newValue) return
      this.getLocations()
    },
  },
  methods: {
    async updateFilters(filters) {
      this.filters = filters
      this.renderChart()
    },
    async getLocations() {
      this.locations = await Util.getSiteKeyLocations(db, this.defaultSiteKey)
      this.locations = groupBy(this.locations, "department")
    },
    async getChartData() {
      if (!this.defaultSiteKey || !this.siteKeyUserPermissionData) return
      let tasks = await Util.getTaskByDateRange(
        db,
        this.defaultSiteKey,
        moment(
          `${this.filters.dates[0].format("MM/DD/YYYY")} 00:00:00`,
          "MM/DD/YYYY HH:mm:ss"
        ).toDate(),
        moment(
          `${this.filters.dates[1].format("MM/DD/YYYY")} 00:00:00`,
          "MM/DD/YYYY HH:mm:ss"
        ).toDate()
      )
      let craftRecords = await Util.getCraftRecordsByDateRange(
        db,
        this.defaultSiteKey,
        moment(
          `${this.filters.dates[0].format("MM/DD/YYYY")} 00:00:00`,
          "MM/DD/YYYY HH:mm:ss"
        ).toDate(),
        moment(
          `${this.filters.dates[1].format("MM/DD/YYYY")} 00:00:00`,
          "MM/DD/YYYY HH:mm:ss"
        ).toDate()
      )
      // Filtering
      const { craftTypes, taskTypes, departments } = this.filters
      // Craft Types
      if (!craftTypes.includes("all") && craftTypes.length > 0) {
        tasks = tasks.filter((task) => craftTypes.includes(task.craftType))
        craftRecords = craftRecords.filter((cr) =>
          craftTypes.includes(cr.craftType)
        )
      }
      // Task Types
      if (!taskTypes.includes("all") && taskTypes.length > 0) {
        tasks = tasks.filter((task) => taskTypes.includes(task.taskType))
      }
      // Departments
      if (!departments.includes("all") && departments.length > 0) {
        let locationIds = []
        for (const dep of departments) {
          const locIds = this.locations[dep].map((loc) => loc.id)
          locationIds = [...locationIds, ...locIds]
        }
        tasks = tasks.filter((task) => locationIds.includes(task.locationID))
        craftRecords = craftRecords.filter((cr) =>
          locationIds.includes(cr.locationID)
        )
      }

      //Location Data
      const siteKeyLocations = await Util.getSiteKeyLocations(
        db,
        this.defaultSiteKey
      )

      //Additional fields
      tasks = await Promise.all(
        tasks.map(async (task: Tasks) => {
          const { createdBy, locationID } = task
          // Get User details
          const locData = siteKeyLocations.find(
            (loc: Location) => locationID === loc.id
          )
          return {
            ...task,
            locationName: locData ? locData.title : "",
          }
        })
      )
      this.totalTasks = tasks.length

      const data = []
      let maxValue = 1
      switch (this.filters.chartType.value) {
        case "task-created-by-user":
          // eslint-disable-next-line no-case-declarations
          const groupedUsers = Util.groupTasksByUser(tasks)
          for (const username in groupedUsers) {
            data.push({
              title: this.getUserDisplayName(username),
              value: groupedUsers[username].length,
            })
            if (groupedUsers[username].length > maxValue) {
              maxValue = groupedUsers[username].length
            }
          }
          this.selectedTasks = tasks.length
          break
        case "task-created-by-user-on-behalf-of":
          // eslint-disable-next-line no-case-declarations
          const groupedUsersOnBehalfOf = Util.groupTasksByOnBehalfOfUser(tasks)
          for (const username in groupedUsersOnBehalfOf) {
            data.push({
              title: this.getUserDisplayName(username),
              value: groupedUsersOnBehalfOf[username].length,
            })
            if (groupedUsersOnBehalfOf[username].length > maxValue) {
              maxValue = groupedUsersOnBehalfOf[username].length
            }
          }
          this.selectedTasks = tasks.length
          break
        case "urgent-tasks-created-by-user":
          // eslint-disable-next-line no-case-declarations
          const urgentTasks = tasks.filter((task) => task.urgent)
          // eslint-disable-next-line no-case-declarations
          const groupedUrgentUsers = Util.groupTasksByUser(urgentTasks)
          this.selectedTasks = urgentTasks.length
          for (const username in groupedUrgentUsers) {
            data.push({
              title: this.getUserDisplayName(username),
              value: groupedUrgentUsers[username].length,
            })
            if (groupedUrgentUsers[username].length > maxValue) {
              maxValue = groupedUrgentUsers[username].length
            }
          }
          break
        case "urgent-tasks-created-by-user-on-behalf-of":
          // eslint-disable-next-line no-case-declarations
          const urgentTasksOnBehalfOf = tasks.filter((task) => task.urgent)
          // eslint-disable-next-line no-case-declarations
          const groupedUrgentOnBehalfOf = Util.groupTasksByOnBehalfOfUser(
            urgentTasksOnBehalfOf
          )
          this.selectedTasks = urgentTasksOnBehalfOf.length
          for (const username in groupedUrgentOnBehalfOf) {
            data.push({
              title: this.getUserDisplayName(username),
              value: groupedUrgentOnBehalfOf[username].length,
            })
            if (groupedUrgentOnBehalfOf[username].length > maxValue) {
              maxValue = groupedUrgentOnBehalfOf[username].length
            }
          }
          break
        case "same-day-tasks-created-by-user":
          // eslint-disable-next-line no-case-declarations
          const sameDayTasksUsers = tasks.filter((task) => {
            // Get tasks where timestampAwaitingStart is the same day as the
            // timestampScheduled and it not before 1am. If the job was scheduled,
            // then timestampAwaitingStart will probably be very close to midnight
            // from our scheduled script that automatically converts from Scheduled
            // to Awaiting. We want to ignore those
            if (!task.timestampScheduled) {
              return false
            }
            if (!task.timestampAwaitingStart) {
              return false
            }
            if (task.urgent) {
              return false
            }
            const tsScheduled = moment(task.timestampScheduled.seconds * 1000)
            const tsAwaitingStart = moment(
              task.timestampAwaitingStart.seconds * 1000
            )
            const tsScheduledOneAM = tsScheduled.clone().hour(1).minute(0)

            return (
              tsScheduled.isSame(tsAwaitingStart, "days") &&
              tsAwaitingStart.isAfter(tsScheduledOneAM)
            )
          })
          // eslint-disable-next-line no-case-declarations
          const groupedSameDayUsers = Util.groupTasksByUser(sameDayTasksUsers)
          for (const username in groupedSameDayUsers) {
            data.push({
              title: this.getUserDisplayName(username),
              value: groupedSameDayUsers[username].length,
            })
            if (groupedSameDayUsers[username].length > maxValue) {
              maxValue = groupedSameDayUsers[username].length
            }
          }
          this.selectedTasks = sameDayTasksUsers.length
          break
        case "same-day-tasks-created-by-on-behalf-of":
          // eslint-disable-next-line no-case-declarations
          const sameDayTasksOnBehalfOfUsers = tasks.filter((task) => {
            // Get tasks where timestampAwaitingStart is the same day as the
            // timestampScheduled and it not before 1am. If the job was scheduled,
            // then timestampAwaitingStart will probably be very close to midnight
            // from our scheduled script that automatically converts from Scheduled
            // to Awaiting. We want to ignore those
            if (!task.timestampScheduled) {
              return false
            }
            if (!task.timestampAwaitingStart) {
              return false
            }
            if (task.urgent) {
              return false
            }
            const tsScheduled = moment(task.timestampScheduled.seconds * 1000)
            const tsAwaitingStart = moment(
              task.timestampAwaitingStart.seconds * 1000
            )
            const tsScheduledOneAM = tsScheduled.clone().hour(1).minute(0)

            return (
              tsScheduled.isSame(tsAwaitingStart, "days") &&
              tsAwaitingStart.isAfter(tsScheduledOneAM)
            )
          })

          // eslint-disable-next-line no-case-declarations
          const groupedSameDayOnBehalfOfUsers = Util.groupTasksByOnBehalfOfUser(
            sameDayTasksOnBehalfOfUsers
          )
          for (const username in groupedSameDayOnBehalfOfUsers) {
            data.push({
              title: this.getUserDisplayName(username),
              value: groupedSameDayOnBehalfOfUsers[username].length,
            })
            if (groupedSameDayOnBehalfOfUsers[username].length > maxValue) {
              maxValue = groupedSameDayOnBehalfOfUsers[username].length
            }
          }
          this.selectedTasks = sameDayTasksOnBehalfOfUsers.length
          break
        case "craft-records-created-without-work-order-by-user":
          // eslint-disable-next-line no-case-declarations
          const tasksNoWo = craftRecords.filter((cr) => {
            return !cr.craftDetails.workOrder
          })
          // eslint-disable-next-line no-case-declarations
          const groupedNoWo = Util.groupCraftRecordsByUser(tasksNoWo)
          for (const username in groupedNoWo) {
            data.push({
              title: this.getUserDisplayName(username),
              value: groupedNoWo[username].length,
            })
            if (groupedNoWo[username].length > maxValue) {
              maxValue = groupedNoWo[username].length
            }
          }
          this.selectedTasks = tasksNoWo.length
          break
        case "tasks-created-by-location":
          // eslint-disable-next-line no-case-declarations
          const groupedLocs = Util.groupTasksByLocation(tasks)
          for (const location in groupedLocs) {
            data.push({ title: location, value: groupedLocs[location].length })
            if (groupedLocs[location].length > maxValue) {
              maxValue = groupedLocs[location].length
            }
          }
          this.selectedTasks = tasks.length

          break
        case "urgent-tasks-created-by-location":
          // eslint-disable-next-line no-case-declarations
          const groupedUrgentLocs = Util.groupTasksByLocation(
            tasks.filter((task) => task.urgent)
          )
          for (const location in groupedUrgentLocs) {
            data.push({
              title: location,
              value: groupedUrgentLocs[location].length,
            })
            if (groupedUrgentLocs[location].length > maxValue) {
              maxValue = groupedUrgentLocs[location].length
            }
          }
          this.selectedTasks = tasks.filter((task) => task.urgent).length

          break
        case "same-day-tasks-created-by-location":
          // eslint-disable-next-line no-case-declarations
          const sameDayTasksLocs = tasks.filter((task) => {
            // Get tasks where timestampAwaitingStart is the same day as the
            // timestampScheduled and it not before 1am. If the job was scheduled,
            // then timestampAwaitingStart will probably be very close to midnight
            // from our scheduled script that automatically converts from Scheduled
            // to Awaiting. We want to ignore those
            if (!task.timestampScheduled) {
              return false
            }
            if (!task.timestampAwaitingStart) {
              return false
            }
            if (task.urgent) {
              return false
            }
            const tsScheduled = moment(task.timestampScheduled.seconds * 1000)
            const tsAwaitingStart = moment(
              task.timestampAwaitingStart.seconds * 1000
            )
            const tsScheduledOneAM = tsScheduled.clone().hour(1).minute(0)

            return (
              tsScheduled.isSame(tsAwaitingStart, "days") &&
              tsAwaitingStart.isAfter(tsScheduledOneAM)
            )
          })
          // eslint-disable-next-line no-case-declarations
          const groupedSameDayLocs = Util.groupTasksByLocation(sameDayTasksLocs)
          for (const location in groupedSameDayLocs) {
            data.push({
              title: location,
              value: groupedSameDayLocs[location].length,
            })
            if (groupedSameDayLocs[location].length > maxValue) {
              maxValue = groupedSameDayLocs[location].length
            }
          }
          this.selectedTasks = sameDayTasksLocs.length

          break
        default:
      }
      return { data, maxValue }
    },
    async renderChart() {
      // Clear chart if any
      if (this.chart) this.chart.dispose()
      this.loading = true

      // Create a new one
      const chart = am4core.create("chartContainer", am4charts.XYChart)

      // Data
      const { data, maxValue } = await this.getChartData()
      chart.data = sortBy(data, "value").reverse()

      // Title
      const title = chart.titles.push(new am4core.Label())
      title.fontSize = 25
      title.marginBottom = 15
      const {
        chartType: { title: titleText },
        dates: [from, to],
      } = this.filters
      title.html = `<span class="align-center d-flex flex-column">
        <span>${titleText}</span>
        <span class="f-16">from ${moment(from).format(
          "MMM DD YYYY"
        )} to ${moment(to).format("MMM DD YYYY")}</span>
         <span class="f-16">${this.selectedTasks} of ${
        this.totalTasks
      } total tasks</span>
      </span>`

      const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.dataFields.category = "title"
      categoryAxis.renderer.minGridDistance = 1
      categoryAxis.renderer.inversed = true
      categoryAxis.renderer.grid.template.disabled = true

      const valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
      valueAxis.min = 0
      valueAxis.max = maxValue * 1.1

      const series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.categoryY = "title"
      series.dataFields.valueX = "value"
      series.tooltipText = "{valueX.value}"
      series.columns.template.strokeOpacity = 0
      series.columns.template.column.cornerRadiusBottomRight = 5
      series.columns.template.column.cornerRadiusTopRight = 5

      const labelBullet = series.bullets.push(new am4charts.LabelBullet())
      labelBullet.label.dx = 20
      labelBullet.label.text =
        "{values.valueX.workingValue.formatNumber('#as')}"

      // Get colors from palette
      series.columns.template.adapter.add("fill", (fill, target) => {
        return am4core
          .color(process.env.VUE_APP_PRIMARY)
          .lighten((1 / chart.data.length) * target.dataItem.index)
      })

      this.chart = chart
      this.loading = false
    },
    getUserDisplayName(uid: string | null) {
      if (uid == null) {
        return ""
      }
      if (uid in this.userDisplayNames) {
        return this.userDisplayNames[uid]
      }
      return ""
    },
  },
  mounted() {
    if (this.defaultSiteKey && this.siteKeyUserPermissionData)
      this.renderChart()
    this.getLocations()
  },
  beforeDestroy() {
    if (this.chart) this.chart.dispose()
  },
}
