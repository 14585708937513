var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-content',{class:`bg-${_vm.theme}`},[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{staticClass:"white-bg",attrs:{"xs12":"","sm8":"","md4":""}},[_c('v-row',{staticClass:"row mt-3 t-a-c"},[_c('v-col',[_c('img',{staticStyle:{"width":"auto"},attrs:{"src":`/image/${_vm.logo}`}})])],1),_c('v-row',{staticClass:"row"},[_c('v-col',[_c('h2',{staticClass:"title primary--text"},[_vm._v(_vm._s(_vm.slogan))])])],1),_c('v-row',{staticClass:"row"},[_c('v-col',{staticClass:"pt-0"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.signIn.apply(null, arguments)}}},[_c('v-container',[_c('v-text-field',{attrs:{"label":"Email","name":"login","prepend-icon":"mdi-mail","type":"text","rules":[
                      () => _vm.username.length > 0 || 'Username is required.',
                    ]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{attrs:{"id":"password","label":"Password","name":"password","prepend-icon":"mdi-lock","append-icon":_vm.passShow ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.typePass,"rules":[
                      () => _vm.password.length > 0 || 'Password is required.',
                    ]},on:{"click:append":function($event){return _vm.passwordVisible()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{class:`w-200p ${
                          _vm.theme === 'stilt'
                            ? 'btn-stilt'
                            : 'btn-gradient white--text'
                        }`,attrs:{"type":"submit","rounded":"","outlined":"","disabled":!_vm.formIsValid,"loading":_vm.loading}},[_vm._v(" Login ")])],1)],1),_c('v-spacer'),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":"/forgot-password"}},[_c('v-btn',{staticClass:"w-200p",attrs:{"color":"primary","rounded":"","outlined":""}},[_vm._v(" Forgot Password? ")])],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":"/create-user","tag":"span"}},[_c('v-btn',{staticClass:"w-200p",attrs:{"color":"primary","rounded":"","outlined":""}},[_vm._v(" Create Account ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }