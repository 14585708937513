import { Module, GetterTree, MutationTree, ActionTree } from "vuex"

// Local
import { DbRead, DbWrite } from "@/database"
import { ReportData } from "@/models/report-data"
import { ReportConfig } from "@/models/report-config"
import { Util } from "@/helpers"
import { sort } from "@/reports"

interface State {
  isDeletingReport: boolean
  isGeneratingReport: boolean
  reportDataList: ReportData[]
  reportDataListListener?: () => void
}

const state: State = {
  isDeletingReport: false,
  isGeneratingReport: false,
  reportDataList: [],
  reportDataListListener: undefined,
}

const getters: GetterTree<State, any> = {
  getAll: (state): State["reportDataList"] => {
    return sort.timestampCreated(state.reportDataList)
  },
  getReport: (state) => (reportId: string) => {
    return state.reportDataList.find((reportData) => reportData.id === reportId)
  },
  isGeneratingReport: (state): State["isGeneratingReport"] => {
    return state.isGeneratingReport
  },
}

const mutations: MutationTree<State> = {
  setReportDataList: (state, payload: State["reportDataList"]) => {
    state.reportDataList = payload
    // Cache in localStorage.
    localStorage.setItem("reportDataList", JSON.stringify(payload))
  },
  setReportDataListListener: (
    state,
    payload: State["reportDataListListener"]
  ) => {
    state.reportDataListListener = payload
  },

  /** Unsubscribe from the listener before resetting the value. */
  clearReportDataListListener: (state) => {
    if (typeof state.reportDataListListener === "function") {
      state.reportDataListListener()
    }

    state.reportDataListListener = undefined
  },
  setIsGeneratingReport: (state, payload: boolean) => {
    if (typeof payload !== "boolean") {
      throw new Error("Cannot set isGeneratingReport to a non-boolean value")
    } else {
      state.isGeneratingReport = payload
    }
  },
  setIsDeletingReport: (state, payload: boolean) => {
    if (typeof payload !== "boolean") {
      throw new Error("Cannot set isDeletingReport to a non-boolean value")
    } else {
      state.isGeneratingReport = payload
    }
  },
}

const actions: ActionTree<State, any> = {
  fetchReportDataList: async (
    { commit },
    payload: {
      siteKey: string
      uid: string
    }
  ): Promise<void> => {
    const callback = (reportDataList: ReportData[]) => {
      commit("setReportDataList", reportDataList)
    }
    commit("clearReportDataListListener")

    const { siteKey, uid } = payload
    const listener = await DbRead.reports.listenAllReportData(
      siteKey,
      uid,
      callback
    )
    commit("setReportDataListListener", listener)
  },

  /** Retrieve from localStorage */
  initReportData: ({ commit }): void => {
    let storedValue = localStorage.getItem("reportDataList")
    if (storedValue != null) {
      storedValue = JSON.parse(storedValue)
      commit("setReportDataList", storedValue)
    }
  },
  /** Reset module state to initial values */
  resetData: ({ commit }): void => {
    commit("clearReportDataListListener")
    commit("setReportDataList", [])
  },
  /**
   * Delete a report data document from Firestore.
   */
  deleteReportData: async (
    { commit },
    payload: { siteKey: string; reportDataId: string }
  ): Promise<void> => {
    commit("setIsDeletingReport", true)
    const { siteKey, reportDataId } = payload

    try {
      await DbWrite.reports.deleteData(reportDataId, siteKey)
    } finally {
      commit("setIsDeletingReport", false)
    }
  },
  /**
   * Generate a report based on supplied report configuration.
   */
  generate: async (
    { commit },
    payload: { siteKey: string; reportConfig: ReportConfig }
  ): Promise<ReportData | undefined> => {
    // Set for loading UI indicator
    commit("setIsGeneratingReport", true)

    const { siteKey, reportConfig } = payload

    let reportData
    try {
      reportData = await DbWrite.reports.generateReportData({
        siteKey: siteKey,
        configuration: reportConfig,
      })
    } catch (e) {
      commit("setIsGeneratingReport", false)
      throw e
    }

    commit("setIsGeneratingReport", false)

    return reportData
  },

  emailReportDownloadURL: async (
    { commit },
    payload: { siteKey: string; reportDataId: string }
  ): Promise<void> => {
    // Set for loading UI indicator
    commit("setIsGeneratingReport", true)

    const { siteKey, reportDataId } = payload

    try {
      const response = await DbRead.reports.sendEmail(siteKey, reportDataId)
      Util.logOnlyOnDev(response)
      commit("setIsGeneratingReport", false)
    } catch (e) {
      commit("setIsGeneratingReport", false)
      throw e
    }
    commit("setIsGeneratingReport", false)
  },
}

export const reportDataModule: Module<State, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
