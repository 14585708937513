import { Module, GetterTree, MutationTree, ActionTree } from "vuex"
import { DbRead } from "@/database"

interface State {
  rootUserSiteKeys: Record<string, boolean>
  rootUserPrivateDocListener: () => void
}

const state: State = {
  rootUserSiteKeys: {},
  rootUserPrivateDocListener: undefined,
}

const getters: GetterTree<State, any> = {
  getRootUserSiteKeys: (state): State["rootUserSiteKeys"] => {
    return state.rootUserSiteKeys
  },
}

const mutations: MutationTree<State> = {
  setRootUserSiteKeys: (state, payload: State["rootUserSiteKeys"]) => {
    state.rootUserSiteKeys = payload
    localStorage.setItem("rootUserSiteKeys", JSON.stringify(payload))
  },
  setRootUserPrivateDocListener: (
    state,
    payload: State["rootUserPrivateDocListener"]
  ) => {
    state.rootUserPrivateDocListener = payload
  },
  /** Unsubscribe from the listener before resetting the value. */
  clearRootUserPrivateDocListener: (state) => {
    if (typeof state.rootUserPrivateDocListener === "function") {
      state.rootUserPrivateDocListener()
    }

    state.rootUserPrivateDocListener = undefined
  },
}

const actions: ActionTree<State, any> = {
  listenRootUserPrivateDoc: async (
    { commit },
    payload: {
      uid: string
    }
  ): Promise<void> => {
    const callback = (rootUserSiteKeys: Record<string, boolean>) => {
      commit("setRootUserSiteKeys", rootUserSiteKeys)
    }
    commit("clearRootUserPrivateDocListener")

    const { uid } = payload
    const listener = await DbRead.rootUser.listenRootUserPrivateDoc(
      uid,
      callback
    )
    commit("setRootUserPrivateDocListener", listener)
  },
  initRootUserPrivateDocData: ({ commit }): void => {
    let storedValue = localStorage.getItem("rootUserSiteKeys")
    if (storedValue != null) {
      storedValue = JSON.parse(storedValue)
      commit("setRootUserSiteKeys", storedValue)
    }
  },
  resetData: ({ commit }): void => {
    commit("clearRootUserPrivateDocListener")
    commit("setRootUserSiteKeys", {})
  },
}

export const rootUserPrivateDocModule: Module<State, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
