import { SiteKey } from "@/models/models"
import * as Sentry from "@sentry/browser"

export function displayNextOpportunity(
  siteKey: SiteKey,
  craftType: number,
  taskType: number
): boolean {
  try {
    if (siteKey.customizations == null) {
      // Show if customizations is null
      return true
    }
    if (!("nextOpportunityAllowed" in siteKey.customizations)) {
      // Show if nextOpportunityAllowed isn't in the customizations map
      return true
    }

    const _nextOpportunityAllowed = siteKey.customizations[
      "nextOpportunityAllowed"
    ] as Record<string, number>[]

    if (
      _nextOpportunityAllowed == null ||
      _nextOpportunityAllowed.length === 0
    ) {
      // Hide no matter the craft/task type
      return false
    }

    for (const index in _nextOpportunityAllowed) {
      const craftTaskType = _nextOpportunityAllowed[index] as Record<
        string,
        number
      >

      if (
        craftTaskType["craftType"] === craftType &&
        craftTaskType["taskType"] === taskType
      ) {
        // craft/task type combo was found, return true to display it
        return true
      }
    }
    return false
  } catch (error) {
    Sentry.captureException(error)
    // And return true as the default
    return true
  }
}

export function awaitingEstimateEnabled(
  siteKey: SiteKey,
  craftType: number,
  taskType: number
): boolean {
  try {
    if (siteKey.customizations == null) {
      return false
    }
    if (!("awaitingEstimateEnabled" in siteKey.customizations)) {
      return false
    }

    const _awaitingEstimateEnabled = siteKey.customizations[
      "awaitingEstimateEnabled"
    ] as Record<string, number>[]

    if (
      _awaitingEstimateEnabled == null ||
      _awaitingEstimateEnabled.length === 0
    ) {
      return false
    }

    for (const index in _awaitingEstimateEnabled) {
      const craftTaskType = _awaitingEstimateEnabled[index] as Record<
        string,
        number
      >

      if (
        craftTaskType["craftType"] === craftType &&
        craftTaskType["taskType"] === taskType
      ) {
        return true
      }
    }
    return false
  } catch (error) {
    Sentry.captureException(error)
    // And return true as the default
    return false
  }
}

export function assignedToEnabled(siteKey: SiteKey) {
  try {
    if (siteKey.customizations == null) {
      return false
    }
    if (!("assignedToEnabled" in siteKey.customizations)) {
      return false
    }

    const _assignedToEnabled = siteKey.customizations[
      "assignedToEnabled"
    ] as boolean

    return _assignedToEnabled === true
  } catch (error) {
    Sentry.captureException(error)
    // And return true as the default
    return false
  }
}

export function inventoryEnabled(siteKey: SiteKey) {
  try {
    if (siteKey.customizations == null) {
      return false
    }
    if (!("inventoryEnabled" in siteKey.customizations)) {
      return false
    }

    const _enabled = siteKey.customizations["inventoryEnabled"] as boolean

    return _enabled === true
  } catch (error) {
    Sentry.captureException(error)
    // And return false as the default
    return false
  }
}

export function feedbackEnabled(siteKey: SiteKey) {
  try {
    if (siteKey.customizations == null) {
      return false
    }
    if (!("feedbackEnabled" in siteKey.customizations)) {
      return false
    }

    const _enabled = siteKey.customizations["feedbackEnabled"] as boolean

    return _enabled === true
  } catch (error) {
    Sentry.captureException(error)
    // And return false as the default
    return false
  }
}

export function complianceEnabled(siteKey: SiteKey): boolean {
  try {
    if (siteKey.customizations == null) {
      return false
    }
    if (!("compliance" in siteKey.customizations)) {
      return false
    }

    const enabled = siteKey.customizations["compliance"]
    return enabled === true
  } catch (e) {
    Sentry.captureException(e)
    return false
  }
}

export function schedulingDispatchEnabled(siteKey: SiteKey): boolean {
  try {
    if (siteKey.customizations == null) {
      return false
    }
    if (!("schedulingDispatchEnabled" in siteKey.customizations)) {
      return false
    }

    const enabled = siteKey.customizations["schedulingDispatchEnabled"]
    return enabled === true
  } catch (e) {
    Sentry.captureException(e)
    return false
  }
}

export function kpisEnabled(siteKey: SiteKey): boolean {
  try {
    if (siteKey.customizations == null) {
      return true
    }
    if (!("kpisEnabled" in siteKey.customizations)) {
      return true
    }

    const enabled = siteKey.customizations["kpisEnabled"]
    return enabled === true
  } catch (e) {
    Sentry.captureException(e)
    return true
  }
}

export function kpisV2Enabled(siteKey: SiteKey): boolean {
  try {
    if (siteKey.customizations == null) {
      return false
    }
    if (!("reportTemplatesEnabled" in siteKey.customizations)) {
      return true
    }

    const enabled = siteKey.customizations["reportTemplatesEnabled"]
    return enabled === true
  } catch (e) {
    Sentry.captureException(e)
    return true
  }
}

export function invoicesEnabled(siteKey: SiteKey): boolean {
  try {
    if (siteKey.customizations == null) {
      return false
    }
    if (!("invoicesEnabled" in siteKey.customizations)) {
      return false
    }

    const enabled = siteKey.customizations["invoicesEnabled"]
    return enabled === true
  } catch (e) {
    Sentry.captureException(e)
    return false
  }
}

export function estimatesEnabled(siteKey: SiteKey): boolean {
  try {
    if (siteKey.customizations == null) {
      return false
    }
    if (!("estimatesEnabled" in siteKey.customizations)) {
      return false
    }

    const enabled = siteKey.customizations["estimatesEnabled"]
    return enabled === true
  } catch (e) {
    Sentry.captureException(e)
    return false
  }
}

export function customersEnabled(siteKey: SiteKey): boolean {
  try {
    if (siteKey.customizations == null) {
      return false
    }
    if (!("siteClassification" in siteKey.customizations)) {
      return false
    }

    return siteKey.customizations["siteClassification"] === "customers"
  } catch (e) {
    Sentry.captureException(e)
    return false
  }
}

export function priceBookEnabled(siteKey: SiteKey): boolean {
  try {
    if (siteKey.customizations == null) {
      return false
    }
    if (!("siteClassification" in siteKey.customizations)) {
      return false
    }

    return siteKey.customizations["siteClassification"] === "customers"
  } catch (e) {
    Sentry.captureException(e)
    return false
  }
}

export function customersSiteClassification(siteKey: SiteKey): boolean {
  try {
    if (siteKey.customizations == null) {
      return false
    }
    if (!("siteClassification" in siteKey.customizations)) {
      return false
    }

    return siteKey.customizations["siteClassification"] === "customers"
  } catch (e) {
    Sentry.captureException(e)
    return false
  }
}

export function routingEnabled(siteKey: SiteKey): boolean {
  try {
    if (siteKey.customizations == null) {
      return false
    }
    if (!("routing" in siteKey.customizations)) {
      return false
    }
    if (!("enabled" in siteKey.customizations["routing"])) {
      return false
    }

    const enabled = siteKey.customizations["routing"]["enabled"]
    return enabled === true
  } catch (e) {
    Sentry.captureException(e)
    return false
  }
}
