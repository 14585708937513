
// Libs
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themesAnimated from "@amcharts/amcharts4/themes/animated"
import { Util } from "@/helpers"
import { db } from "@/firebase-init"
import groupBy from "lodash/groupBy"
import uniqBy from "lodash/uniqBy"
import capitalize from "lodash/capitalize"
import intersection from "lodash/intersection"
import flatten from "lodash/flatten"
import uniq from "lodash/uniq"
import moment from "moment"

//Components
import KPICard from "@/views/KPIs/Historical/KPICard.vue"
import Filters from "@/views/KPIs/Historical/Filters.vue"
import TaskListDialog from "./TaskListDialog.vue"
import StandingScaffolds from "@/components/StandingScaffolds.vue"

// Models
import {
  KPIDocument,
  KPIMetric,
  SiteKeyCompany,
  Location,
  TaskTypes,
  TaskType,
} from "@/models/models"

am4core.useTheme(am4themesAnimated)

interface DataObject {
  loading: boolean
  filters: any
  companies: Array<SiteKeyCompany>
  locations: Array<Location>
  kpis: Array<KPIDocument>
  chart: any | null
  selectedKpi: KPIDocument | null
  primaryColor: string
  kpiMetrics: Array<KPIMetric>
  showTaskListDialog: boolean
  taskListDialogData: any | null
}

export default {
  components: {
    "kpi-card": KPICard,
    "historical-filters": Filters,
    "task-list-dialog": TaskListDialog,
    "standing-scaffolds": StandingScaffolds,
  },
  data(): DataObject {
    return {
      loading: false,
      filters: {
        dates: [moment().subtract(30, "days"), moment()],
        companies: ["all"],
        departments: ["all"],
        craftTypes: ["all"],
        taskTypes: ["all"],
      },
      companies: [],
      locations: [],
      kpis: [],
      chart: null,
      selectedKpi: null,
      primaryColor: process.env.VUE_APP_PRIMARY,
      kpiMetrics: [],
      showTaskListDialog: false,
      taskListDialogData: null,
    }
  },
  beforeDestroy() {
    if (this.chart) this.chart.dispose()
  },
  computed: {
    defaultSiteKey(): string | null {
      const { rootUserData } = this.$store.state.firetableModule
      if (!rootUserData) return null
      return rootUserData.defaultSiteKey
    },
    siteKeyUserPermissionData(): any | null {
      return this.$store.state.firetableModule.siteKeyUserPermissionData
    },
  },
  watch: {
    defaultSiteKey(newValue: string | null) {
      if (newValue) this.getInitialData()
    },
    siteKeyUserPermissionData(newValue: any | null) {
      if (newValue) this.getInitialData()
    },
    kpiMetrics(newValue: Array<KPIMetric> | null) {
      // None selected, select first
      if (newValue && !this.selectedKpi) {
        this.selectedKpi = newValue.find((i) => i.key === "kpiPercentSchedule")
        this.updateFilters({
          ...this.filters,
          dates: [
            moment(
              `${this.filters.dates[0].format("MM/DD/YYYY")} 00:00:00`,
              "MM/DD/YYYY HH:mm:ss"
            ).toDate(),
            moment(
              `${this.filters.dates[1].format("MM/DD/YYYY")} 23:59:59`,
              "MM/DD/YYYY HH:mm:ss"
            ).toDate(),
          ],
        })
      }
    },
  },
  mounted() {
    if (this.defaultSiteKey && this.siteKeyUserPermissionData)
      this.getInitialData()
  },
  methods: {
    async getInitialData() {
      if (!this.defaultSiteKey || !this.siteKeyUserPermissionData) return

      // Get all site key companies list to assign to select dropdown
      this.companies = await Util.getSiteKeyCompanies({
        db,
        defaultSiteKey: this.defaultSiteKey,
        userPermissions: this.siteKeyUserPermissionData,
      })
      this.companies = uniqBy(this.companies, "name")
      this.companies = this.companies.sort((a, b) =>
        a.name.localeCompare(b.name)
      )
      // get all siteKey locations data
      this.locations = await Util.getSiteKeyLocations(db, this.defaultSiteKey)
      this.locations = groupBy(this.locations, "department")

      // Get KPIs config
      this.fetchKPIMetrics()
    },
    async fetchKPIMetrics() {
      const { kpiConfig } = await Util.getSiteKey(db, this.defaultSiteKey)
      this.kpiMetrics = Object.keys(kpiConfig)
        .map((k) => ({
          ...kpiConfig[k],
          key: k,
        }))
        .filter((k) => k.enabled)
        .sort((a, b) => a.order - b.order)
    },
    async updateFilters(filters) {
      this.filters = filters
      const [from, to] = filters.dates
      const { craftTypes, taskTypes, companies, departments } = filters
      // Query kpis
      this.loading = true
      this.kpis = []
      this.kpis = await Util.getKpiRecords(
        db,
        this.defaultSiteKey,
        this.siteKeyUserPermissionData,
        craftTypes,
        from,
        to
      )
      // Filter by task types
      if (!taskTypes.includes("all") && taskTypes.length > 0)
        this.kpis = this.kpis.filter((kpi) => taskTypes.includes(kpi.taskType))

      // Filter by departments
      if (!departments.includes("all") && departments.length > 0) {
        let locationIds = []
        for (const dep of departments) {
          const locIds = this.locations[dep].map((loc) => loc.id)
          locationIds = [...locationIds, ...locIds]
        }
        this.kpis = this.kpis.filter((kpi) =>
          locationIds.includes(kpi.locationID)
        )
      }

      // Filter by companies
      if (!companies.includes("all") && companies.length > 0)
        this.kpis = this.kpis.filter((kpi) => companies.includes(kpi.companyID))

      // Re-fetch KPIs
      await this.fetchKPIMetrics()

      // Build KPIs
      this.buildKpiCards()
    },
    async buildKpiCards() {
      for (const key in this.kpiMetrics) {
        const { type } = this.kpiMetrics[key]
        switch (type) {
          case "total":
            this.kpiMetrics[key].value = Util.calcKpiTotal(
              this.kpiMetrics[key],
              this.kpis
            )
            break
          case "average":
            this.kpiMetrics[key].value = Util.calcKpiAverage(
              this.kpiMetrics[key],
              this.kpis
            )
            break
          case "percentage":
            this.kpiMetrics[key].value = Util.calcKpiPercentage(
              this.kpiMetrics[key],
              this.kpis
            )
            break
          default:
        }
      }
      // Filter visible kpi cards
      this.kpiMetrics = this.kpiMetrics.filter((metric) => {
        // Show card if "All" is selected or no restrictions in metric
        if (
          !metric.craftTypes.length ||
          this.filters.craftTypes.includes("all")
        )
          return true
        // Show card if restrictions and filters intersect
        const matched = intersection(metric.craftTypes, this.filters.craftTypes)
        if (matched.length > 0) return true
        // Don't show card
        return false
      })
      this.loading = false
      // Update chart
      this.updateChart(this.selectedKpi)
    },
    updateChart(metric) {
      this.selectedKpi = metric
      if (!this.kpis.length) return
      switch (metric.chartType) {
        case "date-column":
          this.renderDateColumnChart()
          break
        case "pie-simple":
          this.renderPieSimpleChart()
          break
        default:
          if (this.chart) this.chart.dispose()
      }
    },
    showSelectedTasks() {
      this.taskListDialogData = {
        taskIDs: uniq(flatten(this.kpis.map((kpi) => kpi.taskIDs))),
      }
      this.setShowTaskListDialog(true)
    },
    async renderDateColumnChart() {
      // Clear chart if any
      if (this.chart) this.chart.dispose()
      // Useful data
      const {
        title: kpiTitle,
        currentTimescale,
        type,
        customSeriesFields,
      } = this.selectedKpi
      // Create chart instance
      const chart = am4core.create("chartContainer", am4charts.XYChart)
      chart.zoomOutButton.disabled = true
      // Get Data
      chart.data = Util.calcDateColumnData(this.selectedKpi, this.kpis)

      // Title
      const title = chart.titles.push(new am4core.Label())
      title.fontSize = 25
      title.marginBottom = 15

      // Create daily series and related axes
      const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
      dateAxis.renderer.minGridDistance = 20
      dateAxis.renderer.grid.template.location = 0.5
      dateAxis.renderer.labels.template.rotation = 0
      title.html = `<span class="align-center d-flex flex-column">
        <span>${kpiTitle}</span>
        <span class="f-16">${capitalize(currentTimescale)} Chart</span>
      </span>`
      switch (currentTimescale) {
        case "daily":
        case "weekly":
          dateAxis.dateFormats.setKey("day", "MMM dd")
          dateAxis.renderer.labels.template.rotation = -45
          break
        case "monthly":
          dateAxis.dateFormats.setKey("month", "MMM yy")
          break
        default:
      }

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      if (type === "percentage") {
        valueAxis.min = 0
        valueAxis.max = 100
      }

      const createSeries = (value, name) => {
        const series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = value
        series.dataFields.dateX = "date"
        series.name = name
        series.hidden = false
        series.stacked = true
        series.xAxis = dateAxis
        series.yAxis = valueAxis
        series.columns.template.column.fillOpacity = 0.8
        series.columns.template.strokeWidth = 0
        series.tooltip.pointerOrientation = "vertical"
        series.tooltipText = "{name}: [bold]{valueY}[/]"
        // Determine the series initial visibility
        series.hidden = customSeriesFields ? value === "all" : value !== "all"
        // Tooltip filter to not show the values with 0
        series.adapter.add("tooltipText", (text, target: any) =>
          target.tooltipDataItem.valueY ? text : null
        )
        // Tasks modal
        series.columns.template.events.on("hit", (ev) => {
          this.taskListDialogData = ev.target.dataItem.dataContext
          this.setShowTaskListDialog(true)
        })

        // Colors
        series.columns.template.adapter.add("fill", (fill, target) => {
          return am4core
            .color(process.env.VUE_APP_PRIMARY)
            .lighten((1 / chart.data.length) * target.dataItem.index)
        })
      }

      /**
       * Render only task types for selected craft type
       * or selected task types
       */
      const isCraftTypeFiltered =
        !this.filters.craftTypes.includes("all") &&
        this.filters.craftTypes.length > 0
      const isTaskTypeFiltered =
        !this.filters.taskTypes.includes("all") &&
        this.filters.taskTypes.length > 0

      let taskTypes = []
      // Craft Type filter ON, Task Type OFF: get ONLY task types for selected craft types
      if (isCraftTypeFiltered && !isTaskTypeFiltered) {
        for (const craftType of this.filters.craftTypes)
          taskTypes = [...taskTypes, ...TaskType.getValidTaskTypes(craftType)]
      } else if (!isCraftTypeFiltered && !isTaskTypeFiltered) {
        // Craft Type filter OFF, Task Type OFF: get ALL task types
        taskTypes = TaskType.values()
      } else {
        //Task Type ON: get ONLY selected task types
        taskTypes = this.filters.taskTypes
      }

      // Add total series
      createSeries("all", "All")
      // Create all series segmented by custom series fields
      if (customSeriesFields) {
        const { kpiConfig } = await Util.getSiteKey(db, this.defaultSiteKey)
        for (const field of customSeriesFields) {
          const fieldName = kpiConfig[field].title
          createSeries(field, fieldName)
        }
      } else {
        // Create all series segmented by task types
        for (const taskType of taskTypes)
          createSeries(taskType, TaskTypes.getTaskTypeString(taskType))
      }

      // Add cursor
      chart.cursor = new am4charts.XYCursor()
      // Add legend
      chart.legend = new am4charts.Legend()
      chart.legend.itemContainers.template.events.on("hit", (ev) => {
        // Show just clicked series (ONLY on types !== 'total')
        if (type !== "total") {
          for (const key in chart.series.values) chart.series.values[key].hide()
          return
        }
        // On type === 'total', 'All' series should not render stacked mode
        const firstSeries = chart.series.values[0]
        const clickedIndex = ev.target.dataItem.index
        // 'All' series visble and clicked another series: hide 'All' series
        if (clickedIndex !== 0 && firstSeries.visible) firstSeries.hide()
        // 'All' series clicked, it was hidden: hide the rest
        if (clickedIndex === 0 && !firstSeries.visible)
          for (const key in chart.series.values) chart.series.values[key].hide()
      })

      // Store in view
      this.chart = chart
    },
    async renderPieSimpleChart() {
      // Clear chart if any
      if (this.chart) this.chart.dispose()
      // Useful data
      const { title: kpiTitle } = this.selectedKpi

      // Create chart instance
      const chart = am4core.create("chartContainer", am4charts.PieChart)
      // Get Data
      chart.data = await Util.calcPieSimpleData(
        this.selectedKpi,
        this.kpis,
        this.defaultSiteKey
      )

      // Add and configure Series
      const pieSeries = chart.series.push(new am4charts.PieSeries())
      pieSeries.dataFields.value = "value"
      pieSeries.dataFields.category = "subfield"
      pieSeries.slices.template.stroke = am4core.color("#fff")
      pieSeries.slices.template.strokeOpacity = 1
      pieSeries.slices.template.tooltipText =
        "{category}: [bold]{value.percent.formatNumber('#.#')}%[/]"

      // This creates initial animation
      pieSeries.hiddenState.properties.opacity = 1
      pieSeries.hiddenState.properties.endAngle = -90
      pieSeries.hiddenState.properties.startAngle = -90

      // Colors
      pieSeries.slices.template.adapter.add("fill", (fill, target) => {
        return am4core
          .color(process.env.VUE_APP_PRIMARY)
          .lighten((1 / chart.data.length) * target.dataItem.index)
      })

      chart.hiddenState.properties.radius = am4core.percent(0)

      // Title
      const title = chart.titles.push(new am4core.Label())
      title.fontSize = 25
      title.marginBottom = 15
      title.html = `<span class="align-center d-flex flex-column">
        <span>${kpiTitle}</span>
        <span class="f-16">from ${moment(this.filters.dates[0]).format(
          "MMM DD, YYYY"
        )} to ${moment(this.filters.dates[1]).format("MMM DD, YYYY")}</span>
      </span>`
    },
    setShowTaskListDialog(value) {
      this.showTaskListDialog = value
    },
  },
}
