
export default {
  props: ["dialogData", "loading"],
  data() {
    return { theme: process.env.VUE_APP_THEME }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    action() {
      this.$emit("action")
    },
  },
}
