import { Module, GetterTree, MutationTree, ActionTree } from "vuex"
import { DbRead } from "@/database"
import { AttachmentDocument, SiteKeyUserPermission } from "@/models/models"

interface State {
  attachments: AttachmentDocument[]
  attachmentsListener: () => void
}

const state: State = {
  attachments: [],
  attachmentsListener: undefined,
}

const getters: GetterTree<State, any> = {
  getAttachments: (state): State["attachments"] => {
    return state.attachments
  },
}

const mutations: MutationTree<State> = {
  setAttachments: (state, payload: State["attachments"]) => {
    state.attachments = payload
    localStorage.setItem("attachments", JSON.stringify(payload))
  },
  setAttachmentsListener: (state, payload: State["attachmentsListener"]) => {
    state.attachmentsListener = payload
  },
  /** Unsubscribe from the listener before resetting the value. */
  clearAttachmentsListener: (state) => {
    if (typeof state.attachmentsListener === "function") {
      state.attachmentsListener()
    }

    state.attachmentsListener = undefined
  },
}

const actions: ActionTree<State, any> = {
  listenAttachmentDocuments: async (
    { commit },
    payload: {
      siteKey: string
      siteKeyUserPermissions: SiteKeyUserPermission
      taskID: string | undefined
      craftRecordID: string | undefined
      assetID: string | undefined
    }
  ): Promise<void> => {
    const callback = (attachments: AttachmentDocument[]) => {
      commit("setAttachments", attachments)
    }
    commit("clearAttachmentsListener")

    const { siteKey, siteKeyUserPermissions, taskID, craftRecordID, assetID } =
      payload
    const listener = await DbRead.attachments.listenAttachmentDocuments(
      siteKey,
      siteKeyUserPermissions,
      craftRecordID,
      taskID,
      assetID,
      callback
    )
    commit("setAttachmentsListener", listener)
  },
  initAttachmentsData: ({ commit }): void => {
    let storedValue = localStorage.getItem("attachments")
    if (storedValue != null) {
      storedValue = JSON.parse(storedValue)
      commit("setAttachments", storedValue)
    }
  },
  resetData: ({ commit }): void => {
    commit("clearAttachmentsListener")
    commit("setAttachments", {})
  },
}

export const attachmentsModule: Module<State, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
