import firebase from "firebase"
import Timestamp = firebase.firestore.Timestamp
import DocumentSnapshot = firebase.firestore.DocumentSnapshot
import { CustomerTypes } from "@/models/customer"

export class CustomerLocation {
  id?: string
  refPath?: string
  customerID: string
  fullAddress: string | null
  addressLine1: string | null
  addressLine2: string | null
  streetNumber: string | null
  street: string | null
  city: string | null
  zipCode: string
  state: string | null
  county: string | null
  latitude: number | null
  longitude: number | null
  googlePlaceID: string | null
  notes: string | null
  type: CustomerTypes | null
  tags: string[]
  customData: Record<string, any>
  yearBuilt: number | null
  estimatedValue: number | null
  squareFootage: number | null
  timestampCreated: Timestamp
  timestampLastModified: Timestamp
  createdBy: string
  lastModifiedBy: string
  deleted: boolean

  constructor(args: {
    id?: string
    refPath?: string
    customerID: string
    fullAddress: string | null
    addressLine1: string | null
    addressLine2: string | null
    streetNumber: string | null
    street: string | null
    city: string | null
    zipCode: string
    state: string | null
    county: string | null
    latitude: number | null
    longitude: number | null
    googlePlaceID: string | null
    notes: string | null
    type: CustomerTypes | null
    tags: string[]
    customData: Record<string, any>
    yearBuilt: number | null
    estimatedValue: number | null
    squareFootage: number | null
    timestampCreated: Timestamp
    timestampLastModified: Timestamp
    createdBy: string
    lastModifiedBy: string
    deleted: boolean
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.customerID = args.customerID
    this.fullAddress = args.fullAddress
    this.addressLine1 = args.addressLine1
    this.addressLine2 = args.addressLine2
    this.streetNumber = args.streetNumber
    this.street = args.street
    this.city = args.city
    this.zipCode = args.zipCode
    this.state = args.state
    this.county = args.county
    this.latitude = args.latitude
    this.longitude = args.longitude
    this.googlePlaceID = args.googlePlaceID
    this.notes = args.notes
    this.type = args.type
    this.tags = args.tags
    this.customData = args.customData
    this.yearBuilt = args.yearBuilt
    this.estimatedValue = args.estimatedValue
    this.squareFootage = args.squareFootage
    this.timestampCreated = args.timestampCreated
    this.timestampLastModified = args.timestampLastModified
    this.createdBy = args.createdBy
    this.lastModifiedBy = args.lastModifiedBy
    this.deleted = args.deleted
  }

  static fromFirestore(snapshot: DocumentSnapshot): CustomerLocation {
    return new CustomerLocation({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      customerID: snapshot.get("customerID"),
      fullAddress: snapshot.get("fullAddress"),
      addressLine1: snapshot.get("addressLine1"),
      addressLine2: snapshot.get("addressLine2"),
      streetNumber: snapshot.get("streetNumber"),
      street: snapshot.get("street"),
      city: snapshot.get("city"),
      zipCode: snapshot.get("zipCode"),
      state: snapshot.get("state"),
      county: snapshot.get("county"),
      latitude: snapshot.get("latitude"),
      longitude: snapshot.get("longitude"),
      googlePlaceID: snapshot.get("googlePlaceID"),
      notes: snapshot.get("notes"),
      type: snapshot.get("type"),
      tags: snapshot.get("tags"),
      customData: snapshot.get("customData"),
      yearBuilt: snapshot.get("yearBuilt"),
      estimatedValue: snapshot.get("estimatedValue"),
      squareFootage: snapshot.get("squareFootage"),
      timestampCreated: snapshot.get("timestampCreated"),
      timestampLastModified: snapshot.get("timestampLastModified"),
      createdBy: snapshot.get("createdBy"),
      lastModifiedBy: snapshot.get("lastModifiedBy"),
      deleted: snapshot.get("deleted"),
    })
  }
}
