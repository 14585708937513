
// Libs
import moment from "moment"

// Models
import { GlobalCraftType, CraftType, TaskTypes } from "@/models/models"

export default {
  props: [
    "loading",
    "defaultFilters",
    "locations",
    "siteKeyUserPermissionData",
  ],
  data() {
    return {
      defaultDateRange: [
        moment().subtract(1, "months").startOf("month"),
        moment().subtract(1, "months").endOf("month"),
      ],
      filters: this.defaultFilters,
      craftTypes: GlobalCraftType.values().map((craftType) => ({
        value: craftType,
        name: CraftType.getCraftTypeString(craftType),
      })),
      taskTypes: TaskTypes.values().map((taskType) => ({
        value: taskType,
        name: TaskTypes.getTaskTypeString(taskType),
      })),
    }
  },
  watch: {
    siteKeyUserPermissionData: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) return
        const {
          permissions: { isPlantPersonnel },
        } = newValue
        this.filters.chartTypes = this.filters.chartTypes.map((ct) => {
          if (!ct.restricted) return ct
          return { ...ct, restricted: !isPlantPersonnel }
        })
      },
    },
    defaultFilters: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.filters = newValue
      },
    },
  },
  mounted() {
    this.filters.chartType = this.getDefaultChart()
    this.update()
  },
  methods: {
    moment,
    getObjectKeys(obj) {
      return Object.keys(obj).sort()
    },
    getDefaultChart() {
      // If restricted and user can't see it, pick the first non-restricted one
      return this.filters.chartTypes.find((ch) => ch.default)
    },
    update() {
      this.$emit("updateFilters", this.filters)
    },
  },
}
