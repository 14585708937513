
// Libs
import Vue from "vue"
import firebase from "firebase/app"
import "firebase/functions"
import "firebase/auth"
import { string } from "../string"

export default Vue.extend({
  data() {
    return {
      theme: process.env.VUE_APP_THEME,
      logo: process.env.VUE_APP_LOGO,
      slogan: process.env.VUE_APP_DESC,
      displayName: "",
      email: "",
      password: "",
      companyName: "",
      department: "",
      jobTitle: "",
      phone: "",
      confirmPassword: "",
      showPassword: false,
      loading: false,
    }
  },

  computed: {
    comparePasswords(): string | boolean {
      if (this.password !== this.confirmPassword)
        return "Passwords do not match"
      return true
    },

    formIsValid(): boolean {
      return (
        this.displayName.length > 0 &&
        this.email.length > 0 &&
        this.password.length > 0 &&
        this.confirmPassword.length > 0 &&
        this.comparePasswords === true &&
        this.companyName.length > 0 &&
        this.department.length > 0 &&
        this.jobTitle.length > 0 &&
        this.phone.length > 0
      )
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    fieldNotEmpty(value: string): string | boolean {
      if (value.length === 0) {
        return "This field is required."
      } else {
        return true
      }
    },
    async sendVerificationEmail() {
      try {
        const sendVerificationLink = firebase
          .functions()
          .httpsCallable("sendVerificationLink")
        await sendVerificationLink({
          version: process.env.VUE_APP_THEME,
        })
      } catch (e) {
        this.$store.commit("firetableModule/setError", e.message)
      }
    },
    async createUser() {
      this.loading = true
      const userData = {
        displayName: this.displayName,
        email: this.email,
        password: this.password,
        companyName: this.companyName,
        department: this.department,
        jobTitle: this.jobTitle,
        phone: this.phone,
      }

      // Create a callable version of the HTTPS cloud function 'createAccount'
      const createAccount = firebase.functions().httpsCallable("createAccount")

      try {
        // call the cloud function and await the response
        await createAccount(userData)
        // Sign the user in automatically with email and password to send
        const userCredential = await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
        const firebaseUser = userCredential.user
        if (firebaseUser == null) {
          this.$store.commit(
            "firetableModule/setError",
            string.verificationError
          )
        } else {
          this.$store.commit("firetableModule/setSuccess", string.userRegister)
          this.$router.push("/user-info")
          // We now here call the CF to send a verification link
          this.sendVerificationEmail()
        }
      } catch (e) {
        this.$store.commit("firetableModule/setError", e.message)
      } finally {
        this.loading = false
      }
    },
  },
})
