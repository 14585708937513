import { ReportTypes } from "@/reports"
import { ReportConfig } from "@/models/report-config"
import { ReportSpec } from "@/models/report-spec"

// todo: convert to interface + manager namespace
export class ReportData {
  id?: string
  downloadURL?: string
  type: ReportTypes
  user: string
  timestampCreated: string
  data: { [k: string]: unknown }
  reportSpec: ReportSpec
  reportConfig: ReportConfig

  constructor({
    id,
    downloadURL,
    type,
    user,
    timestampCreated,
    data,
    reportSpec,
    reportConfig,
  }: ReportData) {
    this.id = id
    this.downloadURL = downloadURL
    this.type = type
    this.user = user
    this.timestampCreated = timestampCreated
    this.data = data
    this.reportSpec = reportSpec
    this.reportConfig = reportConfig
  }
}
