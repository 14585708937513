
import { Tasks } from "@/models/task"
import Vue, { PropType } from "vue"
import { TaskStatus, TaskType } from "@/models/models"

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object as PropType<Tasks>,
      required: true,
    },
    changeTaskStatus: {
      type: Function,
      required: true,
    },
    submit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      statusCodes: [TaskStatus.AWAITING_ESTIMATE, TaskStatus.AWAITING],
      TaskType: TaskType,
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
  },
})
