import Vue from "vue"
import Vuetify from "vuetify"
import { UserVuetifyPreset } from "vuetify"

export const config: Partial<UserVuetifyPreset> = {
  icons: {
    iconfont: "mdi",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: process.env.VUE_APP_PRIMARY,
        secondary: process.env.VUE_APP_SECONDARY,
        accent: process.env.VUE_APP_ACCENT,
        error: process.env.VUE_APP_ERROR,
        info: process.env.VUE_APP_INFO,
        success: process.env.VUE_APP_SUCCESS,
        warning: process.env.VUE_APP_WARNING,
      },
    },
  },
}

const vuetify = new Vuetify(config)

Vue.use(Vuetify)

export default vuetify
