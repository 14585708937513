<template>
  <v-container class="f-f">
    <v-row>
      <v-col>
        <h1 class="c-color f-22">User Roles & Permissions</h1>
      </v-col>
    </v-row>

    <!-- <v-row class="pt-3"> -->
    <v-row>
      <v-col cols="12" sm="5" md="4" lg="4" class="pb-0">
        <h3 class="f-18 c-color d-inline-block">Site Key Approval</h3>
        <v-switch v-model="dense" class="ma-0 pl-5 d-inline-block"></v-switch>
      </v-col>
      <v-col cols="12" sm="7" md="8" lg="8" class="p-0">
        <v-row class="key">
          <v-col cols="6" sm="6" md="3" lg="3" class="p-0 mt-4">
            <h1 class="c-color f-18 ml-5 pl-5">Select A Preset</h1>
          </v-col>
          <v-col cols="6" sm="6" md="8" lg="8" class="p-0 mt-2">
            <v-select :items="items" label="Plant User" solo class></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- </v-row> -->

    <v-row>
      <v-col>
        <h1 class="c-color f-18">Permission:</h1>
      </v-col>
    </v-row>
    <div class="p-3 border">
      <v-row class="text_LH_normal permission">
        <v-col xl="4" lg="4" md="6" sm="6" cols="12" class="pt-0">
          <v-row class="border-r">
            <v-col cols="9" sm="9" md="9" lg="9" class="pt-0">
              <h5 class="c-color f-14">Add New Requests</h5>
              <small class="mute-color t-j">User can create new requests</small>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="pt-0">
              <v-switch v-model="dense" class="ma-0 pl-5"></v-switch>
            </v-col>
          </v-row>
        </v-col>
        <v-col xl="4" lg="4" md="6" sm="6" cols="12" class="pt-0">
          <v-row class="border-r">
            <v-col cols="9" sm="9" md="9" lg="9" class="pt-0">
              <h5 class="c-color f-14">Delete Requests</h5>
              <small class="mute-color t-j"
                >Delete Requests created by other users</small
              >
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="pt-0">
              <v-switch v-model="dense" class="ma-0 pl-5"></v-switch>
            </v-col>
          </v-row>
        </v-col>
        <v-col xl="4" lg="4" md="6" sm="6" cols="12" class="pt-0">
          <v-row class="border-r">
            <v-col cols="9" sm="9" md="9" lg="9" class="pt-0">
              <h5 class="c-color f-14">Edit Requests</h5>
              <small class="mute-color t-j"
                >User can edit the details of requests created by other
                users</small
              >
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="pt-0">
              <v-switch v-model="dense" class="ma-0 pl-5"></v-switch>
            </v-col>
          </v-row>
        </v-col>

        <v-col xl="4" lg="4" md="6" sm="6" cols="12" class="pt-0">
          <v-row class="border-r">
            <v-col cols="9" sm="9" md="9" lg="9" class="pt-0">
              <h5 class="c-color f-14">Notified For Awaiting Requests</h5>
              <small class="mute-color t-j"
                >User will receive notifications when a request goes to an
                Awaiting status</small
              >
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="pt-0 t-a-c p-33">
              <v-switch v-model="dense" class="d-inline"></v-switch>
            </v-col>
          </v-row>
        </v-col>
        <v-col xl="4" lg="4" md="6" sm="6" cols="12" class="pt-0">
          <v-row class="border-r">
            <v-col cols="9" sm="9" md="9" lg="9" class="pt-0">
              <h5 class="d-inline c-color f-14">Contractor Employee</h5>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="pt-0 p-33">
              <v-switch v-model="dense" class="d-inline"></v-switch>
            </v-col>
          </v-row>
        </v-col>
        <v-col xl="4" lg="4" md="6" sm="6" cols="12" class="pt-0">
          <v-row class="border-r">
            <v-col cols="9" sm="9" md="9" lg="9" class="pt-0">
              <h5 class="c-color d-inline f-14">Plant Employee</h5>
            </v-col>
            <v-col cols="3" md="3" sm="3" lg="3" class="pt-0 p-33">
              <v-switch v-model="dense" class="d-inline"></v-switch>
            </v-col>
          </v-row>
        </v-col>
        <v-col xl="4" lg="4" md="6" sm="6" cols="12" class="pt-0 pb-0">
          <v-row class="border-r">
            <v-col cols="9" sm="9" md="9" lg="9" class="pt-0 pb-0">
              <h5 class="c-color f-14">Route Awaiting</h5>
              <small class="mute-color"
                >User can change the status of an Awaiting request to In
                Progress, Complete, or Removed. This should be enabled for any
                foremen</small
              >
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="p-33">
              <v-switch v-model="dense" class="d-inline"></v-switch>
            </v-col>
          </v-row>
        </v-col>
        <v-col xl="4" lg="4" md="6" sm="6" cols="12" class="pt-0 pb-0">
          <v-row class="border-r">
            <v-col class="pt-0 pb-0">
              <h5 class="c-color f-14"></h5>
              <small class="mute-color"></small>
            </v-col>
            <v-col class="pt-0 pb-0">
              <h5 class="c-color f-14"></h5>
            </v-col>
          </v-row>
        </v-col>
        <v-col xl="4" lg="4" md="6" sm="6" cols="12" class="pt-0 pb-0">
          <v-row>
            <v-col class="pt-0 pb-0">
              <h5 class="c-color f-14"></h5>
              <small class="mute-color"></small>
            </v-col>
            <v-col class="pt-0 pb-0">
              <h5 class="c-color f-14"></h5>
            </v-col>
          </v-row>
        </v-col>
        <!-- </v-row> -->
        <!-- </v-row> -->
      </v-row>
    </div>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    dense: false,
    items: ["Foreman", "Plant User", "Site Admin"],
  }),
}
</script>
