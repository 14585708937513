var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-content',[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',[_c('v-flex',{attrs:{"xs14":"","sm6":"","md6":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.changePasswordSubmit.apply(null, arguments)}}},[_c('v-container',{staticClass:"elevation-12 mt-5"},[_c('v-row',{staticClass:"mt-5 mb-3"},[_c('v-col',{staticClass:"p-0"},[_c('h3',{staticClass:"f-30 primary--text text-center"},[_vm._v(" Change Password ")])])],1),_c('v-text-field',{attrs:{"label":"Current Password","name":"currentPassword","type":"password","rules":[
                () =>
                  _vm.currentPassword.length > 0 ||
                  'Current Password is required.',
              ]},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}}),_c('v-text-field',{attrs:{"label":"New Password","name":"newPassword","type":"password","rules":[
                () => _vm.newPassword.length > 0 || 'New Password is required.',
              ]},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('v-text-field',{attrs:{"label":"Confirm Password","name":"retypePassword","type":"password","rules":[
                () =>
                  _vm.retypePassword.length > 0 ||
                  'Confirm Password is required.',
              ]},model:{value:(_vm.retypePassword),callback:function ($$v) {_vm.retypePassword=$$v},expression:"retypePassword"}}),(
                _vm.newPassword != _vm.retypePassword &&
                _vm.newPassword != '' &&
                _vm.retypePassword != ''
              )?_c('div',{staticClass:"invalid-feedback",staticStyle:{"color":"red","font-size":"11px"}},[_vm._v(" Confirm Password doesn't match New Password. ")]):_vm._e(),_c('v-row',{staticClass:"mt-3 pb-3"},[_c('v-col',{staticClass:"p-0 t-a-c"},[_c('v-btn',{class:`w-200p ${
                    _vm.theme === 'stilt'
                      ? 'btn-stilt'
                      : 'btn-gradient white--text'
                  }`,attrs:{"type":"submit","rounded":"","outlined":"","disabled":!_vm.formIsValid,"loading":_vm.loading}},[_vm._v(" Submit ")])],1)],1),_c('v-spacer')],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }