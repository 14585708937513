
import Vue from "vue"
import { Util } from "@/helpers"
import firebase from "firebase/app"
import "firebase/auth"
import * as Sentry from "@sentry/browser"

export default Vue.extend({
  data() {
    return {
      theme: process.env.VUE_APP_THEME,
      currentPassword: "",
      newPassword: "",
      retypePassword: "",
      loading: false,
    }
  },
  computed: {
    formIsValid(): boolean {
      return (
        this.currentPassword != "" &&
        this.newPassword != "" &&
        this.retypePassword != "" &&
        this.newPassword == this.retypePassword
      )
    },
  },
  methods: {
    async changePasswordSubmit() {
      try {
        this.loading = true
        const currentUser = this.$store.state.firetableModule.currentUser
        await firebase
          .auth()
          .signInWithEmailAndPassword(currentUser.email, this.currentPassword)
          .catch((error) => {
            this.loading = false
            Util.logOnlyOnDev(error)
            this.$store.commit(
              "firetableModule/setError",
              "Current Password was incorrect."
            )
          })
        await currentUser.updatePassword(this.newPassword)
        this.$store.commit(
          "firetableModule/setSuccess",
          "Password Changed successful."
        )
        this.loading = false
      } catch (error) {
        Sentry.captureException(error)
        this.$store.commit("firetableModule/setError", error.message)
        this.loading = false
      }
    },
  },
})
