
// Libs
import { Util } from "@/helpers"
import moment from "moment"

// Models
import {
  TaskTypes,
  TaskStatus,
  CraftType,
  TaskType,
  SiteKey,
} from "@/models/models"

export default {
  data() {
    return {
      window: { width: 0, height: 0 },
      selectedTask: null,
      CraftType,
      TaskType,
      // records: [],
      loading: false,
      filteredTasks: [],
      filters: {
        taskTypes: TaskTypes.values().map((taskType) => taskType),
        taskStatuses: ["todays-schedule"],
      },
      taskStatuses: [
        {
          value: "today-schedule",
          name: "Today's Schedule",
          color: "rgb(89, 239, 172)",
        },
        {
          value: "awaiting-schedule",
          name: "Awaiting Schedule",
          color: "rgb(10, 169, 251)",
        },
        { value: "backlog", name: "Backlog", color: "green" },
        {
          value: "in-progress",
          name: "In Progress",
          color: "rgb(255, 140, 0)",
        },
      ],
      taskTypes: TaskTypes.values().map((taskType) => ({
        value: taskType,
        name: TaskTypes.getTaskTypeString(taskType),
      })),
      center: {},
    }
  },
  computed: {
    rootUserData(): any | null {
      return this.$store.state.firetableModule.rootUserData
    },
    siteKeyUserPermissionData(): any | null {
      return this.$store.state.firetableModule.siteKeyUserPermissionData
    },
    siteKeyUserLocations(): any | null {
      return this.$store.state.firetableModule.siteKeyUserLocations
    },
    siteKeyData(): SiteKey | null {
      return this.$store.state.firetableModule.siteKey
    },
    globalCraftType(): any | null {
      return this.$store.state.firetableModule.globalCraftType
    },
    tasks(): any[] | null {
      const { taskList, craftRecordsList, siteKeyCompanies } =
        this.$store.state.firetableModule
      if (!taskList || !craftRecordsList) return null

      const allTasks = []
      taskList.map((task) => {
        const craftRecordID = task.craftRecordID.split("/").pop()
        const parentRecord = craftRecordsList.find(
          (pr) => pr.id === craftRecordID
        )

        // Parent record is probably closed! Or mysteriously missing...
        if (!parentRecord) {
          return
        }

        const assignedCompany = siteKeyCompanies.find(
          (company) => company.id === task.assignedCompanyID
        )
        const taskAssignedStatus = this.assignTaskStatus(task)

        const taskAssignedColor =
          this.taskStatuses?.find(
            (status) => status.value === taskAssignedStatus
          )?.color ?? "red"
        allTasks.push({
          ...task,
          parentRecord,
          assignedCompany,
          taskAssignedStatus,
          taskAssignedColor: taskAssignedColor,
        })
      })
      return allTasks
    },
  },
  watch: {
    rootUserData(newValue: string | null) {
      if (!newValue) return
    },
    siteKeyUserPermissionData(newValue: string | null) {
      if (!newValue) return
    },
    siteKeyUserLocations(newValue: string | null) {
      if (!newValue) return
    },
    siteKeyData(newValue: string | null) {
      if (!newValue) return
      this.centerMap()
      this.updateFilterOptions()
      this.startListening()
    },
    globalCraftType(newValue: string | null) {
      if (!newValue) return
      this.updateFilterOptions()
      this.filterTasks()
    },
    filters: {
      handler: function (newValue: string | null) {
        if (!newValue) return
        this.filterTasks()
      },
      deep: true,
    },
    tasks(newValue: any[] | null) {
      if (!newValue) return
      this.updateFilterOptions()
      this.filterTasks()
    },
  },
  methods: {
    startListening() {
      this.$store.commit("firetableModule/unsubscribeTasklistListener")
      this.$store.commit("firetableModule/removeTaskList")
      this.$store.dispatch(
        "firetableModule/listenTaskList",
        this.$route.params.taskParam
      )
      this.$store.dispatch("firetableModule/listenCraftRecordsList", {
        craftType: null,
      })
      this.$store.dispatch("firetableModule/listenSiteKeyCompanies")
    },
    centerMap() {
      if (this.siteKeyData.latitude && this.siteKeyData.longitude) {
        this.center = {
          lat: this.siteKeyData.latitude,
          lng: this.siteKeyData.longitude,
        }
      }
    },
    removeListeners() {
      this.$store.commit("firetableModule/unsubscribeTasklistListener")
      this.$store.commit("firetableModule/removeTaskList")
      this.$store.commit("firetableModule/unsubscribeCraftRecordsListListener")
      this.$store.commit("firetableModule/removeCraftRecordsList")
      this.$store.commit("firetableModule/unsubscribeSiteKeyCompaniesListener")
    },
    updateFilterOptions() {
      this.taskTypes = this.siteKeyData.validTaskTypes.map((taskType) => ({
        value: taskType,
        name: TaskTypes.getTaskTypeString(taskType),
      }))

      this.filters = {
        taskTypes: this.siteKeyData.validTaskTypes.map((taskType) => taskType),
        taskStatuses: ["today-schedule"],
      }

      if (this.siteKeyData.validCraftTypes.includes(10)) {
        if (!this.taskStatuses.find((t) => t.value === "installed-scaffolds")) {
          this.taskStatuses.push({
            value: "installed-scaffolds",
            name: "Installed Scaffolds",
            color: "rgb(127, 95, 248)",
          })
        }

        if (!this.filters.taskStatuses.includes("installed-scaffolds")) {
          this.filters.taskStatuses.push("installed-scaffolds")
        }
      }
    },
    filterTasks() {
      const { taskTypes, taskStatuses } = this.filters
      let filteredTasks = []

      if (!this.tasks) {
        return filteredTasks
      }

      filteredTasks = this.tasks

      // Clear selected task (if any)
      this.selectedTask = null

      // Craft type
      if (this.globalCraftType !== "all") {
        filteredTasks = filteredTasks.filter(
          (task) => task.parentRecord.craftType === this.globalCraftType
        )
      }

      // Task type
      if (taskTypes.length > 0) {
        filteredTasks = filteredTasks.filter((task) =>
          taskTypes.includes(task.taskType)
        )
      } else {
        filteredTasks = []
      }

      // Task statuses
      if (taskStatuses.length > 0) {
        filteredTasks = filteredTasks.filter((task) =>
          taskStatuses.includes(task.taskAssignedStatus)
        )
      } else {
        filteredTasks = []
      }

      // Set map's center to first filtered record
      if (filteredTasks.length > 0) {
        const { latitude: lat, longitude: lng } = filteredTasks[0].parentRecord
        this.center = {
          lat,
          lng,
        }
      }
      this.filteredTasks = filteredTasks
      this.loading = false
    },
    assignTaskStatus(task) {
      const { taskType, taskStatus, timestampScheduled, nextOpportunity } = task
      const isBeforeToday =
        timestampScheduled &&
        moment(timestampScheduled.seconds * 1000).valueOf() <
          moment().startOf("day").valueOf()
      if (
        taskType === TaskTypes.INSTALLATION &&
        taskStatus === TaskStatus.COMPLETE &&
        isBeforeToday
      )
        return "installed-scaffolds"

      const isToday =
        timestampScheduled &&
        moment(timestampScheduled.seconds * 1000).valueOf() >=
          moment().startOf("day").valueOf() &&
        moment(timestampScheduled.seconds * 1000).valueOf() <
          moment().endOf("day").valueOf()
      if (
        [
          TaskStatus.AWAITING,
          TaskStatus.IN_PROGRESS,
          TaskStatus.ON_HOLD,
        ].includes(taskStatus) &&
        isToday
      )
        return "today-schedule"

      if (taskStatus === TaskStatus.AWAITING_SCHEDULE)
        return "awaiting-schedule"

      if (
        (isBeforeToday || nextOpportunity) &&
        taskStatus === TaskStatus.AWAITING
      )
        return "backlog"

      if ([TaskStatus.IN_PROGRESS, TaskStatus.ON_HOLD].includes(taskStatus))
        return "in-progress"

      // No matches
      return null
    },
    selectItem(task) {
      this.$router.push(
        `/craft-record/${task.craftRecordID.split("/").pop()}/${task.id}`
      )
    },
    formatDate: (data) => Util.formatDate(data),
    onResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
  },
  mounted() {
    this.loading = true
    this.startListening()
  },
  created() {
    //this function use for page size calculations
    window.addEventListener("resize", this.onResize)
    // Apply dimentions
    this.onResize()
  },
  beforeDestroy(): void {
    this.removeListeners()
  },
  destroyed() {
    //when page close means we remove map size calculations
    window.removeEventListener("resize", this.onResize)
  },
}
