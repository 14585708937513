
import Vue from "vue"
import { mapActions, mapGetters, mapState } from "vuex"
import moment from "moment"
import { SiteKeyUserPermission } from "@/models/models"
import groupBy from "lodash/groupBy"
export default Vue.extend({
  name: "StandingScaffolds",
  props: {
    departments: {
      type: Array,
      required: false,
    },
  },
  computed: {
    ...mapGetters("craftRecordsModule", {
      standingScaffolds: "getStandingScaffolds",
    }),
    ...mapState("firetableModule", ["rootUserData", "currentUser"]),
    defaultSiteKey(): string | undefined {
      return this.rootUserData?.defaultSiteKey
    },
    siteKeyUserPermissionData(): any | null {
      return this.$store.state.firetableModule.siteKeyUserPermissionData
    },
    totalScaffoldsStanding(): string {
      if (!this.standingScaffolds || this.standingScaffolds.length === 0) {
        return "--"
      }

      return this.filterByDepartment().length.toString()
    },
    averageDaysStanding(): string {
      if (!this.standingScaffolds || this.standingScaffolds.length === 0) {
        return "--"
      }

      const filteredScaffolds = this.filterByDepartment()

      let totalDays = 0
      for (let i = 0; i < filteredScaffolds.length; i++) {
        const timestampMarkedInstalled = moment(
          filteredScaffolds[i].craftDetails.timestampMarkedInstalled.seconds *
            1000
        )
        totalDays += moment().diff(timestampMarkedInstalled, "days")
      }
      return Number(totalDays / filteredScaffolds.length).toFixed()
    },
  },
  methods: {
    ...mapActions("craftRecordsModule", ["listenStandingScaffolds"]),

    loadData() {
      if (
        typeof this.defaultSiteKey === "string" &&
        this.siteKeyUserPermissionData
      ) {
        this.listenStandingScaffolds({
          siteKey: this.defaultSiteKey,
          siteKeyUserPermissions: this.siteKeyUserPermissionData,
        })
      }
    },
    filterByDepartment() {
      let filteredScaffolds = []
      if (this.$store.state.firetableModule.siteKeyLocations) {
        // get all siteKey locations data
        let locations = this.$store.state.firetableModule.siteKeyLocations
        locations = groupBy(locations, "department")

        if (
          !this.departments?.includes("all") &&
          this.departments?.length > 0
        ) {
          let locationIds = []
          for (const dep of this.departments) {
            const locIds = locations[dep].map((loc) => loc.id)
            locationIds = [...locationIds, ...locIds]
          }
          filteredScaffolds = this.standingScaffolds.filter((s) =>
            locationIds.includes(s.locationID)
          )
        } else {
          filteredScaffolds = this.standingScaffolds
        }
      }
      return filteredScaffolds
    },
  },
  watch: {
    defaultSiteKey() {
      this.loadData()
    },
    siteKeyUserPermissionData(newValue: SiteKeyUserPermission | null) {
      if (!newValue) return
      this.loadData()
    },
  },
})
