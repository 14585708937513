<template>
  <v-dialog
    v-model="show"
    content-class="img-preview"
    persistent
    @click:outside="close"
  >
    <v-card class="d-flex justify-center align-center w-auto">
      <img :src="image" alt="Enlarged Image" />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["show", "image"],
  data() {
    return {}
  },
  methods: {
    close() {
      this.$emit("close")
    },
  },
}
</script>
