import moment from "moment"

import {
  CraftTypes,
  TaskStatus,
  TaskTypes,
  SiteKey,
  SiteKeyUserPermission,
  CraftType,
  CraftRecordPersistenceTypes,
} from "@/models/models"
import firebase from "firebase/app"
import "firebase/firestore"
import DocumentSnapshot = firebase.firestore.DocumentSnapshot
import cloneDeep from "lodash/cloneDeep"
import { Util } from "@/helpers"
import { db } from "@/firebase-init"
import { awaitingEstimateEnabled } from "@/customizations"

export function getTaskStatusForApprovedTask(
  scheduleVal: string,
  date: firebase.firestore.Timestamp
) {
  const today = moment().format("YYYY-MM-DD")

  switch (scheduleVal) {
    case "":
      if (moment(date.toDate()).isSameOrBefore(today, "day")) {
        return TaskStatus.AWAITING
      } else {
        return TaskStatus.SCHEDULED
      }
    case "Urgent":
      return TaskStatus.AWAITING
    case "Awaiting Schedule":
      return TaskStatus.AWAITING_SCHEDULE
    case "Next Opportunity":
      return TaskStatus.AWAITING
    default:
      return TaskStatus.AWAITING
  }
}

export function getTaskStatus(
  task: Tasks,
  scheduleVal: string,
  newTaskCreation: boolean,
  siteKeyData: SiteKey,
  siteKeyUserPermissionData: SiteKeyUserPermission
): number {
  if (
    siteKeyData.validTaskStatusCodes.includes(TaskStatus.AWAITING_ESTIMATE) &&
    siteKeyData.validTaskStatusCodes.includes(TaskStatus.AWAITING_APPROVAL) &&
    awaitingEstimateEnabled(siteKeyData, task.craftType, task.taskType)
  ) {
    if (siteKeyUserPermissionData.permissions.isPlantPersonnel === false) {
      // Non-plant personnel can only create tasks in AWAITING_ESTIMATE status
      if (newTaskCreation === true) {
        return TaskStatus.AWAITING_ESTIMATE
      }
      // If not a new task, then they must be changing the schedule date.
      //    So, we need to check if the task has already been moved past
      //    approval status. If it hasn't, then status must remain the same
      if (
        [TaskStatus.AWAITING_APPROVAL, TaskStatus.AWAITING_ESTIMATE].includes(
          task.taskStatus
        )
      ) {
        return task.taskStatus
      } else {
        // Task must already be approved, we can use normal logic now:
        return getTaskStatusForApprovedTask(
          scheduleVal,
          task.timestampScheduled
        )
      }
    } else {
      if (task.urgent === false && newTaskCreation === true) {
        // For plant personnel... non-urgent tasks will go to AWAITING_ESTIMATE if
        //    it is a new task
        return TaskStatus.AWAITING_ESTIMATE
      } else if (task.urgent === true && newTaskCreation === true) {
        return TaskStatus.AWAITING
      } else if (
        [TaskStatus.AWAITING_APPROVAL, TaskStatus.AWAITING_ESTIMATE].includes(
          task.taskStatus
        )
      ) {
        return task.taskStatus
      } else {
        // Task must already be approved, we can use normal logic now:
        return getTaskStatusForApprovedTask(
          scheduleVal,
          task.timestampScheduled
        )
      }
    }
  } else {
    return getTaskStatusForApprovedTask(scheduleVal, task.timestampScheduled)
  }
}

// export function checkPermittingPrompt(task: Tasks, previousTaskStatus: number) {
//   // For Permitting craft type, Lockout && Walktrough will fire an actions modal
//   const permittingTaskTypes = [TaskTypes.LOCKOUT, TaskTypes.WALKTHROUGH]
//   if (
//     task.craftType === CraftTypes.PERMITTING &&
//     permittingTaskTypes.includes(task.taskType) &&
//     task.taskStatus === TaskStatus.COMPLETE && // Completed
//     previousTaskStatus !== task.taskStatus
//   ) {
//     return true
//   }
//   return false
// }

export function checkTsdRequired(
  task: Tasks,
  siteKeyData: SiteKey,
  previousTaskStatus: number
) {
  // Checks if any task-specific details exist
  // for this craft type, task type, and task status

  // If no status change, then no need to show prompt
  if (task.taskStatus === previousTaskStatus) {
    return false
  }

  const taskSpecificDetails = siteKeyData.customizations["taskSpecificDetails"]

  // Get craft record type and task type
  const taskTypeName = TaskTypes.getTaskTypeName(task.taskType)
  const craftTypeName = CraftType.getCraftTypeRecordString(task.craftType)
  if (
    craftTypeName in taskSpecificDetails &&
    taskTypeName in taskSpecificDetails[craftTypeName]
  ) {
    const _taskTypeMap = taskSpecificDetails[craftTypeName][taskTypeName]
    for (const field in _taskTypeMap)
      if (_taskTypeMap[field].onTaskStatus?.includes(task.taskStatus)) {
        return true
      }
  }
  return false
}

export async function checkCraftRecordPersistencePrompt(
  task: Tasks,
  previousTaskStatus: number
) {
  if (
    task.taskStatus === TaskStatus.COMPLETE &&
    task.taskStatus !== previousTaskStatus &&
    task.craftRecordPersistence === CraftRecordPersistenceTypes.PROMPT
  ) {
    const craftRecord = await Util.getCraftRecord(db, task.craftRecordID)
    if (craftRecord.numOpenTasks === 1) {
      return true
    }
  }
  return false
}

async function taskStatusChanged(
  previousTaskStatus: number,
  updatedTaskData: Tasks
) {
  const _task: Tasks = cloneDeep(updatedTaskData)

  if (
    _task.taskStatus === TaskStatus.IN_PROGRESS &&
    previousTaskStatus !== TaskStatus.ON_HOLD
  ) {
    _task.timestampTaskStarted = firebase.firestore.Timestamp.now()
  }

  if (_task.taskStatus === TaskStatus.COMPLETE)
    _task.timestampTaskCompleted = firebase.firestore.Timestamp.now()

  if (
    _task.taskStatus === TaskStatus.AWAITING &&
    previousTaskStatus !== TaskStatus.AWAITING
  ) {
    _task.timestampAwaitingStart = firebase.firestore.Timestamp.now()
  }

  return _task
}

export function shouldDisplayEstimateDialog(
  task: Tasks,
  siteKey: SiteKey,
  siteKeyUserPermissionsDoc: SiteKeyUserPermission
): boolean {
  if (!siteKey?.validTaskStatusCodes.includes(TaskStatus.AWAITING_ESTIMATE)) {
    return false
  }

  if (siteKeyUserPermissionsDoc.permissions.isPlantPersonnel === false) {
    return false
  }

  if (
    task.urgent &&
    task.craftType === CraftTypes.SCAFFOLDING &&
    (task.taskType === TaskTypes.INSTALLATION ||
      task.taskType === TaskTypes.MODIFICATION)
  ) {
    return true
  }
  return false
}

export async function updateTaskFromScheduleChange(
  task: Tasks,
  scheduleVal: string,
  newTaskCreation: boolean,
  siteKeyData: SiteKey,
  siteKeyUserPermissionData: SiteKeyUserPermission
): Promise<Tasks> {
  let _task: Tasks = cloneDeep(task)

  _task.taskStatus = getTaskStatus(
    _task,
    scheduleVal,
    newTaskCreation,
    siteKeyData,
    siteKeyUserPermissionData
  )

  if (_task.taskStatus !== task.taskStatus) {
    // Task status changed!
    _task = await taskStatusChanged(task.taskStatus, _task)
  }

  return _task
}

/**
 * Contains fields for the database Tasks objects.
 */

export class Tasks {
  id?: string
  refPath?: string
  assignedCompanyID: string
  craftRecordID: string
  customerID: string | undefined
  customerLocationID: string | undefined
  craftRecordPersistence: string
  craftType: number
  createdBy: string
  crewCount: number
  description: string | null
  durations: {}
  holdDurations: {}
  locationID: string
  latitude: number | undefined
  longitude: number | undefined
  nextOpportunity: boolean
  notifyCompanyOnCreation: boolean
  taskSpecificDetails: {} | null
  taskStatus: number
  taskType: number
  thumbnailURL: string | null
  timestampAwaitingStart: firebase.firestore.Timestamp | null
  timestampCreated: firebase.firestore.Timestamp
  timestampScheduled: firebase.firestore.Timestamp | null
  timestampTaskCompleted: firebase.firestore.Timestamp | null
  timestampTaskStarted: firebase.firestore.Timestamp | null
  timestampLastModified: firebase.firestore.Timestamp | null
  lastModifiedBy: string
  title: string
  urgent: boolean
  workOrder: string

  constructor(args: {
    id?: string
    refPath?: string
    // todo: add exists property.
    assignedCompanyID: string
    craftRecordID: string
    customerID: string | undefined
    customerLocationID: string | undefined
    // todo: update this to 3 permitted values.
    craftRecordPersistence: string
    craftType: number
    createdBy: string
    crewCount: number
    description: string | null
    durations: {}
    holdDurations: {}
    locationID: string
    latitude: number | undefined
    longitude: number | undefined
    nextOpportunity: boolean
    notifyCompanyOnCreation: boolean
    taskSpecificDetails: {} | null
    taskStatus: number
    taskType: number
    thumbnailURL: string | null
    timestampAwaitingStart: firebase.firestore.Timestamp | null
    timestampCreated: firebase.firestore.Timestamp
    timestampScheduled: firebase.firestore.Timestamp | null
    timestampTaskCompleted: firebase.firestore.Timestamp | null
    timestampTaskStarted: firebase.firestore.Timestamp | null
    timestampLastModified: firebase.firestore.Timestamp | null
    lastModifiedBy: string
    title: string
    urgent: boolean
    workOrder: string
  }) {
    this.id = args.id
    this.refPath = args.refPath
    this.assignedCompanyID = args.assignedCompanyID
    this.craftRecordID = args.craftRecordID
    this.customerID = args.customerID
    this.customerLocationID = args.customerLocationID
    this.craftRecordPersistence = args.craftRecordPersistence
    this.craftType = args.craftType
    this.createdBy = args.createdBy
    this.crewCount = args.crewCount
    this.description = args.description
    this.durations = args.durations
    this.holdDurations = args.holdDurations
    this.locationID = args.locationID
    this.latitude = args.latitude
    this.longitude = args.longitude
    this.nextOpportunity = args.nextOpportunity
    this.notifyCompanyOnCreation = args.notifyCompanyOnCreation
    this.refPath = args.refPath
    this.taskSpecificDetails = args.taskSpecificDetails
    this.taskStatus = args.taskStatus
    this.taskType = args.taskType
    this.thumbnailURL = args.thumbnailURL
    this.timestampAwaitingStart = args.timestampAwaitingStart
    this.timestampCreated = args.timestampCreated
    this.timestampScheduled = args.timestampScheduled
    this.timestampTaskCompleted = args.timestampTaskCompleted
    this.timestampTaskStarted = args.timestampTaskStarted
    this.timestampLastModified = args.timestampLastModified
    this.lastModifiedBy = args.lastModifiedBy
    this.title = args.title
    this.urgent = args.urgent
    this.workOrder = args.workOrder
  }
  /**
   * Create a Tasks object from a Firestore DocumentSnapshot.
   * @param snapshot
   */
  static fromFirestore(snapshot: DocumentSnapshot): Tasks {
    return new Tasks({
      id: snapshot.id,
      refPath: snapshot.ref.path,
      assignedCompanyID: snapshot.get("assignedCompanyID"),
      craftRecordID: snapshot.get("craftRecordID"),
      customerID: snapshot.get("customerID"),
      customerLocationID: snapshot.get("customerLocationID"),
      craftRecordPersistence: snapshot.get("craftRecordPersistence"),
      craftType: snapshot.get("craftType"),
      createdBy: snapshot.get("createdBy"),
      crewCount: snapshot.get("crewCount"),
      description: snapshot.get("description"),
      durations: snapshot.get("durations"),
      holdDurations: snapshot.get("holdDurations"),
      locationID: snapshot.get("locationID"),
      latitude: snapshot.get("latitude"),
      longitude: snapshot.get("longitude"),
      nextOpportunity: snapshot.get("nextOpportunity"),
      notifyCompanyOnCreation: snapshot.get("notifyCompanyOnCreation"),
      taskSpecificDetails: snapshot.get("taskSpecificDetails"),
      taskStatus: snapshot.get("taskStatus"),
      taskType: snapshot.get("taskType"),
      thumbnailURL: snapshot.get("thumbnailURL"),
      timestampAwaitingStart: snapshot.get("timestampAwaitingStart"),
      timestampCreated: snapshot.get("timestampCreated"),
      timestampScheduled: snapshot.get("timestampScheduled"),
      timestampTaskCompleted: snapshot.get("timestampTaskCompleted"),
      timestampTaskStarted: snapshot.get("timestampTaskStarted"),
      timestampLastModified: snapshot.get("timestampLastModified"),
      lastModifiedBy: snapshot.get("lastModifiedBy"),
      title: snapshot.get("title"),
      urgent: snapshot.get("urgent"),
      workOrder: snapshot.get("workOrder"),
    })
  }

  /**
   * Export an object (Map/Dict in other terminology) to use when writing to
   * Firestore.
   */
  toMap(): {} {
    const data = {
      assignedCompanyID: this.assignedCompanyID,
      craftRecordID: this.craftRecordID,
      craftRecordPersistence: this.craftRecordPersistence,
      craftType: this.craftType,
      createdBy: this.createdBy,
      crewCount: this.crewCount,
      description: this.description,
      durations: this.durations,
      holdDurations: this.holdDurations,
      locationID: this.locationID,
      nextOpportunity: this.nextOpportunity,
      notifyCompanyOnCreation: this.notifyCompanyOnCreation,
      taskSpecificDetails: this.taskSpecificDetails,
      taskStatus: this.taskStatus,
      taskType: this.taskType,
      thumbnailURL: this.thumbnailURL,
      timestampAwaitingStart: this.timestampAwaitingStart,
      timestampCreated: this.timestampCreated,
      timestampScheduled: this.timestampScheduled,
      timestampTaskCompleted: this.timestampTaskCompleted,
      timestampTaskStarted: this.timestampTaskStarted,
      timestampLastModified: this.timestampLastModified,
      lastModifiedBy: this.lastModifiedBy,
      title: this.title,
      urgent: this.urgent,
      workOrder: this.workOrder,
    }
    if (this.customerID) {
      data["customerID"] = this.customerID
    }
    if (this.customerLocationID) {
      data["customerLocationID"] = this.customerLocationID
    }
    if (this.latitude) {
      data["latitude"] = this.latitude
    }
    if (this.longitude) {
      data["longitude"] = this.longitude
    }
    return data
  }
}
