
// Libs
import { Drawer } from "ant-design-vue"
import firebase from "firebase/app"
import { Util } from "@/helpers"

// Models
import { CraftTypes, InventoryObjectStatus } from "@/models/models"

export default {
  props: ["visible"],
  data() {
    return {
      formValid: false,
      title: "",
      quantityAvailable: 0,
      quantityAwaitingPickup: 0,
      quantityInUse: 0,
      quantityReserved: 0,
      lowQuantityThreshold: 0,
    }
  },
  components: {
    "a-drawer": Drawer,
  },
  methods: {
    closeAndReset() {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.title = ""
      this.quantityAvailable = 0
      this.quantityAwaitingPickup = 0
      this.quantityInUse = 0
      this.quantityReserved = 0
      this.lowQuantityThreshold = 0
      this.$emit("close")
    },
    async addObject() {
      try {
        if (!this.title) {
          this.$refs.form.validate()
          return
        }
        const {
          rootUserData: { id = null, defaultSiteKey = {} } = {},
          siteKeyUserPermissionData: {
            companyID = null,
            permissions: { isPlantPersonnel = null } = {},
          } = {},
        } = this.$store.state.firetableModule
        const data = {
          title: this.title,
          quantityAvailable: parseFloat(this.quantityAvailable),
          quantityAwaitingPickup: parseFloat(this.quantityAwaitingPickup),
          quantityInUse: parseFloat(this.quantityInUse),
          quantityReserved: parseFloat(this.quantityReserved),
          lowQuantityThreshold: parseFloat(this.lowQuantityThreshold),
          status: InventoryObjectStatus.ACTIVE,
          lastModifiedBy: `${id}`,
          timestampLastModified: firebase.firestore.Timestamp.now(),
          authorizedCompanies: isPlantPersonnel ? [] : [companyID],
          craftTypes: [CraftTypes.SCAFFOLDING],
        }
        await Util.addInventoryObject(defaultSiteKey, data)
        this.closeAndReset()
      } catch (error) {
        // console.log(error)
        Util.errorMessage(error.message)
      }
    },
  },
}
