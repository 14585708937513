/* eslint-disable no-async-promise-executor */
//Libs
import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/storage"
import { db } from "./firebase-init"
import moment from "moment"
import { uuid } from "vue-uuid"
import groupBy from "lodash/groupBy"
import sumBy from "lodash/sumBy"
import flatten from "lodash/flatten"
import * as Sentry from "@sentry/browser"
import { Vue as SentryVueIntegration } from "@sentry/integrations"
import Vue from "vue"

// Models
import {
  Asset,
  CraftRecord,
  CraftRecordPersistenceTypes,
  CraftType,
  CraftTypes,
  DownloadCode,
  KPIDocument,
  KPIMetric,
  Location,
  SiteKey,
  SiteKeyCompany,
  SiteKeyUserLocations,
  SiteKeyUserPermission,
  SiteKeyUsers,
  TaskSubscriber,
  TaskTypes,
  InventoryObject,
  InventoryTransactionTypes,
} from "@/models/models"

import { Tasks } from "./models/task"

// Misc
import { store } from "./store"
import { string } from "./string"

export class Util {
  /**
   * get siteKeyUsers details data function.
   * @param db
   * @param defaultSiteKey
   * @param uid
   */

  static async getSiteKeyUserDetail(
    db: firebase.firestore.Firestore,
    defaultSiteKey: string,
    uid: string
  ): Promise<SiteKeyUsers> {
    let siteKeyUser: SiteKeyUsers
    try {
      const query = await db
        .collection(`siteKeys/${defaultSiteKey}/siteKeyUsers`)
        .doc(uid)
        .get()
      siteKeyUser = SiteKeyUsers.fromFirestore(query)
    } catch (error) {
      return null
    }
    return siteKeyUser
  }

  /**
   * Subscribes user to task notifications
   * @param db Firebase database
   * @param siteKey Default user site key
   * @param subscriber Subscriber data
   * @param taskId Task ID
   */
  static async subscribeToTask(
    db: firebase.firestore.Firestore,
    siteKey: string,
    subscriber,
    taskId: string
  ) {
    try {
      const resp = await db
        .collection(`siteKeys/${siteKey}/tasks`)
        .doc(taskId)
        .collection("subscribers")
        .doc(subscriber.key)
        .set(subscriber)
      return resp
    } catch (error) {
      Util.logOnlyOnDev(error)
      throw error
    }
  }

  /**
   * Unsubscribes user to task notifications
   * @param db Firebase database
   * @param siteKey Default user site key
   * @param authId
   * @param taskId Task ID
   */
  static async unsubscribeToTask(
    db: firebase.firestore.Firestore,
    siteKey: string,
    authId: string,
    taskId: string
  ) {
    try {
      const resp = await db
        .collection(`siteKeys/${siteKey}/tasks`)
        .doc(taskId)
        .collection("subscribers")
        .doc(authId)
        .delete()
      return resp
    } catch (error) {
      Util.logOnlyOnDev(error)
      throw error
    }
  }

  /**
   * Get task notification subscribers
   * @param db Firebase database
   * @param siteKey Default user site key
   * @param taskId Task ID
   */
  static async getTaskSubscribers(
    db: firebase.firestore.Firestore,
    siteKey: string,
    taskId
  ) {
    try {
      const query = await db
        .collection(`/siteKeys/${siteKey}/tasks/${taskId}/subscribers`)
        .get()
      // return a list of site key users.
      return query.docs.map((doc) => TaskSubscriber.fromFirestore(doc))
    } catch (error) {
      Util.logOnlyOnDev(error)
      throw error
    }
  }

  /**
   * Gets an available mobile download code
   * @param db Firebase database
   */
  static async getDownloadCode(db: firebase.firestore.Firestore) {
    try {
      const collections = [
        { mode: "stilt", col: "downloadCodes" },
        { mode: "aimpoint", col: "downloadCodesAIMPOINT" },
      ]
      const col = collections.find(
        (col) => col.mode === process.env.VUE_APP_THEME
      ).col
      const query = await db
        .collection(col)
        .where("redeemed", "==", false)
        .limit(1)
        .get()
      const docs = query.docs.map((doc) => DownloadCode.fromFirestore(doc))
      return docs[0]
    } catch (error) {
      Util.logOnlyOnDev(error)
      throw error
    }
  }

  /**
   * Redeems download code (mobile apps)
   * @param db Firebase database
   * @param codeRefPath Doc ref path
   * @param authId Authenticated user id
   */
  static async redeemDownloadCode(
    db: firebase.firestore.Firestore,
    codeRefPath: string,
    authId: string
  ) {
    try {
      const resp = await db
        .doc(codeRefPath)
        .update({ redeemed: true, redeemedBy: authId })
      return resp
    } catch (error) {
      Util.logOnlyOnDev(error)
      Util.errorMessage(error.message)
      return null
    }
  }

  /**
   * Formats a date using Moment JS.
   * @param date
   * @param format
   */
  static formatDate({ date = null, inFormat = null, outFormat = null }) {
    if (!date) return moment()
    if (!outFormat && inFormat) return moment(date, inFormat)
    if (!outFormat && !inFormat) return moment(date)
    if (outFormat && !inFormat) return moment(date).format(outFormat)
    return moment(date, inFormat).format(outFormat)
  }

  /**
   * get siteKeyLocations details data function.
   * @param db
   * @param defaultSiteKey
   * @param locationID
   */
  static async getLocationDetail(
    db: firebase.firestore.Firestore,
    defaultSiteKey: string,
    locationID: string
  ) {
    let locationDetail: Location
    try {
      const query = await db
        .collection(`siteKeys/${defaultSiteKey}/locations`)
        .doc(locationID)
        .get()
      locationDetail = Location.fromFirestore(query)
    } catch (error) {
      return null
    }
    return locationDetail
  }

  /**
   * get siteKeyCompanies details data function.
   * @param db
   * @param defaultSiteKey
   * @param companyID
   */
  static async getSiteKeyCompanyDetail(
    db: firebase.firestore.Firestore,
    defaultSiteKey: string,
    companyID: string
  ) {
    let companyDetail: SiteKeyCompany
    try {
      const query = await db
        .collection(`siteKeys/${defaultSiteKey}/siteKeyCompanies`)
        .doc(companyID)
        .get()
      companyDetail = SiteKeyCompany.fromFirestore(query)
    } catch (error) {
      return null
    }
    return companyDetail
  }

  /**
   * get assets details data function.
   * @param db
   * @param defaultSiteKey
   * @param assetID
   */
  static async getAssetDetail(
    db: firebase.firestore.Firestore,
    defaultSiteKey: string,
    assetID: string
  ) {
    let assetDetail: Asset
    try {
      const query = await db
        .collection(`siteKeys/${defaultSiteKey}/assets`)
        .doc(assetID)
        .get()
      assetDetail = Asset.fromFirestore(query)
    } catch (error) {
      return null
    }
    return assetDetail
  }

  /**
   * Gets tasks details data function.
   * @param db
   * @param defaultSiteKey
   * @param taskID
   */
  static async getTask(
    db: firebase.firestore.Firestore,
    defaultSiteKey: string,
    taskID: string
  ) {
    let task: Tasks
    try {
      const query = await db
        .collection(`siteKeys/${defaultSiteKey}/tasks`)
        .doc(taskID)
        .get()
      task = Tasks.fromFirestore(query)
    } catch (error) {
      return null
    }
    return task
  }

  // /**
  //  * Builds task dynamic values.
  //  * @param customizations Firebase customization map
  //  * @param task Task object
  //  */
  // static buildTaskDynamicValues(customizations: any, task: Tasks) {
  //   // Get task specific details
  //   const dynValues = {}
  //   const { taskSpecificDetails: detailsTemplate } = customizations
  //   const { craftType, taskType, taskSpecificDetails } = task
  //   // Check if this craftType - taskType combination has specific details
  //   const craftDetailName = CraftType.getCraftTypeRecordString(craftType)
  //   const taskDetailName = TaskTypes.getTaskTypeName(taskType)
  //   const taskDetailsAvailable = has(
  //     detailsTemplate,
  //     `${craftDetailName}.${taskDetailName}`
  //   )
  //   if (!taskDetailsAvailable) return { dynValues }
  //
  //   // Get detail template in customizations
  //   const dynTaskDetails = detailsTemplate[craftDetailName][taskDetailName]
  //
  //   // Set default values
  //   for (const field in dynTaskDetails) {
  //     dynValues[field] = taskSpecificDetails[field]
  //       ? taskSpecificDetails[field]
  //       : dynTaskDetails[field].defaultValue
  //   }
  //   return { dynValues, dynTaskDetails }
  // }

  static _parseDynamicDetails(
    siteKeyData: SiteKey,
    craftRecord: CraftRecord,
    task: Tasks
  ): any[] {
    const _configs = []

    // If craftRecord is null, then parse taskSpecificDetails
    // ...otherwise parse craftDetails
    let _type
    if (craftRecord == null) {
      _type = "taskSpecificDetails"
    } else {
      _type = "craftDetails"
    }

    if (_type in siteKeyData.customizations) {
      const _masterMap = { ...siteKeyData.customizations[_type] }
      const craftTypePathString = CraftType.getCraftTypeRecordString(
        craftRecord?.craftType ?? task.craftType
      )

      // Ensure craftType path exists
      if (craftTypePathString in _masterMap) {
        const _craftTypeMap = { ..._masterMap[craftTypePathString] }
        let _finalMap = {}
        if (_type == "taskSpecificDetails") {
          const taskTypePathString = TaskTypes.getTaskTypeName(task.taskType)
          // Ensure taskType path exists
          if (!(taskTypePathString in _craftTypeMap)) {
            _craftTypeMap[taskTypePathString] = {}
          }
          _finalMap = { ..._craftTypeMap[taskTypePathString] }
        } else {
          _finalMap = _craftTypeMap
        }

        for (const i in _finalMap) {
          _finalMap[i]["key"] = i
          _configs.push(_finalMap[i])
        }
      }
    }

    // Sort by type and by title
    _configs.sort((a, b) => {
      const compareType = a.type.localeCompare(b.type)
      const compareTitle = a.title.localeCompare(b.title)
      return compareType || compareTitle
    })

    return _configs
  }

  static getEditableDynamicDetailConfigs(
    siteKeyData: SiteKey,
    craftRecord: CraftRecord,
    task: Tasks,
    nextTaskStatus: number
  ): any[] {
    const _editableConfigs = []

    const _allConfigs = Util._parseDynamicDetails(
      siteKeyData,
      craftRecord,
      task
    )

    // Add editable configs
    // If nextTaskStatus is supplied, only add if applicable
    for (const i in _allConfigs) {
      if (_allConfigs[i].editable === true) {
        if (nextTaskStatus != null) {
          if (_allConfigs[i].onTaskStatus?.contains(nextTaskStatus) === true) {
            _editableConfigs.push(_allConfigs[i])
          }
        } else {
          _editableConfigs.push(_allConfigs[i])
        }
      }
    }

    return _editableConfigs
  }

  static getReadableDynamicDetailConfigs(
    siteKeyData: SiteKey,
    craftRecord: CraftRecord,
    task: Tasks
  ): any[] {
    return Util._parseDynamicDetails(siteKeyData, craftRecord, task)
  }

  /**
   * Gets craft record
   */
  static async getCraftRecord(
    db: firebase.firestore.Firestore,
    craftPath: string
  ): Promise<CraftRecord> | null {
    let craftRecord: CraftRecord
    try {
      const query = await db.doc(craftPath).get()
      craftRecord = CraftRecord.fromFirestore(query)
    } catch (error) {
      Util.errorMessage(error.message)
      return null
    }
    return craftRecord
  }

  /**
   * Gets user current location
   */
  static getBrowserLocation() {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        // Geolocation available
        window.navigator.geolocation.getCurrentPosition(
          (data) => resolve(data),
          () => resolve(null)
        )
        return
      }
      resolve(null)
    })
  }

  /**
   * get all siteKeyUsers data function.
   * @param db
   * @param defaultSiteKey
   */
  static async getSiteKeyUsers(
    db: firebase.firestore.Firestore,
    defaultSiteKey: string
  ): Promise<SiteKeyUsers[]> {
    // Get all the site key user documents.
    const query = await db
      .collection(`siteKeys/${defaultSiteKey}/siteKeyUsers`)
      .get()
    // return a list of site key users.
    return query.docs.map((doc) => SiteKeyUsers.fromFirestore(doc))
  }

  /**
   * get all siteKeyLocations data function.
   * @param db
   * @param defaultSiteKey
   */
  static async getSiteKeyLocations(
    db: firebase.firestore.Firestore,
    defaultSiteKey: string
  ) {
    const { siteKeyUserLocations } = store.state.firetableModule
    if (!siteKeyUserLocations) return []
    // Get all the site key location documents.
    const query = await db
      .collection(`siteKeys/${defaultSiteKey}/locations`)
      .get()
    // return a list of id strings.
    return query.docs
      .map((doc) => Location.fromFirestore(doc))
      .filter((loc) => siteKeyUserLocations.includes(loc.id))
  }

  /**
   * Get siteKey data.
   * @param db
   * @param defaultSiteKey
   */
  static async getSiteKey(
    db: firebase.firestore.Firestore,
    defaultSiteKey: string
  ) {
    if (typeof defaultSiteKey === "string") {
      // Get all the site key location documents.
      const query = await db.doc(`siteKeys/${defaultSiteKey}`).get()
      return SiteKey.fromFirestore(query)
    } else {
      return null
    }
  }

  /**
   * get all siteKeyCompanies data function.
   * @param db
   * @param defaultSiteKey
   * @param userPermissions
   */

  static async getSiteKeyCompanies({
    db,
    defaultSiteKey,
    userPermissions,
  }: {
    db: firebase.firestore.Firestore
    defaultSiteKey: string
    userPermissions: SiteKeyUserPermission
  }) {
    if (userPermissions === null) {
      return null
    }

    let query
    let docs = []
    const path = `siteKeys/${defaultSiteKey}/siteKeyCompanies`
    const { permissions, companyID } = userPermissions
    if (permissions.isPlantPersonnel) {
      // Get all the site key company documents.
      query = await db.collection(path).get()
      docs = query.docs.map((doc) => SiteKeyCompany.fromFirestore(doc))
    } else {
      // Get only the company assigned to this user
      query = await db.collection(path).doc(companyID).get()
      docs = [SiteKeyCompany.fromFirestore(query)]
    }
    return docs
  }

  /**
   * get all siteKeyassets data function.
   * @param db
   * @param defaultSiteKey
   */
  static async getSiteKeyAssets(
    db: firebase.firestore.Firestore,
    defaultSiteKey: string
  ) {
    // Get all the site key location documents.
    const query = await db.collection(`siteKeys/${defaultSiteKey}/assets`).get()
    // return a list of assets
    return query.docs.map((doc) => Asset.fromFirestore(doc))
  }

  /**
   * Craft Record Persistence is returned based on Craft/Task
   * @param craftType
   * @param taskType
   */
  static getRecordPersistence(craftType: number, taskType: number): string {
    if (
      taskType === TaskTypes.REMOVAL ||
      (craftType === CraftTypes.PERMITTING &&
        taskType === TaskTypes.PERFORMING_WORK)
    )
      return CraftRecordPersistenceTypes.CLOSE

    if (
      craftType === CraftTypes.WATERBLASTING ||
      craftType === CraftTypes.SANDBLASTING ||
      craftType === CraftTypes.INSULATION ||
      craftType === CraftTypes.STEAM_TRACING ||
      craftType === CraftTypes.FIREPROOFING ||
      craftType === CraftTypes.PAINTING ||
      craftType === CraftTypes.HOUSEKEEPING ||
      craftType === CraftTypes.CLEANING ||
      craftType === CraftTypes.VACUUM_TRUCK ||
      craftType === CraftTypes.CARPENTRY ||
      craftType === CraftTypes.GENERAL_LABOR
    )
      return CraftRecordPersistenceTypes.PROMPT

    return CraftRecordPersistenceTypes.KEEP
  }

  /**
   * if add or update to firestore means we want to filter undefined to null convertion function.
   * @param data
   */
  static firestoreUndefinedDataFilter(data: { [key: string]: any }) {
    Object.keys(data).map((key) => {
      if (typeof data[key] !== "number" && typeof data[key] !== "boolean")
        data[key] = data[key] ? data[key] : null
    })
    return data
  }

  /**
   * put to craftRecord into firestore function.
   * @param db
   * @param defaultSiteKey
   * @param craftPath
   * @param craftRecord
   * @param images
   */
  static async saveCraftRecord(
    db: firebase.firestore.Firestore,
    defaultSiteKey: string,
    craftPath: string,
    craftRecord: { [key: string]: any },
    images: {}[]
  ) {
    try {
      craftRecord = this.firestoreUndefinedDataFilter(craftRecord)
      const newCraftRecord = await db
        .collection(`/${craftPath}/`)
        .add(craftRecord)
      const craftId = `${craftPath}/${newCraftRecord.id}`
      const photosPath = `${craftId}/photos/`
      const thumbnailURL = null
      await Promise.all(
        images.map(async (image, index) => {
          const photoURLMap = await Util.savePhotoToStorage(
            defaultSiteKey,
            image
          )

          // add photos in to craftrecord table collection
          await db.collection(photosPath).add({
            ...photoURLMap,
            timestampCreated: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: `${craftRecord.lastModifiedBy}`,
          })

          if (images.length - 1 === index)
            await db.doc(`${craftId}`).update({
              thumbnailURL: photoURLMap.photoURL_thumb,
              timestampLastModified:
                firebase.firestore.FieldValue.serverTimestamp(),
              lastModifiedBy: `${craftRecord.lastModifiedBy}`,
            })
        })
      )
      return [craftId, thumbnailURL]
    } catch (error) {
      return false
    }
  }

  /**
   * put to task into firestore function.
   * @param db
   * @param taskPath
   * @param taskRecord
   */
  static async saveTask(
    db: firebase.firestore.Firestore,
    taskPath: string,
    taskRecord: { [key: string]: any }
  ) {
    try {
      const resp = await db
        .collection(`/${taskPath}/`)
        .add(this.firestoreUndefinedDataFilter(taskRecord))
      return resp
    } catch (error) {
      Util.errorMessage(error.message)
      return false
    }
  }

  /**
   * Gets local storage data by key
   * @param key Key to retrieve
   */
  static getStorage(key: string): string | null {
    return window.localStorage.getItem(key)
  }

  /**
   * remvoes local storage data by key
   * @param key Key to retrieve
   */
  static removeStorage(key: string): void {
    window.localStorage.removeItem(key)
  }

  /**
   * Gets local storage data by key
   * @param key Key to save
   * @param value Value to save
   */
  static setStorage(key: string, value: string): void {
    window.localStorage.setItem(key, value)
  }

  /**
   * put to task into firestore function.
   */
  static saveAsset(
    db: firebase.firestore.Firestore,
    assetPath: string,
    assetRecord: { [key: string]: any },
    type: string
  ) {
    if (type == "add") {
      return db
        .collection(`/${assetPath}/`)
        .add(this.firestoreUndefinedDataFilter(assetRecord))
        .then(function () {
          return true
        })
        .catch(function (error) {
          Sentry.captureException(error)
          return false
        })
    } else if (type === "update") {
      return db
        .doc(`/${assetPath}/`)
        .update(this.firestoreUndefinedDataFilter(assetRecord))
        .then(function () {
          return true
        })
        .catch(function (error) {
          Util.errorMessage(error.message)
          return false
        })
    }
  }

  /**
   * put to task into firestore function.
   */
  static saveLocation(
    db: firebase.firestore.Firestore,
    locationPath: string,
    locationRecord: { [key: string]: any },
    type: string
  ) {
    if (type == "add") {
      return db
        .collection(`/${locationPath}/`)
        .add(this.firestoreUndefinedDataFilter(locationRecord))
        .then(function () {
          return true
        })
        .catch(function (error) {
          Sentry.captureException(error)
          return false
        })
    } else if (type === "update") {
      return db
        .doc(`/${locationPath}/`)
        .update(this.firestoreUndefinedDataFilter(locationRecord))
        .then(function () {
          return true
        })
        .catch(function (error) {
          Util.errorMessage(error.message)
          return false
        })
    }
  }

  /**
   * put to task into firestore function.
   * @param db
   * @param companyPath
   * @param companyRecord
   * @param type
   */
  static saveCompany(
    db: firebase.firestore.Firestore,
    companyPath: string,
    companyRecord: { [key: string]: any },
    type: string
  ) {
    if (type == "add") {
      return db
        .collection(`/${companyPath}/`)
        .add(this.firestoreUndefinedDataFilter(companyRecord))
        .then(function () {
          return true
        })
        .catch(function (error) {
          Sentry.captureException(error)
          return false
        })
    } else if (type === "update") {
      return db
        .doc(`/${companyPath}/`)
        .update(this.firestoreUndefinedDataFilter(companyRecord))
        .then(function () {
          return true
        })
        .catch(function (error) {
          Util.errorMessage(error.message)
          return false
        })
    }
  }

  /**
   * put attachment into firebase storage bucket function.
   * @param defaultSiteKey
   * @param file
   */
  static async saveAttachmentToStorage(
    defaultSiteKey: string,
    file: any
  ): Promise<string | null> {
    try {
      const uuidString = uuid.v1()
      const storageRef = firebase
        .storage()
        .ref()
        .child("siteKeys")
        .child(defaultSiteKey)
        .child("attachments")

      const ref = storageRef.child(uuidString + "_" + file.name)
      const uploadResult = await ref.put(file)
      const downloadURL = await uploadResult.ref.getDownloadURL()
      return downloadURL
    } catch (error) {
      Util.errorMessage("Error uploading file")
      return null
    }
  }

  /**
   * put images into firebase storage bucket function.
   * @param defaultSiteKey
   * @param image
   */
  static async savePhotoToStorage(
    defaultSiteKey: string,
    image: { [key: string]: any }
  ) {
    try {
      const imageDataFull = image.fullUrl
      const imageDataReduced = image.reducedUrl
      const imageDataThumb = image.thumbUrl

      const uuidString = uuid.v1()

      const storageRef = firebase
        .storage()
        .ref()
        .child("siteKeys")
        .child(defaultSiteKey)

      const refFull = storageRef.child(uuidString + ".jpg")
      const refReduced = storageRef.child(uuidString + "_reduced.jpg")
      const refThumb = storageRef.child(uuidString + "_thumb.jpg")

      const uploadTaskFull = refFull.putString(imageDataFull, "data_url")
      const uploadTaskReduced = refReduced.putString(
        imageDataReduced,
        "data_url"
      )
      const uploadTaskThumb = refThumb.putString(imageDataThumb, "data_url")

      const downloadURLs = await Promise.all([
        uploadTaskFull,
        uploadTaskReduced,
        uploadTaskThumb,
      ])

      const urls = await Promise.all([
        downloadURLs[0].ref.getDownloadURL(),
        downloadURLs[1].ref.getDownloadURL(),
        downloadURLs[2].ref.getDownloadURL(),
      ])

      const photoURLMap = {
        photoURL: urls[0],
        // eslint-disable-next-line @typescript-eslint/camelcase
        photoURL_reduced: urls[1],
        // eslint-disable-next-line @typescript-eslint/camelcase
        photoURL_thumb: urls[2],
      }
      return photoURLMap
    } catch (error) {
      return {
        photoURL: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        photoURL_reduced: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        photoURL_thumb: "",
      }
    }
  }

  static deletePhotoFromStorage(imageURL: string) {
    try {
      firebase.storage().refFromURL(imageURL).delete()
      return true
    } catch (error) {
      return false
    }
  }

  static removePhotos(
    db: firebase.firestore.Firestore,
    craftID: string,
    imageID: string
  ) {
    try {
      const docPath = `${craftID + "/photos/" + imageID}`
      db.doc(docPath).delete()
      return true
    } catch (error) {
      return false
    }
  }

  static errorMessage(msg: string) {
    if (msg === "Missing or insufficient permissions.")
      store.commit("firetableModule/setError", string.permissionError)
    else {
      store.commit("firetableModule/setError", string.generalError)
      if (process.env.NODE_ENV === "development") {
        Util.logOnlyOnDev(msg)
      }
      Sentry.captureException(msg)
    }
  }

  /**
   * Wrapper to only use use console.log statements on development.
   */
  static logOnlyOnDev(msg: string | object) {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.log(msg)
    }
  }

  /**
   * Wrapper to only use use console.dir statements on development.
   */
  static consoleDirOnlyOnDev(msg: string | object) {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.dir(msg)
    }
  }

  /**
   * Wrapper to only use use console.error statements on development.
   */
  static consoleErrorOnlyOnDev(msg: string | object) {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.error(msg)
    }
  }

  // reduce the image quality using canvas
  // get the full image url function
  static getFullImageUrl(img: HTMLImageElement) {
    const elem = document.createElement("canvas")
    elem.width = img.width
    elem.height = img.height
    const ctx: any = elem.getContext("2d")
    // img.width and img.height will contain the original dimensions
    ctx.drawImage(img, 0, 0, img.width, img.height)
    const data = ctx.canvas.toDataURL("image/jpeg", 1)
    elem.remove()
    return data
  }

  // reduce the image quality using canvas
  // get the reduced image url function
  static getReducedImageUrl(img: HTMLImageElement) {
    const elem = document.createElement("canvas")
    elem.width = img.width
    elem.height = img.height
    const ctx: any = elem.getContext("2d")
    // img.width and img.height will contain the original dimensions
    ctx.drawImage(img, 0, 0, img.width, img.height)
    const data = ctx.canvas.toDataURL("image/jpeg", 0.8)
    elem.remove()
    return data
  }

  // reduce the image quality using canvapage: numbers
  // get the thumb image url function
  static getThumbImageUrl(img: HTMLImageElement) {
    const elem = document.createElement("canvas")
    elem.width = 300
    elem.height = 300
    const ctx: any = elem.getContext("2d")
    // img.width and img.height will contain the original dimensions
    ctx.drawImage(img, 0, 0, 300, 300)
    const data = ctx.canvas.toDataURL("image/jpeg", 1)
    elem.remove()
    return data
  }

  /**
   * change the rootuser notification setting status.
   * @param db firestore db
   * @param userPath rootuser path
   * @param notificationStatus change status value
   */
  static async changeNotificationStatus(
    db: firebase.firestore.Firestore,
    userPath: string,
    notificationStatus: boolean
  ) {
    try {
      await db
        .doc(userPath)
        .update({ receiveNotifications: notificationStatus })
      store.commit(
        "firetableModule/setSuccess",
        string.notificationStatusChanged
      )
      return true
    } catch (error) {
      this.errorMessage(error.message)
      return false
    }
  }

  /**
   * change the rootuser default sitekey setting.
   * @param db firestore db
   * @param userPath rootuser path
   * @param siteKey change sitekey value
   */
  static async changeDefaultSiteKey(
    db: firebase.firestore.Firestore,
    userPath: string,
    siteKey: string
  ) {
    try {
      await db.doc(userPath).update({ defaultSiteKey: siteKey })
      store.commit("firetableModule/setSuccess", string.defaultSiteKeyChanged)
      return true
    } catch (error) {
      this.errorMessage(error.message)
      return false
    }
  }

  /**
   * get rootUser approved siteKeys.
   * @param db firestore db
   * @param rootUserSiteKeysPath rootuser sitekey path
   */
  static async rootUserSiteKeys(
    db: firebase.firestore.Firestore,
    rootUserSiteKeysPath: string
  ) {
    let rootUserSiteKeys: { [key: string]: any } = {}
    try {
      const siteKeysGet = await db.doc(rootUserSiteKeysPath).get()
      if (siteKeysGet.exists) {
        // Util.logOnlyOnDev(siteKeysGet.get("siteKeys"));
        rootUserSiteKeys = siteKeysGet.get("siteKeys")
      }
    } catch (error) {
      Util.errorMessage(error.message)
    }
    return rootUserSiteKeys
  }

  /**
   * get sitekeyUser locations.
   * @param db firestore db
   * @param siteKeyUserLocationPath sitekeyUser locations path
   */
  static async getsiteKeyUserLocations(
    db: firebase.firestore.Firestore,
    siteKeyUserLocationPath: string
  ) {
    const query = await db.collection(siteKeyUserLocationPath).get()
    return query.docs.map((doc) => SiteKeyUserLocations.fromFirestore(doc))
  }

  /**
   * change siteKeyUser locations.
   */
  static async changeSiteKeyUserLocations(
    db: firebase.firestore.Firestore,
    siteKeyUserLocationPath: string,
    locationObj: any
  ) {
    try {
      await db.doc(siteKeyUserLocationPath).update(locationObj)
      return true
    } catch (error) {
      this.errorMessage(error.message)
      return false
    }
  }

  /**
     * change siteKeyUser phone number.

     */
  static async updatePhoneNo(
    db: firebase.firestore.Firestore,
    siteKeyUserPath: string,
    phoneNo: number
  ) {
    try {
      // Util.logOnlyOnDev(siteKeyuserPath);
      // Util.logOnlyOnDev(phoneNo);
      await db.doc(siteKeyUserPath).update({ phone: phoneNo })
      store.commit("firetableModule/setSuccess", string.siteUserPhoneChanged)
      return true
    } catch (error) {
      this.errorMessage(error.message)
      return false
    }
  }

  /**
   * change siteKeyUser phone number.
   */
  static async updateLocation(
    db: firebase.firestore.Firestore,
    craftRecordPath: string,
    location: { [key: string]: any }
  ) {
    try {
      await db.doc(craftRecordPath).update({
        latitude: location.lat,
        longitude: location.lng,
        timestampLastModified: firebase.firestore.FieldValue.serverTimestamp(),
        lastModifiedBy: `${store.state.firetableModule.rootUserData.id}`,
      })
      store.commit("firetableModule/setSuccess", string.craftLocationChanged)
      return true
    } catch (error) {
      this.errorMessage(error.message)
      return false
    }
  }

  /**
   * Gets site key tasks
   */
  static async getSiteKeyTasks() {
    try {
      const { rootUserData, siteKeyUserLocations, siteKeyUserPermissionData } =
        store.state.firetableModule
      if (
        !rootUserData ||
        !siteKeyUserLocations ||
        (siteKeyUserLocations && siteKeyUserLocations.length === 0)
      )
        return []

      const { defaultSiteKey } = rootUserData
      const { companyID, permissions } = siteKeyUserPermissionData
      let ref: any = db.collection(`siteKeys/${defaultSiteKey}/tasks`)
      if (!permissions.isPlantPersonnel)
        ref = ref.where("assignedCompanyID", "==", companyID)
      ref = await ref.get()
      return ref.docs
        .map((doc) => Tasks.fromFirestore(doc))
        .filter((item) => siteKeyUserLocations.includes(item.locationID))
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  /**
   * get getSiteKey kpis.
   */
  static async getKpiRecords(
    db: firebase.firestore.Firestore,
    defaultSiteKey: string,
    permissionData: any,
    craftTypes: Array<any>,
    from: Date,
    to: Date
  ) {
    try {
      // Initial date filter
      let ref: any = db.collection(`siteKeys/${defaultSiteKey}/kpis`)
      ref = ref.where("date", ">=", from).where("date", "<=", to)
      // Conditional Craft Types filter
      if (!craftTypes.includes("all") && craftTypes.length > 0)
        ref = ref.where("craftType", "in", craftTypes)
      // Permissions filtering
      const {
        permissions: { isPlantPersonnel },
        companyID,
      } = permissionData
      if (!isPlantPersonnel) ref = ref.where("companyID", "==", companyID)
      ref = await ref.get()
      return ref.docs.map((doc) => KPIDocument.fromFirestore(doc))
    } catch (error) {
      this.errorMessage(error.message)
      Util.logOnlyOnDev(error.message)
      return []
    }
  }

  /**
   * Calculates KPI "total" type
   * @param metric KPI Metric data
   * @param kpiData Database stored KPI indicators
   */
  static calcKpiTotal(metric: KPIMetric, kpiData: Array<KPIDocument>) {
    if (!metric.displayCardValue) return
    return sumBy(kpiData, (k) => k[metric.key] || 0)
  }

  /**
   * Calculates KPI "average" type
   * @param metric KPI Metric data
   * @param kpiData Database stored KPI indicators
   */
  static calcKpiAverage(metric: KPIMetric, kpiData: Array<KPIDocument>) {
    /**
     * Simple average
     * avg = (top / bottom)
     * top = sum(currentIndicator)
     * bottom = sum(denominatorKey)
     */
    if (!metric.displayCardValue) return
    const top = sumBy(kpiData, (k) => k[metric.key] || 0)
    const bot = sumBy(kpiData, (k) =>
      k[metric.key] && k[metric.denominatorKey] ? k[metric.denominatorKey] : 0
    )
    return bot > 0 ? top / bot : 0
  }

  /**
   * Calculates KPI "percentage" type
   * @param metric KPI Metric data
   * @param kpiData Database stored KPI indicators
   */
  static calcKpiPercentage(metric: KPIMetric, kpiData: Array<KPIDocument>) {
    /**
     * Simple percentage
     * avg = (top / bottom)
     * top = sum(currentIndicator)
     * bottom = sum(denominatorKey)
     */
    if (!metric.displayCardValue) return
    const top = sumBy(kpiData, (k) => k[metric.key] || 0)
    const bot = sumBy(kpiData, (k) =>
      k[metric.denominatorKey] ? k[metric.denominatorKey] : 0
    )
    return bot > 0 ? (top / bot) * 100 : 0
  }

  /**
   * Calculates needed data to render a "Date-Column" chart type
   * @param metric KPI Metric data
   * @param kpiData Database stored KPI indicators
   */
  static calcDateColumnData(metric: KPIMetric, kpiData: Array<KPIDocument>) {
    let data = []
    const { key: metricKey, currentTimescale, denominatorKey } = metric
    for (const key in kpiData) {
      const obj: any = {
        date: null,
        total: denominatorKey ? 0 : kpiData[key][metricKey],
        numerator: denominatorKey ? kpiData[key][metricKey] : 0,
        denominator: denominatorKey ? kpiData[key][denominatorKey] : 0,
        taskIDs: kpiData[key].taskIDs,
      }
      if (metric.customSeriesFields) {
        for (const field of metric.customSeriesFields)
          obj[field] = kpiData[key][field]
      } else {
        obj.taskType = kpiData[key].taskType
      }
      switch (currentTimescale) {
        case "daily":
          obj.date = moment(kpiData[key].date.seconds * 1000).startOf("day")
          break
        case "weekly":
          obj.date = moment(kpiData[key].date.seconds * 1000).startOf("week")
          break
        case "monthly":
          obj.date = moment(kpiData[key].date.seconds * 1000).startOf("month")
          break
        case "quarterly":
          obj.date = moment(kpiData[key].date.seconds * 1000).startOf("month")
          break
        default:
      }
      data.push(obj)
    }

    const groupedByDate = groupBy(data, "date")
    const groupedData = {}
    for (const date in groupedByDate) {
      groupedData[date] = {
        all: Util.calculateValueType(metric, groupedByDate[date]),
        taskIDs: flatten(groupedByDate[date].map((record) => record.taskIDs)),
      }
      if (metric.customSeriesFields) {
        for (const field of metric.customSeriesFields) {
          // total will be the value of the custom field itself
          const fieldData = groupedByDate[date]
            .filter((record) => record[field] > 0)
            .map((record) => ({ ...record, total: record[field] }))
          groupedData[date] = {
            ...groupedData[date],
            [field]: fieldData,
          }
        }
      } else {
        groupedData[date] = {
          ...groupedData[date],
          ...groupBy(groupedByDate[date], "taskType"),
        }
      }
    }
    data = []
    for (const date in groupedData) {
      const retObj = { date: null, all: 0, taskIDs: [] }
      retObj.date = moment(date).toDate()
      for (const key in groupedData[date])
        if (!["all", "taskIDs"].includes(key))
          retObj[key] = Util.calculateValueType(metric, groupedData[date][key])

      data.push({
        ...retObj,
        all: groupedData[date].all,
        taskIDs: groupedData[date].taskIDs,
      })
    }
    return data
  }

  /**
   * Calculates final values given a set of data and value type
   * @param metric KPI Metric data
   * @param data Grouped by date indicators
   */
  static calculateValueType(metric: KPIMetric, data: Array<any>) {
    let value
    let numerator
    let denominator
    switch (metric.type) {
      case "total":
        value = sumBy(data, "total")
        break
      case "percentage":
        numerator = sumBy(data, "numerator")
        denominator = sumBy(data, "denominator")
        value = denominator > 0 ? (numerator / denominator) * 100 : 0
        break
      case "average":
        numerator = sumBy(data, "numerator")
        denominator = sumBy(data, "denominator")
        value = denominator > 0 ? numerator / denominator : 0
        break
      default:
    }
    return parseFloat(value.toFixed(metric.precision))
  }

  /**
   * Calculates needed data to render a "Pie-Simple" chart type
   * @param metric KPI Metric data
   * @param kpiData Database stored KPI indicators
   * @param defaultSiteKey User site key
   */
  static async calcPieSimpleData(
    metric: KPIMetric,
    kpiData: Array<KPIDocument>,
    defaultSiteKey: string
  ) {
    const data = []
    const { customSeriesFields, type } = metric
    const { kpiConfig } = await Util.getSiteKey(db, defaultSiteKey)

    // We go field by field calculating its value according to the parent kpi type
    for (const field of customSeriesFields) {
      const fieldMetric = kpiConfig[field]
      let numerator
      let denominator
      switch (type) {
        case "percentage":
          numerator = sumBy(kpiData, field)
          denominator = sumBy(kpiData, fieldMetric.denominatorKey)
          data.push({
            subfield: fieldMetric.title,
            value: denominator > 0 ? numerator / denominator : 0,
          })
          break
        default:
      }
    }
    return data
  }

  /**
   * Validates barrier comments
   * @param currentValues Current task specific details selected
   * @param templateValues Customization task specific details
   */
  static barrierCommentsRequired({
    currentValues,
    templateValues,
  }: {
    currentValues: any
    templateValues: any
  }) {
    if (!("barrierOther" in currentValues)) {
      return false
    }
    const barrierConfigs = templateValues.filter((c) => {
      return c.key === "barrierComments" || c.key === "barrierOther"
    })
    if (barrierConfigs.length !== 2) {
      return false
    }
    const barrierCommentsConfig = templateValues.filter((c) => {
      return c.key === "barrierComments"
    })[0]

    const barrierOtherConfig = templateValues.filter((c) => {
      return c.key === "barrierOther"
    })[0]
    if (
      parseInt(currentValues.barrierOther, 10) !==
        barrierOtherConfig.defaultValue &&
      (!("barrierComments" in currentValues) ||
        currentValues.barrierComments === barrierCommentsConfig.defaultValue)
    ) {
      return true
    } else {
      return false
    }
  }

  /**
   * Get parentRecords created within date range.
   * @param db firestore db
   * @param defaultSiteKey site key
   * @param from from date
   * @param to to date
   */
  static async getCraftRecordsByDateRange(
    db: firebase.firestore.Firestore,
    defaultSiteKey: string,
    from: Date,
    to: Date
  ): Promise<CraftRecord[]> {
    try {
      let ref: any = db.collection(`siteKeys/${defaultSiteKey}/parentRecords`)
      ref = ref
        .where("timestampRecordCreated", ">=", from)
        .where("timestampRecordCreated", "<=", to)
      ref = await ref.get()
      return ref.docs.map((doc) => CraftRecord.fromFirestore(doc))
    } catch (error) {
      Util.logOnlyOnDev(error)
      this.errorMessage(error.message)
      return []
    }
  }

  // /**
  //  * Sets the quantities in the inventory object
  //  * document according to the inventory transaction type
  //  * @param transaction Transaction object
  //  */
  // static setInventoryTransactionQuantities(transaction) {
  //   const { type, value } = transaction
  //   const data = {}
  //   switch (type) {
  //     case InventoryTransactionTypes.STOCK_TO_CR:
  //       data["quantityAvailable"] = firebase.firestore.FieldValue.increment(
  //         -value
  //       )
  //       data["quantityInUse"] = firebase.firestore.FieldValue.increment(value)
  //       break
  //     case InventoryTransactionTypes.CR_TO_STOCK:
  //       data["quantityAvailable"] =
  //         firebase.firestore.FieldValue.increment(value)
  //       data["quantityInUse"] = firebase.firestore.FieldValue.increment(-value)
  //       break
  //     case InventoryTransactionTypes.CR_TO_FIELD:
  //       data["quantityAwaitingPickup"] =
  //         firebase.firestore.FieldValue.increment(value)
  //       data["quantityInUse"] = firebase.firestore.FieldValue.increment(-value)
  //       break
  //     case InventoryTransactionTypes.FIELD_TO_CR:
  //       data["quantityAwaitingPickup"] =
  //         firebase.firestore.FieldValue.increment(-value)
  //       data["quantityInUse"] = firebase.firestore.FieldValue.increment(value)
  //       break
  //     case InventoryTransactionTypes.FIELD_TO_STOCK:
  //       data["quantityAwaitingPickup"] =
  //         firebase.firestore.FieldValue.increment(-value)
  //       data["quantityAvailable"] =
  //         firebase.firestore.FieldValue.increment(value)
  //       break
  //     default:
  //       break
  //   }
  //   return data
  // }

  /**
   * Get getSiteKey tasks.
   * @param db firestore db
   * @param defaultSiteKey site key
   * @param from from date
   * @param to to date
   */
  static async getTaskByDateRange(
    db: firebase.firestore.Firestore,
    defaultSiteKey: string,
    from: Date,
    to: Date
  ) {
    try {
      let ref: any = db.collection(`siteKeys/${defaultSiteKey}/tasks`)
      ref = ref
        .where("timestampCreated", ">=", from)
        .where("timestampCreated", "<=", to)
      ref = await ref.get()
      const docs = ref.docs.map((doc) => Tasks.fromFirestore(doc))
      return docs
    } catch (error) {
      Util.logOnlyOnDev(error)
      this.errorMessage(error.message)
      return []
    }
  }

  /**
   * Groups tasks by user
   * @tasks Tasks to be rearranged
   */
  static groupTasksByUser(tasks: [Tasks]) {
    return groupBy(tasks, "createdBy")
  }

  /**
   * Groups tasks by user
   * @tasks Tasks to be rearranged
   */
  static groupTasksByOnBehalfOfUser(tasks: [Tasks]) {
    // Replace taskSpecificDetails.onBehalfOf with task.createdBy if onBehalfOf is undefined or null
    const t = tasks.map((task) => {
      if (!task.taskSpecificDetails["onBehalfOfSynced"]) {
        task.taskSpecificDetails["onBehalfOfSynced"] = task.createdBy
      }
      return task
    })
    return groupBy(t, "taskSpecificDetails.onBehalfOfSynced")
  }

  /**
   * Groups craft records by user
   * @tasks CraftRecords to be rearranged
   */
  static groupCraftRecordsByUser(craftRecords: CraftRecord[]) {
    return groupBy(craftRecords, "createdBy")
  }

  /**
   * Groups tasks by location
   * @tasks Tasks to be rearranged
   */
  static groupTasksByLocation(tasks: [Tasks]) {
    return groupBy(tasks, "locationName")
  }

  /**
   * Adds an Inventory Object to collection
   * @param db
   * @param defaultSiteKey
   * @param record
   */
  static addInventoryObject(
    defaultSiteKey: string,
    record: InventoryObject | any
  ) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const path = `/siteKeys/${defaultSiteKey}/inventoryObjects/`
        const resp = await db.collection(path).add(record)
        resolve(resp)
      } catch (error) {
        Util.errorMessage(error.message)
        reject(error)
      }
    })
  }

  /**
   * Fetches Inventory Object
   * @param defaultSiteKey
   * @param objectId
   */
  static getInventoryObject(
    defaultSiteKey: string,
    objectId: string
  ): Promise<InventoryObject> {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await db
          .collection(`siteKeys/${defaultSiteKey}/inventoryObjects`)
          .doc(objectId)
          .get()
        resolve(InventoryObject.fromFirestore(query))
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * Fetches Inventory Objects for a given craftType
   * @param defaultSiteKey
   * @param craftType
   */
  static getInventoryObjectsByCraftType(
    defaultSiteKey: string,
    craftType: number
  ): Promise<Array<InventoryObject>> {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await db
          .collection(`siteKeys/${defaultSiteKey}/inventoryObjects`)
          .where("craftTypes", "array-contains", craftType)
          .get()
        // return a list of site key users.
        resolve(query.docs.map((doc) => InventoryObject.fromFirestore(doc)))
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * Initializes Sentry
   * @user Logged-in user
   */
  static initSentry(user) {
    const { uid, email, displayName } = user
    // Sentry
    if (process.env.NODE_ENV === "production")
      Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
          new SentryVueIntegration({ Vue, attachProps: true, logErrors: true }),
        ],
        environment: `stilt-web@${process.env.VUE_APP_FIREBASE_PROJECT}`,
        release: process.env.VUE_APP_RELEASE_VERSION,
        beforeSend(event) {
          // Check if it is an exception, and if so, show the report dialog
          if (event.exception) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const { event_id } = event
            Sentry.showReportDialog({
              // eslint-disable-next-line @typescript-eslint/camelcase
              eventId: event_id,
              id: uid,
              user: { name: displayName, email },
            })
          }
          return event
        },
      })

    // Configure Sentry user scope
    Sentry.configureScope((scope) => {
      scope.setUser({ username: displayName, id: uid, email })
    })
  }
}
