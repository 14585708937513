import { mdiAccountBoxMultiple, mdiFileDocument, mdiTimelapse } from "@mdi/js"
import cloneDeep from "lodash/cloneDeep"
import { DateTime } from "luxon"

export const reportTypes = ["userReport", "extendedRentalReport"] as const
export type ReportTypes = (typeof reportTypes)[number]

export const reportIcons: { [p in ReportTypes]: string } = {
  userReport: mdiAccountBoxMultiple,
  extendedRentalReport: mdiTimelapse,
} as const

type HasTimestampLastModified = {
  timestampLastModified?: string
}

type HasTimestampCreated = {
  timestampCreated: string
}

/**
 * Return the svg path string for the icon matching the report type.
 */
export function getReportIcon(reportType: string): string {
  return reportIcons[reportType] ?? mdiFileDocument
}

/**
 * Orders by objects timestampLastModified (ISO string) descending by default.
 */
function sortArrayByTimestampLastModified<T extends HasTimestampLastModified>(
  array: Array<T>,
  ascending?: boolean
): Array<T> {
  const unsorted = cloneDeep(array)
  const sorted = unsorted.sort((first, second) => {
    const dtFirst = DateTime.fromISO(first?.timestampLastModified)
    const dtSecond = DateTime.fromISO(second?.timestampLastModified)
    if (dtFirst > dtSecond) {
      return -1
    } else if (dtFirst < dtSecond) {
      return 1
    } else {
      return 0
    }
  })

  if (ascending === true) {
    return sorted.reverse()
  } else {
    return sorted
  }
}

/**
 * Orders by objects timestampCreated (ISO string) descending by default.
 */
function sortArrayByTimestampCreated<T extends HasTimestampCreated>(
  array: Array<T>,
  ascending?: boolean
): Array<T> {
  const unsorted = cloneDeep(array)
  const sorted = unsorted.sort((first, second) => {
    const dtFirst = DateTime.fromISO(first?.timestampCreated)
    const dtSecond = DateTime.fromISO(second?.timestampCreated)
    if (dtFirst > dtSecond) {
      return -1
    } else if (dtFirst < dtSecond) {
      return 1
    } else {
      return 0
    }
  })

  if (ascending === true) {
    return sorted.reverse()
  } else {
    return sorted
  }
}

export const sort = {
  timestampLastModified: sortArrayByTimestampLastModified,
  timestampCreated: sortArrayByTimestampCreated,
}
