
//Libs
import { CraftRecord, Location, CraftType } from "@/models/models"
import { Util } from "@/helpers"
import { db } from "@/firebase-init"
import firebase from "firebase/app"
import { string } from "@/string"
import moment from "moment"
import sum from "lodash/sum"
import * as Sentry from "@sentry/browser"

// Components
import ConfirmDialog from "@/components/ConfirmDialog.vue"

const defaultColsVisibility = [
  { key: "craftTypeIcon", visible: true },
  { key: "title", visible: true },
  { key: "description", visible: false },
  { key: "tagNumber", visible: true },
  { key: "locationID", visible: true },
  { key: "numOpenTasks", visible: true },
  { key: "installed", visible: false },
  { key: "standingDays", visible: false },
  { key: "createdBy", visible: true },
  { key: "created", visible: false },
  { key: "assetName", visible: false },
]
export default {
  data() {
    return {
      saving: false,
      record: null,
      exportData: [],
      exportLabels: {},
      exportFields: [],
      confirmDialog: {
        show: false,
        title: "",
        message: "",
        cancelText: "",
        okText: "",
      },
      colsVisibility: null,
      exportFilename: `Deleted Craft Records - ${moment().format(
        "MM-DD-YYYY"
      )}.csv`,
      records: [],
      filteredTableRecords: [],
      columns: [],
      sorter: {},
      searchTerm: Util.getStorage("deleted-craft-search-term") || "",
      CraftType,
    }
  },
  components: {
    "confirm-dialog": ConfirmDialog,
  },
  methods: {
    startListening() {
      this.$store.dispatch("firetableModule/listenDeletedCraftRecordsList")
    },
    removeListeners() {
      this.$store.commit(
        "firetableModule/unsubscribeDeletedCraftRecordsListListener"
      )
      this.$store.commit("firetableModule/removeDeletedCraftRecordsList")
    },
    setColsVisibility() {
      const storedCols = Util.getStorage("deleted-craft-list-cols-visibility")
      if (!storedCols) {
        this.colsVisibility = defaultColsVisibility
        return
      }
      if (JSON.stringify(defaultColsVisibility) !== storedCols) {
        this.colsVisibility = defaultColsVisibility
        return
      }
      this.colsVisibility = JSON.parse(storedCols)
    },
    getLocationName(locationID: string) {
      let location = []
      const { siteKeyLocations } = this.$store.state.firetableModule
      if (siteKeyLocations) {
        location = siteKeyLocations.filter(
          (record: Location) => locationID === record.id
        )
      }
      return location.length > 0 ? location[0].title : ""
    },
    saveColVisibility(column, visible) {
      const index = this.colsVisibility.findIndex(
        (col) => col.key === column.dataIndex
      )
      this.colsVisibility[index].visible = visible
      // Save it to local storage
      Util.setStorage(
        "deleted-craft-list-cols-visibility",
        JSON.stringify(this.colsVisibility)
      )
    },
    saveTableFilters(_, filters, sorter, { currentDataSource }) {
      // Update current filtered table records
      this.filteredTableRecords = currentDataSource
      // Sorter
      const { field, order } = sorter
      if (order) {
        this.sorter = { field, order }
        Util.setStorage("deleted-craft-list-sorter-field", field)
        Util.setStorage("deleted-craft-list-sorter-order", order)
      } else {
        this.sorter = {}
        Util.removeStorage("deleted-craft-list-sorter-field")
        Util.removeStorage("deleted-craft-list-sorter-order")
      }

      // Filters
      if (Object.keys(filters).length > 0)
        Util.setStorage("deleted-craft-list-filters", JSON.stringify(filters))
      else Util.removeStorage("deleted-craft-list-filters")

      // Re-build columns
      this.buildColumns()
    },
    showClearFilters() {
      // Saved filters
      const sortedField = Util.getStorage("deleted-craft-list-sorter-field")
      const sortedOrder = Util.getStorage("deleted-craft-list-sorter-order")
      let filters: any = Util.getStorage("deleted-craft-list-filters")
      let colsVisibility = Util.getStorage("deleted-craft-list-cols-visibility")
      filters = filters ? JSON.parse(filters) : null
      colsVisibility = colsVisibility ? JSON.parse(colsVisibility) : null
      if (
        this.searchTerm ||
        sortedOrder ||
        sortedField ||
        filters ||
        colsVisibility
      )
        return true
      return false
    },
    clearFilters() {
      // Set search term
      this.searchTerm = ""
      // Cols visibility
      this.colsVisibility = defaultColsVisibility
      // Storage
      Util.removeStorage("deleted-craft-list-filters")
      Util.removeStorage("deleted-craft-list-sorter-field")
      Util.removeStorage("deleted-craft-list-sorter-order")
      Util.removeStorage("deleted-craft-list-cols-visibility")
      // Re-build columns
      this.buildColumns()
    },
    buildColumns() {
      const sortedField = Util.getStorage("deleted-craft-list-sorter-field")
      const sortedOrder = Util.getStorage("deleted-craft-list-sorter-order")
      let filters: any = Util.getStorage("deleted-craft-list-filters")
      filters = filters ? JSON.parse(filters) : null
      this.columns = [
        {
          title: "Craft Type",
          dataIndex: "craftTypeIcon",
          align: "center",
          show: this.colsVisibility.find((col) => col.key === "craftTypeIcon")
            ?.visible,
          width: 100,
          scopedSlots: {
            customRender: "prIcon",
          },
          sorter: (a, b) => a.craftTypeIcon.length - b.craftTypeIcon.length,
          sortOrder: sortedField === "craftTypeIcon" && sortedOrder,
        },
        {
          title: "Title",
          dataIndex: "title",
          show: this.colsVisibility.find((col) => col.key === "title")?.visible,
          width: 300,
          scopedSlots: {
            customRender: "prTitle",
          },
          sorter: (a, b) => a.title.length - b.title.length,
          sortOrder: sortedField === "title" && sortedOrder,
        },
        {
          title: "Description",
          dataIndex: "description",
          show: this.colsVisibility.find((col) => col.key === "description")
            ?.visible,
          width: 200,
          scopedSlots: {
            customRender: "prDesc",
          },
          sorter: (a, b) => {
            const descA = a.description || "--"
            const descB = b.description || "--"
            return descA.length - descB.length
          },
          sortOrder: sortedField === "description" && sortedOrder,
        },
        {
          title: "Tag Number",
          dataIndex: "tagNumber",
          align: "center",
          show: this.colsVisibility.find((col) => col.key === "tagNumber")
            ?.visible,
          width: 110,
          sorter: (a, b) => a.tagNumber.localeCompare(b.tagNumber),
          sortOrder: sortedField === "tagNumber" && sortedOrder,
        },
        {
          title: "Location",
          dataIndex: "locationID",
          show: this.colsVisibility.find((col) => col.key === "locationID")
            ?.visible,
          width: 150,
          scopedSlots: {
            customRender: "prLocation",
          },
          filteredValue:
            filters && filters.locationID ? filters.locationID : null,
          filters: this.$store.state.firetableModule.siteKeyLocations.map(
            (loc) => ({ text: loc.title, value: loc.id })
          ),
          sorter: (a, b) => {
            const locA = this.getLocationName(a.locationID)
            const locB = this.getLocationName(b.locationID)
            return locA.localeCompare(locB)
          },
          onFilter: (value, record) => record.locationID === value,
        },
        {
          title: "Open Tasks",
          dataIndex: "numOpenTasks",
          show: this.colsVisibility.find((col) => col.key === "numOpenTasks")
            ?.visible,
          width: 100,
          scopedSlots: {
            customRender: "prNumOpenTasks",
          },
          sorter: (a, b) => a.numOpenTasks - b.numOpenTasks,
          sortOrder: sortedField === "numOpenTasks" && sortedOrder,
        },
        {
          title: "Installed",
          dataIndex: "installed",
          show: this.colsVisibility.find((col) => col.key === "installed")
            ?.visible,
          width: 120,
          scopedSlots: {
            customRender: "prInstalled",
          },
          sorter: (a, b) => a.installed - b.installed,
          filteredValue:
            filters && filters.installed ? filters.installed : null,
          filters: [
            { text: "Yes", value: "yes" },
            { text: "No", value: "no" },
          ],
          onFilter: (value, record) => {
            const _value = record.installed ? "yes" : "no"
            return _value === value
          },
        },
        {
          title: "# Days Standing",
          dataIndex: "standingDays",
          show: this.colsVisibility.find((col) => col.key === "standingDays")
            ?.visible,
          width: 120,
          scopedSlots: {
            customRender: "prStandingDays",
          },
          sorter: (a, b) => {
            const _a = a.standingDays === "" ? -1 : a.standingDays
            const _b = b.standingDays === "" ? -1 : b.standingDays
            return _a - _b
          },
          sortOrder: sortedField === "standingDays" && sortedOrder,
        },
        {
          title: "Created By",
          dataIndex: "createdBy",
          show: this.colsVisibility.find((col) => col.key === "createdBy")
            ?.visible,
          width: 120,
          scopedSlots: {
            customRender: "prCreatedBy",
          },
          sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
          sortOrder: sortedField === "createdBy" && sortedOrder,
        },
        {
          title: "Created",
          dataIndex: "created",
          show: this.colsVisibility.find((col) => col.key === "created")
            ?.visible,
          width: 100,
          sorter: (a, b) =>
            a.timestampRecordCreated.seconds - b.timestampRecordCreated.seconds,
          sortOrder: sortedField === "created" && sortedOrder,
        },
        {
          title: "Asset",
          dataIndex: "assetName",
          show: this.colsVisibility.find((col) => col.key === "assetName")
            ?.visible,
          width: 120,
          sorter: (a, b) => a.assetID - b.assetID,
          sortOrder: sortedField === "assetName" && sortedOrder,
        },
        {
          title: "Actions",
          dataIndex: "actions",
          show: true,
          width: 70,
          scopedSlots: {
            customRender: "prActions",
          },
        },
      ]
    },
    closeDialog() {
      this.confirmDialog = {
        show: false,
        title: "",
        message: "",
        cancelText: "",
        okText: "",
      }
    },
    confirmRestore(record) {
      this.record = record
      const { numClosedTasks, numOpenTasks } = this.record
      this.confirmDialog = {
        show: true,
        title: "Restore Craft Record?",
        message: `This Craft Record, along with its ${
          numClosedTasks + numOpenTasks
        } task(s), will be restored. Continue?`,
        cancelText: "Cancel",
        okText: "Restore",
      }
    },
    async restore() {
      try {
        this.saving = true
        const { refPath } = this.record
        const restoreParentRecord = firebase
          .functions()
          .httpsCallable("restoreParentRecord")
        await restoreParentRecord({ refPath })
        this.$store.commit("firetableModule/setSuccess", string.craftRestored)
        this.record = null
        this.closeDialog()
      } catch (error) {
        Util.logOnlyOnDev("Restore Parent Record Error")
        Sentry.captureException(error)
        Util.errorMessage(error.message)
      } finally {
        this.saving = false
      }
    },
    filterRecords(term, records) {
      return records.filter((record) => {
        return term
          .toLowerCase()
          .split(" ")
          .every((v) => {
            const titleMatch = record.title.toLowerCase().includes(v)
            const descMatch = record.description
              ? record.description.toLowerCase().includes(v)
              : false
            const tagNumberMatch = record.craftDetails.tagNumber
              ? record.craftDetails.tagNumber.toLowerCase().includes(v)
              : false
            const workOrderMatch = record.craftDetails.workOrder
              ? record.craftDetails.workOrder.toLowerCase().includes(v)
              : false
            const poNumberMatch = record.craftDetails.purchaseOrder
              ? record.craftDetails.purchaseOrder.toLowerCase().includes(v)
              : false
            const craftTypeMatch =
              this.globalCraftType === "all" ||
              record.craftType === this.globalCraftType
            return (
              (titleMatch ||
                descMatch ||
                tagNumberMatch ||
                workOrderMatch ||
                poNumberMatch) &&
              craftTypeMatch
            )
          })
      })
    },
    async generateCsv() {
      // Get craft details
      this.siteKeyData = await Util.getSiteKey(
        db,
        this.$store.state.firetableModule.rootUserData.defaultSiteKey
      )
      const { craftDetails } = this.siteKeyData.customizations
      const craftDetailLabels = {}
      for (const type in craftDetails)
        for (const craftDetail in craftDetails[type])
          craftDetailLabels[craftDetail] = craftDetails[type][craftDetail].title

      this.exportLabels = {
        id: "ID",
        title: "Title",
        description: "Description",
        created: "Time Created",
        createdBy: "Created By",
        location: "Location",
        openTasks: "Open Tasks",
        closedTasks: "Closed Tasks",
        assetName: "Asset Name",
        ...craftDetailLabels,
        standingDays: "# Days Standing",
      }

      const recordsToExport = this.filterRecords(
        this.searchTerm,
        this.filteredTableRecords.length > 0
          ? this.filteredTableRecords
          : this.records
      )
      this.exportFields = Object.keys(this.exportLabels)
      this.exportData = await Promise.all(
        recordsToExport.map((rec) => {
          const {
            id,
            title,
            description,
            created,
            createdBy,
            locationID,
            numOpenTasks,
            numClosedTasks,
            assetName,
            timestampRecordClosed,
          } = rec

          // Format some fields
          let { craftDetails } = rec
          const { installed, timestampMarkedInstalled, timestampOnHold } =
            craftDetails
          craftDetails = {
            ...rec.craftDetails,
            installed: installed ? "TRUE" : "FALSE",
            timestampMarkedInstalled: timestampMarkedInstalled
              ? moment(timestampMarkedInstalled.seconds * 1000).format(
                  "MM/DD/YYYY hh:mm A"
                )
              : null,
            timestampOnHold: timestampOnHold
              ? moment(timestampOnHold.seconds * 1000).format(
                  "MM/DD/YYYY hh:mm A"
                )
              : null,
          }
          if (rec.open === true) {
            craftDetails.standingDays = timestampMarkedInstalled
              ? moment().diff(
                  moment(timestampMarkedInstalled.seconds * 1000),
                  "days"
                )
              : ""
          }
          if (rec.open === false && timestampRecordClosed) {
            craftDetails.standingDays = timestampMarkedInstalled
              ? moment(timestampRecordClosed.seconds * 1000).diff(
                  moment(timestampMarkedInstalled.seconds * 1000),
                  "days"
                )
              : ""
          }
          return {
            id,
            title,
            description,
            created,
            createdBy,
            location: this.getLocationName(locationID),
            openTasks: numOpenTasks,
            closedTasks: numClosedTasks,
            assetName,
            ...craftDetails,
          }
        })
      )
      setTimeout(() => this.$refs.export.generate())
    },
  },
  watch: {
    siteKeyUserLocations(newValue: string | null) {
      if (!newValue) return
      this.startListening()
    },
    siteKeyLocations(newValue: string | null) {
      if (!newValue) return
      this.buildColumns()
    },
    parentRecords(records) {
      // filter search manualy
      this.records = this.filterRecords(this.searchTerm, records)
    },
    searchTerm(newTerm) {
      // filter search on every term change
      this.records = this.filterRecords(newTerm, this.parentRecords)
      Util.setStorage("deleted-craft-search-term", newTerm)
    },
    globalCraftType() {
      this.records = this.filterRecords(this.searchTerm, this.parentRecords)
    },
  },
  asyncComputed: {
    async parentRecords() {
      const { rootUserData } = this.$store.state.firetableModule
      const { deletedCraftRecordsList } = this.$store.state.firetableModule
      // Util.logOnlyOnDev("deletedCraftRecordsList", deletedCraftRecordsList)
      if (!rootUserData || !deletedCraftRecordsList) return []
      const { defaultSiteKey } = rootUserData
      // Additional fields
      return Promise.all(
        deletedCraftRecordsList.map(async (rec: CraftRecord) => {
          const {
            craftDetails: {
              installed,
              timestampMarkedInstalled,
              tagNumber,
            } = {},
            craftType,
            timestampRecordCreated,
            timestampRecordClosed,
          } = rec
          //Asset details
          const assetDetails = await Util.getAssetDetail(
            db,
            defaultSiteKey,
            rec.assetID
          )
          // Get User details
          const userDetails = await Util.getSiteKeyUserDetail(
            db,
            defaultSiteKey,
            rec.createdBy
          )
          let standingDays = timestampMarkedInstalled
            ? moment().diff(
                moment(timestampMarkedInstalled.seconds * 1000),
                "days"
              )
            : ""
          if (rec.open === false && timestampRecordClosed) {
            standingDays = timestampMarkedInstalled
              ? moment(timestampRecordClosed.seconds * 1000).diff(
                  moment(timestampMarkedInstalled.seconds * 1000),
                  "days"
                )
              : ""
          }
          return {
            ...rec,
            installed,
            standingDays,
            craftTypeIcon: CraftType.getCraftTypeIcons(craftType),
            tagNumber: tagNumber || "[N/A]",
            createdBy: userDetails ? userDetails.displayName : "[Unavailable]",
            assetName: assetDetails ? assetDetails.title : "",
            created: moment(timestampRecordCreated.seconds * 1000).format(
              "MM/DD/YYYY hh:mm A"
            ),
          }
        })
      )
    },
  },
  computed: {
    siteKeyUserLocations(): string[] | null {
      return this.$store.state.firetableModule.siteKeyUserLocations
    },
    siteKeyLocations(): string[] | null {
      return this.$store.state.firetableModule.siteKeyLocations
    },
    loading(): boolean {
      return this.$store.state.firetableModule.isLoading
    },
    tableWidth() {
      return sum(this.columns.filter((col) => col.show).map((col) => col.width))
    },
    globalCraftType(): string | number | null {
      return this.$store.state.firetableModule.globalCraftType
    },
  },
  mounted() {
    if (this.siteKeyUserLocations) this.startListening()
    this.setColsVisibility()
  },
  beforeDestroy(): void {
    this.removeListeners()
  },
}
