
// Models

// Libs
import { Util } from "@/helpers"
import { db } from "@/firebase-init"
import moment from "moment"

export default {
  props: ["show", "close", "data", "selectedKpi", "filters", "siteKey"],
  data() {
    return {
      moment,
      dateFormat: "MMM D, YYYY",
      tasks: [],
      columns: [],
      loading: false,
    }
  },
  watch: {
    data(data) {
      if (!data) return
      this.loading = true
      // Build columns
      this.buildColumns()
    },
  },
  asyncComputed: {
    async tasks() {
      const { siteKeyCompanies, rootUserData } =
        this.$store.state.firetableModule
      if (!siteKeyCompanies || !rootUserData) return []
      //Additional fields
      const tasks = await Promise.all(
        this.data.taskIDs.map(async (taskId: string) => {
          // Get tasks
          const task = await Util.getTask(db, this.siteKey, taskId)
          // const { assignedCompanyID } = task
          // //Company Data
          // const { siteKeyCompanies } = this.$store.state.firetableModule
          // const companyData = siteKeyCompanies.find(
          //   (co: SiteKeyCompany) => co.id === assignedCompanyID
          // )
          return {
            ...task,
          }
        })
      )
      this.loading = false
      return tasks
    },
  },
  methods: {
    destroy(): void {
      this.removeListeners()
    },
    buildColumns() {
      this.columns = [
        {
          title: "Title",
          dataIndex: "title",
          show: true,
          width: 350,
          scopedSlots: {
            customRender: "tskTitle",
          },
          sorter: (a, b) => a.title.length - b.title.length,
        },
      ]
    },
    openTask(task) {
      window.open(
        `/craft-record/${task.craftRecordID.split("/").pop()}/${task.id}`,
        "_blank"
      )
    },
  },
}
